import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { FlywheelIntro } from "~/components/onboarding/content";
import { routes } from "~/constants/routes";
import { useSideNavigation } from "~/contexts/SideNavigationContext";

export const GettingStarted: React.FC = () => {
  const { setHideMainSideNav } = useSideNavigation();
  const navigate = useNavigate();

  // On render of this page - always hide the main side nav
  useEffect(() => {
    setHideMainSideNav(true);
  }, [ setHideMainSideNav ]);

  return (
    <FlywheelIntro
      onNextStep={() => null}
      changeStep={() => null}
      onBack={() => navigate(routes.home)}
      nonOnboardingMode
      isGettingStartedPage
      overrideTitle="Introducing the flywheel"
      overrideSubtitle="Explore the key features of a flywheel"
    />

  );
};