import {
  useMemo,
  useState
} from "react";

import { ParentToggle } from "~/components/search-metrics/ParentToggle";
import { SearchBar } from "~/components/search-metrics/SearchBar";
import { SearchResult } from "~/components/search-metrics/SearchResult";
import { useCustomiseMetricDispatch } from "~/contexts/CustomiseMetricContext/CustomiseMetricContext";
import { CustomiseMetricStage } from "~/contexts/CustomiseMetricContext/metric-reducer";
import { deduplicateByName } from "~/utils/deduplicate-by-name";

import type { FlywheelTemplateMetric } from "@roda/shared/types";

interface SearchableMetricsProps {
  suggestedMetrics: FlywheelTemplateMetric[];
}
export const SearchableMetrics: React.FC<SearchableMetricsProps> = ({ suggestedMetrics }) => {
  const [ searchTerm, setSearchTerm ] = useState("");
  const dispatch = useCustomiseMetricDispatch();

  const sortedFilteredMetrics = useMemo(() => {
    // Checks if a string contains the search term (case-insensitive)
    const includesSearchTerm = (str: string) => str.toLowerCase().includes(searchTerm.toLowerCase());

    if (searchTerm.length === 0) {
      // Return a sorted copy of the entire suggestedMetrics array when the search term is empty
      return [
        ...suggestedMetrics.map(metric => ({
          ...metric,
          units: deduplicateByName(metric.units)
        }))
      ].sort((a, b) => a.name.localeCompare(b.name));
    }

    // Filter and sort the suggested metrics by the search term
    return suggestedMetrics
    // matches metric name or unit name
      .filter(metric => includesSearchTerm(metric.name) || metric.units
        .some(unit => includesSearchTerm(unit.name) || includesSearchTerm(unit.description)))
      // Filter by metric name if match, or unit names
      .map(res => ({
        ...res,
        units: deduplicateByName(includesSearchTerm(res.name) ? res.units : res.units.filter(u => includesSearchTerm(u.name) || includesSearchTerm(u.description)))
      }))
      // Sort alphabetically
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [ searchTerm, suggestedMetrics ]);

  return (
    <div className="flex flex-col px-3.5 py-4 border-solid border-brand-cold-metal-200 border-[1px] rounded-lg max-h-[50vh] overflow-hidden">
      <SearchBar
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />

      <div className="flex flex-col gap-y-2 overflow-y-auto mt-2">
        {sortedFilteredMetrics.length ? sortedFilteredMetrics.map((metric, idx) => (
          <ParentToggle
            key={searchTerm + metric.name + Number(idx)}
            title={metric.name}
            expand={!!searchTerm.length}
            children={metric.units?.map(unit => (
              <SearchResult
                key={unit.slug}
                metricUnit={unit}
                onClick={() => dispatch({
                  type: "SET_SELECTED_METRIC",
                  stage: CustomiseMetricStage.REVIEW,
                  metric: {
                    name: metric.name,
                    unitName: unit.name,
                    unitDisplay: unit.display,
                    unitDescription: unit.description,
                    unitTargetType: unit.targetType,
                    reportingWindowTiming: unit.reportingWindowTiming,
                    slug: unit.slug,
                    cap: unit.cap,
                    unitType: unit.type,
                    unitTypeLabel: unit.typeLabel
                  }
                })}
              />
            ))}
          />

        )) : (
          <div className="p-2 text-sm">
            <p>No metrics matched your search. Try searching for something else.</p>
          </div>
        )}
      </div>
    </div>
  );
};