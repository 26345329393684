/**
 * Function for cleaning a number input
 * It will only allow numbers and limits to 2 decimal places
 * @param value
 * @returns
 */
export const cleanNumberInput = (value: string): string => {
  // Remove leading/trailing whitespace
  value = value.trim();

  // Check if the input is empty
  if (value === "") {
    return "";
  }

  // Extract last character
  const lastChar = value.charAt(value.length - 1);
  // Check if the last character is a number
  const isNumber = /^\d$/.test(lastChar);
  // Count the number of decimal separators
  const decimalSeparatorCount = (value.match(/\./g) || []).length;
  // Check if the input contains only one decimal separator
  const onlyOneSeparator = decimalSeparatorCount === 1;
  // Check if the input contains two or fewer decimal places
  const decimalPart = value.split(".")[ 1 ];
  const hasValidDecimalPlaces = decimalPart ? decimalPart.length <= 2 : true;
  // Check if the input contains a decimal separator
  const hasDecimalSeparator = value.includes(".");

  // Return the cleaned input
  if ((isNumber || lastChar === ".") && onlyOneSeparator && hasValidDecimalPlaces) {
    return value;
  } else if (isNumber && !hasDecimalSeparator) {
    // Allow whole numbers if there isn't a decimal separator
    return value;
  } else {
    return value.slice(0, -1);
  }
};