import { Navigate } from "react-router-dom";

import { useRodaSubscription } from "~/contexts/SubscriptionContext";

interface ProtectedRouteProps {
  children: React.ReactNode;
  fallbackPath?: string;
}

/**
 * Protects routes by ensuring the current user's company is subscribed
 * @returns
 */
export const SubscriberRoute: React.FC<ProtectedRouteProps> = ({ children, fallbackPath = "/" }) => {
  const { activeOrTrialing } = useRodaSubscription();

  return activeOrTrialing ? <>{children}</> : (
    <Navigate
      replace
      to={fallbackPath}
    />
  );
};
