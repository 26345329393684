import { useTypedQuery } from "@roda/graphql/urql";

import {
  ReadOnlyUser,
  FlywheelGoal
} from "~/fragments/index";

type UseTypedQueryOpts = Omit<Parameters<typeof useTypedQuery>[0], "query">;
type Response = NonNullable<ReturnType<typeof useGetFlywheel>["0"]>["data"];
export type FlywheelResponseData = NonNullable<Response>["getFlywheel"];

export type GetFlywheelStepsDataArray = NonNullable<FlywheelResponseData>["steps"];
export type GetFlywheelStepData = NonNullable<GetFlywheelStepsDataArray>[0];

export type GetFlywheelMetricsDataArray = NonNullable<GetFlywheelStepData>["metrics"];
export type GetFlywheelMetricData = NonNullable<GetFlywheelMetricsDataArray>[0];

/**
 * Get a Flywheel by ID
 * @param flywheelId The flywheelId to fetch
 * @param opts UseTypedQueryOpts
 * @returns
 */
export const useGetFlywheel = (flywheelId?: number, opts?: UseTypedQueryOpts) => {
  return useTypedQuery({
    ...opts,
    query: {
      getFlywheel: {
        __args: { flywheelId: flywheelId! },
        id: true,
        name: true,
        companyId: true,
        deletedAt: true,
        ownerId: true,
        currency: true,
        flywheelTemplateId: true,
        timeframe: true,
        updateFrequency: true,
        setupComplete: true,
        steps: {
          id: true,
          name: true,
          ownerId: true,
          order: true,
          metrics: {
            id: true,
            name: true,
            owner: {
              id: true,
              firstName: true,
              lastName: true,
              email: true,
              avatarS3Key: true
            },
            stepId: true,
            unitName: true,
            unitType: true,
            unitTypeLabel: true,
            unitDisplay: true,
            unitDescription: true,
            unitTargetType: true,
            createdAt: true,
            cap: true,
            reportingWindowTiming: true,
            slug: true,
            targets: {
              id: true,
              metricId: true,
              target: true,
              createdBy: ReadOnlyUser,
              fromDate: true,
              toDate: true,
              isCurrent: true,
              createdAt: true,
              updatedAt: true
            },
            isCheckInDue: true,
            isHealthy: true,
            metricUpdates: {
              id: true,
              metricId: true,
              isHealthy: true,
              startDate: true,
              metricTargetId: true,
              metricTarget: {
                id: true,
                metricId: true,
                target: true,
                createdBy: ReadOnlyUser,
                fromDate: true,
                toDate: true,
                isNext: true,
                isCurrent: true,
                createdAt: true,
                updatedAt: true
              },
              notes: true,
              updatedBy: ReadOnlyUser,
              value: true,
              updatedAt: true,
              createdAt: true,
              __typename: true,
              __scalar: true
            },
            fromDate: true,
            toDate: true
          }
        },
        latestFlywheelGoal: FlywheelGoal
      }
    },
    pause: !flywheelId
  });
};