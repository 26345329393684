import { getLastSundayFromEndDate } from "@roda/shared/utils/getLastSundayFromStartDate";

import dayjs from "~/utils/dayjs";

import type { Dayjs } from "dayjs";

/**
 * Get an array of dates for the start dates of the weeks of the flywheel year
 * @param flywheelStartWeek the flywheel
 * @param untilNow an optional boolean - so we can limit to e.g. flywheel weeks until now
 * @returns an array of week start dates as strings
 */
export const getArrayOfFlywheelWeeks = (flywheelStartWeek: Dayjs | null, untilNow?: boolean) => {
  // If we have a latest flywheel goal with at least one subgoal
  if (flywheelStartWeek) {
    const flywheelEndDate = getLastSundayFromEndDate(flywheelStartWeek.startOf("month").add(1, "year").subtract(1, "day"));
    // If we only want weeks until now, calculate the number of weeks of the flywheel to include
    // Otherwise, default to the length of the flywheel year - which could be 52 or 53 weeks
    const weeksDiff = untilNow ? dayjs().diff(flywheelStartWeek, "week") : (flywheelEndDate.diff(flywheelStartWeek, "weeks") + 1);
    // Fill an array with the start dates of each week of the flywheel year (until the optional current week)
    // We get the flywheel start date from the start date of its first subgoal
    const weeks = Array(weeksDiff).fill(0).map((_, i) => dayjs(flywheelStartWeek).add(i, "weeks").format("YYYY-MM-DD"));

    return weeks;
  }

  return [];
};