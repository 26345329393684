import { useState } from "react";

import { Icon } from "~/components/Icon";

import type { PropsWithChildren } from "react";

interface ParentToggle {
  title: string;
  expand: boolean;
}
export const ParentToggle: React.FC<PropsWithChildren<ParentToggle>> = ({
  title, children, expand
}) => {
  const [ isExpanded, setIsExpanded ] = useState(expand);

  return (
    <div
      className="flex flex-col m-2"
    >
      <div
        className="flex flex-row cursor-pointer items-center gap-x-1"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div
          style={{ transition: "transform 500ms cubic-bezier(0.86, 0.14, 0.06, 0.92)" }}
          className={`${isExpanded ? "rotate-180" : "rotate-0"}`}
        >
          <Icon.ChevronDown size={20} />
        </div>

        <p>{title}</p>
      </div>

      {isExpanded && (
        <div
          className="transition-max-height duration-500 ease-out overflow-hidden"
          style={{ transition: "max-height 0.5s cubic-bezier(0.86, 0.14, 0.06, 0.92)" }}
        >{children}
        </div>
      )}
    </div>
  );
};