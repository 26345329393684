import { User } from "~/fragments/index";
import { MetricTarget } from "~/fragments/metricTarget";

import type { MetricUpdateGenqlSelection } from "@roda/graphql/genql";

export const MetricUpdate: MetricUpdateGenqlSelection = {
  id: true,
  metricId: true,
  isHealthy: true,
  startDate: true,
  metricTargetId: true,
  metricTarget: MetricTarget,
  notes: true,
  updatedBy: { ...User },
  value: true,
  updatedAt: true,
  createdAt: true,
  __typename: true,
  __scalar: true
};
