import { CheckInBadge } from "~/components/check-in/CheckInBadge";
import { Icon } from "~/components/Icon";
import dayjs from "~/utils/dayjs";
import { getWeekMetricUpdate } from "~/utils/getWeekMetricUpdate";

import type { Metric } from "@roda/graphql/genql";
import type { Dayjs } from "dayjs";

interface MetricCheckInBadgeProps {
  metric: Metric;
  weekStart: Dayjs;
  weekEnd: Dayjs;
  flywheelCycleNotStarted: boolean;
}

export const MetricCheckInBadge: React.FC<MetricCheckInBadgeProps> = ({
  metric, weekStart, weekEnd, flywheelCycleNotStarted
}) => {
  const updateForWeek = metric.metricUpdates ? getWeekMetricUpdate(metric.metricUpdates, weekStart, weekEnd) : null;

  if (flywheelCycleNotStarted) {
    return null;
  }

  // If there's an update for the week being displayed, show the checked-in badge
  if (updateForWeek) {
    return (
      <CheckInBadge text="Checked-in" />
    );
  }

  // If the target week start is last week, show a check-in due badge if there's a check-in due for
  // this metric
  if (weekStart.isBetween(dayjs().startOf("isoWeek").subtract(1, "week"), dayjs().endOf("isoWeek").subtract(1, "week"), null, "[]") && metric.isCheckInDue) {
    return (
      <CheckInBadge
        text="Check-in due"
        icon={<Icon.Clock className="w-2.5" />}
        bgColour="bg-brand-check-in-due-100"
      />
    );
  }

  // For the current week, show a grey "check in on Monday" badge - because you're always able to
  // check in the week BEFORE the current one
  if (weekStart.isBetween(dayjs().startOf("isoWeek"), dayjs().endOf("isoWeek"), null, "[]")) {
    return (
      <CheckInBadge
        text="Check-in on Monday"
      />
    );
  }

  return null;
};