import { zodResolver } from "@hookform/resolvers/zod";
import { FlywheelTemplateUnitTypeLabelEnum } from "@roda/shared/types";
import { useMemo } from "react";
import { useForm } from "react-hook-form";

import { Button } from "~/components/Button";
import { SelectInput } from "~/components/form";
import { FlywheelCycleSchema } from "~/components/onboarding/content/formSchemas";
import type { OnboardingContentStepProps } from "~/components/onboarding/OnboardingContent";
import { OnboardingContentWrapper } from "~/components/onboarding/OnboardingContentWrapper";
import { Spacer } from "~/components/Spacer";
import { Card } from "~/components/Timeline";
import { Tag } from "~/components/Timeline/Tag";
import { TimelineLayout } from "~/components/Timeline/TimelineLayout";
import {
  useOnboardingDispatch,
  useOnboardingState
} from "~/contexts/OnboardingContext/OnboardingContext";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useIsMobile } from "~/hooks/useIsMobile";
import { breakDownSubgoals } from "~/utils/breakDownSubgoals";
import { cn } from "~/utils/cn";
import { constructUtcDate } from "~/utils/dates/constructUtcDate";
import dayjs from "~/utils/dayjs";

import type { z } from "zod";

export const ReportingCycle: React.FC<OnboardingContentStepProps> = ({
  onNextStep, changeStep, onBack, createFlywheelMode
}) => {
  const isMobile = useIsMobile();
  const { flywheel } = useSelectedFlywheel();
  const { flywheelGoal: onboardingFlywheelGoal } = useOnboardingState();
  const dispatch = useOnboardingDispatch();

  // Get an existing flywheel goal - if in createFlywheelMode always use the onboardingFlywheelGoal
  const existingFlywheelGoal = useMemo(() => createFlywheelMode ? onboardingFlywheelGoal : onboardingFlywheelGoal || flywheel?.latestFlywheelGoal, [
    createFlywheelMode,
    flywheel?.latestFlywheelGoal,
    onboardingFlywheelGoal
  ]);

  const thisMonth = dayjs().startOf("month");
  // Create an array of 11 future months - starting from next month. These are the months when the
  // current flywheel cycle could be completed. There's 11 (not 12) because the current month has to be included in
  // the current flywheel cycle - so 12 months = this month + 11 more
  const availableFlywheelCycleCompletionDates = Array(11).fill(0).map((_, index) => thisMonth.add(index + 1, "months").endOf("month").toString());
  // Get the default achieve by date from onboarding state, flywheel state or a backup of 1 year from now
  const defaultAchieveByDate = useMemo(() => existingFlywheelGoal?.achieveBy ? dayjs(existingFlywheelGoal.achieveBy).toDate() : thisMonth.add(11, "months").endOf("month").millisecond(0).toDate(), [ existingFlywheelGoal?.achieveBy, thisMonth ]);

  const {
    handleSubmit,
    watch,
    setValue
  } = useForm<z.infer<typeof FlywheelCycleSchema>>({
    resolver: zodResolver(FlywheelCycleSchema),
    defaultValues: { achieveByDate: defaultAchieveByDate },
    shouldFocusError: false
  });

  const watchedAchieveByDate = watch("achieveByDate");

  const handleFormSubmit = handleSubmit(values => {
    if (values.achieveByDate) {
      // Update the flywheel goal details in onboarding state
      dispatch({
        type: "SET_FLYWHEEL_GOAL_REPORTING_CYCLE",
        achieveBy: constructUtcDate(values.achieveByDate)
      });
      // Move to the next step
      onNextStep();
    }
  });

  const quarterlyGoals = breakDownSubgoals({
    achieveByDate: watchedAchieveByDate || defaultAchieveByDate,
    mainGoal: "1000000", // doesn't matter here
    timeframe: "QUARTERLY",
    typeLabel: FlywheelTemplateUnitTypeLabelEnum.CURRENCY // doesn't matter
  });

  const formatDateRange = (goal: typeof quarterlyGoals[number]) => `${goal.startDate ? dayjs(goal.startDate).format("MMMM YYYY") : "N/A"} – ${goal.endDate ? dayjs(goal.endDate).format("MMMM YYYY") : "N/A"}`;
  const isInQuarter = (quarter: typeof quarterlyGoals[number]) => dayjs().isBetween(dayjs(quarter.startDate).startOf("day"), dayjs(quarter.endDate).endOf("day"));

  return (
    <OnboardingContentWrapper
      createFlywheelMode={createFlywheelMode}
      hideLogo={createFlywheelMode}
      changeStep={changeStep}
      leftContent={(
        <div className="w-full flex flex-col flex-1">
          <form
            onSubmit={handleFormSubmit}
            className="flex flex-col flex-1"
          >

            <SelectInput
              value={watchedAchieveByDate?.toString() || thisMonth.add(1, "month").toString()}
              onValueChange={value => setValue("achieveByDate", dayjs(value).toDate())}
              label="When do you want this flywheel cycle to be completed?"
              placeholder="*Pick a completion month*"
              options={availableFlywheelCycleCompletionDates}
              renderOptionLabel={value => dayjs(value).format("MMMM YYYY")}
            />

            {isMobile && (<Spacer />)}

            <div className={`flex gap-x-3 self-start pb-5 ${isMobile ? "flex-col w-full gap-3" : "flex-row"} mt-10`}>
              {onBack && (
                <Button
                  title="Back"
                  onClick={onBack}
                  className="bg-brand-cold-metal-200 hover:contrast-75 text-brand-cold-metal-900"
                />
              )}

              <Button
                title="Next"
                type="submit"
                className={`px-6 ${isMobile ? "order-first" : ""}`}
              />
            </div>

          </form>

        </div>
      )}
      rightContent={(
        <div className="w-full h-full lg:grid place-items-center">
          <TimelineLayout
            orientation={isMobile ? "horizontal" : "vertical"}
            rowGap={isMobile ? "14" : undefined /* defaults to 60, ideal for desktop */}
            items={new Array(4).fill(null).map((_, i, { length }) => ({
              renderRight: (
                <Card.TimelineItem
                  size={isMobile ? "lg" : "md"}
                  title={`Quarter ${i + 1}`}
                  subheading={formatDateRange(quarterlyGoals[ i ])}
                  badge={isMobile && isInQuarter(quarterlyGoals[ i ]) ? "Now" : undefined}
                  className={cn(isMobile && "mb-0.5")}
                />
              ),
              renderLeft: Boolean(i === length - 1 || isInQuarter(quarterlyGoals[ i ])) && (
                <div
                  className="flex flex-col items-start gap-2 relative"
                  aria-hidden='true'
                >
                  {(!isMobile && isInQuarter(quarterlyGoals[ i ])) && (
                    <Tag className="[&:not(:only-of-type)]:absolute [&:not(:only-of-type)]:-translate-y-full [&:not(:only-of-type)]:-top-2">
                      Now
                    </Tag>
                  )}

                  {i === length - 1 && (
                    <Tag>
                      End
                    </Tag>
                  )}
                </div>
              ) || undefined
            }))}
          />
        </div>
      )}
    />
  );
};
