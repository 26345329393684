import {
  createContext,
  useContext
} from "react";

import { STORAGE_KEYS } from "~/constants/storageKeys";
import { usePersistReducer } from "~/hooks/usePersistedReducer";

import {
  initialState,
  flywheelGoalReducer
} from "./flywheel-goal-reducer";

import type {
  State,
  TransformActions
} from "./flywheel-goal-reducer";
import type {
  Dispatch,
  PropsWithChildren
} from "react";

// Create contexts for state
const CustomiseFlywheelGoalContext = createContext<State>(initialState);

// Create contexts for actions
const CustomiseFlywheelGoalActionsContext = createContext<Dispatch<TransformActions>>(
  {} as Dispatch<TransformActions> // Safe to typecast we'll never access the initial value
);

// Wraps the providers for both state & action contexts, injecting the reducer to each
export function CustomiseFlywheelGoalProvider({ children }: PropsWithChildren) {
  const [ state, dispatch ] = usePersistReducer(
    STORAGE_KEYS.LOCAL_STORAGE.FLYWHEEL_GOAL,
    flywheelGoalReducer,
    initialState
  );

  return (
    <CustomiseFlywheelGoalContext.Provider value={state}>
      <CustomiseFlywheelGoalActionsContext.Provider value={dispatch}>
        {children}
      </CustomiseFlywheelGoalActionsContext.Provider>
    </CustomiseFlywheelGoalContext.Provider>
  );
}

// Make the state a usable hook
export const useCustomiseFlywheelGoalState = () => useContext(CustomiseFlywheelGoalContext);

// Make the actions to alter state a usable hook
export const useCustomiseFlywheelGoalDispatch = () => useContext(CustomiseFlywheelGoalActionsContext);