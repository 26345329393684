import { withStepTheme } from "~/components/flywheel/roda/RodaFlywheel";
import { HealthStatus } from "~/components/flywheel/roda/types";
import type { StepType } from "~/contexts/OnboardingContext/onboarding-reducer";
import dayjs from "~/utils/dayjs";
import { getHealthStatusColour } from "~/utils/getHealthStatusColour";

import type { MetricType } from "@roda/core/metric";
import type { Step } from "@roda/graphql/genql";
import type { Dayjs } from "dayjs";

// metric and name
export type LooseStep<S extends Step | StepType> = Omit<S, "metrics" | "name"> & Pick<S, "metrics" | "name">;
type ReviewMetricAttributes = Pick<MetricType, "toDate" | "fromDate" | "reviewers">;

/** Each individual metric has their own status (waiting/healthy/attention) */
export const ReviewStatusDots = ({ step, subgoalStartDate }: {
  step: {metrics: ReviewMetricAttributes[]},
  subgoalStartDate: Dayjs
}) => {
  const MAX_METRICS = 6;
  const DOT_SIZE = 6;
  const DOT_SPACING = 4;

  // Function for returning the health status of a metric
  const getMetricReviewStatus = (metric: Pick<MetricType, "toDate" | "fromDate" | "reviewers">): HealthStatus => {
    // Metric is disabled if its toDate is before the start of the quarter we're reviewing
    const isDisabled = metric.toDate && dayjs(metric?.toDate).isBefore(subgoalStartDate);
    // Metric is new if its fromDate is the same as the start of the quarter we're reviewing
    const isNew = dayjs(metric.fromDate).isSame(subgoalStartDate, "day");

    // If the metric is disabled OR the current user has reviewed, set waiting status (grey)
    // If a review has been done, set healthy status (green)
    // Otherwise, set check in due status (blue)
    return isNew ? HealthStatus.Healthy : isDisabled || metric.reviewers?.length ? HealthStatus.Waiting : HealthStatus.CheckInDue;
  };

  return (
    <div className="flex">
      {/* I've had to use SVG circles here because <div> would have weird sizing/spacing defects, presumably some pixel alignment issue on my non-retina screen */}
      {step.metrics?.slice(0, MAX_METRICS).map((metric, i) => {
        const status: HealthStatus = getMetricReviewStatus(metric);
        // Metric is disabled if its toDate is before the start of the quarter we're reviewing
        const isDisabled = metric.toDate && dayjs(metric?.toDate).isBefore(subgoalStartDate);

        return (
          <svg
            key={i.toLocaleString()}
            style={{
              ...withStepTheme(getHealthStatusColour(status)),
              opacity: isDisabled ? 0.6 : 1,
              width: DOT_SIZE + DOT_SPACING,
              height: DOT_SIZE + 2 // +2 fixes another pixel issue
            } as React.CSSProperties}
          >
            <circle
              cx={(DOT_SIZE + DOT_SPACING) / 2}
              cy={DOT_SIZE / 2}
              r={DOT_SIZE / 2}
              fill="var(--step-theme-medium)"
            />
          </svg>
        );
      })}
    </div>
  );
};