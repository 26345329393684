import { useState } from "react";

import { CheckInBadge } from "~/components/check-in/CheckInBadge";
import { Icon } from "~/components/Icon";
import type {
  OnClickRequiredProps,
  NavItemProps
} from "~/components/side-navigation/components/NavItem";
import { useIsMobile } from "~/hooks/useIsMobile";

interface ExpandableNavItemProps {
  expandableOptions?: React.ReactElement[]
}

// Requires either href or onClick or both
type ExpandableNavItemType = NavItemProps & ExpandableNavItemProps & OnClickRequiredProps;

export const ExpandableNavItem: React.FC<ExpandableNavItemType> = ({
  icon, label, banner, expandableOptions, onClick
}) => {
  const isMobile = useIsMobile();
  const [ isExpanded, setIsExpanded ] = useState(false);

  return (
    <>
      <div
        onClick={() => onClick?.()}
        className={`${isMobile ? "p-0 gap-x-4" : "p-2 mx-2 justify-between"} cursor-pointer text-sm text-brand-cold-metal-600 text-start flex items-center gap-1.5 font-medium rounded-lg group`}
      >
        <div
          className="flex flex-row gap-x-2 justify-center items-center"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <span className="group-disabled:opacity-50">{icon}</span>

          <div className="flex flex-row items-center justify-center gap-x-1">
            <span className="group-disabled:opacity-50">{label}</span>

            {isExpanded ? (
              <Icon.ChevronUp
                className="pt-0.5 text-brand-cold-metal-400"
              />
            ) : (
              <Icon.ChevronDown
                className="pt-0.5 text-brand-cold-metal-400"
              />
            )}

          </div>

        </div>

        {banner && (
          <CheckInBadge
            text={banner.bannerText}
            icon={<Icon.Clock className="w-2.5" />}
            bgColour="bg-brand-check-in-due-100"
          />
        )}

      </div>

      {expandableOptions?.length && isExpanded && (
        <div className="flex flex-col">
          {expandableOptions}
        </div>
      )}

    </>
  );
};