import { useTypedMutation } from "@roda/graphql/urql";

import { Metric } from "~/fragments/metric";

import type { MutationGenqlSelection } from "@roda/graphql/genql";

export type UpdateMetricParams = NonNullable<MutationGenqlSelection["updateMetric"]>["__args"];

export const useUpdateMetric = () => {
  return useTypedMutation((vars: UpdateMetricParams) => ({
    updateMetric: {
      __args: vars,
      ...Metric
    }
  }));
};