import type * as React from "react";

type NoWrapProps = {
  /**
	 * @default undefined
	 * @description
	 * `undefined` = No wrapping (potentially overflow)
	 *
	 * `true` = Allow wrapping at any character
	 *
	 * `number | ((v: string) => number)` = Wrap at specific index if necessary
	 *
	 * @example
	 * ```tsx
	 * // Allow wrapping at appropriate character
	 * <NoWrap wrap>...<NoWrap />
	 * // Allow wrapping at 5th character
	 * <NoWrap wrap={5}>...<NoWrap />
	 * // Allow wrapping at '@'
	 * <NoWrap wrap={(v) => v.indexOf("@")}>...<NoWrap />
	 * ```
	*/
  wrap?: true | number | ((str: string) => number);
};

/**
 * Sometimes with text-wrapping its desired to ensure a string of words appear
 * on the same line, or to avoid the one word on a line scenario
 * @example
 * // OH NO
 * big
 * apple
 * // WINNING
 * big apple
 */
export const NoWrap: React.FC<React.ComponentProps<"span"> & NoWrapProps> = ({
  wrap,
  ...props
}) => (
  <span
    {...props}
    style={{
      ...props.style,
      ...(!wrap ? { whiteSpace: "nowrap" } : { overflowWrap: "break-word" })
    }}
  >
    {typeof props.children === "string" && wrap && typeof wrap !== "boolean" ? (
      <>
        {props.children.slice(0, typeof wrap === "function" ?
          wrap(props.children) :
          wrap)}

        <wbr />

        {props.children.slice(typeof wrap === "function" ?
          wrap(props.children) :
          wrap)}
      </>
    ) :
      props.children}
  </span>
);
