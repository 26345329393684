import { zodResolver } from "@hookform/resolvers/zod";
import { FlywheelTemplateReportingWindowTimingEnum } from "@roda/shared/types";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "~/components/Button";
import { SelectInput } from "~/components/form";
import { Spacer } from "~/components/Spacer";
import {
  useCustomiseMetricDispatch,
  useCustomiseMetricState
} from "~/contexts/CustomiseMetricContext/CustomiseMetricContext";
import { CustomiseMetricStage } from "~/contexts/CustomiseMetricContext/metric-reducer";
import { useIsMobile } from "~/hooks/useIsMobile";

export const Schema = z.object({
  unitDisplay: z.string().trim().min(1, "Required").max(50, "Maximum 50 characters"),
  unitTargetType: z.string().trim().min(1, "Required").max(50, "Maximum 50 characters")
});

export const CustomiseMetricDisplay: React.FC = () => {
  const { metric } = useCustomiseMetricState();
  const setCustomiseMetricDispatch = useCustomiseMetricDispatch();
  const isMobile = useIsMobile();

  const {
    watch,
    setValue,
    handleSubmit,
    clearErrors,
    formState: { errors }
  } = useForm<z.infer<typeof Schema>>({
    resolver: zodResolver(Schema),
    defaultValues: {
      unitDisplay: metric?.unitDisplay ?? "quarterly",
      unitTargetType: metric?.unitTargetType ?? "exceed"
    },
    shouldFocusError: false
  });

  // Submit handler
  const onSubmit = handleSubmit(() => {
    setCustomiseMetricDispatch({
      type: "SET_SELECTED_METRIC",
      stage: CustomiseMetricStage.REVIEW,
      metric
    });
  });

  useEffect(() => {
    setCustomiseMetricDispatch({
      type: "SET_SELECTED_METRIC",
      stage: CustomiseMetricStage.CUSTOMISE_DISPLAYS,
      metric: {
        ...metric,
        unitDisplay: metric?.unitDisplay || "quarterly",
        unitTargetType: metric?.unitTargetType || "exceed"
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="h-full w-full">
      <div className="flex flex-col gap-y-2 mb-6 w-full">
        <p className={`font-bold ${isMobile ? "text-xl" : "text-2xl"}`}>Create metric</p>

        <p className="">Set target and display settings</p>
      </div>

      <form
        onSubmit={onSubmit}
        className="flex pt-5 flex-col gap-y-5 flex-1"
      >
        <div className="flex flex-col gap-y-1">
          <SelectInput
            value={watch("unitDisplay")}
            onValueChange={val => {
              setValue("unitDisplay", val);

              setCustomiseMetricDispatch({
                type: "SET_SELECTED_METRIC",
                stage: CustomiseMetricStage.CUSTOMISE_DISPLAYS,
                metric: {
                  ...metric,
                  unitDisplay: val
                }
              });
            }}
            label="Graph type"
            placeholder="Pick a chart type"
            options={[ "weekly", "quarterly" ]}
            renderOptionLabel={item => {
              if (item === "weekly") {
                return "Bar chart";
              } else {
                return "Progress bar";
              }
            }}
            hasError={!!errors.unitDisplay}
            errorMessage={errors.unitDisplay?.message}
            clearFormError={() => clearErrors("unitDisplay")}
            tooltipInfo="How do you want to display your check-in data?"
          />

        </div>

        <div className="flex flex-col gap-y-1">
          <SelectInput
            value={watch("unitTargetType")}
            onValueChange={val => {
              setValue("unitTargetType", val);

              setCustomiseMetricDispatch({
                type: "SET_SELECTED_METRIC",
                stage: CustomiseMetricStage.CUSTOMISE_DISPLAYS,
                metric: {
                  ...metric,
                  unitTargetType: val
                }
              });
            }}
            label="Target type"
            placeholder="Pick your target type"
            options={[ "exceed", "below" ]}
            renderOptionLabel={item => {
              if (item === "exceed") {
                return `Aim to hit or exceed target ${metric?.reportingWindowTiming === FlywheelTemplateReportingWindowTimingEnum.THIS_WEEK ? "each week" : "each quarter"}`;
              } else {
                return `Aim to be below the target ${metric?.reportingWindowTiming === FlywheelTemplateReportingWindowTimingEnum.THIS_WEEK ? "each week" : "each quarter"}`;
              }
            }}
            hasError={!!errors.unitTargetType}
            errorMessage={errors.unitTargetType?.message}
            clearFormError={() => clearErrors("unitTargetType")}
            tooltipInfo="Decide how you want your target to behave and validate your check-ins."
          />

        </div>

        <div className={`flex mt-5 ${isMobile ? "flex-col flex-1" : "flex-row"} justify-between`}>
          {isMobile && (<Spacer />)}

          <div className={`flex gap-x-3 self-start ${isMobile ? "flex-col w-full gap-3" : "flex-row"}`}>

            <Button
              title="Back"
              onClick={() => {
                setCustomiseMetricDispatch({
                  type: "SET_STAGE",
                  stage: CustomiseMetricStage.CUSTOMISE_UNITS
                });
              }}
              className="bg-brand-cold-metal-100 hover:contrast-75 w-full text-brand-cold-metal-900 px-6"
            />

            <Button
              title="Done"
              type="button"
              className={`px-10 ${isMobile ? "order-first" : ""}`}
              onClick={() => {
                onSubmit();
              }}
            />

          </div>

        </div>

      </form>

    </div>
  );
};