import { CheckInBadge } from "~/components/check-in/CheckInBadge";
import { Icon } from "~/components/Icon";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import dayjs from "~/utils/dayjs";
import { getMonthFlywheelGoalUpdate } from "~/utils/getMonthFlywheelGoalUpdate";

import type { Subgoal } from "@roda/graphql/genql";
import type { Dayjs } from "dayjs";

interface FlywheelGoalCheckInBadgeProps {
  monthStart: Dayjs;
  monthEnd: Dayjs;
  subgoal: Subgoal | undefined;
}

export const FlywheelGoalCheckInBadge: React.FC<FlywheelGoalCheckInBadgeProps> = ({
  monthStart, monthEnd, subgoal
}) => {
  const { flywheel } = useSelectedFlywheel();
  const flywheelGoalUpdates = flywheel?.latestFlywheelGoal?.subgoals ? flywheel.latestFlywheelGoal.subgoals.flatMap(subgoal => subgoal.updates) : [];
  const updateForMonth = flywheelGoalUpdates ? getMonthFlywheelGoalUpdate(flywheelGoalUpdates, monthStart, monthEnd) : null;

  // If there's an update for the week being displayed, show the checked-in badge
  if (updateForMonth) {
    return (
      <CheckInBadge text="Checked-in" />
    );
  }

  // If the target month start is last month, show a check-in due badge if there's a check-in due for
  // the flywheel goal
  if (monthStart.isSame(dayjs().startOf("month").subtract(1, "month"), "month") && subgoal?.isCheckInDue) {
    return (
      <CheckInBadge
        text="Check-in due"
        icon={<Icon.Clock className="w-2.5" />}
        bgColour="bg-brand-check-in-due-100"
      />
    );
  }

  // For the current month, show a grey "check in next month" badge - because you're always able to
  // check in the month BEFORE the current one
  if (monthStart.isBetween(dayjs().startOf("month"), dayjs().endOf("month"), null, "[]")) {
    return (
      <CheckInBadge
        text="Check-in next month"
      />
    );
  }

  return null;
};