import {
  Fragment,
  cloneElement
} from "react";

import { useForceScrollHorizontal } from "~/hooks/useForceScrollHorizontal";
import { cn } from "~/utils/cn";

interface TimelineLayoutProps {
  items: Array<{
    // optionally consume as functions so that index can be used
    renderLeft?: React.ReactElement | ((index: number) => React.ReactElement);
    renderRight: React.ReactElement | ((index: number) => React.ReactElement);
  }>;
  /** extra height between points */
  rowGap?: string;
  /** extra width between point-dots and their left/right content */
  columnGap?: string;
  orientation?: "vertical" | "horizontal";
}

export const TimelineLayout: React.FC<TimelineLayoutProps> = ({
  items, columnGap = "24", rowGap = "60", orientation = "vertical"
}) => {
  const vertical = orientation === "vertical";

  // I'm laying things out in a grid-row like so:
  //
  // [  TAG  ][  LINE  ][  DOT  ][  LINE  ][  TILE  ]
  // ^---conditional---^
  //
  // ...where tag is optional, therefore the line next to it is only
  // conditionally rendered.
  //
  // (For mobile, aka horizontal)
  //
  // [   TAG   ] <- conditional
  // [   TILE  ]
  // [   DOT   ]
  //
  // if at least one item has a renderLeft, then we need to add extra cell(s)
  const cell_count_per_item = vertical ?
    items.some(item => item.renderLeft) ? 5 : 3 :
    items.some(item => item.renderLeft) ? 3 : 2;

  const scrollRef = useForceScrollHorizontal<HTMLUListElement>(!vertical);

  // ------- HORIZONTAL DESIGN (MOBILE SCROLLER) ---

  if (orientation === "horizontal") {
    return (
      <div
        className="relative h-full overflow-hidden [--border-color:theme(colors.brand.cold-metal-300)]"
        style={{ "--margin": `calc(${Number.isNaN(Number(columnGap)) ? columnGap : columnGap + "px"} / 2)` } as React.CSSProperties}
      >
        <ul
          className="absolute inset-0 w-full h-full overflow-x-auto grid grid-flow-col snap-x snap-mandatory snap-always *:snap-center items-center border-color:[--border-color] px-[calc(((100dvw-280px)/2)_-_var(--margin))] md:px-[--margin] scrollbar-thin scroll-smooth"
          ref={scrollRef}
          style={{
            gridTemplateColumns: `repeat(${items.length}, 1fr)`,
            gridTemplateRows: `repeat(${cell_count_per_item}, min-content)`,
            rowGap: Number.isNaN(Number(rowGap)) ? rowGap : rowGap + "px",
            scrollbarColor: "rgba(0,0,0,0.1) transparent"
          }}
        >
          {items.map((child, index, { length }) => {
            const isFirst = index === 0;
            const isLast = index === length - 1;

            const branchLine = (
              <span
                className={cn("h-px w-full text-[--border-color] flex-1 from-transparent to-current bg-current", {
                  "first:bg-gradient-to-r first:![background-color:unset]": isFirst,
                  "last:bg-gradient-to-l last:![background-color:unset]": isLast
                })}
              />
            );

            return (
              <Fragment key={index.toLocaleString()}>
                {!!child.renderLeft && (
                  <>
                    {cloneElement(typeof child.renderLeft === "function" ? child.renderLeft(index) : child.renderLeft, {
                      style: {
                        gridRowStart: 1,
                        justifySelf: "center" // push as far as possible to butt against line,
                      }
                    })}
                  </>
                )}

                {!!child.renderRight && cloneElement(typeof child.renderRight === "function" ? child.renderRight(index) : child.renderRight, {
                  style: {
                    // ensure px is added to unit if it's only a number
                    marginInline: "var(--margin)",
                    gridRowStart: cell_count_per_item - 1,
                    alignSelf: "stretch"
                  }
                })}

                {/* DOT & VERTICAL LINES */}
                <div
                  className="flex flex-center"
                  style={{ gridRowStart: cell_count_per_item }}
                >
                  {branchLine}

                  {/* DOT */}
                  <span
                    className="shrink-0 w-[12px] h-[12px] rounded-full bg-[--border-color] mx-2"
                  />

                  {branchLine}
                </div>

              </Fragment>
            );
          })}
        </ul>
      </div>
    );
  }

  // ------- VERTICAL DESIGN (DESKTOP) ---

  return (
    <>
      <div className="[--border-color:theme(colors.brand.cold-metal-300)] relative">
        <ul
          className="grid items-center justify-center relative border-color:[--border-color] grid-flow-row"
          style={{ gridTemplateColumns: `repeat(${cell_count_per_item}, max-content)` }}
        >
          {items.map((child, index, { length }) => {
            const isFirst = index === 0;
            const isLast = index === length - 1;

            const treeLine = (
              <span
                className={cn("w-px h-full text-[--border-color] flex-1 from-transparent to-current bg-current", {
                  "first:bg-gradient-to-b first:![background-color:unset]": isFirst,
                  "last:bg-gradient-to-t last:![background-color:unset]": isLast
                })}
              />
            );

            const branchLine = (colStart: number) => (
              <span
                className="h-px bg-[--border-color]"
                style={{
                  gridColumnStart: colStart,
                  width: Number.isNaN(Number(columnGap)) ? columnGap : columnGap + "px"
                }}
              />
            );

            return (
              <Fragment key={index.toLocaleString()}>
                {!!child.renderLeft && (
                  <>
                    {cloneElement(typeof child.renderLeft === "function" ? child.renderLeft(index) : child.renderLeft, {
                      style: {
                        gridColumnStart: 1,
                        justifySelf: "end" // push as far to right as possible to butt against line
                      }
                    })}

                    {branchLine(cell_count_per_item - 3)}
                  </>
                )}

                {/* DOT & VERTICAL LINES */}
                <div
                  className="w-fit h-full flex-col flex flex-center"
                  style={{ gridColumnStart: cell_count_per_item - 2 }}
                  aria-hidden="true"
                >
                  {treeLine}

                  {/* DOT */}
                  <span
                    className="shrink-0 w-[9px] h-[9px] rounded-full bg-[--border-color] m-1"
                  />

                  {treeLine}

                </div>

                {branchLine(cell_count_per_item - 1)}

                {!!child.renderRight && cloneElement(typeof child.renderRight === "function" ? child.renderRight(index) : child.renderRight, {
                  style: {
                    gridColumnStart: cell_count_per_item /* always pin to the last column */,
                    // ensure px is added to unit if it's only a number
                    marginBlock: `calc(${Number.isNaN(Number(rowGap)) ? rowGap : rowGap + "px"} / 2)`,
                    justifySelf: "start" // push as far to left as possible to butt against line
                  }
                })}
              </Fragment>
            );
          })}
        </ul>
      </div>
    </>
  );
};