import { formatNumber } from "~/utils/formatNumber";
import { CurrencyIcon } from "~/utils/getCurrencyIcon";

// `any` seems to do what I want here and maintains type signature, whereas `unknown` would not..
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyFn = (...args: any[]) => any;

/** A helper just to quickly identify a function in conjunction with the {@link MaybeFunctionalProps} type */
function isFn<T>(value: T | AnyFn): value is AnyFn {
  return typeof value === "function";
}

type Casing = "uppercase" | "lowercase" | "title";

/**
(!) Formatting may introduce rounded results, and therefore should NEVER be used
for calculations or comparisons. It should only be used for display purposes.
*/
export const formatGoalNumber = (
  number: number,
  icon: string,
  opts?: MaybeFunctionalProps<{
    /** i.e. 100M versus 100,000,000 */
    shouldCompact?: boolean,
    stripTrailingZeros?: boolean,
    maxSignificantDigits?: number
    /** @default "lowercase" */
    casing?: Casing
  }, number>
) => {
  const currency = getNumberFormatCurrencyFromIcon(icon);
  const casing = (opts?.casing ? isFn(opts.casing) ? opts.casing(number) : opts.casing : "lowercase") as Casing;

  const computed = formatNumber(getNumberFormatStyleFromIcon(icon) === "percent" ? number / 100 : number, {
    style: getNumberFormatStyleFromIcon(icon),
    currency,
    notation: val => (opts?.shouldCompact && (isFn(opts.shouldCompact) ? opts.shouldCompact(number) : true)) && (val >= 1_000_0 || (String(val).length >= 6)) ? "compact" : undefined,
    maximumSignificantDigits: isFn(opts?.maxSignificantDigits) ? opts?.maxSignificantDigits(number) : opts?.maxSignificantDigits,
    trailingZeroDisplay: isFn(opts?.stripTrailingZeros) ? (opts?.stripTrailingZeros(number) ? "stripIfInteger" : undefined) : (opts?.stripTrailingZeros ? "stripIfInteger" : undefined),
    // lets be sure not to round-up monetary values
    roundingMode: currency ? "floor" : undefined
  });

  if (casing === "uppercase") return computed;

  if (casing === "title") return computed.split(" ").map(part => part.charAt(0).toLocaleUpperCase() + part.slice(1)).join(" ");

  return computed.toLocaleLowerCase();
};

const getNumberFormatStyleFromIcon = (icon: string) => {
  switch (icon) {
    case CurrencyIcon.GBP:
    case CurrencyIcon.USD:
    case CurrencyIcon.EUR: return "currency";
    case "%": return "percent";
    default: return "decimal";
  }
};

const getNumberFormatCurrencyFromIcon = (icon: string) => {
  switch (icon) {
    case CurrencyIcon.GBP: return "GBP";
    case CurrencyIcon.USD: return "USD";
    case CurrencyIcon.EUR: return "EUR";
    default: return undefined;
  }
};