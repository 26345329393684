import { InfoTooltip } from "~/components/InfoTooltip";
import { useIsMobile } from "~/hooks/useIsMobile";

import type React from "react";
import type { PropsWithChildren } from "react";

interface InputLabelProps {
  extraInfo?: string;
}

export const InputLabel: React.FC<PropsWithChildren<{ htmlFor: string }> & InputLabelProps> = ({
  children,
  htmlFor,
  extraInfo
}) => {
  const isMobile = useIsMobile();

  return (
    <label
      htmlFor={htmlFor}
      className={`${isMobile ? "text-xs" : "text-sm"} text-brand-cold-metal-700 text-left flex flex-row items-center gap-x-1`}
    >
      {children}

      {extraInfo && <InfoTooltip text={extraInfo} />}

    </label>
  );
};