import { availableFlywheelTemplates } from "@roda/shared/types";

import { Icon } from "~/components/Icon";
import {
  useCustomiseFlywheelGoalDispatch,
  useCustomiseFlywheelGoalState
} from "~/contexts/CustomiseFlywheelGoalContext/CustomiseFlywheelGoalContext";

interface MetricCardProps {
  onClick: () => void;
}

export const FlywheelTemplateCard: React.FC<MetricCardProps> = ({ onClick }) => {
  const { flywheelTemplate } = useCustomiseFlywheelGoalState();
  const dispatch = useCustomiseFlywheelGoalDispatch();

  return (
    <button
      className="flex flex-row p-3 bg-white border-brand-cold-metal-200 border-[1px] rounded-lg w-full items-center *:min-w-0"
    >
      <div
        className="flex flex-row gap-x-2 items-center w-full cursor-pointer flex-1"
        onClick={onClick}
      >

        <div className="flex flex-col items-start text-sm overflow-hidden">
          <p className="font-semibold text-base">{flywheelTemplate?.name}</p>

          <p className="text-sm text-brand-cold-metal-500 text-left max-w-full ">{availableFlywheelTemplates.find(template => template.id === flywheelTemplate?.id)?.description || `${flywheelTemplate?.steps.length} steps`}</p>
        </div>
      </div>

      <div
        className="flex px-[8px] py-1 rounded-lg hover:contrast-50 cursor-pointer shrink-0"
        onClick={onClick}
      >
        <Icon.Edit
          className="text-brand-cold-metal-300"
          size={20}
        />
      </div>

      <div
        className="flex px-[8px] py-1 rounded-lg hover:contrast-50 cursor-pointer shrink-0"
        onClick={() => dispatch({ type: "RESET" })}
      >
        <Icon.Bin
          className="text-brand-cold-metal-300"
          size={20}
        />
      </div>

    </button>
  );
};