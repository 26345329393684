import { Link } from "react-router-dom";

import { CheckInBadge } from "~/components/check-in/CheckInBadge";
import { Icon } from "~/components/Icon";
import { useIsMobile } from "~/hooks/useIsMobile";

import type { To } from "react-router-dom";

export interface NavItemProps {
  icon?: React.ReactNode;
  label: string;
  comingSoon?: boolean;
  banner?: Banner;
}

interface Banner {
  bannerText: string;
}

interface HrefRequiredProps {
  href: To;
  onClick?: () => void;
}

export interface OnClickRequiredProps {
  onClick: () => void;
  href?: never;
}

// Requires either href or onClick or both
export type NavItemType = NavItemProps & (HrefRequiredProps | OnClickRequiredProps);

export const NavItem: React.FC<NavItemType> = ({
  icon, label = "", href, onClick, comingSoon = false, banner
}) => {
  const isLink = !!href;
  const LinkOrButton = isLink ? Link : "button";
  const isMobile = useIsMobile();

  return (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - To should not be undefined
    <LinkOrButton
      {...isLink ? { to: href } : {}}
      onClick={() => onClick?.()}
      className={`${isMobile ? "p-0 gap-x-4" : "p-2 mx-2 justify-between"} text-sm text-brand-cold-metal-600 text-start flex items-center gap-1.5 font-medium hover:bg-brand-cold-metal-100 rounded-lg group`}
      disabled={comingSoon}
    >
      <div className="flex flex-row gap-x-2 justify-center items-center">
        <span className="group-disabled:opacity-50">{icon}</span>

        <span className="group-disabled:opacity-50">{label}</span>
      </div>

      {comingSoon && !banner && (
        <small className="!text-opacity-100 inline-block bg-[#7b5afb] rounded-full px-1 text-white text-xs [font-size:9px] uppercase tracking-wide">Coming Soon</small>
      )}

      {banner && (
        <CheckInBadge
          text={banner.bannerText}
          icon={<Icon.Clock className="w-2.5" />}
          bgColour="bg-brand-check-in-due-100"
        />
      )}
    </LinkOrButton>
  );
};