import { useTypedQuery } from "@roda/graphql/urql";

import { FlywheelTemplate } from "~/fragments/index";

type UseTypedQueryOpts = Omit<Parameters<typeof useTypedQuery>[0], "query">;

export const useGetFlywheelTemplate = (flywheelId?: number, opts?: UseTypedQueryOpts) => {
  return useTypedQuery({
    ...opts,
    query: {
      getFlywheelTemplate: {
        __args: { flywheelId },
        ...FlywheelTemplate
      }
    }
  });
};