import { ProgressBar } from "~/components/flywheel/roda/ProgressBar";
import { Card } from "~/components/Timeline";
import { Tag } from "~/components/Timeline/Tag";
import { cn } from "~/utils/cn";

import type {
  ComponentProps,
  ReactNode
} from "react";

interface TimelineItemProps {
  title: string;
  size?: "md" | "lg";
  subheading: string;
  /** `String`, or custom JSX */
  badge?: React.ReactNode | (string & {});
  progressBarProps?: {
    min?: number;
    max: number;
    value: number;
    /** `String`, or custom JSX */
    labelLeft?: React.ReactNode | (string & {});
    /** `String`, or custom JSX */
    labelRight?: React.ReactNode | (string & {});
    neutral?: boolean;
    isHealthy?: boolean;
  },
  children?: ReactNode;
}

export const TimelineItem: React.FC<TimelineItemProps & ComponentProps<"li">> = ({
  size = "md", title, subheading, progressBarProps, badge, children, ...restProps
}) => {
  const large = size === "lg";

  return (
    <Card.Container
      {...restProps}
      className={cn("text-brand-cold-metal-700 text-sm font-medium", large && "min-w-[280px] gap-3 p-5 pt-4", restProps.className)}
    >
      <div className="flex items-center justify-between gap-2 w-full">
        <Card.Heading className={cn(large && "text-2xl")}>{title}</Card.Heading>

        {typeof badge === "string" ? (
          <Tag
            size="sm"
            className={cn(large && "text-sm [font-size:12px]")}
          >{badge}
          </Tag>
        ) : badge}
      </div>

      <Card.SubHeading className={cn(large && "!text-sm")}>{subheading}</Card.SubHeading>

      {progressBarProps && (
        <>
          <ProgressBar
            progress={progressBarProps.value}
            target={progressBarProps.max}
            isHealthy={progressBarProps.isHealthy ?? true}
            neutral={progressBarProps.neutral}
          />

          <div className={cn("flex gap-2 items-baseline justify-between w-full text-brand-cold-metal-700 text-xs font-medium", large && "text-sm")}>
            {typeof progressBarProps.labelLeft === "string" ? (
              <span className="font-medium">
                {progressBarProps.labelLeft}
              </span>
            ) : progressBarProps.labelLeft}

            {typeof progressBarProps.labelRight === "string" ? (
              <span className="font-semibold">
                {progressBarProps.labelRight}
              </span>
            ) : progressBarProps.labelRight}
          </div>
        </>
      )}

      {children}
    </Card.Container>
  );
};