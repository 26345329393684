import { cloneElement } from "react";

import type { ReactNode } from "react";

export const ConditionalWrapper: React.FC<{if: boolean, children: ReactNode, wrapper: JSX.Element}> = ({
  if: cond, children, wrapper: W
}) => {
  if (!cond) return children;

  return cloneElement(W, {}, children);
};