import ReactDOM from "react-dom";

import type { PropsWithChildren } from "react";

/**
 * Modal
 * Shows a modal with a black background, relative to the window.
 * @param onCancel pass a prop to cancel the modal
 */
export const Modal: React.FC<PropsWithChildren<{onCancel: () => void}>> = ({ children, onCancel }) => {
  const modalRoot = document.getElementById("modal-root");

  // Portal renders at the "modal-root" level (see index.html)
  return ReactDOM.createPortal(
    <div
      id="modal-active"
      className=" fixed top-0 left-0 w-screen h-[100dvh] flex items-center justify-center z-50"
    >

      {/* Overlay */}
      <div
        className="fixed top-0 left-0 w-screen h-[100dvh] bg-black animate-in fade-in opacity-50 z-10"
        onClick={onCancel}
      />

      {children}

    </div>,
    modalRoot!
  );
};
