import type { Metric } from "@roda/graphql/genql";
import type { Optional } from "@roda/shared/types";

export enum CustomiseMetricStage {
  SELECT_OR_CREATE = "select_or_create",
  CUSTOMISE_NAMING = "customise_1_naming",
  CUSTOMISE_UNITS = "customise_2_units",
  CUSTOMISE_DISPLAYS = "customise_3_display",
  REVIEW = "review",
}

export type MetricType = Optional<Pick<Metric, "name" | "unitName" | "unitType" | "unitTypeLabel" | "unitDisplay" | "unitDescription" | "unitTargetType"| "stepId"| "cap" | "reportingWindowTiming" | "slug" | "fromDate" | "toDate" >> & {
  ownerEmail?: string;
  target?: string;
  metricIdx?: number; // Need for handling updates of current metrics during onboarding
  id?: string; // For existing metric updates
  isCheckInDue?: boolean;
  isHealthy?: boolean | null;
};

interface MinimisedStep {
  // Provide an index if the step is not yet created.
  index?: number;
  // Provide an ID if the step is created in the database.
  id?: string;
  name: string;
}

export interface State {
  stage: CustomiseMetricStage | null;
  step?: MinimisedStep;
  metric?: MetricType
}

export type TransformActions =
  | { type: "RESET"}
  | { type: "SET_STATE", state: State, }
  | { type: "SET_STAGE", stage: CustomiseMetricStage}
  | { type: "SET_SELECTED_STEP", step: MinimisedStep, stage: CustomiseMetricStage }
  | { type: "SET_SELECTED_METRIC", metric?: MetricType, step?: MinimisedStep, stage: CustomiseMetricStage };

export const initialState: State = { stage: null };

export const metricReducer = (prevState: State, action: TransformActions): State => {
  switch (action.type) {
    case "RESET":
      return initialState;
    case "SET_STATE":
      return {
        ...prevState,
        ...action.state
      };
    case "SET_STAGE":
      return {
        ...prevState,
        stage: action.stage
      };
    case "SET_SELECTED_STEP":
      return {
        ...prevState,
        stage: action.stage,
        step: action.step
      };
    case "SET_SELECTED_METRIC":
      return {
        ...prevState,
        step: action.step || prevState.step,
        stage: action.stage,
        metric: action.metric
      };
    default:
      return prevState;
  }
};