import { useTypedQuery } from "@roda/graphql/urql";

import { ReducedFlywheel } from "~/fragments/index";

type UseTypedQueryOpts = Omit<Parameters<typeof useTypedQuery>[0], "query">;

export type ListFlywheelsResponse = ReturnType<typeof useListFlywheels>[0]["data"];
export type ListFlywheelType = NonNullable<ListFlywheelsResponse>["listFlywheels"];

/**
 * List Flywheels for a Company.
 * @param companyId The company ID for the flywheels you are fetching
 * @param opts UseTypedQueryOpts
 * @returns
 */
export const useListFlywheels = (companyId?: number, opts?: UseTypedQueryOpts) => {
  return useTypedQuery({
    ...opts,
    query: {
      listFlywheels: {
        __args: { companyId: companyId! },
        ...ReducedFlywheel
      }
    },
    pause: !companyId
  });
};