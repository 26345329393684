import { useTypedMutation } from "@roda/graphql/urql";

import { Metric } from "~/fragments/index";

import type { MutationGenqlSelection } from "@roda/graphql/genql";

export type CreateMetricWithTargetInReviewParams = NonNullable<MutationGenqlSelection["createMetricWithTargetInReview"]>["__args"];
export type CreateMetricWithTargetInReviewResponse = ReturnType<typeof useCreateMetricWithTargetInReview>[0]["data"];

export const useCreateMetricWithTargetInReview = () => {
  return useTypedMutation((vars: CreateMetricWithTargetInReviewParams) => ({
    createMetricWithTargetInReview: {
      __args: vars,
      ...Metric
    }
  }));
};