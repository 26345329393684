import { enumUserRole } from "@roda/graphql/genql";
import { UserRole } from "@roda/shared/types";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";

import { useAuth } from "~/contexts/AuthContext";
import { useCurrentUser } from "~/contexts/UserContext";
import type { ListCompaniesResponse } from "~/hooks/company";
import { useListCompanies } from "~/hooks/company";

import type { PropsWithChildren } from "react";

interface RodaAdminCompaniesContextProps {
  loading: boolean;
  companies: ListCompaniesResponse | null;
  currentCompany: ListCompaniesResponse[0] | null;
  setCurrentCompany: (companyId: string | null) => void;
  refetchCompanies: () => void;
}

const RodaAdminCompaniesContext = createContext<RodaAdminCompaniesContextProps>({
  loading: false,
  companies: null,
  currentCompany: null,
  setCurrentCompany: () => null,
  refetchCompanies: () => null
});

export const useRodaAdminCompaniesContext = () => useContext(RodaAdminCompaniesContext);

export const RodaAdminCompaniesProvider = (props: PropsWithChildren) => {
  const { user } = useCurrentUser();
  const { authenticated } = useAuth();
  const [ currentCompany, setCurrentCompany ] = useState<ListCompaniesResponse[0] | null>(null);

  // Fetch all of the companies - but only for Roda admins
  const [ { data, fetching }, refetch ] = useListCompanies({
    pause: !authenticated || !user || user.role !== enumUserRole.RODA_ADMIN,
    requestPolicy: "cache-and-network"
  });

  const refetchCompanies = useCallback(() => {
    if (user?.role === UserRole.RODA_ADMIN) {
      refetch({ requestPolicy: "cache-and-network" });
    }
  }, [ refetch, user ]);

  const selectCompany = useCallback((companyId: string | null) => {
    const selectedCompany = data?.listCompanies?.find(company => company.id === companyId);

    setCurrentCompany(selectedCompany || null);
  }, [ data?.listCompanies ]);

  // Make sure the currentCompany's data updates when we refetch the full companies list
  useEffect(() => {
    if (currentCompany) {
      const updatedCurrentCompany = data?.listCompanies?.find(company => company.id === currentCompany.id);

      setCurrentCompany(updatedCurrentCompany || null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ data ]);

  const memoedValue = useMemo<RodaAdminCompaniesContextProps>(
    () => ({
      loading: fetching,
      companies: data ? data.listCompanies : null,
      currentCompany,
      setCurrentCompany: selectCompany,
      refetchCompanies
    }),
    [
      currentCompany,
      data,
      fetching,
      selectCompany,
      refetchCompanies
    ]
  );

  return (
    <RodaAdminCompaniesContext.Provider value={memoedValue}>
      {props.children}
    </RodaAdminCompaniesContext.Provider>
  );
};