import { useLayoutEffect } from "react";

export function useDynamicViewportHeightUnitFallback() {
  useLayoutEffect(() => {
    const setVh = function () {
      const svh = document.documentElement.clientHeight * 0.01;

      document.documentElement.style.setProperty("--1svh", (svh + "px"));
      const dvh = window.innerHeight * 0.01;

      document.documentElement.style.setProperty("--1dvh", (dvh + "px"));

      if (document.body) {
        const fixed = document.createElement("div");

        fixed.style.width = "1px";
        fixed.style.height = "100vh";
        fixed.style.position = "fixed";
        fixed.style.left = "0";
        fixed.style.top = "0";
        fixed.style.bottom = "0";
        fixed.style.visibility = "hidden";
        document.body.appendChild(fixed);

        const fixedHeight = fixed.clientHeight;

        fixed.remove();

        const lvh = fixedHeight * 0.01;

        document.documentElement.style.setProperty("--1lvh", (lvh + "px"));
      }
    };

    const initialize = function () {
      // SSR support
      if (typeof window === "undefined") {
        return;
      }

      // Don't run polyfill if browser supports the units natively
      if ("CSS" in window &&
        "supports" in window.CSS &&
        window.CSS.supports("height: 100svh") &&
        window.CSS.supports("height: 100dvh") &&
        window.CSS.supports("height: 100lvh")
      ) {
        // just set the CSS variables to the correct values, they're supported!
        document.documentElement.style.setProperty("--1svh", "1svh");
        document.documentElement.style.setProperty("--1dvh", "1dvh");
        document.documentElement.style.setProperty("--1lvh", "1lvh");

        return;
      }

      // We run the calculation as soon as possible (eg. the script is in document head)
      setVh();
      // We run the calculation again when DOM has loaded
      document.addEventListener("DOMContentLoaded", setVh);
      // We run the calculation when window is resized
      window.addEventListener("resize", setVh, { passive: true });

      return () => {
        document.removeEventListener("DOMContentLoaded", setVh);
        window.removeEventListener("resize", setVh);
      };
    };

    initialize();
  }, []);
}