import {
  useLayoutEffect,
  useState
} from "react";
import defaultTheme from "tailwindcss/defaultTheme";

/**
 * Custom hook to determine if the current viewport width is below the "lg" breakpoint of TailwindCSS.
 *
 * @param {string} screen The TailwindCSS breakpoint to compare the viewport
 * width against.
 * @default "lg"
 *
 * @returns {boolean} - A boolean indicating if the current viewport is
 * considered "mobile" (width is less than the "lg" etc breakpoint).
 */
export const useIsMobile = (screen: keyof typeof defaultTheme.screens = "lg"): boolean => {
  const width = useViewport();
  const { screens } = defaultTheme;

  // Check if width is less than the <screen> breakpoint in Tailwind CSS
  return width < parseInt(screens[ screen ]);
};

export const useViewport = (immediate = true): number => {
  // Initialize the size state with the current window width
  const [ size, setSize ] = useState<number>(window.innerWidth);

  useLayoutEffect(() => {
    const handler = () => {
      setSize(window.innerWidth);
    };

    window.addEventListener("resize", handler, { passive: true });

    // Runs handler once on mount, even before an event fires
    if (immediate) window.dispatchEvent(new Event("resize"));

    return () => window.removeEventListener("resize", handler);
  }, [ immediate ]);

  return size;
};
