import { FlywheelGoal } from "~/fragments/flywheelGoal";
import {
  Step,
  ReducedStep
} from "~/fragments/step";

import type { FlywheelGenqlSelection } from "@roda/graphql/genql";

export const Flywheel: FlywheelGenqlSelection = {
  id: true,
  name: true,
  companyId: true,
  deletedAt: true,
  ownerId: true,
  currency: true,
  flywheelTemplateId: true,
  timeframe: true,
  updateFrequency: true,
  setupComplete: true,
  steps: Step,
  latestFlywheelGoal: FlywheelGoal
};

export const ReducedFlywheel: FlywheelGenqlSelection = {
  id: true,
  name: true,
  companyId: true,
  ownerId: true,
  currency: true,
  flywheelTemplateId: true,
  deletedAt: true,
  timeframe: true,
  updateFrequency: true,
  setupComplete: true,
  steps: ReducedStep,
  latestFlywheelGoal: FlywheelGoal
};
