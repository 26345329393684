import { MetricEditExistingCheckInPopup } from "~/components/check-in/MetricEditCheckInPopup";
import type { GetFlywheelMetricData } from "~/hooks/flywheel/use-get-flywheel";

interface EditMetricUpdateMenuItemProps {
  openPopup: () => void;
  closePopup: () => void;
  isOpen: boolean;
  metric: GetFlywheelMetricData;
  update: NonNullable<GetFlywheelMetricData["metricUpdates"]>[0];
}

export const EditExistingMetricUpdateMenuItem: React.FC<EditMetricUpdateMenuItemProps> = ({
  openPopup, closePopup, isOpen, metric, update
}) => {
  return (
    <>
      <button onClick={openPopup}>
        Edit check-in
      </button>

      <MetricEditExistingCheckInPopup
        isOpen={isOpen}
        onClose={closePopup}
        metric={metric}
        update={update}
      />
    </>
  );
};