import { useTypedMutation } from "@roda/graphql/urql";

import { Company } from "~/fragments/index";

import type { MutationGenqlSelection } from "@roda/graphql/genql";

type Params = NonNullable<MutationGenqlSelection["markCompanyAsOnboarded"]>["__args"];

export const useMarkCompanyAsOnboarded = () => {
  return useTypedMutation((vars: Params) => ({
    markCompanyAsOnboarded: {
      __args: vars,
      ...Company
    }
  }));
};