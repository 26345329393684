import type dayjs from "../dayjs";

/**
 * We set up subgoals with absolute starts and ends - e.g. 1st Jan - 31st March
 * But in reality, we have to use these as approximate windows for when the quarter / year begins -
 * because if the 1st Jan isn't a Monday, we'll have a week which is both the previous and new quarter!
 *
 * To account for this, we make sure the first week of the quarter is actually the first week in which
 * ALL DAYS are within the given quarter - so if the 1st Jan is a Monday, that'll be the first week
 * Otherwise, it'll be the first full week after the week including the 1st Jan - which could start
 * on anything from the 1st to the 7th Jan
 */
export const getFirstMondayFromStartDate = (absoluteStartDate: dayjs.Dayjs) => {
  // If it's a Monday, then it is the first Monday!
  if (absoluteStartDate.day() === 1) return absoluteStartDate;

  // Otherwise, the first Monday will be the start of the following isoWeek
  return absoluteStartDate.add(1, "week").startOf("isoWeek");
};