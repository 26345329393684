import { User } from "~/fragments/user";

import type { FlywheelGoalUpdateGenqlSelection } from "@roda/graphql/genql";

export const FlywheelGoalUpdate: FlywheelGoalUpdateGenqlSelection = {
  id: true,
  flywheelGoalId: true,
  startDate: true,
  value: true,
  notes: true,
  isHealthy: true,
  updatedAt: true,
  updatedBy: User
};
