import clsx from "clsx";
import { useRef } from "react";

import { Icon } from "~/components/Icon";
import { useIsMobile } from "~/hooks/useIsMobile";

export interface AccordionItemProps {
  title: string;
  text: string;
  isOpen?: boolean;
  onPress?: () => void;
  onToggle?: () => void;
  className?: string;
}
/**
 * AccordionItem is a single item component for an accordion structure.
 * It displays a title and text that can be shown or hidden based on its `isOpen` state.
 * The component can be used within an AccordionWrapper to form a complete accordion.
 *
 * @param title - The title of the accordion item.
 * @param text - The content text displayed when the item is open.
 * @param onPress - Optional: A user-defined function that is called when the accordion item is clicked.
 * @param onToggle - Optional: Internal function used for toggling the open state of the item.
 * @param className - Optional: Additional CSS classes for custom styling.
 * @returns {React.ReactElement} - A rendered accordion item element.
 *
 * @example
 * <AccordionItem
 *   title="Accordion Title"
 *   text="Detailed information about this section."
 *   onPress={() => console.log('Accordion item pressed')}
 *   className="my-custom-class"
 * />
 */
export const AccordionItem: React.FC<AccordionItemProps> = ({
  onPress, // User-defined action
  onToggle, // Internal toggle action
  title,
  text,
  isOpen,
  className
}) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    onToggle?.();
    onPress?.();
  };

  // Get the height of the content for animation
  const contentHeight = isOpen ? `${contentRef.current?.scrollHeight}px` : "0px";
  const isMobile = useIsMobile();

  return (
    <div
      className={clsx(isMobile ? "p-2" : "p-3", "rounded-xl border-[1px] cursor-pointer border-brand-cold-metal-200", className)}
      onClick={handleClick}
    >
      <div className="flex justify-between items-start w-full">
        <div className="font-semibold flex-1 text-brand-cold-metal-900">{title}</div>

        <div
          style={{ transition: "transform 500ms cubic-bezier(0.86, 0.14, 0.06, 0.92)" }}
          className={`${isOpen ? "rotate-180" : "rotate-0"}`}
        >
          <Icon.ChevronDown size={20} />
        </div>
      </div>

      <div
        ref={contentRef}
        className="transition-max-height duration-500 ease-out overflow-hidden"
        style={{
          transition: "max-height 0.5s cubic-bezier(0.86, 0.14, 0.06, 0.92)",
          maxHeight: contentHeight
        }}
      >
        <div className="text-brand-cold-metal-500 mt-2 text-xs md:text-sm">
          {text}
        </div>
      </div>
    </div>
  );
};
