import { zodResolver } from "@hookform/resolvers/zod";
import { enumUpdateFrequency } from "@roda/graphql/genql";
import { FlywheelTemplateUnitTypeLabelEnum } from "@roda/shared/types";
import {
  useCallback,
  useEffect,
  useMemo
} from "react";
import { useForm } from "react-hook-form";

import "react-datepicker/dist/react-datepicker.css";
import { Button } from "~/components/Button";
import { Input } from "~/components/form";
import { Icon } from "~/components/Icon";
import type { OnboardingContentStepProps } from "~/components/onboarding/OnboardingContent";
import { OnboardingContentWrapper } from "~/components/onboarding/OnboardingContentWrapper";
import { Spacer } from "~/components/Spacer";
import { Loading } from "~/components/Spinner";
import { Card } from "~/components/Timeline";
import { TimelineLayout } from "~/components/Timeline/TimelineLayout";
import {
  useOnboardingDispatch,
  useOnboardingState
} from "~/contexts/OnboardingContext/OnboardingContext";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useBulkUpdateSubgoalsAndProgress } from "~/hooks/subgoal/use-bulk-update-subgoals-and-progress";
import { useError } from "~/hooks/useError";
import { useIsMobile } from "~/hooks/useIsMobile";
import { calculateSubgoalMonthUpdates } from "~/utils/calculateSubgoalMonthUpdates";
import { cn } from "~/utils/cn";
import dayjs from "~/utils/dayjs";
import { formatGoalNumber } from "~/utils/formatGoalNumber";
import { getUnitSymbol } from "~/utils/getUnitSymbol";
import { cleanNumberInput } from "~/utils/validation/cleanNumberInput";

import { FlywheelGoalProgressSchema } from "./formSchemas";

import type {
  Currency,
  FlywheelGoalUpdateArgs,
  Subgoal
} from "@roda/graphql/genql";
import type { z } from "zod";

export const FlywheelGoalProgress: React.FC<OnboardingContentStepProps> = ({
  onNextStep, changeStep, onBack, createFlywheelMode
}) => {
  const {
    flywheelSubgoals, checkInSubgoal, refetchFlywheel
  } = useSelectedFlywheel();

  const dispatch = useOnboardingDispatch();
  const [ bulkUpdateSubgoalsAndProgressRes, bulkUpdateSubgoalsAndProgressReq ] = useBulkUpdateSubgoalsAndProgress();
  const { assertGraphQLSuccess, handleRodaError } = useError();
  const isMobile = useIsMobile();

  const {
    flywheel: onboardingFlywheel, flywheelGoal: onboardingFlywheelGoal, company: onboardingCompany
  } = useOnboardingState();

  const unitIcon = useMemo(() => getUnitSymbol(onboardingFlywheelGoal?.unitTypeLabel, onboardingCompany?.currency as Currency), [ onboardingCompany?.currency, onboardingFlywheelGoal?.unitTypeLabel ]);
  const FlywheelGoalProgressSchemaWithLatestGoal = FlywheelGoalProgressSchema();

  // Get existing subgoals - if in createFlywheelMode always use the onboardingFlywheelGoal subgoals
  const existingSubgoals = useMemo(() => createFlywheelMode ? onboardingFlywheelGoal?.subgoals : onboardingFlywheelGoal?.subgoals || flywheelSubgoals, [
    createFlywheelMode,
    flywheelSubgoals,
    onboardingFlywheelGoal?.subgoals
  ]);

  // Get an existing checkInSubgoal - if in createFlywheelMode always use the onboardingFlywheelGoal.checkInSubgoal
  const existingCheckInSubgoal = useMemo(() => createFlywheelMode ? onboardingFlywheelGoal?.checkInSubgoal : onboardingFlywheelGoal?.checkInSubgoal || checkInSubgoal, [
    checkInSubgoal,
    createFlywheelMode,
    onboardingFlywheelGoal?.checkInSubgoal
  ]);

  // Find the checkInSubgoal index
  const checkInSubgoalIdx = useMemo(() => existingSubgoals?.findIndex(s => s.id === existingCheckInSubgoal?.id), [ existingCheckInSubgoal, existingSubgoals ]);

  const subgoalsUntilCurrent = useMemo(() => existingSubgoals?.filter(s => {
    const now = new Date();

    return new Date(s.startDate) <= now;
  }), [ existingSubgoals ]);

  /**
   * Function that constructs subgoals
   * gets the correct progress so far for each subgoal (sum of all updates except for percentages)
   */
  const constructSubgoals = () => {
    return existingSubgoals?.reduce((prev, curr) => {
      const subgoalProgress = onboardingFlywheelGoal?.unitTypeLabel === FlywheelTemplateUnitTypeLabelEnum.PERCENTAGE ? curr.updates.length !== 0 && curr.updates[ curr.updates.length - 1 ].value || 0 : Number(curr.updates.reduce((prev, curr) => prev + parseFloat(curr.value), 0));

      return ({
        ...prev,
        // We have to prefix the key so it formats as an object - if we use numbers here then react-hook-form
        // assumes we want an array (which we don't!!!)
        [ `subgoal-${curr.id}` ]: {
          id: curr.id,
          goal: curr.goal || undefined,
          // Calculate the cumulative progress of all the updates for this subgoal
          progress: subgoalProgress ? subgoalProgress.toString() : undefined
        }
      });
    }, {});
  };

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm<z.infer<typeof FlywheelGoalProgressSchemaWithLatestGoal>>({
    resolver: zodResolver(FlywheelGoalProgressSchemaWithLatestGoal),
    defaultValues: { subgoals: constructSubgoals() },
    shouldFocusError: false
  });

  const subgoalFormData = watch("subgoals");

  // Whenever onboarding state's goal value changes, update the goals in form state
  useEffect(() => {
    const newSubgoalData = constructSubgoals();

    if (newSubgoalData) {
      setValue("subgoals", newSubgoalData);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ setValue ]);

  // Submit handler
  const onSubmit = handleSubmit(fields => {
    // First, check if the current subgoal goal is different to the value from setup
    // If so, update current and future subgoals in this cycle to use the new goal
    const checkInSubgoalFormData = existingCheckInSubgoal ? fields.subgoals[ `subgoal-${existingCheckInSubgoal.id}` ] : undefined;

    if (checkInSubgoalFormData && existingCheckInSubgoal) {
      // Bulk update the subgoals, passing in any progress updates - this mutation will create those as
      // flywheel goal updates as well

      // Construct an array containing each subgoal with progress data, and its progress update
      const progressUpdates: FlywheelGoalUpdateArgs[] = onboardingFlywheelGoal?.subgoals ? onboardingFlywheelGoal?.subgoals
        // Filter the full list of subgoals to only ones which have progress data provided on this form
        ?.filter(subgoal => !!fields.subgoals[ `subgoal-${subgoal.id}` ] && !!fields.subgoals[ `subgoal-${subgoal.id}` ].progress)
        .flatMap(subgoal => calculateSubgoalMonthUpdates({
          flywheelGoalId: existingCheckInSubgoal.flywheelGoalId,
          subgoal: subgoal as Subgoal,
          totalProgressForSubgoal: Number(fields.subgoals[ `subgoal-${subgoal.id}` ].progress!),
          goalTypeLabel: (onboardingFlywheelGoal.unitTypeLabel || FlywheelTemplateUnitTypeLabelEnum.CURRENCY) as FlywheelTemplateUnitTypeLabelEnum
        })) :
        [];

      bulkUpdateSubgoalsAndProgressReq({
        flywheelGoalId: existingCheckInSubgoal.flywheelGoalId,
        subgoalGoalValue: checkInSubgoalFormData.goal ? checkInSubgoalFormData.goal?.toString() : existingCheckInSubgoal.goal,
        progressUpdates: progressUpdates || []
      })
        // Then update onboarding state with the updated subgoals
        .then(res => {
          assertGraphQLSuccess(res);

          dispatch({
            type: "SET_FLYWHEEL_GOAL",
            flywheelGoal: {
              ...onboardingFlywheelGoal,
              updateFrequency: enumUpdateFrequency.MONTHLY,
              subgoals: res.data?.bulkUpdateSubgoalsAndProgress as Subgoal[]
            }
          });
          refetchFlywheel();
          // Then move to the next step in onboarding
          onNextStep();
        })
        .catch(e => {
          handleRodaError(e, "Failed to update subgoals");
        });
    }
  });

  const getAmountLeft = useCallback(() => {
    const checkInSubgoalFormData = existingCheckInSubgoal && subgoalFormData && subgoalFormData[ `subgoal-${existingCheckInSubgoal.id}` ] ? subgoalFormData[ `subgoal-${existingCheckInSubgoal.id}` ] : undefined;

    if (!checkInSubgoalFormData?.goal) return 0;

    return (+checkInSubgoalFormData?.goal - (checkInSubgoalFormData.progress ? +checkInSubgoalFormData.progress : 0));
  }, [ existingCheckInSubgoal, subgoalFormData ]);

  type SubgoalLike = {startDate: string, endDate: string, /** ... */};
  const formatDateRange = <T extends SubgoalLike>(goal: T) => `${goal.startDate ? dayjs(goal.startDate).format("MMMM YYYY") : "N/A"} – ${goal.endDate ? dayjs(goal.endDate).format("MMMM YYYY") : "N/A"}`;

  return (
    <OnboardingContentWrapper
      changeStep={changeStep}
      createFlywheelMode={createFlywheelMode}
      hideLogo={createFlywheelMode}
      leftContent={existingSubgoals ? (
        <div className="flex flex-col w-full gap-y-5 flex-1">
          <form
            onSubmit={onSubmit}
            className="flex flex-col flex-1"
          >
            <div className=" flex flex-col w-full">
              <div className="flex flex-col gap-y-5 justify-center">

                <div className="flex flex-col gap-2 mt-5 mb-3">

                  {subgoalsUntilCurrent?.map(subgoal => {
                    const subgoalIdx = existingSubgoals?.findIndex(s => s.id === subgoal.id);
                    const now = new Date();
                    const isCurrentQuarter = new Date(subgoal.startDate) <= now && new Date(subgoal.endDate) >= now;

                    // If we're not over 1 month into the current quarter, don't show this for that quarter -
                    // because we can't check-in a partial month!
                    if (isCurrentQuarter && dayjs(subgoal.startDate).isSame(now, "month")) {
                      return null;
                    }

                    return (
                      <Input
                        key={subgoalIdx}
                        {...register(`subgoals.subgoal-${subgoal.id}.progress`, {
                          onChange: e => {
                            const cleanedValue = cleanNumberInput(e.target.value);

                            setValue(`subgoals.subgoal-${subgoal.id}.progress`, cleanedValue as unknown as string);
                          }
                        })}
                        name={`subgoals.subgoal-${subgoal.id}.progress`}
                        min={0}
                        inputMode="numeric"
                        autoComplete="off"
                        autoCorrect="off"
                        label={`Progress made in Q${subgoalIdx ? subgoalIdx + 1 : 1}${isCurrentQuarter ? " so far" : ""}`}
                        optionalLabel
                        hasError={!!errors.subgoals && !!errors.subgoals[ `subgoal-${subgoal.id}` ] && !!errors.subgoals[ `subgoal-${subgoal.id}` ]?.progress?.message}
                        errorMessage={errors.subgoals && errors.subgoals[ `subgoal-${subgoal.id}` ] ? errors.subgoals[ `subgoal-${subgoal.id}` ]?.progress?.message : undefined}
                        // scroll to corresponding subgoal card when focused
                        onFocusCapture={() => isMobile && document.getElementById(`subgoal-${subgoalIdx}`)?.scrollIntoView({
                          behavior: "smooth",
                          inline: "nearest"
                        })}
                        iconPosition="left"
                        iconComponent={<p>{unitIcon}</p>}
                      />
                    );
                  })}

                  {existingCheckInSubgoal && (
                    <Input
                      {...register(`subgoals.subgoal-${existingCheckInSubgoal.id}.goal`, {
                        onChange: e => {
                          const cleanedValue = cleanNumberInput(e.target.value);

                          setValue(`subgoals.subgoal-${existingCheckInSubgoal.id}.goal`, cleanedValue as unknown as string);
                        }
                      })}
                      name={`subgoals.subgoal-${existingCheckInSubgoal.id}.goal`}
                      min={0}
                      autoComplete="off"
                      autoCorrect="off"
                      inputMode="numeric"
                      label={`What's your target for Q${checkInSubgoalIdx ? checkInSubgoalIdx + 1 : 1}?`}
                      hasError={!!errors.subgoals && (!!errors.subgoals[ `subgoal-${existingCheckInSubgoal.id}` ]?.goal || !!errors.subgoals.root)}
                      errorMessage={errors.subgoals ? (errors.subgoals[ `subgoal-${existingCheckInSubgoal.id}` ]?.goal?.message || errors.subgoals?.root?.message) : undefined}
                      // scroll to corresponding subgoal card when focused
                      onFocusCapture={() => isMobile && document.getElementById(`subgoal-${checkInSubgoalIdx}`)?.scrollIntoView({
                        behavior: "smooth",
                        inline: "nearest"
                      })}
                      iconPosition="left"
                      iconComponent={<p>{unitIcon}</p>}
                    />
                  )}

                  {existingCheckInSubgoal?.goal && onboardingFlywheel?.currency && subgoalFormData[ `subgoal-${existingCheckInSubgoal.id}` ].goal && subgoalFormData[ `subgoal-${existingCheckInSubgoal.id}` ].progress && onboardingFlywheelGoal?.unitTypeLabel !== FlywheelTemplateUnitTypeLabelEnum.PERCENTAGE && (
                    <p
                      className="opacity-80 text-xs"
                    >

                      <>
                        <span className="font-semibold">
                          {formatGoalNumber(getAmountLeft() < 0 ? 0 : getAmountLeft(), unitIcon, {
                            shouldCompact: true,
                            stripTrailingZeros: true
                          })}
                        </span>

                        <span className="text-brand-cold-metal-400">
                          {` remaining in Q${checkInSubgoalIdx ? checkInSubgoalIdx + 1 : 1}`}
                        </span>
                      </>
                    </p>
                  )}

                </div>

                <div className="flex flex-row gap-1 items-center text-xs text-brand-cold-metal-400">
                  <Icon.InfoCircle className="min-w-[14px]" />

                  <p>We will remind you to update your progress towards your flywheel goal each month.</p>
                </div>
              </div>

            </div>

            {isMobile && (<Spacer />)}

            <div className={`flex gap-x-3 self-start pb-5 mt-10 ${isMobile ? "flex-col w-full gap-3" : "flex-row"}`}>
              {onBack && (
                <Button
                  title="Back"
                  onClick={onBack}
                  className="bg-brand-cold-metal-100 hover:contrast-75 w-full text-brand-cold-metal-900"
                />
              )}

              <Button
                title="Next"
                type="submit"
                className={`px-6 ${isMobile ? "order-first" : ""}`}
                loading={bulkUpdateSubgoalsAndProgressRes.fetching}
              />
            </div>
          </form>
        </div>
      ) : <Loading.FlexCenter />}
      rightContent={existingSubgoals?.[ 0 ].goal ? (
        <div className="w-full h-full lg:grid place-items-center">
          <TimelineLayout
            orientation={isMobile ? "horizontal" : "vertical"}
            rowGap={isMobile ? "14" : undefined /* defaults to 60, ideal for desktop */}
            items={new Array(4).fill(null).map((_, i, { length }) => {
              const isInQuarter = i === checkInSubgoalIdx;
              const watchedSubgoal = watch(`subgoals.subgoal-${existingSubgoals[ i ].id}`);

              return {
                renderLeft: existingSubgoals?.[ i ] ? Boolean(i === length - 1 || (!isMobile && isInQuarter)) && (
                  <div
                    className="flex flex-col items-start gap-2 relative"
                    aria-hidden='true'
                  >
                    {(!isMobile && isInQuarter) && (
                      <Card.Tag className="[&:not(:only-of-type)]:absolute [&:not(:only-of-type)]:-translate-y-full [&:not(:only-of-type)]:-top-2">
                        Now
                      </Card.Tag>
                    )}

                    {i === length - 1 && (
                      <Card.Tag>
                        Goal: {formatGoalNumber(
                        onboardingFlywheelGoal?.unitTypeLabel === FlywheelTemplateUnitTypeLabelEnum.PERCENTAGE ?
                          +existingSubgoals[ existingSubgoals.length - 1 ].goal :
                          existingSubgoals.reduce((sum, arr) => sum + parseFloat(arr.goal), 0),
                        unitIcon,
                        {
                          shouldCompact: true,
                          stripTrailingZeros: true
                        })}
                      </Card.Tag>
                    )}
                  </div>
                ) || undefined : undefined,
                renderRight: existingSubgoals?.[ i ] ? (
                  <Card.TimelineItem
                    title={`Quarter ${i + 1}`}
                    size={isMobile ? "lg" : "md"}
                    subheading={formatDateRange(existingSubgoals?.[ i ])}
                    badge={isMobile && isInQuarter ? "Now" : undefined}
                    progressBarProps={isInQuarter ? {
                      value: watchedSubgoal?.progress ? Number(watchedSubgoal.progress) : 0,
                      max: watchedSubgoal?.goal ? Number(watchedSubgoal.goal) : Infinity,
                      labelLeft: watchedSubgoal?.progress ? formatGoalNumber(Number(watchedSubgoal.progress), unitIcon, {
                        shouldCompact: true,
                        stripTrailingZeros: true
                      }) : "Progress",
                      labelRight: "Target" + (i <= (checkInSubgoalIdx ?? Infinity) && watchedSubgoal?.goal ? ": " + formatGoalNumber(Number(watchedSubgoal.goal), unitIcon, {
                        shouldCompact: true,
                        stripTrailingZeros: true
                      }) : "")
                    } : undefined}
                    className={cn(isMobile && "mb-0.5")}
                    id={`subgoal-${i}`}
                  >
                    {/* Show previous quarter values (collected from inputs) in their card */}
                    {i < (checkInSubgoalIdx ?? -Infinity) && watch(`subgoals.subgoal-${existingSubgoals[ i ].id}`)?.progress ? (
                      <p className="text-sm">
                        {formatGoalNumber(watch(`subgoals.subgoal-${existingSubgoals[ i ].id}`)?.progress ? Number(watch(`subgoals.subgoal-${existingSubgoals[ i ].id}`).progress) : 0, unitIcon, {
                          shouldCompact: true,
                          stripTrailingZeros: true
                        })}
                      </p>
                    ) : null}
                  </Card.TimelineItem>
                ) : <></>
              };
            })}
          />
        </div>
      ) : <></>}
    />

  );
};