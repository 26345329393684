import { enumUserRole } from "@roda/graphql/genql";
import { ReviewPeriod } from "@roda/shared/types";
import { getFirstMondayFromStartDate } from "@roda/shared/utils/getFirstMondayFromStartDate";
import { getLastSundayFromEndDate } from "@roda/shared/utils/getLastSundayFromStartDate";
import {
  useMemo,
  useState
} from "react";
import { useNavigate } from "react-router-dom";

import { ReminderBanner } from "~/components/ReminderBanner";
import { routes } from "~/constants/routes";
import { STORAGE_KEYS } from "~/constants/storageKeys";
import { useRodaAdminCompaniesContext } from "~/contexts/RodaAdminCompaniesContext";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useRodaSubscription } from "~/contexts/SubscriptionContext";
import { useCurrentUser } from "~/contexts/UserContext";
import dayjs from "~/utils/dayjs";

export const ReviewReminderBanner = () => {
  const { user } = useCurrentUser();

  const {
    flywheel, checkInSubgoal, isReviewDue, subgoalInReview, activeFlywheelReview
  } = useSelectedFlywheel();

  const { isSubscriptionBannerActive } = useRodaSubscription();
  const { currentCompany } = useRodaAdminCompaniesContext();
  const navigate = useNavigate();
  const [ reviewReminderDismissedUntil, setReviewReminderDismissedUntil ] = useState(localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE.REVIEW_REMINDER_DISMISSED_UNTIL));
  // Permissions
  const isAdmin = user?.role === enumUserRole.ADMIN;
  const isRodaAdmin = user?.role === enumUserRole.RODA_ADMIN;
  const isMetricOwner = !!activeFlywheelReview?.metrics?.find(metric => metric.owner?.id === user?.id);
  const isFlywheelGoalOwner = flywheel?.latestFlywheelGoal?.ownerId == user?.id;
  // Logic for whether to show the banner
  const checkInSubgoalStartDate = getFirstMondayFromStartDate(dayjs(checkInSubgoal?.startDate));
  const hasAccessToReview = isAdmin || isRodaAdmin || isMetricOwner || isFlywheelGoalOwner;
  const twoWeeksInQuarter = dayjs(checkInSubgoalStartDate).add(2, "weeks");
  const isAtStartOfQuarter = dayjs().isBetween(dayjs(checkInSubgoalStartDate), twoWeeksInQuarter, null, "[]");
  // Check that there's a subgoal in review AND if it's at the start of the quarter then check if there's still a review due
  const checkExtendedDeadlineReviewDue = !!subgoalInReview && (isAtStartOfQuarter ? isReviewDue : true);

  // Check if we should show the review banner
  const reviewIsAvailable = useMemo(() => activeFlywheelReview ? hasAccessToReview && (isAtStartOfQuarter ? checkExtendedDeadlineReviewDue : true) : false, [
    checkExtendedDeadlineReviewDue,
    activeFlywheelReview,
    hasAccessToReview,
    isAtStartOfQuarter
  ]);

  // Show true if there's not a subgoalInReview yet - means that they haven't set it up -> review is due
  const reviewIsIncompleteForCompany = useMemo(() => activeFlywheelReview?.reviewPeriod === ReviewPeriod.NEXT_YEAR ? subgoalInReview === null ? true : isReviewDue : isReviewDue, [
    activeFlywheelReview?.reviewPeriod,
    isReviewDue,
    subgoalInReview
  ]);

  // Check if the user has completed review - for metric owners, this means checking whether they or an admin
  // have reviewed all of their owned metrics
  const reviewIsIncompleteForUser = useMemo(() => {
    // For admins and roda admins, return true since we don't actually care if they've checked in any metrics
    if (isAdmin || isRodaAdmin) return true;

    // Find all the metrics owned by the user in the review quarter
    const userOwnedMetrics = activeFlywheelReview?.metrics ? activeFlywheelReview.metrics.filter(metric => metric.owner?.id === user?.id) : [];
    // Filter to all metrics owned by the user which don't have a review from them or an admin
    const userMetricsWithoutReview = userOwnedMetrics.filter(metric => !metric.reviewers?.find(review => review.userId === user?.id || review.user.role === enumUserRole.ADMIN || review.user.role === enumUserRole.RODA_ADMIN));

    // Review is incomplete if there's still metrics without a review
    return !!userMetricsWithoutReview.length;
  }, [
    activeFlywheelReview?.metrics,
    isAdmin,
    isRodaAdmin,
    user?.id
  ]);

  const getReviewReminderFromLocalStorage = () => {
    const reviewReminder = localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE.REVIEW_REMINDER_DISMISSED_UNTIL);

    setReviewReminderDismissedUntil(reviewReminder);
  };

  if (reviewIsAvailable && reviewIsIncompleteForCompany && reviewIsIncompleteForUser && (!reviewReminderDismissedUntil || !dayjs(reviewReminderDismissedUntil).isAfter(dayjs(new Date())))) {
    let reminderBannerText = "";

    // If we don't have a review to format, return undefined
    if (activeFlywheelReview) {
      const now = dayjs();
      // The available review is for the current quarter if the subgoal started before now
      const reviewIsCurrentQuarter = activeFlywheelReview.subgoal && getFirstMondayFromStartDate(dayjs(activeFlywheelReview.subgoal.startDate)).isBefore(now);

      // If it's the current quarter, we want "your quarter started X days ago" text
      if (reviewIsCurrentQuarter && activeFlywheelReview.subgoal) {
        // Get the actual start date of the review subgoal - this could be anything from the 1st to the
        // 7th of the given month
        const subgoalStartDate = getFirstMondayFromStartDate(dayjs(activeFlywheelReview.subgoal.startDate));
        // Find how many days of the quarter have passed
        const daysIntoQuarter = Math.abs(now.diff(subgoalStartDate, "days"));
        // Format this text with "yesterday" if it's 1 day, or the day count
        const daysIntoQuarterText = daysIntoQuarter === 0 ? "starts today" : daysIntoQuarter === 1 ? "started yesterday" : `started ${daysIntoQuarter} day${daysIntoQuarter > 1 ? "s" : ""} ago`;

        reminderBannerText = `Your new ${activeFlywheelReview?.reviewPeriod === "next_year" ? "cycle" : "quarter"} ${daysIntoQuarterText}`;
      } else if (!reviewIsCurrentQuarter) {
        const checkInSubgoalEndDate = getLastSundayFromEndDate(dayjs(flywheel?.latestFlywheelGoal?.checkInSubgoal?.endDate));
        // If it's the next quarter, we want "your next quarter begins in X days" text
        const daysToNextQuarter = Math.abs(now.diff(checkInSubgoalEndDate, "days")) + 1;
        // Format this text with "tomorrow" if it's 1 day, or the day count
        const daysToNextQuarterText = daysToNextQuarter === 1 ? "tomorrow" : `in ${daysToNextQuarter} day${daysToNextQuarter > 1 ? "s" : ""}`;

        reminderBannerText = `Your next ${activeFlywheelReview?.reviewPeriod === "next_year" ? "cycle" : "quarter"} begins ${daysToNextQuarterText}`;
      }
    }

    // If the subscription banner is active, it should be shown instead
    if (isSubscriptionBannerActive) {
      return null;
    }

    return (
      <ReminderBanner
        bannerTitle={reminderBannerText!}
        bannerText="Take 5-10 minutes to review your targets"
        onDismiss={() => {
          localStorage.setItem(STORAGE_KEYS.LOCAL_STORAGE.REVIEW_REMINDER_DISMISSED_UNTIL, dayjs(new Date()).endOf("day").toString());
          // Refetch this once we've set it
          getReviewReminderFromLocalStorage();
        }}
        actionText="Review"
        onAction={() => navigate(routes.review(currentCompany ? currentCompany.id : undefined))}
      />
    );
  }

  return null;
};