import { useTypedQuery } from "@roda/graphql/urql";
import { type Stripe } from "stripe";

import { Company } from "~/fragments/company";
import {
  ReadOnlyUser,
  User
} from "~/fragments/user";

export type SessionResponse = ReturnType<typeof useGetSession>[0]["data"];
export type UserCompany = NonNullable<SessionResponse>["session"]["company"] & {stripeStatus: Stripe.Subscription["status"] | null};
export type UserSession = NonNullable<SessionResponse>["session"] & {company: UserCompany};
export type ReducedCompanyFlywheelType = NonNullable<UserCompany>["flywheels"];

export const useGetSession = (authenticated: boolean) => {
  return useTypedQuery({
    requestPolicy: "network-only",
    pause: !authenticated,
    query: {
      session: {
        ...User,
        fakeNowDate: true,
        company: {
          ...Company,
          activeUsers: ReadOnlyUser,
          lastSeenUser: ReadOnlyUser
        }
      }
    }
  });
};