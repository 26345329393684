import { enumUserRole } from "@roda/graphql/genql";
import {
  useMemo,
  useState
} from "react";

import { EditFlywheelGoalOwnerMenuItem } from "~/components/flywheel-goal/EditFlywheelGoalOwnerMenuItem";
import { EditFlywheelGoalTypeMenuItem } from "~/components/flywheel-goal/EditFlywheelGoalTypeMenuItem";
import { EditMainFlywheelGoalMenuItem } from "~/components/flywheel-goal/EditMainFlywheelGoalMenuItem";
import { SaveImageMenuItem } from "~/components/metric/SaveImageMenuItem";
import { VerticalDotMenu } from "~/components/VerticalDotMenu";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useCurrentUser } from "~/contexts/UserContext";
import { useIsMobile } from "~/hooks/useIsMobile";

enum EditFlywheelGoalAction {
  EditMainFlywheelGoal,
  EditFlywheelGoalType,
  EditFlywheelGoalOwner,
  SaveImage
}

export const EditFlywheelGoalMenu: React.FC = () => {
  const [ editPopupType, setEditPopupType ] = useState<EditFlywheelGoalAction | null>(null);
  const { user } = useCurrentUser();
  const { flywheel } = useSelectedFlywheel();
  const isMobile = useIsMobile();

  // Roda admins, admins and the flywheel goal owner can view this menu
  const canViewFlywheelGoalMenu = useMemo(() => user?.role === enumUserRole.RODA_ADMIN || user?.role === enumUserRole.ADMIN || (user?.id && flywheel?.latestFlywheelGoal?.ownerId === parseInt(user.id)), [
    flywheel?.latestFlywheelGoal?.ownerId,
    user?.id,
    user?.role
  ]);

  if (!canViewFlywheelGoalMenu) return null;

  // Everything here can be viewed & used by Roda admins, admins and the flywheel goal owner - except where
  // there's additional permission checks
  return (
    <VerticalDotMenu
      buttonClassName="m-0 -mr-4 md:-mr-8 -mt-3 text-brand-cold-metal-400 hover:text-black"
    >

      <EditMainFlywheelGoalMenuItem
        openPopup={() => setEditPopupType(EditFlywheelGoalAction.EditMainFlywheelGoal)}
        closePopup={() => setEditPopupType(null)}
        isOpen={editPopupType === EditFlywheelGoalAction.EditMainFlywheelGoal}
      />

      {/* Only Roda admins and admins can change the flywheel goal owner */}
      {(user?.role === enumUserRole.RODA_ADMIN || user?.role === enumUserRole.ADMIN) ? (
        <EditFlywheelGoalOwnerMenuItem
          openPopup={() => setEditPopupType(EditFlywheelGoalAction.EditFlywheelGoalOwner)}
          closePopup={() => setEditPopupType(null)}
          isOpen={editPopupType === EditFlywheelGoalAction.EditFlywheelGoalOwner}
        />
      ) : null}

      {/* Only Roda admins and admins can change the flywheel goal owner */}
      {(user?.role === enumUserRole.RODA_ADMIN || user?.role === enumUserRole.ADMIN) ? (
        <EditFlywheelGoalTypeMenuItem
          openPopup={() => setEditPopupType(EditFlywheelGoalAction.EditFlywheelGoalType)}
          closePopup={() => setEditPopupType(null)}
          isOpen={editPopupType === EditFlywheelGoalAction.EditFlywheelGoalType}
        />
      ) : null}

      {!isMobile && (
        <SaveImageMenuItem
          captureId="main-goal"
          html2canvasOptions={{
            width: 600,
            onclone(_, element) {
              const borderW = parseInt(getComputedStyle(element).borderInlineWidth ?? 0) * 2; // * 2 because it only accounts for one side

              element.style.width = (600 - borderW) + "px";
              element.style.height = "auto";
              element.style.flex = "unset";
            }
          }}
        />
      )}

    </VerticalDotMenu>
  );
};