import { enumUserRole } from "@roda/graphql/genql";
import { useState } from "react";
import { Navigate } from "react-router-dom";

import LinkSent from "~/assets/illustrations/link.png";
import SignUp from "~/assets/illustrations/signup.png";
import Logo from "~/assets/logo.svg";
import { Icon } from "~/components/Icon";
import { MagicLinkForm } from "~/components/magic-link/MagicLinkForm";
import { MagicLinkSent } from "~/components/magic-link/MagicLinkSent";
import { Loading } from "~/components/Spinner";
import { routes } from "~/constants/routes";
import { useAuth } from "~/contexts/AuthContext";
import { useCurrentUser } from "~/contexts/UserContext";
import { useIsMobile } from "~/hooks/useIsMobile";

export const Home: React.FC = () => {
  const [ magicLinkSentTo, setMagicLinkSentTo ] = useState<string | null>(null);
  const { authenticated, loading } = useAuth();
  const { user } = useCurrentUser();
  const isMobile = useIsMobile();

  if (user && authenticated && !loading) {
    if (user.role === enumUserRole.RODA_ADMIN) {
      return (
        <Navigate
          replace
          to={routes.rodaAdminOrganisationManagement}
        />
      );
    } else if (user.role === enumUserRole.ADMIN && !user.company?.completedOnboarding) {
      return (
        <Navigate
          replace
          to={routes.onboarding}
        />
      );
    } else
      return (
        <Navigate
          replace
          to={routes.dashboard()}
        />
      );
  }

  return (
    <div
      className="flex flex-row w-full h-dvh"
    >

      {/* Content Container LEFT SIDE OF SCREEN */}
      <div className={`flex flex-col h-full flex-1 flex-center ${isMobile ? "w-full" : "w-1/2"} `}>

        {/* Logo */}
        <div className="w-full flex items-start">
          <img
            src={Logo}
            className="max-w-[130px] pt-5 pl-5"
          />
        </div>

        {/* Magic link components */}
        <div className="w-full flex items-center px-8 max-w-[500px] flex-1">
          {loading && <Loading.FlexCenter />}

          {!magicLinkSentTo ?
            (
              <MagicLinkForm
                id="email1"
                onLinkSent={email => setMagicLinkSentTo(email)}
              />
            ) :
            (
              <MagicLinkSent
                email = {magicLinkSentTo}
              />
            )}
        </div>

        {/* Footer */}
        <div className={`flex w-full justify-between px-5 mt-auto pb-10 ${isMobile && "flex-col items-start gap-3 pb-5 text-xs"}`}>

          <div className="flex justify-center items-center gap-1 md:gap-2 order-1 md:order-2">
            <Icon.MailClosed
              size={isMobile ? 15 : 20}
              className="text-brand-cold-metal-500"
            />

            <a
              href="mailto:help@rodastrategy.co.uk"
              className="text-brand-cold-metal-500"
            >
              help@rodastrategy.co.uk
            </a>
          </div>

          <div className="text-brand-cold-metal-500 order-2 md:order-1">© Roda</div>

        </div>
      </div>

      {/* Image Container RIGHT SIDE OF SCREEN 50/50 SPLIT */}
      {!isMobile && (

        <div className=" flex flex-col h-[100dvh] w-1/2 bg-brand-cold-metal-50">
          <img
            src={magicLinkSentTo ? LinkSent : SignUp}
            className="w-full h-full object-cover"
          />
        </div>
      )}

    </div>

  );
};