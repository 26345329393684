import { ProgressBar } from "~/components/flywheel/roda/ProgressBar";
import { Card } from "~/components/Timeline";
import { formatGoalNumber } from "~/utils/formatGoalNumber";

interface FlywheelGoalProgressCardProps {
  flywheelGoalTarget: number;
  flywheelGoalProgress: number;
  unitIcon: string;
  isHealthy: boolean;
}

export const FlywheelGoalProgressCard: React.FC<FlywheelGoalProgressCardProps> = ({
  flywheelGoalTarget, flywheelGoalProgress, unitIcon, isHealthy
}) => {
  // Construct the formatted goals to display (main goal)
  const formattedFlywheelGoalTarget = formatGoalNumber(flywheelGoalTarget, unitIcon, {
    shouldCompact: true,
    stripTrailingZeros: true
  });

  const formattedFlywheelGoalProgress = formatGoalNumber(flywheelGoalProgress, unitIcon, {
    shouldCompact: true,
    stripTrailingZeros: true
  });

  return (
    <Card.Tag>
      <div className="flex flex-col gap-y-1">

        <span className="text-xs">Flywheel Goal</span>

        <span className="text-brand-cold-metal-400 text-[10px] pb-1">{`${formattedFlywheelGoalProgress} of ${formattedFlywheelGoalTarget} target`}</span>

        <ProgressBar
          target={flywheelGoalTarget}
          progress={flywheelGoalProgress}
          isHealthy={isHealthy}
          neutral
        />
      </div>
    </Card.Tag>
  );
};