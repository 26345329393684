import type { CustomFlywheelTemplate } from "~/contexts/CustomiseFlywheelGoalContext/flywheel-goal-reducer";

export const dummyIntroFlywheel: CustomFlywheelTemplate = {
  name: "Growth",
  id: 1,
  steps: [
    {
      id: "1",
      name: "Awareness",
      description: "",
      order: 1,
      health: "check-in-due",
      metrics: [
        {
          id: "1-1",
          name: "",
          health: "check-in-due"
        },
        {
          id: "1-2",
          name: "",
          health: "check-in-due"
        },
        {
          id: "1-3",
          name: "",
          health: "check-in-due"
        }
      ]
    },
    {
      id: "2",
      name: "Conversion",
      order: 2,
      description: "",
      health: "needs-attention",
      metrics: [
        {
          id: "2-1",
          name: "",
          health: "needs-attention"
        },
        {
          id: "2-2",
          name: "",
          health: "needs-attention"
        },
        {
          id: "2-3",
          name: "",
          health: "needs-attention"
        }
      ]
    },
    {
      id: "3",
      name: "Delivery",
      description: "",
      order: 3,
      health: "healthy",
      metrics: [
        {
          id: "3-1",
          name: "",
          health: "healthy"
        },
        {
          id: "3-2",
          name: "",
          health: "healthy"
        },
        {
          id: "3-3",
          name: "",
          health: "healthy"
        }
      ]
    },
    {
      id: "4",
      name: "Retention",
      order: 4,
      description: "",
      health: "needs-attention",
      metrics: [
        {
          id: "4-1",
          name: "",
          health: "needs-attention"
        },
        {
          id: "4-2",
          name: "",
          health: "needs-attention"
        },
        {
          id: "4-3",
          name: "",
          health: "needs-attention"
        }
      ]
    },
    {
      id: "5",
      name: "Innovation",
      order: 5,
      health: "healthy",
      description: "",
      metrics: [
        {
          id: "5-1",
          name: "",
          health: "healthy"
        },
        {
          id: "5-2",
          name: "",
          health: "healthy"
        },
        {
          id: "5-3",
          name: "",
          health: "healthy"
        }
      ]
    }
  ]
};