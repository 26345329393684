import {
  useState,
  useCallback,
  useEffect
} from "react";

/**
 * Sets a flag (state) to `true`, then back to `false` after `timeoutMs` (arg)
 *
 * Ideal for showing a short success state, for example.
 */
export const useTimedBoolean = (timeoutMs: number) => {
  const [ value, setValue ] = useState(false);
  const start = useCallback(() => setValue(true), []);

  useEffect(() => {
    if (value) {
      const t = setTimeout(() => setValue(false), timeoutMs);

      return () => clearTimeout(t);
    }
  }, [ timeoutMs, value ]);

  return [ value, start ] as const;
};