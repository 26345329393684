import {
  createContext,
  useContext
} from "react";

import { STORAGE_KEYS } from "~/constants/storageKeys";
import { usePersistReducer } from "~/hooks/usePersistedReducer";

import {
  initialState,
  metricReducer
} from "./metric-reducer";

import type {
  State,
  TransformActions
} from "./metric-reducer";
import type {
  Dispatch,
  PropsWithChildren
} from "react";

// Create contexts for state
const CustomiseMetricContext = createContext<State>(initialState);

// Create contexts for actions
const CustomiseMetricActionsContext = createContext<Dispatch<TransformActions>>(
  {} as Dispatch<TransformActions> // Safe to typecast we'll never access the initial value
);

// Wraps the providers for both state & action contexts, injecting the reducer to each
export function CustomiseMetricProvider({ children }: PropsWithChildren) {
  const [ state, dispatch ] = usePersistReducer(
    STORAGE_KEYS.LOCAL_STORAGE.METRIC,
    metricReducer,
    initialState
  );

  return (
    <CustomiseMetricContext.Provider value={state}>
      <CustomiseMetricActionsContext.Provider value={dispatch}>
        {children}
      </CustomiseMetricActionsContext.Provider>
    </CustomiseMetricContext.Provider>
  );
}

// Make the state a usable hook
export const useCustomiseMetricState = () => useContext(CustomiseMetricContext);

// Make the actions to alter state a usable hook
export const useCustomiseMetricDispatch = () => useContext(CustomiseMetricActionsContext);