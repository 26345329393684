import { useTypedMutation } from "@roda/graphql/urql";

import { Company } from "~/fragments/index";

import type { MutationGenqlSelection } from "@roda/graphql/genql";

type Params = NonNullable<MutationGenqlSelection["markPaymentPrompt"]>["__args"];

export const useMarkPaymentPrompt = () => {
  return useTypedMutation((vars: Params) => ({
    markPaymentPrompt: {
      __args: vars,
      ...Company
    }
  }));
};