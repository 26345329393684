import { useTypedQuery } from "@roda/graphql/urql";

import { Industry } from "~/fragments/index";

type UseTypedQueryOpts = Omit<Parameters<typeof useTypedQuery>[0], "query">;

export const useListIndustries = (opts?: UseTypedQueryOpts) => {
  return useTypedQuery({
    ...opts,
    query: { listIndustries: { ...Industry } }
  });
};