import React, {
  useState,
  useEffect,
  useRef,
  createContext,
  useLayoutEffect
} from "react";

import { Button } from "~/components/Button";
import { Icon } from "~/components/Icon";
import { cn } from "~/utils/cn";

type VerticalDotMenuChild = React.ReactElement | null | false;

interface VerticalDotMenuProps {
  children: VerticalDotMenuChild[] | VerticalDotMenuChild;
  buttonClassName?: string;
}

interface VerticalDotMenuContextType {
  closeVDotMenu: () => void;
}

// This context is used to provide the child components with a function to close the menu. use const { closeVDotMenu } = useContext(VerticalDotMenuContext); to access it.
export const VerticalDotMenuContext = createContext<VerticalDotMenuContextType>({ closeVDotMenu: () => null });

export const VerticalDotMenu: React.FC<VerticalDotMenuProps> = ({ children, buttonClassName }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [ showEditMenu, setShowEditMenu ] = useState(false);

  const [ menuPosition, setMenuPosition ] = useState({
    right: "auto",
    left: "auto"
  });

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      // Check we aren't clicking an inner component, and that a modal or a dropdown isn't active
      if (containerRef.current && !containerRef.current.contains(event.target as Node) && !document.getElementById("modal-active") && !document.getElementById("dropdownMenu")) {
        setShowEditMenu(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [ containerRef ]);

  // Adjust the menu position based on the available space
  useLayoutEffect(() => {
    if (showEditMenu && containerRef.current) {
      const menuWidth = 250; // Define an average width for your menu items
      const { right } = containerRef.current.getBoundingClientRect();
      const spaceRight = window.innerWidth - right;

      if (spaceRight < menuWidth) {
        setMenuPosition({
          right: "0",
          left: "auto"
        });
      } else {
        setMenuPosition({
          right: "auto",
          left: "0"
        });
      }
    }
  }, [ showEditMenu ]);

  if (!React.Children.count(children)) return null;

  return (
    <VerticalDotMenuContext.Provider value={{ closeVDotMenu: () => setShowEditMenu(false) }}>
      <div
        ref={containerRef}
        className="relative"
        onClick={e => e.stopPropagation()}
        data-capture-ignore
      >
        <Button
          className={cn("bg-transparent p-2 m-2", buttonClassName)}
          iconComponent={<Icon.DotMenu size={20} />}
          onClick={e => {
            e.stopPropagation();
            setShowEditMenu(!showEditMenu);
          }}
        />

        {showEditMenu && (
          <div
            data-capture-ignore
            role="list"
            className="absolute bg-white border border-brand-cold-metal-200 rounded-md min-w-60 z-20 [&>button]:p-4 [&>button]:cursor-pointer [&>button:hover]:bg-brand-cold-metal-100 [&>button]:w-full [&>button]:text-start"
            style={{
              right: menuPosition.right,
              left: menuPosition.left
            }}
          >
            {React.Children.map(children, child => child)}
          </div>
        )}
      </div>
    </VerticalDotMenuContext.Provider>
  );
};
