import {
  enumCurrency,
  enumUserRole
} from "@roda/graphql/genql";
import {
  useEffect,
  useMemo
} from "react";
import { useNavigate } from "react-router-dom";

import { OnboardingContent } from "~/components/onboarding/OnboardingContent";
import type { OnboardingStep } from "~/components/onboarding/OnboardingProgressBar";
import { routes } from "~/constants/routes";
import {
  useOnboardingDispatch,
  useOnboardingState
} from "~/contexts/OnboardingContext/OnboardingContext";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useSideNavigation } from "~/contexts/SideNavigationContext";
import { useCurrentUser } from "~/contexts/UserContext";

import type { User } from "@roda/graphql/genql";

interface OnboardingProps {
  createFlywheelMode?: boolean;
  firstStep?: OnboardingStep;
}
export const Onboarding: React.FC<OnboardingProps> = ({ createFlywheelMode }) => {
  const { user } = useCurrentUser();

  const {
    step, flywheel: onboardingFlywheel, flywheelGoal: onboardingFlywheelGoal, company: onboardingCompany, user: onboardingUser, invitedUsers
  } = useOnboardingState();

  const { flywheel, loading: fetchingFlywheels } = useSelectedFlywheel();
  const dispatch = useOnboardingDispatch();
  const navigate = useNavigate();
  const { setHideMainSideNav } = useSideNavigation();
  // Make sure that onboarding flywheel has setupComplete as FALSE
  const existingOnboardingFlywheel = useMemo(() => !onboardingFlywheel?.setupComplete ? onboardingFlywheel : null, [ onboardingFlywheel ]);
  const existingOnboardingFlywheelGoal = useMemo(() => !existingOnboardingFlywheel?.setupComplete ? onboardingFlywheelGoal : null, [ existingOnboardingFlywheel?.setupComplete, onboardingFlywheelGoal ]);

  // Initialise onboarding state using current user and flywheel context
  useEffect(() => {
    // Hide main side nav if we're on createFlywheelMode
    if (createFlywheelMode) {
      setHideMainSideNav(true);
    }

    if (user?.role === enumUserRole.RODA_ADMIN) {
      navigate(routes.rodaAdminOrganisationManagement);
    }

    // Redirect to dashboard if onboarding is completed and !createFlywheelMode
    if (user?.company?.completedOnboarding && !createFlywheelMode) {
      navigate(routes.dashboard());
    }
    const activeUsers = (user?.company?.activeUsers || []) as User[];

    if (onboardingUser?.email) {
      return;
    }

    dispatch({
      type: "SET_STATE",
      state: {
        step,
        user: {
          firstName: user?.firstName ?? onboardingUser?.firstName ?? null,
          lastName: user?.lastName ?? onboardingUser?.lastName ?? null,
          email: user?.email ?? onboardingUser?.email ?? null,
          jobTitle: user?.jobTitle ?? onboardingUser?.jobTitle ?? null
        },
        // Always default to onboarding states for createFlywheelMode
        company: user?.company ? {
          id: +user?.company?.id,
          name: user?.company?.name,
          industryId: user?.company?.industryId,
          currency: onboardingCompany?.currency ?? enumCurrency.GBP
        } : onboardingCompany,
        // When in createFlywheelMode we should default to an incompleteFlywheel or fallback to onboarding flywheel
        flywheel: existingOnboardingFlywheel,
        // When in createFlywheelMode we should default to the flywheel goal of the incompleteFlywheel or fallback to onboardingFlywheelGoal
        flywheelGoal: existingOnboardingFlywheelGoal,
        invitedUsers: createFlywheelMode ? activeUsers?.map(user => user.email) : invitedUsers
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ step, user ]);

  // Re-direct out of onboarding if needed
  useEffect(() => {
    // Wait for the flywheels to load - any user without a company id should remain here
    if (!fetchingFlywheels) {
      // If completedOnboarding is true
      if (user?.company?.completedOnboarding && !createFlywheelMode) {
        navigate(routes.dashboard(), { replace: true });
        // If completedOnboarding is not complete but there's a flywheel
      } else if (!user?.company?.completedOnboarding && flywheel) {
        // If setupComplete is true re-direct to ready to start
        if (flywheel.setupComplete) {
          navigate(routes.readyToStart);
        }
      }
    }
  }, [
    createFlywheelMode,
    fetchingFlywheels,
    flywheel,
    navigate,
    user?.company?.completedOnboarding
  ]);

  // Change step function
  const changeStep = (step: OnboardingStep) => {
    dispatch({
      type: "SET_STEP",
      step
    });
  };

  return (
    <div className="flex flex-col gap-2 flex-1">

      <OnboardingContent
        currentStep={step}
        changeStep={changeStep}
        createFlywheelMode={createFlywheelMode}
      />

    </div>
  );
};