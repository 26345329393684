import clsx from "clsx";

import { Avatar } from "~/components/Avatar";
import { ProgressBar } from "~/components/flywheel/roda/ProgressBar";
import { InfoTooltip } from "~/components/InfoTooltip";
import { formatGoalNumber } from "~/utils/formatGoalNumber";
import { getUnitSymbol } from "~/utils/getUnitSymbol";
import { getUserDisplayName } from "~/utils/getUserDisplayName";

import type { FlywheelGoalUnit } from "@roda/shared/types";

interface MetricPreviewProps {
  flywheelGoal?: Partial<FlywheelGoalUnit>;
  currency?: string;
  target?: string;
}
export const FlywheelGoalPreview: React.FC<MetricPreviewProps> = ({
  flywheelGoal, currency, target
}) => {
  const previewText = "bg-brand-cold-metal-100 rounded-md px-3 py-2 ";
  const metricIcon = getUnitSymbol(flywheelGoal?.unitTypeLabel, currency);

  const PreviewName = () => {
    return (
      <div className="flex transition-all flex-row justify-between gap-2 items-center relative overflow-hidden ">
        <p className="truncate w-52 font-medium overflow-hidden">{flywheelGoal?.name || "Total number of customers"}</p>

        <InfoTooltip
          forceOpen
          text={flywheelGoal?.unitDescription || "Key customers you served during the period"}
          slideDirection="top"
        />

      </div>
    );
  };

  const PreviewUnit = () => {
    return (
      <div>

        <div className="flex mb-2 flex-row flex-wrap gap-x-1">
          <p className="text-sm font-medium transition-all ">{flywheelGoal?.unitName || "Number of customers"}</p>

        </div>

        <div className="flex flex-row">

          <p className="min-h-8 text-2xl font-semibold text-left">{flywheelGoal?.unitTypeLabel ? metricIcon : "#"}</p>
        </div>

      </div>
    );
  };

  const PreviewDisplay = () => {
    return (
      <div className="w-full flex flex-col gap-4">

        <div className="flex flex-row flex-wrap gap-2">
          <p className={clsx([ previewText, "w-[60px]" ])} />

          <ProgressBar
            progress={0}
            neutral
          />
        </div>

        <div className="flex flex-row justify-between antialiased items-center text-sm">

          <p
            className={clsx([ previewText, "w-[30px]" ])}
          />

          <p className={clsx([ target ? "" : previewText, "w-[50px] text-right" ])}>{target && formatGoalNumber(+target, metricIcon, {
            shouldCompact: true,
            stripTrailingZeros: true
          })}
          </p>
        </div>

      </div>
    );
  };

  return (
    <div className="flex flex-col p-6 rounded-xl bg-white min-w-[300px] border-solid border-[1px] border-grey-200">
      <div className="flex flex-col gap-y-2">
        {PreviewName()}

        {PreviewUnit()}

        {PreviewDisplay()}

        <div className="flex flex-row items-center gap-2 pt-4">
          <Avatar
            preview
            user={{
              firstName: "",
              lastName: "",
              email: ""
            }}
            px={24}
          />

          <p
            className={clsx([ "min-h-6 w-[40%]", previewText ])}
          >
            {getUserDisplayName({
              firstName: "",
              lastName: "",
              email: ""
            })}
          </p>
        </div>

      </div>
    </div>
  );
};
