/**
 * If elements scrollWidth is greater than clientWidth, truncate the text content of the element
 * Remove end characters one by one until the text fits (including ellipsis)
 */
export function truncateElementText(element: HTMLElement) {
  if (!element?.textContent) {
    return;
  }

  let truncatedText = element.textContent || "";

  // Start by checking if the text overflows
  if (element.scrollWidth > element.clientWidth) {
    // Append ellipsis first
    truncatedText += "…";

    // Continue removing one character at a time until the text fits
    for (let i = 0; i < element.textContent!.length; i++) {
      truncatedText = element.textContent!.slice(0, -1 - i) + "…";
      element.textContent = truncatedText;

      if (element.scrollWidth <= element.clientWidth) {
        break;
      }
    }
  }

  // Set the text of the element to the last valid truncation
  element.textContent = truncatedText;
}