import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

import { Button } from "~/components/Button";
import { EmailInputSchema } from "~/components/form/formSchemas";
import { Input } from "~/components/form/Input";
import { useRequestMagicLink } from "~/hooks/useRequestMagicLink";

import type React from "react";

interface MagicLinkFormProps {
  id: string;
  onLinkSent: (email: string) => void;
}

export const MagicLinkForm: React.FC<MagicLinkFormProps> = ({ id, onLinkSent }) => {
  const {
    register, handleSubmit, getValues, formState: { errors }
  } = useForm<{email: string}>({
    resolver: zodResolver(EmailInputSchema),
    shouldFocusError: false
  });

  const { requestLink, loading } = useRequestMagicLink({ onLinkSent });

  const handleFormSubmit = handleSubmit(() => {
    requestLink(getValues("email"));
  });

  const [ search ] = useSearchParams();
  const message = search.get("message");
  const isErrorPage = location.href.includes("auth/error");

  return (
    <form
      onSubmit={handleFormSubmit}
      className="flex flex-col w-full"
    >

      {isErrorPage ? (

        <div className="flex items-start text-left flex-col">

          <p className="text-2xl font-bold">Sign into roda</p>

          <span
            className="pt-2 text-brand-error-red-600 "
          >
            {message || "Please try again."}
          </span>
        </div>

      ) : (

        <div className="flex items-start text-left flex-col">

          <p className="text-2xl font-bold">Sign into roda</p>

          <p className="text-brand-cold-metal-500 pt-2">We recommend using your work email.</p>
        </div>
      )}

      <div className="flex flex-col mt-8 gap-8">
        <Input
          id={`input-${id}`}
          {...register("email")}
          autoComplete="email"
          inputMode="email"
          type="email"
          autoCapitalize="none"
          label="Email"
          className=""
          hasError={!!errors.email}
          errorMessage={errors.email?.message}
        />

        <Button
          id={`button-${id}`}
          disabled={loading}
          loading={loading}
          type="submit"
          title="Get started"
        />
      </div>

    </form>
  );
};