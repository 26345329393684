import { useTypedMutation } from "@roda/graphql/urql";

import { User } from "~/fragments/index";

import type { MutationGenqlSelection } from "@roda/graphql/genql";

type Params = NonNullable<MutationGenqlSelection["updateUser"]>["__args"];

export const useUpdateUser = () => {
  return useTypedMutation((vars: Params) => ({
    updateUser: {
      __args: vars,
      ...User
    }
  }));
};