import type {
  Company,
  Metric,
  Step,
  User
} from "@roda/graphql/genql";

// If something's typed like `"my_string" | (string & {})` it's to prevent
// TS from generalizing the type to just a regular string type –
// but instead be exactly the string "my_string" (AND/OR any other string).
// Intellisense will then suggest the string "my_string" while still accepting
// other strings.

export const routes = {
  home: "/",
  authError: "/auth/error",
  onboarding: "/onboarding",
  createFlywheel: "/create-flywheel",
  readyToStart: "/ready-to-start",
  newFlywheelSetupComplete: "/flywheel-setup-complete",
  account: "/account",
  rodaAdminOrganisationManagement: "/organisations",
  gettingStarted: "/getting-started",
  companySettings: (companyId: ":companyId" | (Company["id"] & {})) => `${"/organisations/" + companyId}/settings`,
  dashboard: (companyId?: ":companyId" | (Company["id"] & {})) => `${companyId ? "/organisations/" + companyId : ""}/flywheel`,
  flywheelGoal: (companyId?: ":companyId" | (Company["id"] & {})) => `${companyId ? "/organisations/" + companyId : ""}/flywheel/goal`,
  editFlywheelGoal: (companyId?: ":companyId" | (Company["id"] & {})) => `${companyId ? "/organisations/" + companyId : ""}/flywheel/goal/edit`,
  step: (stepId: ":stepId" | (Step["id"] & {}), companyId?: ":companyId" | (Company["id"] & {})) => `${companyId ? "/organisations/" + companyId : ""}${"/flywheel/step/" + stepId}`,
  addMetric: (stepId: ":stepId" | (Step["id"] & {}), companyId?: ":companyId" | (Company["id"] & {})) => `${companyId ? "/organisations/" + companyId : ""}${"/flywheel/step/" + stepId + "/create"}`,
  stepMetric: (stepId: ":stepId" | (Step["id"] & {}), metricId: ":metricId" | (Metric["id"] & {}), companyId?: ":companyId" | (Company["id"] & {})) => `${companyId ? "/organisations/" + companyId : ""}${"/flywheel/step/" + stepId + "/metric/" + metricId}`,
  customiseMetric: (stepId: ":stepId" | (Step["id"] & {}), metricId: ":metricId" | (Metric["id"] & {}), companyId?: ":companyId" | (Company["id"] & {})) => `${companyId ? "/organisations/" + companyId : ""}${"/flywheel/step/" + stepId + "/metric/" + metricId + "/edit"}`,
  organisationSettings: (companyId?: ":companyId" | (Company["id"] & {})) => `${companyId ? "/organisations/" + companyId : ""}/organisation-settings`,
  members: (companyId?: ":companyId" | (Company["id"] & {})) => `${companyId ? "/organisations/" + companyId : ""}/members`,
  editMember: (userId: ":userId" | (User["id"] & {}), companyId?: ":companyId" | (Company["id"] & {})) => `${companyId ? companyId : ""}/members/${userId}/edit`,
  checkIn: (companyId?: ":companyId" | (Company["id"] & {})) => `${companyId ? "/organisations/" + companyId : ""}/check-in`,
  review: (companyId?: ":companyId" | (Company["id"] & {})) => `${companyId ? "/organisations/" + companyId : ""}/review`,
  reviewFlywheelGoal: (companyId?: ":companyId" | (Company["id"] & {})) => `${companyId ? "/organisations/" + companyId : ""}/review/flywheel-goal`,
  reviewMetrics: (companyId?: ":companyId" | (Company["id"] & {})) => `${companyId ? "/organisations/" + companyId : ""}/review/metrics`,
  reviewMetric: (metricId: ":metricId" | (Metric["id"] & {}), companyId?: ":companyId" | (Company["id"] & {})) => `${companyId ? "/organisations/" + companyId : ""}/review/metrics/${metricId}`,
  customiseFlywheel: (companyId?: ":companyId" | (Company["id"] & {})) => `${companyId ? "/organisations/" + companyId : ""}/customise-flywheel`
} as const;