// we only require loose definitions of steps for ease-of-use, only their

import dayjs from "dayjs";

import { withStepTheme } from "~/components/flywheel/roda/RodaFlywheel";
import type { HealthStatus } from "~/components/flywheel/roda/types";
import type { StepType } from "~/contexts/OnboardingContext/onboarding-reducer";
import { getHealthStatusColour } from "~/utils/getHealthStatusColour";
import { getMetricHealthStatus } from "~/utils/getMetricHealthStatus";

import type { Step } from "@roda/graphql/genql";

// metric and name
export type LooseStep<S extends Step | StepType> = Omit<S, "metrics" | "name"> & Pick<S, "metrics" | "name">;

/** Each individual has their own status (waiting/healthy/attention) */
export const MetricStatusDots = ({ step, metricStatus }: {
  step: LooseStep<Step | StepType>,
  metricStatus?: HealthStatus;
}) => {
  const MAX_METRICS = 6;
  const DOT_SIZE = 6;
  const DOT_SPACING = 4;

  return (
    <div className="flex">
      {/* I've had to use SVG circles here because <div> would have weird sizing/spacing defects, presumably some pixel alignment issue on my non-retina screen */}
      {step.metrics?.filter(metric => !metric.toDate || dayjs(metric.toDate).isAfter(dayjs()))?.slice(0, MAX_METRICS).map((metric, i) => {
        const status: HealthStatus = metricStatus || getMetricHealthStatus(metric);

        return (
          <svg
            key={i.toLocaleString()}
            style={{
              ...withStepTheme(getHealthStatusColour(status)),
              width: DOT_SIZE + DOT_SPACING,
              height: DOT_SIZE + 2 // +2 fixes another pixel issue
            } as React.CSSProperties}
          >
            <circle
              cx={(DOT_SIZE + DOT_SPACING) / 2}
              cy={DOT_SIZE / 2}
              r={DOT_SIZE / 2}
              fill="var(--step-theme-medium)"
            />
          </svg>
        );
      })}
    </div>
  );
};