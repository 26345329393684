import { Avatar } from "~/components/Avatar";
import { parseDbDate } from "~/utils/dates/parseDbDate";

import type { User } from "@roda/graphql/genql";
import type React from "react";

interface UpdateNotesProps {
  reportedAt?: string | null;
  reportedBy?: Pick<User, "firstName" | "lastName" | "email" | "avatarS3Key"> | null;
  notes?: string | null;
}

/**
 * Component to display the notes and the user who reported the notes
 * @param reportedAt - The date the notes were reported
 * @param reportedBy - The user who reported the notes
 * @param notes - The notes to display
 * @returns The UpdateNotes component
 */
export const UpdateNotes: React.FC<UpdateNotesProps> = ({
  reportedAt, reportedBy, notes
}) => {
  return (
    <div className="relative p-3 flex-1 flex flex-col max-h-[248px] overflow-hidden">
      <div className="flex-1 relative overflow-y-auto">
        <div className={`${notes ? "text-brand-cold-metal-800 p-2" : "text-brand-cold-metal-400"} break-words`}>
          {notes ? notes : "No comments added"}
        </div>
      </div>

      <div className="relative flex flex-row items-center gap-2 pt-2 pr-4 text-sm">
        {reportedBy && reportedAt && (
          <>
            <Avatar
              user={reportedBy}
              px={24}
            />

            <p className="truncate text-sm font-medium text-brand-cold-metal-500">
              Reported at {parseDbDate(reportedAt).format("lll")}
            </p>
          </>
        )}
      </div>

      <div
        data-capture-ignore
        className="absolute bottom-10 inset-x-0 h-8 bg-gradient-to-t from-white to-transparent pointer-events-none"
      />
    </div>
  );
};