import type { ObjectCannedACL } from "../types";

interface Params {
  baseURL: string;
  /** The key of the s3 object you want to sign e.g. "uploads/123.jpg" */
  key: string;
  /** How you intend to use the signed URL - get for retrieval, put for uploading. Use both if you wish */
  methods: Array<"get" | "put">
  /** Additional options */
  options?: {
    /** File type - required for upload */
    contentType?: string;
    /** ACL */
    acl?: ObjectCannedACL;
  }
}

interface Response {
  uploadURL?: string;
  downloadURL?: string;
  key: string;
  bucket: string;
}

/**
 * Pre-sign S3 URL
 * @param params Params
 * @returns Requested uploadURL and downloadURL
 */
export const signMediaURL = async ({
  baseURL, key, methods, options
}: Params): Promise<Response> => {
  let qs = `?key=${key}`;

  if (options?.contentType) {
    qs += `&type=${options.contentType}`;
  }

  if (options?.acl) {
    qs += `&acl=${options.acl}`;
  }

  methods.forEach(method => {
    qs += `&${method}=true`;
  });

  const response = await fetch(baseURL + `/handle-media${qs}`, {
    method: "GET",
    headers: options?.acl ? { "x-amz-acl": options?.acl } : undefined
  });

  return await response.json() as Response;
};

export const fetchImageFromUri = async (uri: string) => {
  const response = await fetch(uri);

  return await response.blob();
};

export const getFallbackFileName = (uri: string) => {
  return uri?.substring(uri.lastIndexOf("/") + 1, uri.length);
};
