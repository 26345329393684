import type { ChangeStep } from "~/components/onboarding/OnboardingProgressBar";

import type { OnboardingStepDetails } from "./OnboardingProgressBar";

interface OnboardingProgressProps {
  stepDetails: OnboardingStepDetails;
  changeStep: ChangeStep;
  shouldBeEmpty: boolean;
  totalSteps: number;
}

export const OnboardingProgressStep: React.FC<OnboardingProgressProps> = ({
  shouldBeEmpty, changeStep, stepDetails, totalSteps
}) => {
  const flexBasisPercentage = `${100 / totalSteps}%`;

  return (
    <button
      className={`${shouldBeEmpty ? "bg-brand-cold-metal-200" : "bg-brand-cold-metal-700"} rounded-lg mx-1 cursor-pointer h-[3px]`}
      style={{ flex: `1 1 ${flexBasisPercentage}` }}
      disabled={shouldBeEmpty}
      onClick={() => changeStep(stepDetails.step)}
    >
      <p className="text-xs text-white mx-1" />
    </button>
  );
};
