import html2canvas from "html2canvas";

import type { ImageCaptureTargetId } from "~/contexts/ImageCaptureContext";
import { truncateElementText } from "~/utils/truncateElementText";

/**
 * Capturing flow namely involves 3 types of element attributes:
 * - `data-capture-ignore` (although classname __CAPTURE_IGNORE is also supported
 * - `data-capture-target` (added by wrapping in `<ImageCaptureTarget>`)
 *
 * Where:
 * - `data-capture-ignore`: is used to ignore elements from being captured
 * - `data-capture-target`: is used to specify the element to capture
 *
 */
export const handleCapture = async (
  captureId: ImageCaptureTargetId,
  onCapture: (url: string) => void,
  html2canvasOptions?: Parameters<typeof captureElement>[1]
) => {
  const captureTarget = document.querySelector(`[data-capture-target='${captureId}']`);

  if (!captureTarget) {
    console.error("No capture target found");

    return;
  }

  return captureElement(captureTarget, html2canvasOptions)
    .then(onCapture)
    .catch(console.error);
};

export const captureElement = (el: Element | HTMLElement, html2canvasOptions?: Parameters<typeof html2canvas>[1]) => new Promise<string>((resolve, rej) => {
  if (!el) return rej("No element passed for capture");

  const { width, height } = el.getBoundingClientRect();

  return html2canvas(el as HTMLElement, {
    width,
    height,
    imageTimeout: 9000, // ms
    ignoreElements: node => {
      // prefer usage of data-capture-ignore attribute
      // -but sometimes custom components don't accept data-attrs but will accept classnames
      return node.classList.contains("__CAPTURE_IGNORE") || node.getAttribute("data-capture-ignore") === "true";
    },
    ...(html2canvasOptions || {}),
    onclone(_, capturedEl) {
      capturedEl.style.setProperty("--width", `${width}px`);
      capturedEl.style.setProperty("--height", `${width}px`);
      // fixes layout whereby el resizes within its cloned container
      capturedEl.style.setProperty("width", "var(--width)", "important");
      capturedEl.style.setProperty("height", "var(--height)", "important");

      // removes buttons type attr which breaks it from rendering (wtf!!)
      capturedEl.querySelectorAll("button[type]").forEach(button => {
        button.removeAttribute("type");
      });
      // truncate all elements with the truncate class
      // (canvas doesn't support text-overflow: ellipsis)
      capturedEl.querySelectorAll(".truncate").forEach(e => truncateElementText(e as HTMLElement));
      html2canvasOptions?.onclone?.(_, capturedEl);
    }
  }).then(canvas => {
    canvas.toBlob(blob => {
      if (!blob) return rej("Conversion failed");

      resolve(URL.createObjectURL(blob));
    });
  });
});