import { useContext } from "react";
import {
  useNavigate,
  useParams
} from "react-router-dom";

import { CalloutBadge } from "~/components/CalloutBadge";
import { ConfirmationPopup } from "~/components/ConfirmationPopup";
import { VerticalDotMenuContext } from "~/components/VerticalDotMenu";
import { routes } from "~/constants/routes";
import { useCustomiseFlywheelGoalDispatch } from "~/contexts/CustomiseFlywheelGoalContext/CustomiseFlywheelGoalContext";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";

interface EditFlywheelGoalTypeMenuItemProps {
  openPopup: () => void;
  closePopup: () => void;
  isOpen: boolean;
}

export const EditFlywheelGoalTypeMenuItem: React.FC<EditFlywheelGoalTypeMenuItemProps> = ({
  openPopup, closePopup, isOpen
}) => {
  const { closeVDotMenu } = useContext(VerticalDotMenuContext);
  const { flywheel } = useSelectedFlywheel();
  const navigate = useNavigate();
  const dispatch = useCustomiseFlywheelGoalDispatch();
  const params = useParams() as { companyId?: string };

  return (
    <>
      <button
        onClick={openPopup}
        className="leading-normal"
      >
        Change goal type
      </button>

      {/** Edit flywheel goal owner popup */}
      <ConfirmationPopup
        subtitle={(
          <div className="mb-4">
            <CalloutBadge variant="warning" />
          </div>
        )}
        isOpen={isOpen}
        title="Change goal type?"
        cancelText="Cancel"
        onCancel={() => {
          closePopup();
          closeVDotMenu();
        }}
        onContinue={() => {
          if (flywheel?.latestFlywheelGoal) {
            dispatch({
              type: "SET_SELECTED_FLYWHEEL_GOAL",
              flywheelGoal: {
                cap: flywheel.latestFlywheelGoal.cap,
                name: flywheel.latestFlywheelGoal.name,
                unitDescription: flywheel.latestFlywheelGoal.unitDescription,
                unitDisplay: flywheel.latestFlywheelGoal.unitDisplay,
                unitName: flywheel.latestFlywheelGoal.unitName,
                unitTypeLabel: flywheel.latestFlywheelGoal.unitTypeLabel
              }
            });
            navigate(routes.editFlywheelGoal(params.companyId));
          }
        }}
        continueText="Continue"
        text={(
          <p className="text-pretty">
            Changing your goal type will remove all saved data relating to the current goal. This will not affect your metrics.
          </p>
        )}
      />
    </>
  );
};