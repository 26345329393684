import type { CurrencyIcon } from "~/utils/getCurrencyIcon";

type NumberFormatUnit = "acre" | "bit" | "byte" | "celsius" | "centimeter" | "cup" | "day" | "degree" | "fahrenheit" | "fluid-ounce" | "foot" | "gallon" | "gigabit" | "gigabyte" | "gram" | "hectare" | "hour" | "inch" | "kilobit" | "kilobyte" | "kilogram" | "kilometer" | "liter" | "megabit" | "megabyte" | "meter" | "mile" | "mile-scandinavian" | "milliliter" | "millimeter" | "millisecond" | "minute" | "month" | "ounce" | "percent" | "petabyte" | "pound" | "second" | "stone" | "terabit" | "terabyte" | "week" | "yard" | "year";

/**
  OMG(!!) what are we doing declaring a namespace for a global object?!

  Well, Typescript doesn't seem to declare the full interface for
  `Intl.NumberFormatOptions` (see mozilla link below). And there's just too much
  goodness in there to ignore. So, I'm redeclaring the interface here to include
  the missing ones!

  GH issue: https://github.com/microsoft/TypeScript/issues/52072

  Ref: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#options
 */

// eslint-disable-next-line @typescript-eslint/no-namespace
declare namespace Intl {
  interface NumberFormatOptions {
    style?: "decimal" | "currency" | "percent" | "unit" | undefined;
    /** @requires {style: "unit"} - ref: https://tc39.es/ecma402/#table-sanctioned-single-unit-identifiers */
    unit?: NumberFormatUnit | undefined;
    /** @default "short" - i.e. short=>'16 l' | long=>'16 liters' | narrow=>'16l' */
    unitDisplay?: "long" | "short" | "narrow" | undefined;
    /** @requires {style: "currency"} - ref: https://en.wikipedia.org/wiki/ISO_4217#List_of_ISO_4217_currency_codes */
    currency?: keyof typeof CurrencyIcon | undefined;
    /** @default "symbol" - i.e. code=>'USD' | symbol=>'$' | name=>'dollar' */
    currencyDisplay?: "symbol" | "narrowSymbol" | "code" | "name" | undefined;
    notation?: "standard" | "compact" | undefined;
    compactDisplay?: "short" | "long" | undefined;
    roundingMode?: "ceil" | "floor" | "expand" | "trunc" | "halfCeil" | "halfFloor" | "halfExpand" | "halfTrunc" | "halfEven" | undefined;
    trailingZeroDisplay?: "auto" | "stripIfInteger" | "lessPrecision" | undefined;
    roundingPriority?: "auto" | "morePrecision" | "lessPrecision" | undefined;
    roundingIncrement?: 1 | 2 | 5 | 10 | 20 | 25 | 50 | 100 | 200 | 250 | 500 | 1000 | 2000 | 2500 | 5000 | undefined;
    minimumIntegerDigits?: number | undefined;
    minimumFractionDigits?: number | undefined;
    maximumFractionDigits?: number | undefined;
    minimumSignificantDigits?: number | undefined;
    maximumSignificantDigits?: number | undefined;
  }
}

/**
  Typed some of the options for the `Intl.NumberFormat` constructor which
  otherwise only requires 'string'. And narrows the scope of the options to the ones that are relevant.

  I haven't documented the digit options because they are already well documented and vast in capabilities.
  See:
  https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#digit_options

  @example
  formatNumber(123456.78910, {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2
  }); // => "$123,456.79"

  formatNumber(1234567.89, {
    style: "unit",
    unit: "hour",
    unitDisplay: "long"
  }); // => "1,234,567.89 hours"

  formatNumber(1.23456789, {
    style: "decimal",
    minimumIntegerDigits: 2
  }); // => "01.235"

  formatNumber(123456789, {
    notation: "compact",
    compactDisplay: "short"
  }) // => "123M" (long => "123 million")
 */
export const formatNumber = (value: number, opts: MaybeFunctionalProps<Intl.NumberFormatOptions, number> = { style: "decimal" }) => {
  let locale = "en-GB";

  if (opts.style === "currency" && opts.currency === "USD") {
    locale = "en-US";
  } else if (opts.style === "currency" && opts.currency === "EUR") {
    locale = navigator?.language ?? navigator?.languages?.at(-1) ?? "en-IE";
  }

  const computedOpts: Intl.NumberFormatOptions = {};

  for (const key in opts) {
    if (typeof opts[ key as keyof typeof opts ] === "function") {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - I'm not sure how to appease TS here, the key is obviously
      // a keyof typeof opts since we're iterating over it
      computedOpts[ key as keyof typeof opts ] = opts[ key ](value);
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - I'm not sure how to appease TS here, the key is obviously
      // a keyof typeof opts since we're iterating over it
      computedOpts[ key ] = opts[ key ];
    }
  }

  const formatted = new Intl.NumberFormat(locale, computedOpts).format(value);

  if (opts.style !== "currency") {
    return formatted;
  }

  // ensure 2 decimal places (if exist) for currency
  // it:
  // - matches a comma or period
  // - followed by a digit
  // - followed by anything that isn't a digit
  // ..
  // and replaces it with the same match, but with a 0 before the ending
  // non-digit(s), if any exist
  return formatted
    .replace(/([,.])(\d)([^\d].+)?$/, "$1$2" + "0" + "$3");
};