import { CheckInBadge } from "~/components/check-in/CheckInBadge";
import { Icon } from "~/components/Icon";
import dayjs from "~/utils/dayjs";

import type { Metric } from "@roda/graphql/genql";
import type { Dayjs } from "dayjs";

interface CheckInSummaryWeekBadgeProps {
  metrics: Metric[]
  weekStart: Dayjs;
  flywheelCycleNotStarted: boolean
}

export const CheckInSummaryWeekBadge: React.FC<CheckInSummaryWeekBadgeProps> = ({
  metrics, weekStart, flywheelCycleNotStarted
}) => {
  const allCheckedIn = metrics.every(metric => !metric.isCheckInDue);

  if (flywheelCycleNotStarted) {
    return null;
  }

  // If there's an update for all the weeks passed show the checked-in badge
  if (allCheckedIn) {
    return (
      <CheckInBadge text="Checked-in" />
    );
  }

  const atLeastOneNeedsCheckIn = metrics.some(metric => metric.isCheckInDue);

  // If the target week start is last week, show a check-in due badge if there's a check-in due for any of these metrics
  if (weekStart.isBetween(dayjs().startOf("isoWeek").subtract(1, "week"), dayjs().endOf("isoWeek").subtract(1, "week"), null, "[]") && atLeastOneNeedsCheckIn) {
    return (
      <CheckInBadge
        text="Check-in due"
        icon={<Icon.Clock className="w-2.5" />}
        bgColour="bg-brand-check-in-due-100"
      />
    );
  }

  return null;
};