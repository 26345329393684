import {
  useState,
  useCallback
} from "react";

import { Loading } from "~/components/Spinner";
import { useRequestMagicLink } from "~/hooks/useRequestMagicLink";

interface MagicLinkSentProps {
  email: string;
}
export const MagicLinkSent: React.FC<MagicLinkSentProps> = ({ email }) => {
  const { requestLink, loading } = useRequestMagicLink();
  const [ retries, setRetries ] = useState(0);

  const handleResendClick = useCallback(() => {
    requestLink(email);
    setRetries(retries + 1);
  }, [
    retries,
    setRetries,
    email,
    requestLink
  ]);

  return (
    <div className="flex flex-col text-left">
      <p className="pb-3 text-3xl font-semibold">
        Keep an eye out for an email!
      </p>

      <div className="flex flex-col gap-y-2 text-[#6B7280]">
        <p>
          We have sent <span className="font-medium text-black pb-2">{email}</span> a welcome email. Simply click the link inside, to effortlessly log into Roda.
        </p>

        {retries > 4 ? (
          <p>If the email isn't being delivered then there could be a temporary problem with delivery. Please try again later, or contact support if this problem persists.</p>
        ) : (

          <p>
            <span>Didn't receive the email? </span>

            {loading ? (
              <span className="text-black font-medium">
                Resending...<Loading.Spinner className="mt-[5px] ml-1" />
              </span>
            ) : (
              <>
                <span
                  className="cursor-pointer underline text-black font-medium"
                  onClick={handleResendClick}
                >Click here
                </span>

                <span> to resend it.</span>
              </>

            )}

          </p>
        )}

      </div>
    </div>
  );
};