import React from "react";
import { twMerge } from "tailwind-merge";

import { Icon } from "./Icon";

import type { IconBaseProps } from "react-icons";

interface SpinnerProps extends IconBaseProps {
  containerProps?: JSX.IntrinsicElements["span"]
}

const BaseSpinner = ({ containerProps, ...iconProps }: SpinnerProps) => (
  <span
    {...containerProps}
    className={twMerge("inline-flex min-w-[1em] flex-center w-5 h-5 relative", containerProps?.className)}
  >
    <Icon.Loader
      {...iconProps}
      className={twMerge("inset-0 absolute size-full text-current animate-spin [animation-duration:_0.5s]", iconProps.className)}
    />
  </span>
);

/**
 * For more control/predictability over SVG (like img, video etc.) its advisable
 * to always have it wrapped inside a span/div, and scaled to fill its 100%.
 *
 * Thats why for the position focused variants we apply yet another container,
 * rather than apply the styled directly to the first wrapper.
 */

/**
 * Aligns to center of screen, or nearest not-statically positioned ancestor
 */
const AbsoluteCenter = ({ containerProps, ...iconProps }: SpinnerProps) => {
  return (
    <div className="absolute-center absolute inset-0 h-full w-full">
      <BaseSpinner
        containerProps={{
          ...containerProps,
          className: twMerge("absolute absolute-center", containerProps?.className)
        }}
        {...iconProps}
      />
    </div>
  );
};

/**
 * Centers within a flex block
 */
const FlexCenter = ({ containerProps, ...iconProps }: SpinnerProps) => {
  return (
    <div className="flex w-full flex-1 flex-center">
      <BaseSpinner
        containerProps={containerProps}
        {...iconProps}
      />
    </div>
  );
};

export const Loading = {
  Spinner: BaseSpinner,
  AbsoluteCenter,
  FlexCenter
};