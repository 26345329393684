import { forwardRef } from "react";

import { Button } from "~/components/Button";
import { Modal } from "~/components/Modal";
import { Loading } from "~/components/Spinner";
import { cn } from "~/utils/cn";

import type {
  ComponentProps,
  PropsWithChildren
} from "react";

interface ConfirmationPopupProps extends PropsWithChildren {
  isOpen: boolean;
  onContinue: () => void;
  onCancel?: () => void;
  loading?: boolean;
  title: string;
  subtitle?: React.ReactNode | (string & {});
  text?: React.ReactNode | (string & {});
  iconComponent?: React.ReactNode;
  continueText?: string | (React.ReactNode & {});
  cancelText?: string;
  continueButtonClassName?: ComponentProps<"button">["className"];
  continueDisabled?: boolean;
}
/**
 * A custom confirmation popup component with loading state title, text, and optional icon.
 * @param isOpen - Whether the popup is open.
 * @param onContinue - Function to call when the continue button is pressed.
 * @param onCancel - Function to call when the cancel button is pressed.
 * @param loading - Whether the popup is in a loading state.
 * @param title - Title to display on the popup.
 * @param subtitle - Optional subtitle to display above the title.
 * @param text - Text to display on the popup.
 * @param iconComponent - Optional: Icon to display on the left side of the popup. please use our custom Icon component.
 * @param continueDisabled - Whether the continue button should be disabled
 * @param continueButtonClassName - Optional: Classname to apply to the continue button.
 * @returns {React.ReactElement} - Rendered confirmation popup component.
 */
export const ConfirmationPopup = forwardRef<HTMLDivElement, ConfirmationPopupProps>(({
  isOpen,
  onContinue,
  onCancel,
  loading,
  title,
  subtitle,
  text,
  iconComponent,
  continueText = "Continue",
  cancelText = "Cancel",
  continueDisabled,
  continueButtonClassName,
  children
}, ref) => {
  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      onCancel={() => onCancel?.()}
    >

      {/* Modal content */}
      <div
        className="bg-white animate-in fade-in p-8 rounded-lg shadow-lg w-[90%] text-left z-20 max-w-[400px]"
        ref={ref}
      >
        {iconComponent && (
          <div className="flex justify-start border-[1px] border-brand-cold-metal-200 rounded-lg items-center mb-6 p-3 w-fit">
            {iconComponent}
          </div>
        )}

        {subtitle && (
          <p className="text-xs mb-2 text-brand-cold-metal-500">{subtitle}</p>
        )}

        <p className="text-lg font-semibold mb-4">{title}</p>

        <div className="mb-8 flex flex-col gap-4">
          {text && (
            <p className="text-xs sm:text-base text-brand-cold-metal-500">
              {text}
            </p>
          )}

          {children}
        </div>

        {loading ? (
          <div className="flex justify-center items-center">
            <Loading.Spinner containerProps={{ className: "w-8 h-8" }} />
          </div>
        ) : (
          <div className="flex gap-5">
            <Button
              onClick={onContinue}
              title={continueText}
              className={cn("w-full", continueButtonClassName)}
              disabled={continueDisabled}
            />

            {onCancel && (
              <Button
                onClick={onCancel}
                title={cancelText}
                className="bg-white border-brand-cold-metal-200 text-brand-cold-metal-500 hover:contrast-75 border-2 w-full"
              />
            )}

          </div>
        )}
      </div>
    </Modal>
  );
});
