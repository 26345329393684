import { FlywheelTemplateUnitTypeLabelEnum } from "@roda/shared/types";

import dayjs from "~/utils/dayjs";

import { constructUtcDate } from "./dates/constructUtcDate";

import type {
  FlywheelGoalUpdateArgs,
  Subgoal
} from "@roda/graphql/genql";

interface Params {
  flywheelGoalId: number;
  subgoal: Subgoal;
  totalProgressForSubgoal: number;
  goalTypeLabel: FlywheelTemplateUnitTypeLabelEnum;
}
/**
 * For a given subgoal, we receive a total progress value from the user - e.g. £1000
 * This function splits that value across the past months of that subgoal (i.e. 3 months for past subgoals,
 * or the number of past months for the current subgoal)
 *
 * We make sure that we divide the value to always result in the exact value entered by the user - even if
 * that means splitting the decimal value slightly unequally
 *
 * E.g. for £1000 for a past subgoal, we split across 3 months
 * £1000 / 3 = £333.33recurring - which won't store properly in our database
 * So instead, we floor this value - floored £333.33recurring = £333
 * Then we use that as the update value for months 1 and 2
 * And add on the remainder for the 3rd month: £1000 - (2 * £333) = £334, which we store for the third update
 */
export const calculateSubgoalMonthUpdates = (params: Params): FlywheelGoalUpdateArgs[] => {
  const {
    flywheelGoalId, subgoal, totalProgressForSubgoal, goalTypeLabel
  } = params;

  const now = dayjs();
  const subgoalStartDate = dayjs(subgoal.startDate);
  const subgoalEndDate = dayjs(subgoal.endDate);
  // Calculate the number of months passed in the given quarter - 3 for any past quarter
  // or the number of past months for the current quarter (max 2)
  const numberOfMonthsElapsedInQuarter = subgoalEndDate.isBefore(now) ? 3 : Math.abs(subgoalStartDate.diff(now, "months"));
  // Divide the total progress value by the number of elapsed months to get the proportion of the progress
  // to assign to each month. Floor this so we get an integer value - this lets us account for recurring and
  // decimal values by avoiding splitting the decimal across multiple months - instead we'll put the full
  // decimal remainder on the update for the final month of the quarter
  // FOR PERCENTAGES DO NOTHING - JUST RETURN THE SAME PROGRESS for all months elapsed
  const integerMonthProgressWithoutDecimal = goalTypeLabel !== FlywheelTemplateUnitTypeLabelEnum.PERCENTAGE ? Math.floor(totalProgressForSubgoal / numberOfMonthsElapsedInQuarter) : totalProgressForSubgoal;

  // Create an array of flywheel goal update items for this subgoal
  return Array(numberOfMonthsElapsedInQuarter).fill(0).map((_, index) => {
    // If this month is the last in the quarter, figure out the remainder from all of the other floored values
    // and use this (possibly a decimal) as the update value - which lets us account for decimals whilst
    // avoiding recurring values when splitting
    const monthUpdateValue = goalTypeLabel !== FlywheelTemplateUnitTypeLabelEnum.PERCENTAGE ? (index === numberOfMonthsElapsedInQuarter - 1 ? getFinalMonthValue(totalProgressForSubgoal, integerMonthProgressWithoutDecimal, numberOfMonthsElapsedInQuarter) : integerMonthProgressWithoutDecimal) : integerMonthProgressWithoutDecimal;
    const monthStartDate = subgoalStartDate.add(index, "months").startOf("month");

    return {
      // Pass this through so we don't have to calculate which subgoal this belongs to
      subgoalId: parseInt(subgoal.id),
      flywheelGoalId,
      value: monthUpdateValue.toString(),
      // Set the startDate to the start of the given month within the quarter
      startDate: constructUtcDate(monthStartDate)
    };
  });
};

const getFinalMonthValue = (totalProgressForSubgoal: number, integerMonthProgress: number, numberOfMonthsElapsedInQuarter: number) => {
  // Figure out the number of months we've already divided the progress across
  const numberOfMonthsElapsedBeforeCurrent = numberOfMonthsElapsedInQuarter === 1 ? 0 : numberOfMonthsElapsedInQuarter - 1;

  // Subtract the total so far (an integer) from the full total we're expecting (possibly a decimal)
  // This will assign any decimal remainder to this final month in the quarter
  return totalProgressForSubgoal - (integerMonthProgress * numberOfMonthsElapsedBeforeCurrent);
};