import { HealthStatus } from "~/components/flywheel/roda/types";

export const getHealthStatusColour = (status: HealthStatus) => {
  switch (status) {
    case HealthStatus.CheckInDue:
      return "blue";
    case HealthStatus.Healthy:
      return "green";
    case HealthStatus.NeedsAttention:
      return "orange";
    default:
      return "grey";
  }
};