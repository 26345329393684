import { useTypedMutation } from "@roda/graphql/urql";

import { Metric } from "~/fragments/metric";

import type { MutationGenqlSelection } from "@roda/graphql/genql";

type Params = NonNullable<MutationGenqlSelection["toggleActive"]>["__args"];

export const useMetricToggleActive = () => {
  return useTypedMutation((vars: Params) => ({
    toggleActive: {
      __args: vars,
      ...Metric
    }
  }));
};