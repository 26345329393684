import clsx from "clsx";

import { Button } from "~/components/Button";
import { Icon } from "~/components/Icon";

interface AddMetricButtonProps {
  onClick: () => void;
  isFirst?: boolean;
  disabled?: boolean;
  highlight?: boolean;
}

export const AddMetricButton: React.FC<AddMetricButtonProps> = ({
  isFirst, onClick, highlight, disabled
}) => {
  return (
    <Button
      className={clsx("rounded-lg bg-brand-cold-metal-100 flex flex-row justify-center items-center py-2.5 px-6 gap-x-2 w-full hover:contrast-[90%] mt-5 relative text-brand-cold-metal-700", isFirst && " bg-brand-check-in-due-100", highlight && "ring-2 ring-brand-attention-orange-200")}
      onClick={onClick}
      disabled={disabled}
      iconComponent={<Icon.PlusCircle className="text-brand-cold-metal-700" />}
      title={`Add ${isFirst ? "your first" : "another"} metric`}
    />
  );
};