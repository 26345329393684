import { FlywheelTemplateUnitTypeLabelEnum } from "@roda/shared/types";

import { getCurrencyIcon } from "~/utils/getCurrencyIcon";

/**
 * Returns the correct symbol for the unit type, such as
 * currency, percentage, etc. Falls back to `#`.
 *
 * @example <>{getUnitSymbol("Currency", "GBP")}{value}</>
 */
export const getUnitSymbol = (unitTypeLabel?: FlywheelTemplateUnitTypeLabelEnum | string, currency?: string, hideCount?: boolean) => {
  switch (unitTypeLabel?.trim() || FlywheelTemplateUnitTypeLabelEnum.CURRENCY) {
    case FlywheelTemplateUnitTypeLabelEnum.CURRENCY:
      return getCurrencyIcon(currency || "GBP");
    case FlywheelTemplateUnitTypeLabelEnum.PERCENTAGE:
      return "%";
    case FlywheelTemplateUnitTypeLabelEnum.COUNT:
      return hideCount ? "" : "#";
    default:
      return hideCount ? "" : "#";
  }
};