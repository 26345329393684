import { removeEmojis } from "@roda/shared/utils";
import z from "zod";

import { removeExtraSpaces } from "~/utils/validation";

export const CompanyDetailsSchema = z.object({
  name: z.string().trim().min(1, "Required").max(50).transform(removeExtraSpaces).refine(value => {
    const sanitized = removeEmojis(value);

    return sanitized === value;
  }, { message: "Emojis are not allowed" }),
  industryId: z.number().min(1, "Required"),
  currency: z.string().trim().min(1, "Required")
});

export const FlywheelCycleSchema = z.object({ achieveByDate: z.date().nullable() });

export const FlywheelGoalProgressSchema = () => z.object({
  subgoals: z.record(z.string(), z.object({
    id: z.coerce.number().optional(),
    goal: z.string().trim().min(1, "The current quarter target is required").regex(/^[\d.]+$/, "Invalid number"),
    progress: z.string()
      .trim()
      .refine(val => val === "" || /^[\d.]+$/.test(val), { message: "Invalid number" })
      .optional()
  }))
});

export const StepAliasSchema = z.object({ alias: z.string().trim().max(25, "Maximum character count exceeded").transform(removeExtraSpaces) });
