import type {
  ChartEvent,
  Chart as ChartJS
} from "chart.js/auto";

/**
 * Get Label Being Hovered
 * A function to determine what label is being hovered on a bar chart
 */
export const getLabelBeingHovered = (
  event: ChartEvent,
  chart: ChartJS
) => {
  const {
    data, chartArea: {
      left, right, top, bottom
    }
  } = chart;

  if (!event.x || !event.y) {
    return null;
  }

  // Check if the mouse is within the chart area
  if (event.x < left || event.x > right || event.y < top || event.y > bottom) {
    return null; // Mouse is outside the chart area
  }

  if (!data.labels) {
    return null;
  }
  const barWidth = (right - left) / data.labels.length;
  // Calculate the index of the hovered segment
  const roundedIndex = Math.floor((event.x - left) / barWidth);
  // Extract week number from the label. E.g. "W1", "W2" or "M1", "M2"
  const label = chart.data.labels ? chart.data.labels[ roundedIndex ] as string : "";

  return label;
};