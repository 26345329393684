import dayjs from "~/utils/dayjs";
import { getArrayOfFlywheelMonths } from "~/utils/getArrayOfFlywheelMonths";
import { getLabelBeingHovered } from "~/utils/getLabelBeingHovered";

import type {
  Chart as ChartJS,
  ChartEvent
} from "chart.js/auto";
import type { Dayjs } from "dayjs";

/**
 * Handles Hovering over a month bar chart
 * Checks the current hovered label, and shows a cursor for valid months
 */
export const handleBarChartHoverForMonth = (
  event: ChartEvent,
  chart: ChartJS,
  flywheelStartMonth: Dayjs | null
) => {
  const chartElement = document.getElementById("performance-history-chart");
  const label = getLabelBeingHovered(event, chart);
  // get dayjs object for each month for the year beginning with the flywheelStartMonth
  const monthStarts = getArrayOfFlywheelMonths(flywheelStartMonth);
  const monthIndex = monthStarts.findIndex(month => dayjs(month).format("MMM").toUpperCase() === label);
  const selectedMonth = dayjs(monthStarts[ monthIndex ]).startOf("month").startOf("month");

  if (chartElement) {
    if (!selectedMonth.isAfter(dayjs().endOf("month").subtract(1, "m"))) {
      chartElement.style.cursor = "pointer";
    } else {
      chartElement.style.cursor = "";
    }
  }
};

/**
 * Handles Hovering over a week bar chart
 * Checks the current hovered label, and shows a cursor for valid weeks
 */
export const handleBarChartHoverForWeek = (
  event: ChartEvent,
  chart: ChartJS,
  flywheelStartWeek: Dayjs | null
) => {
  const chartElement = document.getElementById("performance-history-chart");
  const label = getLabelBeingHovered(event, chart);

  if (!label) {
    return null;
  }
  const weekNumber = parseInt(label.substring(1), 10); // Remove the "W" and parse the number
  // Calculate the start of the selected week based on the week number
  const newSelectedWeekStart = dayjs(flywheelStartWeek).startOf("year").add(weekNumber - 1, "week").startOf("isoWeek");

  if (chartElement) {
    if (!newSelectedWeekStart.isAfter(dayjs().endOf("isoWeek").subtract(1, "w"))) {
      chartElement.style.cursor = "pointer";
    } else {
      chartElement.style.cursor = "";
    }
  }
};