import { Icon } from "~/components/Icon";

export const BackButtonHeader = ({ label, onClick }: {
  label: string, onClick: () => void
}) => {
  return (
    <header className="flex flex-row gap-3 items-center border-brand-cold-metal-200 border-solid border-b w-full">
      <button
        className="flex-1 font-semibold p-3 pr-6 truncate capitalize text-left flex items-center gap-4 hover:bg-brand-cold-metal-100"
        title="Go back"
        aria-label="Go back to main menu"
        onClick={() => onClick()}
      >
        <Icon.ChevronLeft />

        {label}
      </button>
    </header>
  );
};