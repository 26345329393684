export const CurrencyIcon = {
  EUR: "€",
  USD: "$",
  GBP: "£"
};

export const getCurrencyIcon = (currency: string): string => {
  switch (currency) {
    case "EUR":
      return CurrencyIcon.EUR;
    case "USD":
      return CurrencyIcon.USD;

    default:
      return CurrencyIcon.GBP;
  }
};