// Used in the AvatarControls component to resize and smooth the image before uploading it to the server
export const resizeAndSmoothImage = (file: File, maxSize: number): Promise<Blob | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = event => {
      const img = new Image();

      img.src = event.target?.result as string;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const aspectRatio = img.width / img.height;
        let newWidth,
          newHeight;

        if (img.width > img.height) {
          newWidth = maxSize;
          newHeight = maxSize / aspectRatio;
        } else {
          newHeight = maxSize;
          newWidth = maxSize * aspectRatio;
        }

        canvas.width = newWidth;
        canvas.height = newHeight;

        if (ctx) {
          ctx.imageSmoothingEnabled = true;
          ctx.imageSmoothingQuality = "high";
          ctx.drawImage(img, 0, 0, newWidth, newHeight);
          // Applying slight blur to the image to reduce pixelation
          ctx.filter = "blur(0.75px)";
          ctx.drawImage(canvas, 0, 0);
          canvas.toBlob(blob => resolve(blob), file.type);
        } else {
          reject(new Error("Failed to get canvas context"));
        }
      };
    };
    reader.onerror = error => reject(error);
  });
};