import type { FlywheelTemplateUnit } from "@roda/shared/types";

interface SearchResultProps {
  metricUnit: FlywheelTemplateUnit;
  onClick: () => void;
}

export const SearchResult: React.FC<SearchResultProps> = ({ metricUnit, onClick }) => {
  return (
    <div
      className="flex flex-col gap-y-1 cursor-pointer hover:bg-brand-cold-metal-100 px-3 py-2.5 rounded-lg"
      onClick={onClick}
    >
      <p className="text-sm">{metricUnit.name}</p>

      <p className="text-xs text-brand-cold-metal-500">{metricUnit.description}</p>
    </div>
  );
};