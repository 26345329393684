import { Subgoal } from "~/fragments/subgoal";
import { User } from "~/fragments/user";

import type { FlywheelGoalGenqlSelection } from "@roda/graphql/genql";

export const FlywheelGoal: FlywheelGoalGenqlSelection = {
  id: true,
  achieveBy: true,
  ownerId: true,
  owner: User,
  createdBy: true,
  goal: true,
  cap: true,
  name: true,
  unitName: true,
  unitType: true,
  unitTypeLabel: true,
  unitDisplay: true,
  unitDescription: true,
  updateFrequency: true,
  subgoals: Subgoal,
  latestTotalValue: true,
  checkInSubgoal: Subgoal,
  isHealthy: true,
  fromDate: true,
  toDate: true
};
