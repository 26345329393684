import { FlywheelTemplateUnitTypeLabelEnum } from "@roda/shared/types";

import { constructUtcDate } from "~/utils/dates/constructUtcDate";
import dayjs from "~/utils/dayjs";

import type { CreateSubgoalType } from "@roda/core/flywheel-goal/createFlywheelGoalAndSubgoals";
import type { Timeframe } from "@roda/graphql/genql";

interface GetSubgoalsParams {
  mainGoal: string;
  achieveByDate: Date;
  timeframe: Timeframe;
  typeLabel: FlywheelTemplateUnitTypeLabelEnum;
}

/**
 * Function for breaking down subgoals depending on a timeframe
 * For each subgoal, we get a start and an end date depending on the number of total subgoals
 * @returns array of subgoals
 */
export const breakDownSubgoals = ({
  mainGoal,
  achieveByDate,
  timeframe,
  typeLabel
}: GetSubgoalsParams): CreateSubgoalType[] => {
  const achieveByYear = dayjs(achieveByDate).year();
  const achieveByMonth = dayjs(achieveByDate).month();
  let segmentsNo = 4; // Defaults to quarters

  switch (timeframe) {
    case "MONTHLY":
      segmentsNo = 12; // 12 months in a year
      break;
    case "QUARTERLY":
      segmentsNo = 4; // 4 quarters in a year
      break;
    case "HALF_YEARLY":
      segmentsNo = 2; // 2 6 months in a year
      break;
    case "YEARLY":
      segmentsNo = 1; // 1 year
      break;

    default:
      segmentsNo = 4;
      break;
  }

  const segmentDurationMonths = 12 / segmentsNo; // Calculate the duration for each segment
  let segmentGoal;

  // For percentages, subgoals need to have the same goal as the main flywheel goal
  // Flywheel goals are always cummulative - percentages shouldn't be divided
  if (typeLabel === FlywheelTemplateUnitTypeLabelEnum.PERCENTAGE) {
    segmentGoal = +mainGoal;
  } else {
    segmentGoal = +mainGoal / segmentsNo; // Calculate the individual goal for each segment
  }

  const segments: CreateSubgoalType[] = [];

  let currentStartDate = dayjs()
    .year(achieveByYear - 1)
    .month(achieveByMonth)
    .endOf("month")
    .add(1, "day") // Go one day forward to get the first day of the next month
    .startOf("day");

  for (let i = 0; i < segmentsNo; i++) {
    // Construct the array of segments

    const nextStartDate = currentStartDate.add(segmentDurationMonths, "month");
    const segmentEndDate = nextStartDate.subtract(1, "day").endOf("day").millisecond(0); // Subtract one day to get the last day of the segment

    const newSegment = {
      startDate: constructUtcDate(currentStartDate),
      endDate: constructUtcDate(segmentEndDate.millisecond(0)),
      goal: segmentGoal.toString()
    };

    segments.push(newSegment);
    currentStartDate = nextStartDate;
  }

  return segments;
};
