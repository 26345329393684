import clsx from "clsx";

import type { HealthStatus } from "~/components/flywheel/roda/types";
import { Icon } from "~/components/Icon";

interface StatusBubbleProps {
  status: HealthStatus;
}

export const StatusBubble: React.FC<StatusBubbleProps> = ({ status }) => {
  const IconComponent = status === "waiting" ? Icon.Clock : status === "healthy" ? Icon.Healthy : Icon.Attention;

  return (
    <span
      data-status-bubble
      className={clsx("text-xs [font-size:9px] font-semibold inline-flex items-center gap-1 rounded-full bg-[var(--step-theme-lighter)] px-2 py-0.5 ring-1 ring-[var(--step-theme-light)] text-[var(--step-theme-dark)] transition-all")}
    >
      {status === "check-in-due" ? "Check-in required" : status === "healthy" ? "Healthy" : status === "needs-attention" ? "Needs attention" : "Check-in Monday"}

      <IconComponent className="w-3 h-3" />
    </span>
  );
};