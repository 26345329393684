import Half_Flywheel from "~/assets/half_flywheel.svg";
import { useIsMobile } from "~/hooks/useIsMobile";

import type React from "react";

export interface ReviewContentWrapperProps {
  leftContent: JSX.Element;
  rightContent?: JSX.Element;
  title?: string;
  subTitle?: string;
}

/**
 * Component to be used for the Review pages that need a left/right rendering
 * Follows the exact same style as OnboardingContentWrapper
 */
export const ReviewContentWrapper: React.FC<ReviewContentWrapperProps> = ({
  leftContent, rightContent, title, subTitle
}) => {
  const isMobile = useIsMobile();

  return (
    <div className="relative flex flex-1 bg-brand-cold-metal-50 flex-col-reverse lg:flex-row [--top-h:300px] pt-[--top-h] lg:pt-0">

      {/** Left (OR bottom) content */}
      <div className="flex flex-col z-10 bg-white flex-1 px-3 w-full rounded-t-2xl lg:rounded-r-2xl lg:w-1/2">
        <div className={`flex flex-1 flex-col items-center ${isMobile ? "w-full pt-6 px-5 pb-5" : "w-[min(100%,600px)] pt-[0%]"} justify-center self-center z-10`}>
          {/** Title & subtitle */}

          <div className="flex flex-col gap-y-2 pt-16 mb-3 w-full">
            {title && (
              <p className={`font-bold ${isMobile ? "text-xl" : "text-2xl"}`}>{title}</p>
            )}

            {subTitle && (
              <p className={`${isMobile ? "text-xs" : "text-base"} opacity-80`}>{subTitle}</p>
            )}
          </div>

          {leftContent}
        </div>
      </div>

      {/** Right (OR top) graphic */}
      <div className="flex flex-col lg:w-1/2 fixed top-[--mobile-header] inset-x-0 w-full h-[--top-h] lg:h-[unset] lg:top-[unset] lg:my-auto lg:items-center md:self-start lg:relative">
        {rightContent ? (

          rightContent

        ) : (
          <img
            src={Half_Flywheel}
            className={`absolute inset-y-0 object-cover ${isMobile ? "w-[50%] h-[calc(var(--top-h)+32px)]" : "w-full h-full"}`}
          />
        )}

      </div>
    </div>
  );
};
