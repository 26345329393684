import { Icon } from "~/components/Icon";
import type { MetricType } from "~/contexts/CustomiseMetricContext/metric-reducer";
import { getMetricSvgIcon } from "~/utils/getMetricSvgIcon";

import type { FlywheelTemplateUnitTypeLabelEnum } from "@roda/shared/types";

interface MetricCardProps {
  metric: MetricType;
  onClick: () => void;
}

export const MetricCard: React.FC<MetricCardProps> = ({ onClick, metric }) => {
  return (
    <button
      className="flex flex-row p-3 bg-white border-brand-cold-metal-200 border-[1px] rounded-lg w-full items-center *:min-w-0"
    >
      <div
        className="flex flex-row gap-x-2 items-center w-full cursor-pointer flex-1"
        onClick={onClick}
      >
        <div className="border-brand-cold-metal-200 border-[1px] p-2 rounded-lg">
          {getMetricSvgIcon(metric.unitTypeLabel as FlywheelTemplateUnitTypeLabelEnum)}
        </div>

        <div className="flex flex-col items-start 500 text-left text-sm overflow-hidden">
          <p className="font-semibold">{metric.unitName}</p>

          <p className="text-sm text-brand-cold-metal-500 text-left max-w-full ">{metric.unitDescription}</p>
        </div>
      </div>

      <div
        className="flex px-[8px] py-1 rounded-lg hover:contrast-50 cursor-pointer shrink-0"
        onClick={onClick}
      >
        <Icon.Edit
          className="text-brand-cold-metal-300"
          size={20}
        />
      </div>

    </button>
  );
};