import {
  type Metric,
  type Step
} from "@roda/graphql/genql";
import {
  Link,
  useNavigate
} from "react-router-dom";

import { Avatar } from "~/components/Avatar";
import { Button } from "~/components/Button";
import { Icon } from "~/components/Icon";
import { MetricCardWrapper } from "~/components/metric/MetricCardWrapper";
import { routes } from "~/constants/routes";
import { useRodaAdminCompaniesContext } from "~/contexts/RodaAdminCompaniesContext";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { formatGoalNumber } from "~/utils/formatGoalNumber";
import { getMetricSvgIcon } from "~/utils/getMetricSvgIcon";
import { getUnitSymbol } from "~/utils/getUnitSymbol";

import type { FlywheelTemplateUnitTypeLabelEnum } from "@roda/shared/types";

interface CheckinMetricCardProps {
  metric: Metric;
  step: Step;
  onClick: (metric: Metric) => void;
  disabled?: boolean;
}
export const CheckinMetricCard: React.FC<CheckinMetricCardProps> = ({
  metric, step, onClick, disabled
}) => {
  const { flywheel, flywheelCycleNotStarted } = useSelectedFlywheel();
  const latestMetricUpdate = metric.metricUpdates ? metric.metricUpdates[ metric.metricUpdates.length - 1 ] : undefined;
  // Check in due if latestMetricUpdate is undefined
  const isCheckInDue = !flywheelCycleNotStarted && !latestMetricUpdate;
  const navigate = useNavigate();
  const { currentCompany } = useRodaAdminCompaniesContext();

  // Navigate user to actual step page.
  const handleTitleClick = () => {
    navigate(routes.stepMetric(step.id, metric.id, currentCompany?.id));
  };

  return (
    <MetricCardWrapper
      bgColour={isCheckInDue ? "#FAFCFF" : undefined}
      borderColour={isCheckInDue ? "#D1E0FF" : undefined}
    >
      <div className="flex flex-row shrink-[0.5] items-center gap-x-3 max-sm:@container">
        <div className={`shrink-0 self-baseline border-[1px] p-2 rounded-lg ${isCheckInDue ? "text-brand-check-in-due-800 border-brand-check-in-due-100" : "border-brand-cold-metal-200"} `}>
          {getMetricSvgIcon(metric.unitTypeLabel as FlywheelTemplateUnitTypeLabelEnum)}
        </div>

        <div className="flex flex-col items-start text-sm overflow-hidden flex-1">
          <p
            className={`font-semibold cursor-pointer ${isCheckInDue ? "text-brand-check-in-due-900" : " text-brand-cold-metal-800"}`}
            onClick={() => handleTitleClick()}
          >{metric.name}
          </p>

          <p className={`text-sm break-all opacity-60 ${isCheckInDue ? "text-brand-check-in-due-700" : "text-brand-cold-metal-500"}`}>{metric.unitName}</p>
        </div>

        {latestMetricUpdate && (
          <p className="sm:hidden flex">{formatGoalNumber(Number(latestMetricUpdate.value), getUnitSymbol(metric.unitTypeLabel, flywheel?.currency), {
            shouldCompact: true,
            stripTrailingZeros: true
          })}
          </p>
        )}
      </div>

      {isCheckInDue ? (
        <div className="pt-3 sm:pt-0 justify-end items-center flex">
          <Button
            title="Check-in"
            iconComponent={<Icon.Clock className="size-3" />}
            className="font-normal bg-brand-check-in-due-800 min-w-[100px] max-h-[30px]"
            onClick={() => onClick(metric)}
            disabled={disabled}
          />
        </div>
      ) : latestMetricUpdate && (
        <div className="pt-3 sm:pt-0 sm:justify-end flex flex-row-reverse sm:flex-col sm:self-stretch sm:pl-4 self-start">

          <p className="hidden flex-1 sm:flex only:items-center self-end">{formatGoalNumber(Number(latestMetricUpdate.value), getUnitSymbol(metric.unitTypeLabel, flywheel?.currency), {
            shouldCompact: true,
            stripTrailingZeros: true
          })}
          </p>

          {!!latestMetricUpdate.notes && (
            <Link
              to={routes.stepMetric(step.id, metric.id, currentCompany?.id)}
              className="flex items-baseline text-xs gap-1.5 *:min-w-0 break-all flex-1 sm:flex-none"
            >
              {!!latestMetricUpdate.updatedBy && (
                <div className="relative -top-0.5 shrink-0">
                  <Avatar
                    user={latestMetricUpdate.updatedBy!}
                    px={18}
                  />
                </div>
              )}

              <p className="text-xs flex-1 truncate text-gray-200 max-w-[66cqw] sm:max-w-[300px]">
                {latestMetricUpdate.notes}
              </p>
            </Link>
          )}
        </div>
      )}

    </MetricCardWrapper>
  );
};