// Dynamically calculates stepOffset based on window width for responsive design. Designed for use in the IntroFlywheel component.
export const getDynamicStepOffset = () => {
  const maxWidth = 1920; // Upper bound for window width.
  const minWidth = 764; // Lower bound for window width.
  const minStep = 5; // Minimum step offset.
  const maxStep = 30; // Maximum step offset.
  const width = window.innerWidth; // Current window width.

  // Return min/max stepOffset for extremes, otherwise calculate dynamically.
  if (width <= minWidth) {
    return minStep;
  } else if (width >= maxWidth) {
    return maxStep;
  } else {
    // Linear interpolation between minStep and maxStep to give a responsive stepOffset.
    return minStep + ((width - minWidth) / (maxWidth - minWidth)) * (maxStep - minStep);
  }
};