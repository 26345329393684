import { MetricTarget } from "~/fragments/metricTarget";
import { MetricUpdate } from "~/fragments/metricUpdate";

import type { MetricGenqlSelection } from "@roda/graphql/genql";

export const Metric: MetricGenqlSelection = {
  id: true,
  name: true,
  owner: {
    id: true,
    firstName: true,
    lastName: true,
    email: true,
    avatarS3Key: true
  },
  stepId: true,
  unitName: true,
  unitType: true,
  unitTypeLabel: true,
  unitDisplay: true,
  unitDescription: true,
  unitTargetType: true,
  cap: true,
  reportingWindowTiming: true,
  slug: true,
  targets: MetricTarget,
  isCheckInDue: true,
  isHealthy: true,
  metricUpdates: MetricUpdate,
  fromDate: true,
  toDate: true
};
export const ReducedMetric: MetricGenqlSelection = {
  id: true,
  name: true,
  owner: {
    id: true,
    firstName: true,
    lastName: true,
    email: true,
    avatarS3Key: true
  },
  stepId: true,
  unitName: true,
  unitType: true,
  unitTypeLabel: true,
  unitDisplay: true,
  unitDescription: true,
  unitTargetType: true,
  cap: true,
  reportingWindowTiming: true,
  slug: true,
  isCheckInDue: true,
  isReviewDue: true,
  isHealthy: true,
  fromDate: true,
  toDate: true
};
