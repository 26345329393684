import * as Sentry from "@sentry/react";

export const sentryInit = () => {
  return Sentry.init({
    dsn: "https://36d7d7944cec9930914645d3e84e571f@o4507057257840640.ingest.us.sentry.io/4507067309162496",
    integrations: [],
    tracesSampleRate: 1.0,
    enabled: !import.meta.env.DEV,
    environment: import.meta.env.MODE
  });
};

export default Sentry;