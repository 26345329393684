import { HealthStatus } from "~/components/flywheel/roda/types";

import type { Metric } from "@roda/graphql/genql";

// Function for returning the health status of a metric
export const getMetricHealthStatus = (metric: Metric): HealthStatus => {
  // If a check-in is due -> always return "check-in-due"
  // If isHealthy is null then set status to waiting
  // othwerwise get the value of isHealthy (true -> healthy, false -> needs-attention)
  return metric.isCheckInDue ? HealthStatus.CheckInDue : metric.isHealthy === null ? HealthStatus.Waiting : metric.isHealthy ? HealthStatus.Healthy : HealthStatus.NeedsAttention;
};