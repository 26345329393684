import { useTypedMutation } from "@roda/graphql/urql";

import { Flywheel } from "~/fragments/index";

import type { MutationGenqlSelection } from "@roda/graphql/genql";

export type CreateMetricWithTargetParams = NonNullable<MutationGenqlSelection["createMetricWithTarget"]>["__args"];
export type CreateMetricWithTargetResponse = ReturnType<typeof useCreateMetricWithTarget>[0]["data"];

export const useCreateMetricWithTarget = () => {
  return useTypedMutation((vars: CreateMetricWithTargetParams) => ({
    createMetricWithTarget: {
      __args: vars,
      ...Flywheel
    }
  }));
};