import {
  useEffect,
  useMemo,
  useState
} from "react";

import { useCurrentUser } from "~/contexts/UserContext";
import { getAvatarColours } from "~/utils/getAvatarColours";
import { getUserDisplayName } from "~/utils/getUserDisplayName";
import { getUserInitials } from "~/utils/getUserInitials";

import type { User as UserType } from "@roda/graphql/genql";

interface AvatarProps {
  user: Pick<UserType, "avatarS3Key" | "firstName" | "lastName" | "email">;
  px?: number;
  displayName?: boolean;
  preview?: boolean;
  /** aria-label and title attrs */
  title?: string | ((s: string) => string);
}

export const Avatar: React.FC<AvatarProps> = ({
  user, px = 42, displayName, title, preview
}) => {
  const [ avatarValue, setAvatarValue ] = useState<string | undefined>(user.avatarS3Key ?? undefined);
  const { refetch } = useCurrentUser();

  const {
    firstName, lastName, email
  } = user;

  // On image error, refetch the user session to get the url re-signed
  const handleImageError = async () => {
    // refetch the user session to get the url re-signed
    refetch();
  };

  // Update the avatar value when the user session changes
  useEffect(() => {
    setAvatarValue(user.avatarS3Key ?? undefined);
  }, [ user.avatarS3Key ]);

  const initials = getUserInitials({
    firstName,
    lastName,
    email: email ?? ""
  });

  const defaultAriaLabel = useMemo(() => getUserDisplayName({
    firstName,
    lastName,
    email: email ?? ""
  }), [
    firstName,
    lastName,
    email
  ]);

  const ariaLabel = useMemo(() => title ?
    typeof title === "function" ?
      title(defaultAriaLabel ?? "") :
      title :
    defaultAriaLabel,
  [ defaultAriaLabel, title ]);

  if (!firstName && !lastName && email === "-") {
    return null;
  }

  const avatarColours = getAvatarColours(initials);

  return (
    <div
      className="flex flex-row gap-x-2 items-center *:min-w-0"
      role="img"
      aria-label={ariaLabel}
      title={ariaLabel}
    >
      <div
        style={{
          width: `${px}px`,
          height: `${px}px`,
          backgroundColor: !avatarValue && !preview ? avatarColours.bgColour : undefined,
          fontSize: `${px}px`,
          minHeight: `${px}px`,
          minWidth: `${px}px`
        }}
        className={`${avatarValue ? "shrink-0 aspect-square rounded-full relative overflow-hidden grid place-items-center" : `rounded-full flex items-center justify-center  ${preview && "bg-brand-cold-metal-100"}`}`}
      >
        {avatarValue ? (
          <img
            className="absolute inset-0 w-full h-full object-cover"
            src={avatarValue}
            onError={handleImageError}
            crossOrigin="anonymous"
          />
        ) : (
          <div
            style={{
              fontSize: "0.35em", // fontSize is relative to the parent's size
              color: avatarColours.textColour
            }}
            className="font-semibold text-center"
          >
            {initials}
          </div>
        )}

      </div>

      {displayName && (
        <span
          className="text-brand-cold-metal-400 text-base truncate"
        >{getUserDisplayName({
          firstName,
          lastName,
          email: email ?? ""
        })}
        </span>
      )}
    </div>
  );
};
