import { FlywheelGoalUpdate } from "./flywheelGoalUpdate";

import type { SubgoalGenqlSelection } from "@roda/graphql/genql";

export const Subgoal: SubgoalGenqlSelection = {
  id: true,
  endDate: true,
  startDate: true,
  goal: true,
  flywheelGoalId: true,
  latestUpdate: FlywheelGoalUpdate,
  updates: FlywheelGoalUpdate,
  isHealthy: true,
  isCheckInDue: true,
  latestProgress: true
};
