import clsx from "clsx";

import { Avatar } from "~/components/Avatar";
import { ProgressBar } from "~/components/flywheel/roda/ProgressBar";
import { InfoTooltip } from "~/components/InfoTooltip";
import { useCustomiseMetricState } from "~/contexts/CustomiseMetricContext/CustomiseMetricContext";
import type { MetricType } from "~/contexts/CustomiseMetricContext/metric-reducer";
import { useRodaAdminCompaniesContext } from "~/contexts/RodaAdminCompaniesContext";
import { useCurrentUser } from "~/contexts/UserContext";
import { formatGoalNumber } from "~/utils/formatGoalNumber";
import { getUnitSymbol } from "~/utils/getUnitSymbol";
import { getUserDisplayName } from "~/utils/getUserDisplayName";

interface MetricPreviewProps {
  metric?: MetricType;
  currency?: string;
}
export const MetricPreview: React.FC<MetricPreviewProps> = ({ metric, currency }) => {
  const { stage } = useCustomiseMetricState();
  const { user } = useCurrentUser();
  const { currentCompany } = useRodaAdminCompaniesContext();
  const previewText = "bg-brand-cold-metal-100 rounded-md px-3 py-2 ";
  const activeText = "bg-brand-check-in-due-50 rounded-md px-3 py-2 ";
  const metricIcon = getUnitSymbol(metric?.unitTypeLabel, currency);

  const PreviewName = () => {
    const isActive = stage === "customise_1_naming";
    const isComplete = !!metric?.name && !!metric?.unitDescription;
    const isPreview = !isActive && !isComplete;

    return (
      <div className={clsx((isActive && activeText) || (isPreview && previewText), "flex transition-all flex-row justify-between gap-2 items-center relative overflow-hidden ")}>
        <p className="truncate w-52 font-medium overflow-hidden">{metric?.name || (isActive ? "Network leads" : "")}</p>

        {!isPreview && (
          <InfoTooltip
            forceOpen={stage === "customise_1_naming"}
            infoCircleStyle={stage === "customise_1_naming" ? "text-brand-check-in-due-300" : "text-brand-cold-metal-300"}
            text={metric?.unitDescription || "Total number of qualified leads in CRM"}
            slideDirection="top"
          />
        )}

      </div>
    );
  };

  const PreviewUnit = () => {
    const isActive = stage === "customise_2_units";
    const isComplete = !!metric?.unitName && !!metric?.unitTypeLabel && !!metric.reportingWindowTiming;
    const isPreview = !isActive && !isComplete;

    return (
      <div>

        <div className="flex mb-2 flex-row flex-wrap gap-x-1">
          <p className={clsx((isActive && activeText) || (isPreview && previewText), "text-sm font-medium transition-all ")}>{metric?.unitName || (isActive ? "New network leads" : "")}</p>

          <p className={clsx((isActive && activeText) || (isPreview && previewText), "text-sm font-medium transition-all min-w-16")}>{metric?.reportingWindowTiming ? metric?.reportingWindowTiming === "quarter to date" ? metric?.reportingWindowTiming : "this week" : (isActive ? "this week" : "")}</p>

        </div>

        <div className="flex flex-row">

          <p className={clsx((isActive && activeText) || (isPreview && previewText), "min-h-8 text-2xl font-semibold text-left")}>{metric?.unitTypeLabel ? metricIcon : isActive && "#"}</p>
        </div>

      </div>
    );
  };

  const PreviewDisplay = () => {
    const isActive = stage === "customise_3_display";
    const isComplete = !!metric?.unitDisplay && !!metric?.unitTargetType;
    const isPreview = !isActive && !isComplete;

    return (
      <div className="w-full flex flex-col gap-4">
        {metric?.unitDisplay === "weekly" ? (
          <div className="flex flex-row justify-evenly items-end gap-2">
            <div className={clsx((isActive && activeText) || (previewText), "h-8")} />

            <div className={clsx((isActive && activeText) || (previewText), "h-2")} />

            <div className={clsx((isActive && activeText) || (previewText), "h-12")} />

            <div className={clsx((isActive && activeText) || (previewText), "h-8")} />

            <div className={clsx((isActive && activeText) || (previewText), "h-4")} />

            <div className={clsx((isActive && activeText) || (previewText), "h-10")} />

            <div className={clsx((isActive && activeText) || (previewText), "h-10")} />

            <div className={clsx((isActive && activeText) || (previewText), "h-16")} />
          </div>
        ) : (
          <div className="flex flex-row flex-wrap gap-2">
            <p className={clsx((isActive && activeText) || (isPreview && previewText), "text-sm font-medium transition-all ")}>{!isPreview && "Q4 remaining target"}</p>

            <ProgressBar
              progress={0}
              isCheckInDue={isActive}
              neutral={!isActive}
            />
          </div>
        )}

        <div className="flex flex-row justify-between antialiased items-center text-sm">
          <p className={clsx(stage !== "review" ? [ previewText, "w-[30px]" ] : "font-medium text-brand-cold-metal-400")}>
            {stage === "review" ? metricIcon : ""}
          </p>

          <p
            className={clsx(stage !== "review" ? [ previewText, "w-[50px]" ] : "font-semibold text-brand-cold-metal-600")}
          >{stage === "review" ? `Target ${formatGoalNumber(Number(metric?.target || 0), metricIcon, {
            stripTrailingZeros: true,
            shouldCompact: true
          })}` : ""}
          </p>
        </div>

      </div>
    );
  };

  const foundUser = user?.company?.activeUsers?.find(u => u.email === metric?.ownerEmail) || currentCompany?.activeUsers?.find(u => u.email === metric?.ownerEmail);

  return (
    <div className="flex flex-col p-6 rounded-xl bg-white m-6 min-w-[300px] border-solid border-[1px] border-grey-200">
      <div className="flex flex-col gap-y-2">
        {PreviewName()}

        {PreviewUnit()}

        {PreviewDisplay()}

        <div className="flex flex-row items-center gap-2 pt-4">
          <Avatar
            preview
            user={{
              avatarS3Bucket: foundUser?.avatarS3Bucket,
              avatarS3Key: foundUser?.avatarS3Key,
              firstName: foundUser?.firstName || "",
              lastName: foundUser?.lastName || "",
              email: metric?.ownerEmail
            }}
            px={24}
          />

          <p
            className={clsx(!metric?.ownerEmail ? [ "min-h-6 w-[40%]", previewText ] : "truncate text-sm max-w-[170px] text-brand-cold-metal-500")}
          >
            {getUserDisplayName({
              firstName: foundUser?.firstName || "",
              lastName: foundUser?.lastName || "",
              email: metric?.ownerEmail
            })}
          </p>
        </div>

      </div>
    </div>
  );
};
