import { zodResolver } from "@hookform/resolvers/zod";
import { Currency } from "@roda/shared/types";
import {
  useContext,
  useRef
} from "react";
import { useForm } from "react-hook-form";

import { ConfirmationPopup } from "~/components/ConfirmationPopup";
import { Input } from "~/components/form";
import { FlywheelGoalSchema } from "~/components/form/formSchemas";
import { VerticalDotMenuContext } from "~/components/VerticalDotMenu";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useUpdateFlywheelGoal } from "~/hooks/flywheel-goal";
import { useError } from "~/hooks/useError";
import dayjs from "~/utils/dayjs";
import { getUnitSymbol } from "~/utils/getUnitSymbol";
import { cleanNumberInput } from "~/utils/validation/cleanNumberInput";

import type { z } from "zod";

interface EditMainFlywheelGoalMenuItemProps {
  openPopup: () => void;
  closePopup: () => void;
  isOpen: boolean;
}

export const EditMainFlywheelGoalMenuItem: React.FC<EditMainFlywheelGoalMenuItemProps> = ({
  openPopup, closePopup, isOpen
}) => {
  const { handleRodaError, assertGraphQLSuccess } = useError();
  const { flywheel } = useSelectedFlywheel();
  const { closeVDotMenu } = useContext(VerticalDotMenuContext);
  const flywheelGoal = flywheel?.latestFlywheelGoal;
  const editMainFlywheelGoalContainerRef = useRef<HTMLDivElement>(null);
  const [ updateFlywheelGoalRes, updateFlywheelGoalReq ] = useUpdateFlywheelGoal();
  const flywheelGoalUnitSymbol = getUnitSymbol(flywheelGoal?.unitTypeLabel, flywheel?.currency as Currency || Currency.GBP, true);

  // Edit flywheel goal form
  const {
    reset: resetMainFlywheelGoalForm,
    register: registerMainFlywheelGoalForm,
    handleSubmit: handleSubmitMainFlywheelGoalForm,
    setValue,
    formState: { errors: mainFlywheelGoalFormErrors }
  } = useForm<z.infer<typeof FlywheelGoalSchema>>({
    resolver: zodResolver(FlywheelGoalSchema),
    // Default to the current flywheel goal
    defaultValues: { goal: flywheelGoal?.goal ?? "0" },
    shouldFocusError: false
  });

  const handleUpdateMainFlywheelGoal = handleSubmitMainFlywheelGoalForm(formValues => {
    // Update the flywheel goal
    if (flywheelGoal?.id) {
      updateFlywheelGoalReq({
        flywheelGoalId: Number(flywheelGoal?.id),
        goal: String(formValues.goal)
      })
        .then(res => {
          assertGraphQLSuccess(res);
          closePopup();
          closeVDotMenu();
        })
        .catch(e => {
          handleRodaError(e, "Something went wrong. Please try again.");
        });
    }
  });

  return (
    <>
      <button onClick={openPopup}>
        Update target
      </button>

      {/** Edit main flywheel goal popup */}
      <ConfirmationPopup
        isOpen={isOpen}
        title="Edit main flywheel goal"
        subtitle="Change your goal"
        text=""
        ref={editMainFlywheelGoalContainerRef}
        continueText="Save"
        onContinue={() => handleUpdateMainFlywheelGoal()}
        onCancel={() => {
          if (!updateFlywheelGoalRes.fetching) {
            resetMainFlywheelGoalForm();
            closePopup();
            closeVDotMenu();
          }
        }}
        loading={updateFlywheelGoalRes.fetching}
        continueDisabled={updateFlywheelGoalRes.fetching}
      >
        <form
          onSubmit={handleUpdateMainFlywheelGoal}
          className="flex flex-col flex-1 gap-4"
        >
          <Input
            label={`Current target for ${dayjs().year()}`}
            value={flywheelGoal?.goal}
            iconPosition="left"
            iconComponent={<p>{flywheelGoalUnitSymbol}</p>}
            disabled
          />

          <Input
            {...registerMainFlywheelGoalForm("goal", {
              onChange: e => {
                const cleanedValue = cleanNumberInput(e.target.value);

                setValue("goal", cleanedValue);
              }
            })}
            label={`Enter the new target for ${dayjs().year()}`}
            hasError={!!mainFlywheelGoalFormErrors.goal}
            errorMessage={mainFlywheelGoalFormErrors.goal?.message}
            min={1}
            inputMode="numeric"
            autoComplete="off"
            autoCorrect="off"
            iconPosition="left"
            iconComponent={<p>{flywheelGoalUnitSymbol}</p>}
          />
        </form>
      </ConfirmationPopup>
    </>
  );
};