import { useCallback } from "react";
import toast from "react-hot-toast";

import type { GraphQLError } from "graphql";
import type { OperationResult } from "urql";

// I wanted to use `error instance of GraphQLError` but its broken
// https://github.com/graphql/graphql-js/issues/3918
// https://github.com/graphql/graphql-js/issues/3925

type AutoToastConfig = {shouldToast: boolean};
type CallbackWithParams<E, M> = (props: {
  message: M;
  isDev: boolean;
  error: E;
  errorStringified: string;
  toast: typeof toast;
}) => void;

const isCallbackFn = (uiAction: unknown): uiAction is CallbackWithParams<unknown, string> => typeof uiAction === "function";

/**
 * - Ingests and formats error, and displays it nicely in the console in DEV.
 * - **By default**, the error will be displayed as an error toast unless you
 *   pass `{shouldError: false}` or utilise a callback function for full
 *   customisation (as the 3rd argument, see callback implementation details
 *   below).
 *
 *
 * - As a callback, `uiAction` provides:
 *   - `message` (which is the `userFacingMessage` - 2nd string arg) you
 *   supplied back to you so that you do not have to write the string twice, or
 *   declare it as a variable, as a matter of convenience.
 *   - `error` is a javascript object so as a convenience/fallback we also
 *     provide `errorStringified` which is displayable as a string (probably
 *     most convenient as a DEV fallback)
 *   - `isDev` is a boolean that you may use to conditionally display different
 *     errors - again, as a matter of convenience.
 *   - `toast` just so you don't have to import it everywhere
 *
 * @example
 * // will toast by default:
 * handleRodaError(response.error, "Failed to fetch user");
 * // will not toast, caller has full control:
 *handleRodaError(response.error, "Failed to fetch user", ({
    message, errorStringified, isDev, toast
}) => toast.error(isDev ? errorStringified : message));
 */

export const useError = () => {
  function handleRodaError<E = unknown, M extends string = string>(error: E, userFacingMessage: M, uiAction?: AutoToastConfig | CallbackWithParams<typeof error, typeof userFacingMessage>) {
    const isErrorLike = error instanceof Error;
    const isGraphQLError = "graphQLErrors" in (error ?? {});
    const isDev = import.meta.env.DEV;
    const fallbackErr = JSON.stringify(error);
    let stringified = isErrorLike ? error.message : isGraphQLError ? (error as GraphQLError).originalError?.message ?? fallbackErr : fallbackErr;

    // Remove the [GraphQL] prefix and quotes
    stringified = stringified.replace("[GraphQL] ", "").replaceAll("\"", "");
    const style = `background:${isDev ? "orangered" : "khaki"};color:${isDev ? "white" : "black"};padding:.2em .5em;border-radius:.5em;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;font-weight:normal`;
    const title = `[Roda] ${userFacingMessage}`;

    // eslint-disable-next-line no-console
    console.groupCollapsed("%c" + title, style);

    if (error != null) console.error(stringified);

    // eslint-disable-next-line no-console
    if (isDev && isErrorLike) console.dir(error);

    // eslint-disable-next-line no-console
    console.groupEnd();

    if (isCallbackFn(uiAction)) {
      uiAction?.({
        message: userFacingMessage,
        isDev,
        error,
        errorStringified: stringified,
        toast
      });
    } else if (!uiAction || (uiAction as AutoToastConfig)?.shouldToast !== false) {
      toast.error(isDev ? stringified : userFacingMessage);
    }
  }

  /**
   * GraphQL errors return a 200 status - so we need a function to check that the result of a mutation
   * is successful. If there's an error, we throw a proper error - which our try / catch logic will handle
   * @param res The GraphQL mutation result to check for errors
   */
  const assertGraphQLSuccess = (res: OperationResult) => {
    if (res.error) {
      if (res.error.message) {
        throw new Error(res.error.message.replace("[GraphQL] [Roda]", ""));
      }

      if (res.error.graphQLErrors?.length) {
        throw new Error(res.error.graphQLErrors[ 0 ].message.replace("[GraphQL] [Roda]", ""));
      }
    }
  };

  return {
    assertGraphQLSuccess,
    handleRodaError: useCallback(handleRodaError, [ ])
  };
};