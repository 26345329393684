import React from "react";
import { Provider as UrqlProvider } from "urql";

import type { Client } from "urql";

interface Props {
  makeClient: () => Client,
  children: React.ReactNode
}

const ClientContext = React.createContext<{resetClient: () => void}>({ resetClient: () => undefined });

export function ClientProvider({ makeClient, children }: Props) {
  const [ client, setClient ] = React.useState(makeClient());

  return (
    <ClientContext.Provider
      value={{ resetClient: () => setClient(makeClient()) }}
    >
      <UrqlProvider value={client}>
        {children}
      </UrqlProvider>
    </ClientContext.Provider>
  );
}

export const useGraphQLClient = () => React.useContext(ClientContext);
