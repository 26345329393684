import { Navigate } from "react-router-dom";

interface ProtectedRouteProps {
  isAllowed: boolean;
  children: React.ReactNode;
  fallbackPath?: string;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  isAllowed, children, fallbackPath = "/"
}) => {
  return isAllowed ? <>{children}</> : (
    <Navigate
      replace
      to={fallbackPath}
    />
  );
};
