import { useTypedMutation } from "@roda/graphql/urql";

import type { MutationGenqlSelection } from "@roda/graphql/genql";

type Params = NonNullable<MutationGenqlSelection["deleteCompany"]>["__args"];

export const useDeleteCompany = () => {
  return useTypedMutation((vars: Params) => ({
    deleteCompany: {
      __args: vars,
      id: true
    }
  }));
};