import { FlywheelGoalProgressCard } from "~/components/review/FlywheelGoalProgressCard";
import { Card } from "~/components/Timeline";
import { TimelineLayout } from "~/components/Timeline/TimelineLayout";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useIsMobile } from "~/hooks/useIsMobile";
import { cn } from "~/utils/cn";
import dayjs from "~/utils/dayjs";
import { formatGoalNumber } from "~/utils/formatGoalNumber";

import type { CreateSubgoalType } from "@roda/core/flywheel-goal/createFlywheelGoalAndSubgoals";
import type { Subgoal } from "@roda/graphql/genql";

interface NextYearReviewGoalTimelineProps {
  subgoalValue: number;
  mainGoalValue: number;
  subgoals: Array<Subgoal | CreateSubgoalType>;
  rolledIntoNewYear: boolean;
  unitIcon: string;
}
export const NextYearReviewGoalTimeline: React.FC<NextYearReviewGoalTimelineProps> = ({
  subgoalValue, mainGoalValue, subgoals, rolledIntoNewYear, unitIcon
}) => {
  const isMobile = useIsMobile();
  const { flywheel } = useSelectedFlywheel();

  return (
    <TimelineLayout
      orientation={isMobile ? "horizontal" : "vertical"}
      rowGap={isMobile ? "14" : undefined /* defaults to 60, ideal for desktop */}
      items={subgoals.map((subgoal, i, { length }) => {
        const isFirst = i === 0;
        const isLast = i === length - 1;
        const targetOfSubgoal = isFirst ? subgoalValue : +subgoal.goal;

        return {
          renderLeft: isLast || (!isMobile && isFirst) ? (
            <div
              className="flex flex-col items-start gap-2 relative"
              aria-hidden='true'
            >
              {isLast && flywheel?.latestFlywheelGoal && (
                <FlywheelGoalProgressCard
                  flywheelGoalTarget={mainGoalValue}
                  flywheelGoalProgress={0}
                  unitIcon={unitIcon}
                  isHealthy
                />
              )}
            </div>
          ) : undefined,
          renderRight: (
            <Card.TimelineItem
              title={`Quarter ${i + 1}`}
              size={isMobile ? "lg" : "md"}
              subheading={`${dayjs(subgoal.startDate).format("MMMM YYYY")} - ${dayjs(subgoal.endDate).format("MMMM YYYY")}`}
              badge={isFirst ? rolledIntoNewYear ? "Review-due" : "Coming up" : undefined}
              progressBarProps={{
                value: 0,
                max: subgoal.goal ? Number(subgoal.goal) : Infinity,
                labelLeft: "Progress",
                labelRight: isFirst ? `Target ${formatGoalNumber(targetOfSubgoal, unitIcon, {
                  shouldCompact: true,
                  stripTrailingZeros: true
                })}` : "Target",
                neutral: true
              }}
              className={cn(isMobile && "mb-0.5", !isFirst && "opacity-45")}
            />)
        };
      })}
    />
  );
};