import type { PropsWithChildren } from "react";

interface MetricCardWrapperProps {
  onCardClick?: () => void;
  bgColour?: string;
  borderColour?: string;
}

export const MetricCardWrapper: React.FC<PropsWithChildren & MetricCardWrapperProps> = ({
  onCardClick, bgColour, borderColour, children
}) => {
  return (
    <div
      style={{
        borderColor: borderColour,
        backgroundColor: bgColour
      }}
      className={`flex flex-col items sm:flex-row sm:*:min-w-0 gap-2 sm:p-6 p-3 border-brand-cold-metal-200 border-[1px] justify-between sm:items-stretch rounded-lg  ${onCardClick ? "cursor-pointer" : ""}`}
      onClick={onCardClick}
    >
      {children}
    </div>
  );
};