export enum UserAccountFieldType {
  FirstName = "firstName",
  LastName = "lastName",
  JobTitle = "jobTitle",
  Email = "email",
}

export const UserAccountFieldDisplayText: Record<UserAccountFieldType, string> = {
  [ UserAccountFieldType.FirstName ]: "First Name",
  [ UserAccountFieldType.LastName ]: "Last Name",
  [ UserAccountFieldType.JobTitle ]: "Job Title",
  [ UserAccountFieldType.Email ]: "Email"
};