import { cn } from "~/utils/cn";

import type { ComponentProps } from "react";

type Size = "sm" | "md";

interface TagProps extends ComponentProps<"p"> {
  size?: Size;
}

export const Tag: React.FC<TagProps> = ({ size = "md", ...props }) => {
  return (
    <div
      aria-hidden="true"
      {...props}
      className={cn("bg-brand-cold-metal-200 rounded-lg py-2 px-3 text-xs font-semibold text-brand-cold-metal-800 text-center", { "text-xs [font-size:10px] py-0.5 px-1.5 rounded-[5px]": size === "sm" }, props.className)}
    />
  );
};