import { useTypedMutation } from "@roda/graphql/urql";

import type { MutationGenqlSelection } from "@roda/graphql/genql";

type Params = NonNullable<MutationGenqlSelection["softDeleteFlywheel"]>["__args"];
export type DeleteFlywheelResponse = NonNullable<ReturnType<typeof useDeleteFlywheel>[0]["data"]>;

export const useDeleteFlywheel = () => {
  return useTypedMutation((vars: Params) => ({
    softDeleteFlywheel: {
      __args: vars,
      id: true,
      deletedAt: true
    }
  }));
};