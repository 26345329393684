import * as Popover from "@radix-ui/react-popover";
import clsx from "clsx";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from "react";
import { twMerge } from "tailwind-merge";
import { useOnClickOutside } from "usehooks-ts";

import { ConditionalWrapper } from "~/components/ConditionalWrapper";
import { SelectInputDropdown } from "~/components/form/SelectInput";
import { Icon } from "~/components/Icon";

import { InputLabel } from "./InputLabel";

import type { ReactNode } from "react";

export type InputProps = Omit<JSX.IntrinsicElements["input"], "ref"> & ({
  label?: string | null;
  hasError?: boolean;
  errorMessage?: string;
  subText?: string;
  iconComponent?: ReactNode;
  onPressIcon?: () => void;
  iconPosition?: "left" | "right";
  tooltipInfo?: string;
  optionalLabel?: boolean
} & (
  // require onSuggestionSelect etc if suggestions is present
  {
    suggestions?: string[];
    onSuggestionSelect: (value: string) => void;
    filterSuggestions?: (suggestionValue: string, index: number) => boolean;
  } | {
    suggestions?: undefined;
    onSuggestionSelect?: never;
    filterSuggestions?: never;
  }
));

/**
 * A custom Input component with label, error message, and optional icon and subtext.
 * VERY IMPORTANT, please use the config option for React useForm "shouldFocusError: false" to prevent the input from automatically focusing when an error occurs.
 *
 * @param label - Optional: Label for the input.
 * @param hasError - Optional: Whether the input has an error. Note: We could remove this and rely on errorMessage, but this covers more use cases and is clearer.
 * @param errorMessage - Optional: Error message to display.
 * @param subText - Optional: Subtext to display below the input.
 * @param iconComponent - Optional: Icon to display inside the input.
 * @param onPressIcon - Optional: Function to call when the icon is pressed.
 * @param iconPosition - Optional: Position of the icon, either "left" or "right". Defaults to "right".
 * @param {React.Ref<HTMLInputElement>} ref - Ref forwarded to the input element.
 * @returns {React.ReactElement} - Rendered input component.
 * @example Within a useForm hook:
 *  <Controller
      name="name"
      control={control}
      render={({ field }) => (
        <Input
          {...field}
          label="Workspace name"
          hasError={!!errors.name}
          errorMessage={errors.name?.message}
        />
      )}
    />
 *
 */
export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({
    label, hasError, errorMessage, subText, iconComponent, onPressIcon, iconPosition = "right", tooltipInfo, optionalLabel, suggestions, onSuggestionSelect, filterSuggestions, ...inputProps
  }, ref) => {
    const [ isFocused, setIsFocused ] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const dropdownTriggerRef = useRef<HTMLButtonElement>(null);
    const outsideClickRef = useRef<HTMLDivElement>(null);
    const internalRef = useRef<HTMLInputElement>(null);
    const [ isDropdownOpen, setIsDropdownOpen ] = useState(false);

    useOnClickOutside(outsideClickRef, () => setIsDropdownOpen(false));
    // Use imperative handle to expose the internal ref, this is important to preserve the ref forwarding for focus input
    useImperativeHandle(ref, () => internalRef.current as HTMLInputElement);

    // Focus the input when the error message is clicked (if it's not already focused)
    const focusInput = () => {
      if (hasError && !isFocused && internalRef.current) {
        internalRef.current.focus();
      }
    };

    const handleSuggestionSelection = (option: string) => {
      onSuggestionSelect?.(option);
      setIsDropdownOpen(false);
    };

    const computedSuggestions = useMemo(() => suggestions ? filterSuggestions ? suggestions.filter(filterSuggestions) : suggestions : [], [ filterSuggestions, suggestions ]);
    // If the focused element is outside the ref - then its "focused away"
    const focusedAway = outsideClickRef.current?.contains(document.activeElement) === false;

    useEffect(() => {
      if (isDropdownOpen) {
        if (focusedAway) {
          return setIsDropdownOpen(false);
        }

        const handle = (e: Event) => {
          e.preventDefault();
          setIsDropdownOpen(!focusedAway);
        };

        window.addEventListener("scroll", handle);
        window.addEventListener("mousedown", handle);
        window.addEventListener("touchstart", handle);
        window.addEventListener("touchmove", handle);

        return () => {
          window.removeEventListener("scroll", handle);
          window.removeEventListener("mousedown", handle);
          window.removeEventListener("touchstart", handle);
          window.removeEventListener("touchmove", handle);
        };
      }
    }, [ focusedAway, isDropdownOpen ]);

    return (
      <ConditionalWrapper
        if={!!computedSuggestions?.length}
        wrapper={(
          <Popover.Root
            modal={false}
            open={isDropdownOpen}
          />
        )}
      >
        <div
          className="flex flex-col items-start gap-2"
          ref={outsideClickRef}
        >
          {label && (
            <InputLabel
              htmlFor={label}
              extraInfo={tooltipInfo}
            >
              {label}

              {optionalLabel && (
                <p className="text-brand-cold-metal-300">(optional)</p>
              )}
            </InputLabel>
          )}

          <div className="relative w-full">
            {iconComponent && !hasError && iconPosition === "left" && (
              <div
                className={clsx("text-brand-cold-metal-500 absolute left-2 top-1/2 -translate-y-1/2", { "cursor-pointer": onPressIcon })}
                onClick={onPressIcon}
              >
                {iconComponent}
              </div>
            )}

            <input
              ref={internalRef}
              id={label ?? undefined}
              type={inputProps.type ?? "text"}
              {...inputProps}
              value={inputProps.value}
              onFocus={() => {
                setIsFocused(true);

                if (dropdownTriggerRef.current?.dataset.state === "closed") {
                  dropdownTriggerRef.current?.click();
                }

                if (suggestions?.length) {
                  setIsDropdownOpen(true);
                }
              }}
              onBlur={e => {
                inputProps.onBlur && inputProps.onBlur(e);
                setIsFocused(false);
              }}
              onKeyDown={() => setIsDropdownOpen(false)}
              onMouseDown={e => e.currentTarget === document.activeElement && setIsDropdownOpen(false)}
              onTouchStart={e => e.currentTarget === document.activeElement && setIsDropdownOpen(false)}
              placeholder={inputProps.placeholder}
              className={clsx(
                "w-full rounded-lg text-base border p-2",
                {
                  "bg-white text-brand-cold-metal-500 border-brand-cold-metal-200 focus:border-brand-dark-blue": !hasError,
                  "bg-brand-error-red-50 border-brand-error-red-300 focus:border-brand-error-red-500": hasError,
                  "cursor-not-allowed opacity-50": inputProps.disabled,
                  "pr-10": (iconComponent && iconPosition === "right") || (hasError && !isFocused),
                  "pl-5": (iconComponent && iconPosition === "left") || (hasError && !isFocused),
                  "text-transparent placeholder:text-transparent": (hasError && !isFocused)
                },
                twMerge(inputProps.className ?? "")
              )}
            />

            {iconComponent && !hasError && iconPosition === "right" && (
              <div
                className={clsx("text-brand-cold-metal-500 absolute right-2 top-1/2 -translate-y-1/2", { "cursor-pointer": onPressIcon })}
                onClick={onPressIcon}
              >
                {iconComponent}
              </div>
            )}

            {hasError && !isFocused && (
              <>
                <span
                  className="absolute inset-y-0 left-2 flex items-center text-brand-error-red-600 select-none cursor-text pointer-events-none"
                  onClick={focusInput}
                >
                  {errorMessage}
                </span>
              </>
            )}

            {hasError && !isFocused && (
              <Icon.Error
                size={20}
                className="absolute right-2 top-1/2 -translate-y-1/2 text-brand-error-red-600"
              />
            )}

            {!!computedSuggestions?.length && (
              <Popover.Trigger
                ref={dropdownTriggerRef}
                className="w-full sr-only block"
              />
            )}

            {!!computedSuggestions?.length && (
              // I've not wrapped this in a portal so that outside-click
              // detection works properly and doesn't close the dropdown when
              // inner items are clicked
              <Popover.Content
                className="z-20 focus-within:ring-2"
                onOpenAutoFocus={e => e.preventDefault()}
                onInteractOutside={e => outsideClickRef.current?.contains(e.target as Node) || setIsDropdownOpen(false)}
              >
                <SelectInputDropdown
                  handleSelection={v => handleSuggestionSelection(v)}
                  isOpen={isDropdownOpen}
                  options={computedSuggestions}
                  value={inputProps.value as string}
                  ref={dropdownRef}
                />
              </Popover.Content>
            )}
          </div>

          {subText && !hasError && (
            <span className="text-xs text-brand-cold-metal-400">
              {subText}
            </span>
          )}
        </div>
      </ConditionalWrapper>
    );
  }
);