import { ReadOnlyUser } from "~/fragments/user";

import type { UserMetricReviewGenqlSelection } from "@roda/graphql/genql";

export const UserMetricReview: UserMetricReviewGenqlSelection = {
  id: true,
  userId: true,
  user: ReadOnlyUser,
  createdAt: true,
  updatedAt: true
};