import { generateQueryOp } from "@roda/graphql/genql";

import { Session as SessionFragment } from "~/fragments/session";
import { User as UserFragment } from "~/fragments/user";
import type { DeleteUserResponse } from "~/hooks/user/use-delete-user-from-company";

import type {
  Session,
  User
} from "@roda/graphql/genql";
import type {
  Cache,
  Variables,
  ResolveInfo
} from "@urql/exchange-graphcache";

/**
 * Runs after successful deleteUserFromCompany
 * Takes the results of a mutation and adds to our cache, to insert the new user into the user's company cache
 * @returns Updates the normalised cache with the results of the mutation
 */
export function deleteUserFromCompany(result: DeleteUserResponse, _args: Variables, cache: Cache, _info: ResolveInfo) {
  try {
    // For Admins and Users:
    // Regenerate the query
    const { query, variables } = generateQueryOp({ listUsers: { ...UserFragment } });
    const deletedIds = result.deleteUserFromCompany;

    // Query our cache for session
    cache.updateQuery<{listUsers: User[] | null}>({
      query,
      variables
    }, data => {
      const listUsers = data?.listUsers;

      if (!listUsers) {
        // null causes network refetch instead
        return data;
      }

      // Finally update our cache with the result of our successful mutation
      return { listUsers: listUsers?.filter(s => !deletedIds.includes(s.id)) };
    });

    // For Admins and Users:
    // Regenerate the query
    const { query: sessionQuery, variables: sessionVariables } = generateQueryOp({ session: { ...SessionFragment } });

    // Query our cache for session
    cache.updateQuery<{session: Session | null}>({
      query: sessionQuery,
      variables: sessionVariables
    }, data => {
      const session = data?.session as Session;

      if (!session) {
        // null causes network refetch instead
        return data;
      }

      // Finally update our cache with the result of our successful mutation
      return {
        session: {
          ...session,
          company: {
            ...session.company,
            activeUserCount: session.company?.activeUsers?.filter(s => !deletedIds.includes(s.id)).length,
            activeUsers: session.company?.activeUsers?.filter(s => !deletedIds.includes(s.id))
          }
        } as Session
      };
    });
  } catch (error) {
    console.error("error updating cache", error);
  }
}