import { zodResolver } from "@hookform/resolvers/zod";
import { FlywheelTemplateUnitTypeLabelEnum } from "@roda/shared/types";
import { removeEmojis } from "@roda/shared/utils";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "~/components/Button";
import {
  Input,
  SelectInput
} from "~/components/form";
import { Spacer } from "~/components/Spacer";
import {
  useCustomiseFlywheelGoalState,
  useCustomiseFlywheelGoalDispatch
} from "~/contexts/CustomiseFlywheelGoalContext/CustomiseFlywheelGoalContext";
import { useIsMobile } from "~/hooks/useIsMobile";

export const Schema = z.object({
  name: z.string().trim().min(1, "Required").max(100, "Maximum 100 characters").refine(value => {
    const sanitized = removeEmojis(value);

    return sanitized === value;
  }, { message: "Emojis are not allowed" }),
  unitDescription: z.string().trim().min(1, "Required").max(100, "Maximum 100 characters").refine(value => {
    const sanitized = removeEmojis(value);

    return sanitized === value;
  }, { message: "Emojis are not allowed" }),
  unitName: z.string().trim().min(1, "Required").max(50, "Maximum 50 characters").refine(value => {
    const sanitized = removeEmojis(value);

    return sanitized === value;
  }, { message: "Emojis are not allowed" }),
  unitTypeLabel: z.string().trim().min(1, "Required").max(100, "Maximum 100 characters")
});

export const CustomiseFlywheelGoal: React.FC = () => {
  const isMobile = useIsMobile();
  const dispatch = useCustomiseFlywheelGoalDispatch();
  const { flywheelGoal } = useCustomiseFlywheelGoalState();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    clearErrors,
    formState: { errors }
  } = useForm<z.infer<typeof Schema>>({
    resolver: zodResolver(Schema),
    defaultValues: {
      name: flywheelGoal?.name ?? "",
      unitDescription: flywheelGoal?.unitDescription ?? "",
      unitTypeLabel: flywheelGoal?.unitTypeLabel ?? FlywheelTemplateUnitTypeLabelEnum.COUNT,
      unitName: flywheelGoal?.unitName ?? ""
    },
    shouldFocusError: false
  });

  // Submit handler
  const onSubmit = handleSubmit(() => {
    dispatch({ type: "FINISH" });
  });

  return (
    <div className="h-full w-full">

      <form
        onSubmit={onSubmit}
        className="flex pt-5 flex-col gap-y-5 flex-1"
      >
        <div className="flex flex-col gap-y-1">
          <Input
            {...register("name", {
              onChange: e => {
                dispatch({
                  type: "SET_SELECTED_FLYWHEEL_GOAL",
                  flywheelGoal: {
                    ...flywheelGoal,
                    name: e.target.value
                  }
                });
              }
            })}
            label="How will you measure your goal?"
            name="name"
            min={1}
            placeholder="E.g. Total number of customers"
            inputMode="text"
            autoComplete="off"
            autoCorrect="off"
            hasError={!!errors.name}
            errorMessage={errors.name?.message}
          />

        </div>

        <div className="flex flex-col gap-y-1">
          <Input
            {...register("unitDescription", {
              onChange: e => {
                dispatch({
                  type: "SET_SELECTED_FLYWHEEL_GOAL",
                  flywheelGoal: {
                    ...flywheelGoal,
                    unitDescription: e.target.value
                  }
                });
              }
            })}
            label="Description"
            name="unitDescription"
            min={1}
            placeholder="E.g. Key customers you served during the period"
            inputMode="text"
            autoComplete="off"
            autoCorrect="off"
            hasError={!!errors.unitDescription}
            errorMessage={errors.unitDescription?.message}
          />

        </div>

        <div className="flex flex-col gap-y-1">
          <Input
            {...register("unitName", {
              onChange: e => {
                dispatch({
                  type: "SET_SELECTED_FLYWHEEL_GOAL",
                  flywheelGoal: {
                    ...flywheelGoal,
                    unitName: e.target.value
                  }
                });
              }
            })}
            label="Unit"
            name="unitName"
            min={1}
            placeholder="E.g. Number of customers"
            inputMode="text"
            autoComplete="off"
            autoCorrect="off"
            hasError={!!errors.unitName}
            errorMessage={errors.unitName?.message}
          />

        </div>

        <div className="flex flex-col gap-y-1">
          <SelectInput
            value={watch("unitTypeLabel")}
            onValueChange={val => {
              setValue("unitTypeLabel", val);

              dispatch({
                type: "SET_SELECTED_FLYWHEEL_GOAL",
                flywheelGoal: {
                  ...flywheelGoal,
                  unitTypeLabel: val
                }
              });
            }}
            label="Unit type"
            placeholder="Pick a unit type"
            options={[
              FlywheelTemplateUnitTypeLabelEnum.COUNT,
              FlywheelTemplateUnitTypeLabelEnum.PERCENTAGE,
              FlywheelTemplateUnitTypeLabelEnum.CURRENCY
            ]}
            hasError={!!errors.unitTypeLabel}
            errorMessage={errors.unitTypeLabel?.message}
            clearFormError={() => clearErrors("unitTypeLabel")}
            tooltipInfo="The type of unit to check-in"
          />

        </div>

        <div className={`flex mt-5 ${isMobile ? "flex-col flex-1" : "flex-row"} justify-between`}>
          {isMobile && (<Spacer />)}

          <div className={`flex gap-x-3 self-start ${isMobile ? "flex-col w-full gap-3" : "flex-row"}`}>

            <Button
              title="Back"
              onClick={() => {
                dispatch({ type: "FINISH" });
              }}
              className="bg-brand-cold-metal-100 hover:contrast-75 w-full text-brand-cold-metal-900 px-6"
            />

            <Button
              title="Save"
              type="button"
              className={`px-10 ${isMobile ? "order-first" : ""}`}
              onClick={() => {
                onSubmit();
              }}
            />

          </div>

        </div>

      </form>

    </div>
  );
};