import type { UserGenqlSelectionRes } from "~/hooks/user/use-list-users";

// helper function to sort users by firstName alphabetically
export const sortUsers = (usersResponse: UserGenqlSelectionRes[] | null | undefined): UserGenqlSelectionRes[] => {
  return usersResponse?.sort((a, b) => {
    // Check if firstName of either user is undefined, and handle accordingly
    if (!a.firstName && !b.firstName) {
      return 0; // Both are undefined, so we consider them equal in terms of sorting
    } else if (!a.firstName) {
      return 1; // Only a's firstName is undefined, so a should come after b
    } else if (!b.firstName) {
      return -1; // Only b's firstName is undefined, so b should come after a
    } else {
      return a.firstName.localeCompare(b.firstName);
    }
  }) ?? [];
};
