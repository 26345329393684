import { Icon } from "~/components/Icon";
import { cn } from "~/utils/cn";

import type { PropsWithChildren } from "react";

interface CalloutBadgeProps {
  variant: "warning" | "info" | "danger" | "positive";
  /** Optional - otherwise renders default icon */
  renderLeft?: JSX.Element;
}

const defaultVariantLabel: Record<CalloutBadgeProps["variant"], string> = {
  warning: "Warning",
  info: "Notice",
  danger: "Danger",
  positive: "Success"
};

const defaultVariantIcon: Record<CalloutBadgeProps["variant"], JSX.Element> = {
  warning: <Icon.Attention className="size-[16px]" />,
  danger: <Icon.Error className="size-[16px]" />,
  info: <Icon.InfoCircle className="size-[16px]" />,
  positive: <Icon.Verified className="size-[16px]" />
};

export const CalloutBadge: React.FC<PropsWithChildren<CalloutBadgeProps>> = ({
  variant, children, renderLeft
}) => {
  const classname = cn({
    "bg-brand-attention-orange-100 text-brand-attention-orange-500": variant === "warning",
    "bg-brand-check-in-due-50 text-brand-check-in-due-500": variant === "info",
    "bg-brand-error-red-100 text-brand-error-red-500": variant === "danger",
    "bg-brand-healthy-green-100 text-brand-healthy-green-500": variant === "positive"
  });

  return (
    <span className={cn("border-[1.5px] border-solid border-current rounded-full p-2 min-w-[12ch] leading-[17px] inline-flex items-center gap-1.5", classname)}>
      {renderLeft || defaultVariantIcon[ variant ]}

      <span className="flex-1">
        {children || defaultVariantLabel[ variant ]}
      </span>
    </span>
  );
};