import type {
  FlywheelGoalUnit,
  FlywheelTemplateStep
} from "@roda/shared/types";

export interface CustomFlywheelTemplate {
  id?: number | null;
  name?: string;
  steps: Array<Omit<FlywheelTemplateStep, "metrics"> & {id: string, health?: string; metrics?: TemplateMetric[]}>
}

interface TemplateMetric {
  id: string;
  name: string;
  health: string
}
export interface State {
  isCustomisingMeasurement: boolean;
  isCustomisingTemplate: boolean;
  flywheelTemplate?: CustomFlywheelTemplate;
  flywheelGoal?: Partial<FlywheelGoalUnit>
}

export type TransformActions =
  | { type: "RESET"}
  | { type: "SET_STATE", state: State, }
  | { type: "CUSTOMISE_GOAL" }
  | { type: "CUSTOMISE_TEMPLATE", flywheelTemplate?: CustomFlywheelTemplate }
  | { type: "FINISH" }
  | { type: "MOVE_STEP_UP", stepIdx: number }
  | { type: "MOVE_STEP_DOWN", stepIdx: number }
  | { type: "SET_SELECTED_FLYWHEEL_GOAL", flywheelGoal?: Partial<FlywheelGoalUnit> }
  | { type: "SET_SELECTED_FLYWHEEL_TEMPLATE", flywheelTemplate?: CustomFlywheelTemplate };

export const initialState: State = {
  isCustomisingMeasurement: false,
  isCustomisingTemplate: false
};

export const flywheelGoalReducer = (prevState: State, action: TransformActions): State => {
  switch (action.type) {
    case "RESET":
      return initialState;
    case "SET_STATE":
      return {
        ...prevState,
        ...action.state
      };
    case "CUSTOMISE_GOAL":
      return {
        ...prevState,
        isCustomisingMeasurement: true
      };
    case "CUSTOMISE_TEMPLATE":
      return {
        ...prevState,
        isCustomisingTemplate: true,
        flywheelTemplate: action.flywheelTemplate || prevState.flywheelTemplate
      };
    case "MOVE_STEP_UP": {
      if (!prevState.flywheelTemplate?.steps) {
        return prevState;
      }

      const steps = prevState.flywheelTemplate.steps;
      const stepIdx = action.stepIdx;

      if (action.stepIdx > 0) {
        const newItems = [ ...steps ];

        // Swap the elements
        [ newItems[ stepIdx - 1 ], newItems[ stepIdx ] ] = [ newItems[ stepIdx ], newItems[ stepIdx - 1 ] ];

        return {
          ...prevState,
          flywheelTemplate: {
            ...prevState.flywheelTemplate,
            steps: newItems
          }
        };
      } else {
        return prevState;
      }
    }
    case "MOVE_STEP_DOWN": {
      if (!prevState.flywheelTemplate?.steps) {
        return prevState;
      }

      const steps = prevState.flywheelTemplate.steps;
      const stepIdx = action.stepIdx;

      if (action.stepIdx < prevState.flywheelTemplate.steps.length - 1) {
        const newItems = [ ...steps ];

        // Swap the elements
        [ newItems[ stepIdx ], newItems[ stepIdx + 1 ] ] = [ newItems[ stepIdx + 1 ], newItems[ stepIdx ] ];

        return {
          ...prevState,
          flywheelTemplate: {
            ...prevState.flywheelTemplate,
            steps: newItems
          }
        };
      } else {
        return prevState;
      }
    }

    case "FINISH":
      return {
        ...prevState,
        isCustomisingMeasurement: false,
        isCustomisingTemplate: false
      };
    case "SET_SELECTED_FLYWHEEL_GOAL":
      return {
        ...prevState,
        flywheelGoal: action.flywheelGoal
      };
    case "SET_SELECTED_FLYWHEEL_TEMPLATE": {
      return {
        ...prevState,
        flywheelTemplate: action.flywheelTemplate
      };
    }

    default:
      return prevState;
  }
};