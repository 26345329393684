import { filterMetricUpdatesByWeek } from "~/utils/check-in/filterMetricUpdatesByWeek";

import type { Metric } from "@roda/graphql/genql";

interface Params {
  metrics: Metric[];
  weekStart: string;
  weekEnd: string;
}

/**
 * Function for filtering metrics (their metricUpdates) for a selected week.
 * @param params Params
 * @returns
 */
export const filterMetricsByWeek = ({
  metrics, weekEnd, weekStart
}: Params) => {
  return metrics.map(
    metric => {
      // Filter metric updates by selected week
      const filteredMetricUpdates = metric?.metricUpdates ? filterMetricUpdatesByWeek({
        metricUpdates: metric?.metricUpdates,
        weekStart,
        weekEnd
      }) : [];

      const latestMetricUpdate = filteredMetricUpdates ? filteredMetricUpdates[ filteredMetricUpdates.length - 1 ] : undefined;
      // GET THE CORRECT isCheckInDue for the SELECTED WEEK
      const isCheckInDue = !latestMetricUpdate;

      return {
        ...metric,
        isCheckInDue,
        metricUpdates: filteredMetricUpdates
      };
    }
  );
};