import { cn } from "~/utils/cn";

import { Tag } from "./Tag";
import { TimelineItem } from "./TimelineItem";

import type {
  ComponentProps,
  PropsWithChildren
} from "react";

const Heading: React.FC<PropsWithChildren<{className?: string}>> = ({ children, className }) => (
  <h4 className={cn("text-lg font-semibold text-brand-cold-metal-800 -mb-1", className)}>{children}</h4>
);

const SubHeading: React.FC<PropsWithChildren<{className?: string}>> = ({ children, className }) => (
  <p className={cn("text-xs [font-size:10px] text-brand-cold-metal-400", className)}>{children}</p>
);

const Container: React.FC<ComponentProps<"li">> = ({ ...restProps }) => {
  return (
    <li
      {...restProps}
      className={cn("bg-white min-w-[202px] p-4 pt-3 flex flex-col gap-2 items-start rounded-lg border border-solid border-brand-cold-metal-200", restProps.className)}
    />
  );
};

export const Card = {
  Container,
  Heading,
  SubHeading,
  TimelineItem,
  Tag
};