const tailwindBrandColours = {
  "gray-50": "#38383A",
  "gray-100": "#667085",
  "gray-200": "#9AA3B5",
  "gray-300": "#D1D5DB",
  "gray-400": "#E5E7EB",
  "gray-500": "#F9FAFB",
  "gray-600": "#F3F4F6",
  "gray-700": "#EEEFF1",
  "gray-800": "#E5E7EB",
  "gray-900": "#D1D5DB",
  "gray-950": "#9AA3B5",
  "body": "#0A182A",
  "body-medium": "#334054",
  "body-light": "#667085",
  "body-lighter": "#9AA3B5",
  "gray-bg": "#FBFBFB",
  "gray-border": "#EEEFF1",
  "gray-hover": "#F4F5F6",
  "brand": {
    "gray": "#38383A",
    "beige": "#F5F0E9",
    "yellow": "#F3B53F",
    "green": "#7BCC6B",
    "sub-text": "#667085",
    "text": "#0A192A",
    "grey-icon-bg": "#344054",
    "cold-metal-50": "#F9FAFB",
    "cold-metal-100": "#F3F4F6",
    "cold-metal-200": "#E5E7EB",
    "cold-metal-300": "#D1D5DB",
    "cold-metal-400": "#9CA3B0",
    "cold-metal-500": "#6B7280",
    "cold-metal-600": "#4B5563",
    "cold-metal-700": "#384252",
    "cold-metal-800": "#1F2937",
    "cold-metal-900": "#111722",
    "error-red-50": "#FEF3F2",
    "error-red-100": "#FEE4E2",
    "error-red-200": "#FECDCA",
    "error-red-300": "#FDA29B",
    "error-red-400": "#F9675D",
    "error-red-500": "#F34A44",
    "error-red-600": "#D92920",
    "error-red-700": "#B42318",
    "error-red-800": "#911E18",
    "error-red-900": "#7A221A",
    "attention-orange-50": "#FFF6EB",
    "attention-orange-100": "#FEE6C7",
    "attention-orange-200": "#FECD89",
    "attention-orange-300": "#FEAA4B",
    "attention-orange-400": "#FD9322",
    "attention-orange-500": "#F78512",
    "attention-orange-600": "#DC6103",
    "attention-orange-700": "#B54708",
    "attention-orange-800": "#93370D",
    "attention-orange-900": "#7A2E0E",
    "healthy-green-50": "#ECFDF3",
    "healthy-green-100": "#DCFAE6",
    "healthy-green-200": "#A9EFC5",
    "healthy-green-300": "#75E0A0",
    "healthy-green-400": "#47CD86",
    "healthy-green-500": "#17B26A",
    "healthy-green-600": "#079455",
    "healthy-green-700": "#067647",
    "healthy-green-800": "#085D3A",
    "healthy-green-900": "#074D31",
    "check-in-due-50": "#EAF1FF",
    "check-in-due-100": "#D1E0FF",
    "check-in-due-200": "#A6C3FF",
    "check-in-due-300": "#84ADFF",
    "check-in-due-400": "#528BFF",
    "check-in-due-500": "#3779FF",
    "check-in-due-600": "#0F58ED",
    "check-in-due-700": "#0E4BC6",
    "check-in-due-800": "#0D3B99",
    "check-in-due-900": "#09296C"
  },
  "flywheel": {
    "green-100": "#DCFAE6",
    "green-200": "#A9EFC5",
    "green-500": "#17B26A",
    "green-800": "#085D3A",
    //
    "orange-100": "#FEE6C7",
    "orange-200": "#FECD89",
    "orange-500": "#F78512",
    "orange-800": "#93370D",
    //
    "grey-100": "#F9FAFB",
    "grey-200": "#EAECF0",
    "grey-500": "#9AA3B5",
    "grey-800": "#334054"
  }
};

export default tailwindBrandColours;