import {
  useRef,
  useEffect
} from "react";

import type React from "react";

interface ResponsiveNameProps {
  displayName?: string;
  firstName?: string;
  lastName?: string;
}

export const ResponsiveName: React.FC<ResponsiveNameProps> = ({
  displayName, firstName, lastName
}) => {
  const nameRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    const nameElement = nameRef.current;

    if (nameElement) {
      // Check if the content overflows its container
      if (nameElement.scrollWidth > nameElement.offsetWidth) {
        // If it overflows, allow it to wrap onto the next line
        nameElement.style.whiteSpace = "normal";
      } else {
        // Otherwise, keep it on a single line and truncate if necessary
        nameElement.style.whiteSpace = "nowrap";
      }
    }
  }, [
    displayName,
    firstName,
    lastName
  ]);

  return (
    <div className="flex-1">
      <h5
        ref={nameRef}
        className="text-xs text-brand-cold-metal-600 font-medium capitalize truncate"
        title={firstName && lastName ? `${firstName} ${lastName}` : firstName ?? displayName}
      >
        {firstName && lastName ? `${firstName} ${lastName}` : firstName ?? displayName}
      </h5>
    </div>
  );
};

export default ResponsiveName;
