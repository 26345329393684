import {
  AiFillCaretDown,
  AiFillCaretUp
} from "react-icons/ai";
import {
  BiLoaderAlt,
  BiEdit,
  BiCheckCircle,
  BiDotsVerticalRounded
} from "react-icons/bi";
import {
  CgChevronDown,
  CgChevronRight,
  CgChevronUp
} from "react-icons/cg";
import { FaRegEnvelope } from "react-icons/fa6";
import {
  FiUpload,
  FiPlusSquare
} from "react-icons/fi";
import { GiHamburgerMenu } from "react-icons/gi";
import {
  PiWarningCircle,
  PiEnvelopeOpenLight
} from "react-icons/pi";
import { RiDeleteBin6Line } from "react-icons/ri";
// (!) SVGs loaded using the vite-plugin-svgr plugin must append `?react` to the end of the import path

import Announcement from "~/assets/icon-svg/announcement.svg?react";
import Attention from "~/assets/icon-svg/attention.svg?react";
import BarChart from "~/assets/icon-svg/bar-chart-square-plus.svg?react";
import Bolt from "~/assets/icon-svg/bolt.svg?react";
import Calendar from "~/assets/icon-svg/calendar.svg?react";
import Camera from "~/assets/icon-svg/camera.svg?react";
import ChevronLeft from "~/assets/icon-svg/chevron-left.svg?react";
import ChevronRightDouble from "~/assets/icon-svg/chevron-right-double.svg?react";
import Clock from "~/assets/icon-svg/clock.svg?react";
import Company from "~/assets/icon-svg/company.svg?react";
import DownloadImage from "~/assets/icon-svg/download-image.svg?react";
import HamburgerMenu from "~/assets/icon-svg/hamburger-menu.svg?react";
import Healthy from "~/assets/icon-svg/healthy.svg?react";
import HelpCircle from "~/assets/icon-svg/help-circle.svg?react";
import InfoCircle from "~/assets/icon-svg/info-circle.svg?react";
import InviteUsers from "~/assets/icon-svg/invite-users.svg?react";
import Logout from "~/assets/icon-svg/logout.svg?react";
import Metrics from "~/assets/icon-svg/metrics.svg?react";
import NotificationBell from "~/assets/icon-svg/notification-bell.svg?react";
import PlusCircle from "~/assets/icon-svg/plus-circle.svg?react";
import Profile from "~/assets/icon-svg/profile.svg?react";
import Refresh from "~/assets/icon-svg/refresh.svg?react";
import Search from "~/assets/icon-svg/search.svg?react";
import Send from "~/assets/icon-svg/send.svg?react";
import SettingsCog from "~/assets/icon-svg/settings-cog.svg?react";
import Steps from "~/assets/icon-svg/steps.svg?react";
import Support from "~/assets/icon-svg/support.svg?react";
import Users from "~/assets/icon-svg/users.svg?react";
import XSquare from "~/assets/icon-svg/x-square.svg?react";
import MetricCount from "~/assets/metrics-icons/count.svg?react";
import MetricCurrency from "~/assets/metrics-icons/currency.svg?react";
import MetricHoursDaysWeeks from "~/assets/metrics-icons/hoursdaysweeks.svg?react";
import MetricMinutes from "~/assets/metrics-icons/minutes.svg?react";
import MetricNPS from "~/assets/metrics-icons/nps.svg?react";
import MetricPercentage from "~/assets/metrics-icons/percentage.svg?react";
import MetricProjects from "~/assets/metrics-icons/projects.svg?react";
import MetricSessions from "~/assets/metrics-icons/sessions.svg?react";
import MetricSubs from "~/assets/metrics-icons/subs.svg?react";
import MetricUsers from "~/assets/metrics-icons/users.svg?react";
import MetricViews from "~/assets/metrics-icons/views.svg?react";
/**
 * LETS ALL USE THE SAME ICONS
 */

export const Icon = {
  Loader: BiLoaderAlt,
  Camera,
  ChevronRight: CgChevronRight,
  ChevronDown: CgChevronDown,
  ChevronUp: CgChevronUp,
  Menu: GiHamburgerMenu,
  DotMenu: BiDotsVerticalRounded,
  Edit: BiEdit,
  FillCaretDown: AiFillCaretDown,
  FillCaretUp: AiFillCaretUp,
  Clock,
  Error: PiWarningCircle,
  PlusSquare: FiPlusSquare,
  Upload: FiUpload,
  Verified: BiCheckCircle,
  MailOpen: PiEnvelopeOpenLight,
  MailClosed: FaRegEnvelope,
  Bin: RiDeleteBin6Line,
  Refresh,
  NotificationBell,
  SettingsCog,
  InviteUsers,
  HelpCircle,
  Logout,
  Metrics,
  Steps,
  Company,
  Users,
  Bolt,
  Support,
  Profile,
  ChevronLeft,
  ChevronRightDouble,
  Calendar,
  Attention,
  Healthy,
  InfoCircle,
  PlusCircle,
  Send,
  MetricCount,
  MetricCurrency,
  MetricHoursDaysWeeks,
  MetricMinutes,
  MetricNPS,
  MetricPercentage,
  MetricProjects,
  MetricSessions,
  MetricSubs,
  MetricUsers,
  MetricViews,
  HamburgerMenu,
  BarChart,
  Announcement,
  XSquare,
  DownloadImage,
  Search
};
