import type { StepType } from "~/contexts/OnboardingContext/onboarding-reducer";

import type { Step } from "@roda/graphql/genql";

export enum HealthStatus {
  Waiting = "waiting",
  Healthy = "healthy",
  NeedsAttention = "needs-attention",
  CheckInDue = "check-in-due"
}
// we only require loose definitions of steps for ease-of-use, only their
// metric, name and alias
export type LooseStep<S extends Step | StepType> = Omit<S, "metrics" | "name"> & Pick<S, "metrics" | "name" | "alias">;
// we can loosely provide flywheel props, knowing that we mainly are looking for steps
export type FlywheelFromContextRequireLooseSteps = {[key: string]: unknown, steps: Array<LooseStep<Step | StepType>>};