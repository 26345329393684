import { TargetType } from "@roda/shared/types";

import type { GetFlywheelMetricData } from "~/hooks/flywheel/use-get-flywheel";
import type { FlywheelReviewTargetData } from "~/hooks/flywheel/use-get-flywheel-review";
import { getWeekMetricTarget } from "~/utils/getWeekMetricTarget";

import type { MetricType } from "@roda/core/metric";
import type { Dayjs } from "dayjs";

type Targets = Array<Pick<FlywheelReviewTargetData, "target" | "toDate" | "fromDate">>;
type MetricProp = Pick<MetricType, "unitTargetType">& {
  targets: Targets | null,

};

export const weekIsHealthy = (metric: MetricProp, selectedWeekStart: Dayjs, selectedQuarterWeeks: Dayjs[], cumulativeTotalForWeek: number, selectedWeekUpdate: NonNullable<GetFlywheelMetricData["metricUpdates"]>[0] | null | undefined) => {
  // If we've got an update for the week, use that to judge the health of the bar
  if (selectedWeekUpdate) return selectedWeekUpdate.isHealthy;

  if (!metric.targets) return null;

  // Find the index of the current week in the quarter
  const weekIndex = selectedQuarterWeeks.findIndex(weekStart => weekStart.isSame(selectedWeekStart));
  // Get the target for the given week (aka for the quarter it falls into, for cumulative data)
  const quarterTarget = getWeekMetricTarget(metric.targets, selectedWeekStart);
  const weeklyTarget = quarterTarget ? +quarterTarget?.target / selectedQuarterWeeks.length : 0;
  const targetForSelectedWeek = (weekIndex + 1) * weeklyTarget;

  // If the cumulative value for the current week is above / below the target by that week
  // (depending on the target type), we consider it to be healthy - irrespective of whether
  // there's an update or not
  return metric.unitTargetType === TargetType.EXCEED ? +cumulativeTotalForWeek >= +targetForSelectedWeek : +cumulativeTotalForWeek < +targetForSelectedWeek;
};