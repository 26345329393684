import { useTypedMutation } from "@roda/graphql/urql";

import { Metric } from "../../fragments/metric";

import type { MutationGenqlSelection } from "@roda/graphql/genql";

type Params = NonNullable<MutationGenqlSelection["editExistingMetricUpdate"]>["__args"];

export const useEditExistingMetricUpdate = () => {
  return useTypedMutation((vars: Params) => ({
    editExistingMetricUpdate: {
      __args: vars,
      ...Metric
    }
  }));
};