import { zodResolver } from "@hookform/resolvers/zod";
import { removeEmojis } from "@roda/shared/utils";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import z from "zod";

import WelcomeImg from "~/assets/illustrations/welcome.png";
import { Button } from "~/components/Button";
import { Input } from "~/components/form";
import { Icon } from "~/components/Icon";
import type { OnboardingContentStepProps } from "~/components/onboarding/OnboardingContent";
import { OnboardingContentWrapper } from "~/components/onboarding/OnboardingContentWrapper";
import { PillMessage } from "~/components/PillMessage";
import { Spacer } from "~/components/Spacer";
import {
  useOnboardingDispatch,
  useOnboardingState
} from "~/contexts/OnboardingContext/OnboardingContext";
import { useError } from "~/hooks/useError";
import { useIsMobile } from "~/hooks/useIsMobile";
import { useUpdateSelf } from "~/hooks/user";
import { removeExtraSpaces } from "~/utils/validation";

const UserDetailsSchema = z.object({
  firstName: z.string().trim().min(1, "Required").max(50).transform(removeExtraSpaces).refine(value => {
    const sanitized = removeEmojis(value);

    return sanitized === value;
  }, { message: "Emojis are not allowed" }),
  lastName: z.string().trim().min(1, "Required").max(50).transform(removeExtraSpaces).refine(value => {
    const sanitized = removeEmojis(value);

    return sanitized === value;
  }, { message: "Emojis are not allowed" }),
  jobTitle: z.string().trim().min(1, "Required").max(50).transform(removeExtraSpaces).refine(value => {
    const sanitized = removeEmojis(value);

    return sanitized === value;
  }, { message: "Emojis are not allowed" })
});

export const UserDetails: React.FC<OnboardingContentStepProps> = ({ onNextStep, changeStep }) => {
  const { user } = useOnboardingState();
  const dispatch = useOnboardingDispatch();
  const [ updateUserRes, updateUserReq ] = useUpdateSelf();
  const { handleRodaError } = useError();
  const isMobile = useIsMobile();

  useEffect(() => {
    if (updateUserRes.error) {
      handleRodaError(updateUserRes.error, "Failed to update user details");
    }

    // If user has been updated move to the next step
    if (updateUserRes.data) {
      onNextStep();
    }
  }, [
    handleRodaError,
    onNextStep,
    updateUserRes.data,
    updateUserRes.error
  ]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<z.infer<typeof UserDetailsSchema>>({
    resolver: zodResolver(UserDetailsSchema),
    shouldFocusError: false
  });

  // Initialise user details using the user from onboarding state
  useEffect(() => {
    if (user) {
      user?.firstName && setValue("firstName", user?.firstName);
      user?.lastName && setValue("lastName", user?.lastName);
      user?.jobTitle && setValue("jobTitle", user?.jobTitle);
    }
  }, [ setValue, user ]);

  // Submit handler
  const onSubmit = handleSubmit(fields => {
    // Update user details in state
    if (user?.email) {
      dispatch({
        type: "UPDATE_USER",
        user: {
          firstName: fields.firstName ?? null,
          lastName: fields.lastName ?? null,
          email: user.email,
          jobTitle: fields.jobTitle ?? null
        }
      });

      // Update user in DB
      updateUserReq({
        firstName: fields.firstName ?? null,
        lastName: fields.lastName ?? null,
        jobTitle: fields.jobTitle ?? null
      });
    }
  });

  return (
    <OnboardingContentWrapper
      changeStep={changeStep}
      rightContent={(
        <img
          src={WelcomeImg}
          className="absolute inset-y-0 top-5 object-contain desktop:max-w-[min(35vmax,800px)] desktop:absolute-center w-full h-full"
        />
      )}
      leftContent={(
        <div className="w-full flex-1 flex flex-col">
          <PillMessage
            text="Setting up your flywheel should take no more than 15 minutes"
            icon={<Icon.Clock />}
          />

          <form
            onSubmit={onSubmit}
            className="flex-1 flex-col flex"
          >
            <div className="flex flex-col gap-y-5 flex-1">
              <Input
                {...register("firstName")}
                label="First name"
                name="firstName"
                placeholder="Your first name"
                inputMode="text"
                hasError={!!errors.firstName}
                errorMessage={errors.firstName?.message}
              />

              <Input
                {...register("lastName")}
                label="Last name"
                name="lastName"
                inputMode="text"
                placeholder="Your last name"
                hasError={!!errors.lastName}
                errorMessage={errors.lastName?.message}
              />

              <Input
                {...register("jobTitle")}
                label="Job title"
                name="jobTitle"
                inputMode="text"
                placeholder="Your job title"
                hasError={!!errors.jobTitle}
                errorMessage={errors.jobTitle?.message}
              />

              {isMobile && (<Spacer />)}

              <div className={`flex gap-x-3 self-start pb-5 ${isMobile ? "flex-col w-full gap-3" : "flex-row mt-5"}`}>
                <Button
                  title="Next"
                  className={`${isMobile && "w-full"}`}
                  type="submit"
                  loading={updateUserRes.fetching}
                />
              </div>
            </div>

          </form>

          {user?.email && (
            <div className="flex flex-row mb-2 py-2 rounded-md">
              <div className="flex flex-row gap-2 items-center text-brand-cold-metal-500 justify-center text-sm">
                <Icon.InfoCircle />

                <p>Logged in as {user?.email}</p>
              </div>
            </div>
          )}
        </div>
      )}
    />

  );
};