import clsx from "clsx";
import React, { forwardRef } from "react";
import { twMerge } from "tailwind-merge";

import { InputLabel } from "./InputLabel";

export type TextAreaProps = Omit<JSX.IntrinsicElements["textarea"], "ref"> & {
  label?: string | null;
  hasError?: boolean;
  errorMessage?: string;
  subText?: string;
  optionalLabel?: boolean;
};

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({
    label, hasError, errorMessage, subText, optionalLabel, ...inputProps
  }, ref) => {
    return (
      <div className="flex flex-col items-start gap-2">
        {label && (
          <InputLabel
            htmlFor={label}
          >
            {label}

            {optionalLabel && (
              <p className="text-brand-cold-metal-300">(optional)</p>
            )}
          </InputLabel>
        )}

        <textarea
          ref={ref}
          id={label ?? undefined}
          {...inputProps}
          className={clsx(
            "w-full rounded-lg text-base border px-2.5 py-1 min-h-28",
            {
              "bg-white text-brand-cold-metal-500 border-brand-cold-metal-200 focus:border-brand-dark-blue": !hasError,
              "bg-brand-error-red-50 border-brand-error-red-300 focus:border-brand-error-red-500": hasError,
              "cursor-not-allowed opacity-50": inputProps.disabled
            },
            twMerge(inputProps.className ?? "")
          )}
        />

        {hasError && (
          <span className="text-xs text-brand-error-red-600">
            {errorMessage}
          </span>
        )}

        {subText && !hasError && (
          <span className="text-xs text-brand-cold-metal-400">
            {subText}
          </span>
        )}
      </div>
    );
  },
);
