import { FlywheelGoalEditCheckInPopup } from "~/components/flywheel-goal/FlywheelGoalEditCheckInPopup";

import type { FlywheelGoalUpdate } from "@roda/graphql/genql";

interface EditMetricUpdateMenuItemProps {
  openPopup: () => void;
  closePopup: () => void;
  isOpen: boolean;
  update: FlywheelGoalUpdate;
}

export const EditExistingFlywheelGoalUpdateMenuItem: React.FC<EditMetricUpdateMenuItemProps> = ({
  openPopup, closePopup, isOpen, update
}) => {
  return (
    <>
      <button onClick={openPopup}>
        Edit check-in
      </button>

      <FlywheelGoalEditCheckInPopup
        isOpen={isOpen}
        onClose={closePopup}
        update={update}
      />
    </>
  );
};