import { useTypedMutation } from "@roda/graphql/urql";

import { ReducedFlywheel } from "~/fragments/flywheel";

import type { MutationGenqlSelection } from "@roda/graphql/genql";

export type CreateStepsWithMetricsParams = NonNullable<MutationGenqlSelection["createStepsWithMetrics"]>["__args"];

export const useCreateStepsWithMetrics = () => {
  return useTypedMutation((vars: CreateStepsWithMetricsParams) => ({
    createStepsWithMetrics: {
      __args: vars,
      ...ReducedFlywheel
    }
  }));
};