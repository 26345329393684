import { Button } from "~/components/Button";
import { Icon } from "~/components/Icon";
import {
  useImageCaptureContext,
  type ImageCaptureTargetId
} from "~/contexts/ImageCaptureContext";
import type { handleCapture } from "~/utils/captureElement";
import { cn } from "~/utils/cn";

import type { ComponentProps } from "react";

/**
 * (!) Remember to wrap an element with `<ImageCaptureTarget>`, and
 * optionally `data-capture-ignore` attribute to exclude elements from being
 * captured.
 */
export const SaveImageButton: React.FC<{
  captureId: ImageCaptureTargetId,
  /** if provided, button will `useImageCaptureContext.capture`, otherwise it
   * will default to `useImageCaptureContext.captureAndPreview` */
  onImageUrl?: Parameters<typeof handleCapture>[1],
  html2canvasOptions?: Parameters<typeof handleCapture>[2]
} & Omit<ComponentProps<typeof Button>, "onClick">> = ({
  captureId, onImageUrl, html2canvasOptions, ...props
}) => {
  const { captureAndPreview, capture } = useImageCaptureContext();

  return (
    <Button
      onClick={() => {
        if (onImageUrl) {
          return capture(captureId, onImageUrl, html2canvasOptions);
        }

        captureAndPreview(captureId, html2canvasOptions);
      }}
      data-capture-ignore
      title="Save as image"
      iconComponent={<Icon.Camera className="-ml-0.5" />}
      {...props}
      className={cn("ring-1 ring-brand-cold-metal-200 bg-brand-cold-metal-50 text-body-medium font-medium !gap-1 !px-[13px] !py-[7px]", props.className)}
    />
  );
};