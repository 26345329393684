import { enumUserRole } from "@roda/graphql/genql";
import { useMemo } from "react";

import { CompanyAvatar } from "~/components/CompanyAvatar";
import { Icon } from "~/components/Icon";
import { useRodaAdminCompaniesContext } from "~/contexts/RodaAdminCompaniesContext";
import { useCurrentUser } from "~/contexts/UserContext";

interface HeaderProps {
  onBack?: () => void;
}

export const Header: React.FC<HeaderProps> = ({ onBack }) => {
  const { user } = useCurrentUser();
  const { currentCompany } = useRodaAdminCompaniesContext();
  const isRodaAdmin = user?.role === enumUserRole.RODA_ADMIN;

  const userCompany = useMemo(() => isRodaAdmin ? currentCompany : user?.company, [
    currentCompany,
    isRodaAdmin,
    user?.company
  ]);

  return (
    <header className="flex flex-row gap-3 p-3 items-center border-brand-cold-metal-200 border-solid border-b w-full">
      {isRodaAdmin && (
        <button
          className="font-semibold py-3 px-1 truncate capitalize text-left flex items-center gap-4 hover:bg-brand-cold-metal-100"
          title="Go back"
          aria-label="Go back to admin menu"
          onClick={onBack}
        >
          <Icon.ChevronLeft />
        </button>
      )}

      {/* avatar */}
      {userCompany && (
        <CompanyAvatar
          companyName={userCompany.name}
          logoS3Key={userCompany.logoS3Key ?? undefined}
        />
      )}

      {/* name */}
      <h4
        className="flex-1 font-semibold pr-3 truncate"
        title={userCompany?.name}
      >
        {userCompany?.name}
      </h4>
    </header>
  );
};