export interface Tier {
  name: string;
  sub?: string;
  min: number;
  max: number;
  batch: number;
  basePrice: number;
  pricePerUser: number;
}

export const rodaPriceTiers: Tier[] = [
  {
    name: "Base plan",
    sub: "First 5 users",
    basePrice: 60,
    pricePerUser: 0,
    min: 1,
    max: 5,
    batch: 0
  },
  {
    name: "Next 10 users",
    basePrice: 0,
    pricePerUser: 12,
    min: 6,
    max: 15,
    batch: 5
  },
  {
    name: "Next 15 users",
    basePrice: 0,
    pricePerUser: 11,
    min: 16,
    max: 30,
    batch: 15
  },
  {
    name: "Next 20 users",
    basePrice: 0,
    pricePerUser: 10,
    min: 31,
    max: Infinity,
    batch: 30
  }
];

// Based on the tiers above, and the number of verified & active users for a company,
// calculate the total cost of a subscription.
// E.g: 16 users:
// First 5 × £0.00 + £60.00 base price = £60.00
// Next 10 × £12.00 = £120.00
// Next 1 × £11.00 = £11.00
// Total £191.00
export function calculateTotalCost(userCount: number) {
  let totalCost = 0;

  if (userCount < 0) {
    throw "Input must be positive integer";
  }

  if (userCount === 0) {
    throw "Input must be at least 1";
  }

  // If the number is a float
  if (userCount % 1) {
    throw "Input must be an integer";
  }

  for (const tier of rodaPriceTiers) {
    if (userCount >= tier.min && userCount <= tier.max) {
      const usersInTier = userCount - tier.batch;

      totalCost += tier.basePrice + (tier.pricePerUser * usersInTier);
      break;
    } else if (userCount > tier.max) {
      const usersInTier = tier.max - tier.batch;

      totalCost += tier.basePrice + (tier.pricePerUser * usersInTier);
    }
  }

  return totalCost;
}