import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";

import type { StepType } from "~/contexts/OnboardingContext/onboarding-reducer";
import { useOnboardingState } from "~/contexts/OnboardingContext/OnboardingContext";

import type { PropsWithChildren } from "react";
import type React from "react";

interface SelectedStepProps {
  selectedStep: StepType | null;
  selectedStepIdx: number | undefined;
  setSelectedStep: (step: StepType | null) => void;
  setSelectedStepIdx: (idx: number) => void;
}

const SelectedStepContext = createContext<SelectedStepProps>({
  selectedStep: null,
  selectedStepIdx: 0,
  setSelectedStep: () => null,
  setSelectedStepIdx: () => null
});

export const SelectedStepProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [ selectedStep, setSelectedStep ] = useState<StepType | null>(null);
  const [ selectedStepIdx, setSelectedStepIdx ] = useState<number | undefined>(0);
  const { flywheel: onboardingFlywheel } = useOnboardingState();

  // Select the associated step to match the selectedIdx
  useEffect(() => {
    if (selectedStepIdx !== undefined) {
      const step = onboardingFlywheel?.steps?.find(step => step?.stepIdx === selectedStepIdx);

      // If there's no step yet then set to null
      setSelectedStep(step || null);
    }
  }, [ onboardingFlywheel?.steps, selectedStepIdx ]);

  // Select the associated stepIdx to match the selectedStep
  useEffect(() => {
    if (selectedStep) {
      if (selectedStep.stepIdx !== undefined) {
        setSelectedStepIdx(selectedStep.stepIdx);
      } else {
        // find the stepIdx
        const stepIdx = onboardingFlywheel?.steps?.findIndex(step => step?.name === selectedStep.name);

        // If there's no stepIdx then set to undefined
        stepIdx !== -1 ? setSelectedStepIdx(stepIdx) : setSelectedStepIdx(undefined);
      }
    }
  }, [ onboardingFlywheel?.steps, selectedStep ]);

  const memoedValue = useMemo<SelectedStepProps>(
    () => ({
      selectedStep,
      selectedStepIdx,
      setSelectedStep,
      setSelectedStepIdx
    }),
    [ selectedStep, selectedStepIdx ]
  );

  return (
    <SelectedStepContext.Provider value={memoedValue}>
      {children}
    </SelectedStepContext.Provider>
  );
};

export const useSelectedStep = () => useContext(SelectedStepContext);
