import { useTypedMutation } from "@roda/graphql/urql";

import { Metric } from "~/fragments/metric";
import { UserMetricReview } from "~/fragments/userMetricReview";

import type { MutationGenqlSelection } from "@roda/graphql/genql";

type Params = NonNullable<MutationGenqlSelection["reviewMetric"]>["__args"];

export const useReviewMetric = () => {
  return useTypedMutation((vars: Params) => ({
    reviewMetric: {
      __args: vars,
      reviewers: UserMetricReview,
      ...Metric
    }
  }));
};