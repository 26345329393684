import {
  useNavigate,
  useParams
} from "react-router-dom";

import { routes } from "~/constants/routes";
import { useCustomiseMetricDispatch } from "~/contexts/CustomiseMetricContext/CustomiseMetricContext";
import { CustomiseMetricStage } from "~/contexts/CustomiseMetricContext/metric-reducer";

import type { Step } from "@roda/graphql/genql";

interface AddMetricMenuItemProps {
  step: Step;
}

export const AddMetricMenuItem: React.FC<AddMetricMenuItemProps> = ({ step }) => {
  const navigate = useNavigate();
  const dispatch = useCustomiseMetricDispatch();
  const params = useParams() as { companyId?: string };

  return (
    <>
      <button
        onClick={() => {
          dispatch({
            type: "SET_SELECTED_METRIC",
            stage: CustomiseMetricStage.SELECT_OR_CREATE,
            step: {
              id: step.id,
              name: step.name
            },
            metric: undefined
          });
          navigate(routes.addMetric(step.id, params.companyId));
        }}
      >
        Add metric
      </button>
    </>
  );
};