import tailwindBrandColours from "@roda/shared/tailwindBrandColours";
import clsx from "clsx";

interface ProgressBarProps {
  target?: number;
  /** The total progress towards the target */
  progress: number;
  /** The progress made in the recent period being displayed (e.g. this week) */
  recentProgress?: number;
  isHealthy?: boolean;
  isCheckInDue?: boolean;
  progressbBarBaseColour?: string; // override the base colour
  neutral?: boolean;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
  target = 100, progress, recentProgress, isHealthy, isCheckInDue, neutral
}) => {
  const targetRelativeToProgressPercent = (target / progress) * 100;
  // We show total progress up until the recent progress and (optionally) just recent progress as a separate colour
  let pastProgressPercent = ((progress - (recentProgress || 0)) / target) * 100;
  let recentProgressPercent = recentProgress ? (recentProgress / target) * 100 : 0;
  // Use the appropriate shades for each colour
  const barProgressColour = isCheckInDue ? tailwindBrandColours.brand[ "check-in-due-500" ] : !isHealthy ? tailwindBrandColours.brand[ "attention-orange-500" ] : tailwindBrandColours.brand[ "healthy-green-500" ];
  const barBaseColour = neutral ? tailwindBrandColours.brand[ "cold-metal-100" ] : isCheckInDue ? tailwindBrandColours.brand[ "check-in-due-100" ] : !isHealthy ? tailwindBrandColours.brand[ "attention-orange-100" ] : tailwindBrandColours.brand[ "healthy-green-100" ];
  let barCumulativeColour = neutral ? tailwindBrandColours.brand[ "cold-metal-300" ] : isCheckInDue ? tailwindBrandColours.brand[ "check-in-due-300" ] : !isHealthy ? tailwindBrandColours.brand[ "attention-orange-300" ] : tailwindBrandColours.brand[ "healthy-green-300" ];

  if (!recentProgress) {
    // If no recent progress then this should bar has the strongest shade
    barCumulativeColour = barProgressColour;
  }

  // If we have exceeded the target, we need to make sure it still fits on the graph
  if (targetRelativeToProgressPercent < 100) {
    const allProgress = pastProgressPercent + recentProgressPercent;

    pastProgressPercent = pastProgressPercent / allProgress * 100;
    recentProgressPercent = recentProgressPercent / allProgress * 100;
  }

  return (
    <div
      className={clsx("relative w-full h-1.5 rounded-lg z-0")}
    >
      {targetRelativeToProgressPercent < 100 && (
        <div
          className={clsx(`absolute h-5 border-solid ${isCheckInDue ? " border-[#FAFCFF]" : "border-white"} rounded-2xl border-2 z-30 top-1/2 -translate-y-1/2 w-2 inset-0 `)}
          style={{
            transition: "all 0.5s ease-in-out",
            background: barProgressColour,
            left: `${targetRelativeToProgressPercent}%`
          }}
        />
      )}

      <div
        className="absolute rounded-2xl overflow-hidden inset-0 h-full flex flex-row "
        style={{ background: barBaseColour }}
      >
        <div
          className={clsx("inset-0 h-full flex-none ", !recentProgress && "rounded-r-2xl")}
          style={{
            transition: "all 0.5s ease-in-out",
            width: `${pastProgressPercent}%`,
            background: barCumulativeColour,
            left: `${pastProgressPercent}%`
          }}
        />

        <div
          className="h-full rounded-r-2xl flex-none z-10"
          style={{
            width: `${recentProgressPercent}%`,
            background: barProgressColour,
            transition: "all 0.5s ease-in-out",
            left: `${pastProgressPercent - recentProgressPercent}%`
          }}
        />
      </div>

    </div>
  );
};