interface AvatarColours {
  bgColour: string;
  textColour: string;
}

/**
 * Function for getting back colours for avatars based on their initials
 */
export const getAvatarColours = (initials: string): AvatarColours => {
  const avatarColours: AvatarColours[] = [
    {
      bgColour: "#FDA29B",
      textColour: "#911E18"
    },
    {
      bgColour: "#75E0A0",
      textColour: "#085D3A"
    },
    {
      bgColour: "#a4dbfd",
      textColour: "#3264a6"
    },
    {
      bgColour: "#FAA7E0",
      textColour: "#9E165F"
    },
    {
      bgColour: "#FEAA4B",
      textColour: "#93370D"
    },
    {
      bgColour: "#D6BBFB",
      textColour: "#53389E"
    }
  ];

  let initialsNumber = 0;

  initials.split("").map(initial => (initialsNumber += initial.charCodeAt(0)));

  return avatarColours[ initialsNumber % avatarColours.length ];
};
