import { enumUserRole } from "@roda/graphql/genql";
import { useMemo } from "react";

import { AddMetricMenuItem } from "~/components/step/AddMetricMenuItem";
import { VerticalDotMenu } from "~/components/VerticalDotMenu";
import { useCurrentUser } from "~/contexts/UserContext";

import type { Step } from "@roda/graphql/genql";
import type { ComponentProps } from "react";

export enum EditMetricAction {
  EditMetricName,
  EditMetricTarget,
  EditMetricOwner,
  SaveImage,
  EditMetricUpdate
}

interface EditStepMenuProps extends Pick<ComponentProps<typeof VerticalDotMenu>, "buttonClassName"> {
  step: Step;
}

export const EditStepMenu: React.FC<EditStepMenuProps> = ({ step }) => {
  const { user } = useCurrentUser();
  // Roda admins, admins and the metric owner can view this menu
  const canViewMetricMenu = useMemo(() => user?.role === enumUserRole.RODA_ADMIN || user?.role === enumUserRole.ADMIN, [ user?.role ]);

  if (!canViewMetricMenu) return null;

  return (
    <VerticalDotMenu buttonClassName="m-0 p-0 sm:p-0 text-brand-cold-metal-400 hover:text-black">

      <AddMetricMenuItem step={step} />

    </VerticalDotMenu>
  );
};