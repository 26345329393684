import { zodResolver } from "@hookform/resolvers/zod";
import { enumUserRole } from "@roda/graphql/genql";
import { useEffect } from "react";
import {
  Controller,
  useForm
} from "react-hook-form";
import toast from "react-hot-toast";
import {
  useNavigate,
  useParams
} from "react-router-dom";

import {
  AvatarControls,
  AvatarType
} from "~/components/AvatarControls";
import { Breadcrumbs } from "~/components/Breadcrumbs";
import { Button } from "~/components/Button";
import { Divider } from "~/components/Divider";
import { CompanySettingsSchema } from "~/components/form/formSchemas";
import { Input } from "~/components/form/Input";
import { Spacer } from "~/components/Spacer";
import { routes } from "~/constants/routes";
import { useRodaAdminCompaniesContext } from "~/contexts/RodaAdminCompaniesContext";
import { useSideNavigation } from "~/contexts/SideNavigationContext";
import { useRodaSubscription } from "~/contexts/SubscriptionContext";
import { useCurrentUser } from "~/contexts/UserContext";
import { useUpdateCompany } from "~/hooks/company/use-update-company";
import { useError } from "~/hooks/useError";
import { useIsMobile } from "~/hooks/useIsMobile";

import type { Company } from "@roda/graphql/genql";

export const CompanySettings = () => {
  // Hooks for fetching the current user and updating organisation details
  const { user: currentUser } = useCurrentUser();

  const {
    isSubscriptionActive, activeOrTrialing, setPlanModal, trialDaysRemaining, handlePortal, loadingPortal
  } = useRodaSubscription();

  const [ updateCompanyRes, updateCompanyReq ] = useUpdateCompany();
  const isMobile = useIsMobile();
  // State for managing update status
  const { handleRodaError } = useError();
  const { companyId } = useParams();

  const {
    currentCompany, setCurrentCompany, refetchCompanies
  } = useRodaAdminCompaniesContext();

  const navigate = useNavigate();
  const company = currentUser?.company || currentCompany;

  /** Load in the admin company from the path param, if there isn't one loaded already or it's the wrong company */
  useEffect(() => {
    if (currentUser?.role === enumUserRole.RODA_ADMIN && (!currentCompany || (companyId && currentCompany.id !== companyId))) {
      if (!companyId) {
        navigate(routes.rodaAdminOrganisationManagement);
      } else {
        setCurrentCompany(companyId);
      }
    }
  }, [
    companyId,
    currentCompany,
    navigate,
    setCurrentCompany,
    currentUser?.role
  ]);

  const { setHideMainSideNav } = useSideNavigation();

  // On render of this page - always show the main side nav
  useEffect(() => {
    setHideMainSideNav(false);
  }, [ setHideMainSideNav ]);

  // Form hook for managing form state and interactions
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty }
  } = useForm({
    resolver: zodResolver(CompanySettingsSchema),
    defaultValues: { name: company?.name || "" },
    shouldFocusError: false
  });

  // Function for handling form submission
  const submitHandler = handleSubmit(async formData => {
    if (company) {
      // Update company details via API request

      const response = await updateCompanyReq({
        companyId: +company.id,
        ...formData
      });

      if (response.data) {
        refetchCompanies();
        toast.success("Organisation updated");
        reset({ name: response.data.updateCompany.name });
      } else if (response.error) {
        handleRodaError(response.error, "Failed to update organisation");
      }
    }
  });

  return (
    <div className={`flex flex-col w-full flex-1 bg-white ${!isMobile && "px-10"}`}>
      {/* Breadcrumbs */}
      {!isMobile && (
        <Breadcrumbs
          crumbs={[ { label: "General" } ]}
        />
      )}

      <div className="flex flex-col items-center justify-start w-full flex-1">

        {/* Container for form */}
        <div className={`max-w-[900px] flex-1 w-full text-xs sm:text-sm flex flex-col px-5 ${isMobile ? "mt-4 gap-4" : "mt-10 gap-8"}`}>

          {/* Header */}
          <div className="flex flex-col w-full">
            <div className={`font-bold text-left pb-2 ${isMobile ? "text-lg" : "text-2xl"}`}>Company & Billing</div>

            <p className={`text-brand-cold-metal-500 ${isMobile ? "text-md" : "text-lg"}`}>Change settings for your organisation here</p>
          </div>

          {!isSubscriptionActive && activeOrTrialing && trialDaysRemaining ? (
            <>
              <Divider />

              <div className="flex flex-col w-full gap-2">
                <div className="font-bold text-left pb-2 text-lg">Billing</div>

                <p className="text-brand-cold-metal-500 text-md">{`Your trial ends ${trialDaysRemaining === 1 ? "tomorrow" : trialDaysRemaining > 0 ? `in ${trialDaysRemaining} days` : "today"}.`}</p>

                <p className="text-brand-cold-metal-500 text-md">
                  Please add your payment details to continue using Roda after your trial period ends.
                </p>

                <div className="pt-6">
                  <Button
                    title="See pricing"
                    onClick={() => setPlanModal(true)}
                  />
                </div>
              </div>
            </>
          ) : <></>}

          <Divider />

          {!activeOrTrialing && (
            <>
              <div className="flex flex-col w-full gap-3">
                <div className="font-bold text-left text-lg">Billing</div>

                <p className="text-brand-cold-metal-500 text-md">Your company does not have an active trial or subscription.</p>

                <p className="text-brand-cold-metal-500 text-md">
                  Please add your payment details to continue using Roda.
                </p>

                <div>
                  <Button
                    title="See pricing"
                    onClick={() => setPlanModal(true)}
                  />
                </div>
              </div>

              <Divider />
            </>
          )}

          {isSubscriptionActive && (
            <>
              <div className="flex flex-col w-full gap-3">
                <div className="font-bold text-left text-lg">Billing</div>

                <p className="text-brand-cold-metal-500 text-md">Thank you for subscribing to Roda!</p>

                <div>
                  <Button
                    title="Manage subscription"
                    loading={loadingPortal}
                    onClick={handlePortal}
                  />
                </div>
              </div>

              <Divider />
            </>
          )}

          <AvatarControls
            type={AvatarType.COMPANY}
            company={company as Company}
          />

          <Divider />

          <form
            onSubmit={submitHandler}
            className="flex-1 flex flex-col"
          >
            <div className="flex flex-col gap-3">
              <div className="text-brand-cold-metal-900 font-semibold text-lg">Organisation Name</div>

              {/* Organisation Name Input */}
              <div className="mb-6 flex flex-col">
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      aria-label="Organisation name"
                      hasError={!!errors.name}
                      errorMessage={errors.name?.message}
                    />
                  )}
                />
              </div>
            </div>

            {isMobile && (<Spacer />)}

            {/* Action buttons for saving or discarding changes */}
            {isDirty && (
              <div className={`flex gap-x-3 self-start ${isMobile ? "flex-col w-full gap-3 pb-5" : "flex-row"}`}>
                <Button
                  type="submit"
                  disabled={updateCompanyRes.fetching || Object.keys(errors).length > 0}
                  loading={updateCompanyRes.fetching}
                  title="Save"
                  className={`px-6 ${isMobile ? "order-first" : ""}`}
                />

                <Button
                  type="button"
                  disabled={updateCompanyRes.fetching}
                  onClick={() => reset()}
                  title="Discard"
                  className="bg-brand-cold-metal-100 text-brand-cold-metal-900 hover:contrast-75"
                />
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};
