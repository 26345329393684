interface PillMessageProps {
  text: string;
  icon: JSX.Element;
  highlight?: boolean;
}

export const PillMessage: React.FC<PillMessageProps> = ({
  text, icon, highlight
}) => {
  return (
    <div className="items-center flex pb-2">
      <div className={`flex flex-row bg-brand-cold-metal-50 px-3 py-1 border-solid text-xs font-medium  ${highlight ? "bg-brand-attention-orange-50 border-brand-attention-orange-200 text-brand-attention-orange-600" : "border-brand-cold-metal-200 text-brand-cold-metal-600"} border-[1.5px] rounded-3xl opacity-80 gap-x-3 self-start items-center max-w-fit text-xs md:text-sm`}>
        <p className="overflow-hidden text-ellipsis">{text}</p>

        <div className="shrink-0 [&_path]:stroke-[currentColor]">
          {icon}
        </div>
      </div>
    </div>
  );
};