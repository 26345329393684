import { MetricCard } from "~/components/customise-metric/MetricCard";
import { AddMetricButton } from "~/components/onboarding/metrics-and-targets/AddMetricButton";
import { useCustomiseMetricDispatch } from "~/contexts/CustomiseMetricContext/CustomiseMetricContext";
import { CustomiseMetricStage } from "~/contexts/CustomiseMetricContext/metric-reducer";
import type { StepType } from "~/contexts/OnboardingContext/onboarding-reducer";

interface StepOverviewProps {
  step: StepType | null;
}
export const StepOverview: React.FC<StepOverviewProps> = ({ step }) => {
  const dispatch = useCustomiseMetricDispatch();

  return (
    <div className="flex flex-col gap-y-1  pb-12 pt-4">
      <div className="flex flex-col">
        <div className="flex flex-row gap-x-2 items-center">
          <p className="text-base text-body-medium font-semibold">{step?.alias || step?.name}</p>

        </div>

        {!step?.alias && (
          <p className="text-sm text-body-medium">{step?.description}</p>
        )}
      </div>

      {step?.metrics?.map((metric, idx) => (
        <li
          className="mt-5 [&+&]:mt-2 list-none"
          key={`metric_${+idx}`}
        >
          <MetricCard
            onClick={() => dispatch({
              type: "SET_SELECTED_METRIC",
              stage: CustomiseMetricStage.REVIEW,
              step: {
                index: step.stepIdx!,
                name: step.name!
              },
              metric
            })}
            metric={metric}
          />
        </li>
      ))}

      {step && (
        <AddMetricButton
          key={step?.name} // purposefully to force re-render when invalid step is deselected
          isFirst={step?.metrics?.length === 0}
          onClick={() => dispatch({
            type: "SET_SELECTED_STEP",
            stage: CustomiseMetricStage.SELECT_OR_CREATE,
            step: {
              index: step.stepIdx!,
              name: step.name!
            }
          })}
          disabled={step?.metrics?.length === 6}
        />
      )}

    </div>
  );
};