import {
  Children,
  cloneElement,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from "react";

import { ImagePreviewConfirmationPopup } from "~/components/metric/SaveImageMenuItem";
import { handleCapture } from "~/utils/captureElement";

import type {
  PropsWithChildren,
  ReactElement
} from "react";

export interface ImageCaptureContextType {
  imageUrl: string;
  setImageUrl: (url: string) => void;
  clear: () => void;
  captureAndPreview: (captureId: ImageCaptureTargetId, html2canvasOptions?: Parameters<typeof handleCapture>[2]) => Promise<void>,
  capture: typeof handleCapture
}

const ImageCaptureContext = createContext<ImageCaptureContextType>({
  imageUrl: "",
  setImageUrl: () => null,
  clear: () => null,
  captureAndPreview: () => Promise.resolve(),
  capture: () => Promise.resolve()
});

export const useImageCaptureContext = () => useContext(ImageCaptureContext);

export const ImageCaptureProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [ imageUrl, setImageUrl ] = useState<string>("");
  const clear = () => setImageUrl("");
  /**
   * @param captureId specify exactly which part of the DOM to capture - should
   * align with a `data-capture-target` attribute
   */
  const captureAndPreview = useCallback((captureId: ImageCaptureTargetId, html2canvasOptions?: Parameters<typeof handleCapture>[2]) => handleCapture(captureId, setImageUrl, html2canvasOptions), []);

  const ctx = useMemo(() => ({
    imageUrl,
    setImageUrl,
    clear,
    /** generates image AND sets URL */
    captureAndPreview,
    /** generates image URL only (doesn't set in state), utilise callback arg
     * which receives the URL */
    capture: handleCapture
  }), [ captureAndPreview, imageUrl ]);

  return (
    <ImageCaptureContext.Provider value={ctx}>
      {children}

      {imageUrl && (
        <ImagePreviewConfirmationPopup
          handleClose={clear}
          imageUrl={imageUrl}
        />
      )}
    </ImageCaptureContext.Provider>
  );
};

export type ImageCaptureTargetId =
  | "metric-all"
  | "metric"
  | "metric-chart"
  | "flywheel"
  | "main-goal-all"
  | "main-goal"
  | "main-goal-chart"
  | "step-all"
  | "step"
  | string & {};

/**
  * Wrap target elements with the <ImageCaptureTarget> component and
  * specify the `captureId` prop. This is what will be captured when an
  * accompanying `<SaveImageButton>` or `<SaveImageMenuItem>` is clicked that
  * has the same `captureId` prop.
  */
export const ImageCaptureTarget: React.FC<{captureId: ImageCaptureTargetId, children: ReactElement}> = ({ children, captureId }) => {
  if (Children.count(children) !== 1) {
    throw new Error("ImageCaptureTarget must have exactly one child");
  }

  return cloneElement(children, { "data-capture-target": captureId });
};