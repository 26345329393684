import { enumUserRole } from "@roda/graphql/genql";
import {
  FlywheelTemplateUnitTypeLabelEnum,
  ReviewPeriod
} from "@roda/shared/types";
import {
  getFirstMondayFromStartDate,
  getLastSundayFromEndDate
} from "@roda/shared/utils";
import {
  useEffect,
  useMemo
} from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "~/components/Button";
import { FlywheelGoalProgressCard } from "~/components/review/FlywheelGoalProgressCard";
import { ReviewContentWrapper } from "~/components/review/ReviewContentWrapper";
import { Card } from "~/components/Timeline";
import { TimelineLayout } from "~/components/Timeline/TimelineLayout";
import { routes } from "~/constants/routes";
import { useRodaAdminCompaniesContext } from "~/contexts/RodaAdminCompaniesContext";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useSideNavigation } from "~/contexts/SideNavigationContext";
import { useCurrentUser } from "~/contexts/UserContext";
import { useIsMobile } from "~/hooks/useIsMobile";
import { cn } from "~/utils/cn";
import dayjs from "~/utils/dayjs";
import { formatGoalNumber } from "~/utils/formatGoalNumber";
import { getUnitSymbol } from "~/utils/getUnitSymbol";

export const ReviewIntro = () => {
  const { user } = useCurrentUser();
  const isMobile = useIsMobile();
  const { setHideMainSideNav } = useSideNavigation();
  const navigate = useNavigate();
  const { currentCompany } = useRodaAdminCompaniesContext();

  const {
    flywheel, flywheelSubgoals, checkInSubgoal, activeFlywheelReview
  } = useSelectedFlywheel();

  // Get review period
  const reviewPeriod = useMemo(() => activeFlywheelReview?.reviewPeriod, [ activeFlywheelReview ]);
  const subgoalToReview = useMemo(() => activeFlywheelReview?.subgoal, [ activeFlywheelReview ]);
  const checkInSubgoalIdx = useMemo(() => flywheelSubgoals?.findIndex(s => s.id === checkInSubgoal?.id) || 0, [ checkInSubgoal, flywheelSubgoals ]);
  const isNextYearReview = useMemo(() => activeFlywheelReview?.reviewPeriod === ReviewPeriod.NEXT_YEAR, [ activeFlywheelReview?.reviewPeriod ]);
  const isRodaAdmin = user?.role === enumUserRole.RODA_ADMIN;
  const isAdmin = user?.role === enumUserRole.ADMIN;
  const isMetricOwner = !!activeFlywheelReview?.metrics?.find(metric => metric.owner?.id === user?.id);
  // TODO: Refactor for year review to check upcoming flywheel goal owner
  const isFlywheelGoalOwner = flywheel?.latestFlywheelGoal?.ownerId == user?.id;

  const canReviewFlywheelGoal = useMemo(() => isRodaAdmin || isAdmin || isFlywheelGoalOwner, [
    isAdmin,
    isFlywheelGoalOwner,
    isRodaAdmin
  ]);

  const unitIcon = useMemo(() => getUnitSymbol(flywheel?.latestFlywheelGoal?.unitTypeLabel, flywheel?.currency), [ flywheel ]);
  let reviewReasonText = "";

  // If we don't have a review to format, return undefined
  if (activeFlywheelReview) {
    const now = dayjs();
    // The available review is for the current quarter if the subgoal started before now
    const reviewIsCurrentQuarter = activeFlywheelReview.subgoal && getFirstMondayFromStartDate(dayjs(activeFlywheelReview.subgoal.startDate)).isBefore(now);

    // If it's the current quarter, we want "your quarter started X days ago" text
    if (reviewIsCurrentQuarter && activeFlywheelReview.subgoal) {
      // Get the actual start date of the review subgoal - this could be anything from the 1st to the
      // 7th of the given month
      const subgoalStartDate = getFirstMondayFromStartDate(dayjs(activeFlywheelReview.subgoal.startDate));
      // Find how many days of the quarter have passed
      const daysIntoQuarter = Math.abs(now.diff(subgoalStartDate, "days"));
      // Format this text with "yesterday" if it's 1 day, or the day count
      const daysIntoQuarterText = daysIntoQuarter === 0 ? "starts today" : daysIntoQuarter === 1 ? "started yesterday" : `started ${daysIntoQuarter} day${daysIntoQuarter > 1 ? "s" : ""} ago`;

      reviewReasonText = `Your new ${activeFlywheelReview?.reviewPeriod === "next_year" ? "cycle" : "quarter"} ${daysIntoQuarterText}`;
    } else if (!reviewIsCurrentQuarter) {
      const checkInSubgoalEndDate = getLastSundayFromEndDate(dayjs(flywheel?.latestFlywheelGoal?.checkInSubgoal?.endDate));
      // If it's the next quarter, we want "your next quarter begins in X days" text
      const daysToNextQuarter = Math.abs(now.diff(checkInSubgoalEndDate, "days")) + 1;
      // Format this text with "tomorrow" if it's 1 day, or the day count
      const daysToNextQuarterText = daysToNextQuarter === 1 ? "tomorrow" : `in ${daysToNextQuarter} day${daysToNextQuarter > 1 ? "s" : ""}`;

      reviewReasonText = `Your next ${activeFlywheelReview?.reviewPeriod === "next_year" ? "cycle" : "quarter"} begins ${daysToNextQuarterText}`;
    }
  }

  const nextQuarterSubtitle = `${reviewReasonText}. Please take a few minutes to review your ${canReviewFlywheelGoal ? "flywheel goal and" : ""} metrics for the upcoming quarter.`;
  const nextYearSubtitle = `${reviewReasonText}. Please take a few minutes to review your ${canReviewFlywheelGoal ? "flywheel goal and" : ""} metrics for the upcoming flywheel cycle.`;
  // Permissions to review
  const hasPermissionToReview = isAdmin || isRodaAdmin || isMetricOwner || isFlywheelGoalOwner;
  // Check if we have rolled into the new year
  const twoWeeksInQuarter = dayjs(checkInSubgoal?.startDate).add(2, "weeks");
  const isAtStartOfQuarter = dayjs().isBetween(dayjs(checkInSubgoal?.startDate), twoWeeksInQuarter, null, "[]");
  const rolledIntoNewYear = isAtStartOfQuarter && isNextYearReview;

  // On render of this page - always hide the main side nav
  useEffect(() => {
    setHideMainSideNav(true);
  }, [ setHideMainSideNav ]);

  const moveToNextPage = () => {
    // Move to flywheel goal or metric review depending on user role
    if (canReviewFlywheelGoal) {
      navigate(routes.reviewFlywheelGoal(currentCompany ? currentCompany.id : undefined));
    } else {
      navigate(routes.reviewMetrics(currentCompany ? currentCompany.id : undefined));
    }
  };

  useEffect(() => {
    if (!hasPermissionToReview) {
      navigate(routes.dashboard(currentCompany ? currentCompany.id : undefined));
    }
  }, [
    currentCompany,
    hasPermissionToReview,
    navigate
  ]);

  return (
    <>
      <ReviewContentWrapper
        title={reviewPeriod === ReviewPeriod.NEXT_QUARTER ? "Your next quarter is coming up" : rolledIntoNewYear ? "Your new annual cycle has started" : "Your current annual cycle is nearly complete"}
        subTitle={reviewPeriod === ReviewPeriod.NEXT_QUARTER ? nextQuarterSubtitle : nextYearSubtitle}
        leftContent={(
          <div className="w-full flex flex-col">
            <div className={`flex gap-x-3 self-start pb-5 ${isMobile ? "flex-col w-full gap-3" : "flex-row mt-5"}`}>
              <Button
                title="Back"
                className="bg-brand-cold-metal-200 text-brand-cold-metal-800"
                onClick={() => {
                  setHideMainSideNav(false);
                  navigate(routes.dashboard(currentCompany ? currentCompany.id : undefined));
                }}
              />

              <Button
                title="Review"
                onClick={moveToNextPage}
              />
            </div>
          </div>
        )}
        rightContent={flywheelSubgoals ? (
          <TimelineLayout
            orientation={isMobile ? "horizontal" : "vertical"}
            rowGap={isMobile ? "14" : undefined /* defaults to 60, ideal for desktop */}
            items={flywheelSubgoals.map((subgoal, i, { length }) => {
              const isInQuarter = i === checkInSubgoalIdx;
              const isUpcomingQuarter = subgoalToReview ? subgoal.id === subgoalToReview.id : false;
              const isLastSubgoal = i === length - 1;
              const isAfterCheckInSubgoal = i > checkInSubgoalIdx;
              // Check if the quarter in review is the current quarter
              const rolledIntoQuarter = isInQuarter && isUpcomingQuarter;

              return {
                renderLeft: isLastSubgoal && flywheel?.latestFlywheelGoal ? (
                  <div
                    className="flex flex-col items-start gap-2 relative mt-5 lg:mt-0"
                    aria-hidden='true'
                  >
                    <FlywheelGoalProgressCard
                      flywheelGoalTarget={+flywheel?.latestFlywheelGoal.goal}
                      flywheelGoalProgress={flywheel?.latestFlywheelGoal.latestTotalValue}
                      unitIcon={unitIcon}
                      isHealthy={flywheel.latestFlywheelGoal.isHealthy ?? true}
                    />
                  </div>
                ) : undefined,
                renderRight: (
                  <Card.TimelineItem
                    title={`Quarter ${i + 1}`}
                    size={isMobile ? "lg" : "md"}
                    subheading={`${dayjs(subgoal.startDate).format("MMMM YYYY")} - ${dayjs(subgoal.endDate).format("MMMM YYYY")}`}
                    badge={rolledIntoQuarter ? "Review-due" : isInQuarter ? "Current" : isUpcomingQuarter ? "Coming up" : undefined}
                    progressBarProps={{
                      // for percentages NEVER get a sum of updates
                      value: flywheel?.latestFlywheelGoal?.unitTypeLabel === FlywheelTemplateUnitTypeLabelEnum.PERCENTAGE ?
                        subgoal.updates.length !== 0 ?
                          +subgoal.updates[ subgoal.updates.length - 1 ].value : 0 :
                        subgoal.updates.reduce((prev, curr) => prev + Number(curr.value), 0),
                      max: subgoal.goal ? Number(subgoal.goal) : Infinity,
                      labelLeft: isAfterCheckInSubgoal || rolledIntoQuarter ? "Progress" : formatGoalNumber(subgoal.latestProgress, unitIcon, {
                        shouldCompact: true,
                        stripTrailingZeros: true
                      }),
                      labelRight: isAfterCheckInSubgoal || rolledIntoQuarter ? "Target" : `Target ${formatGoalNumber(Number(subgoal.goal), unitIcon, {
                        shouldCompact: true,
                        stripTrailingZeros: true
                      })}`,
                      neutral: isAfterCheckInSubgoal || rolledIntoQuarter,
                      isHealthy: subgoal.isHealthy ?? false
                    }}
                    className={cn(isMobile && "mb-0.5", (!isInQuarter && !isUpcomingQuarter && reviewPeriod !== ReviewPeriod.NEXT_YEAR) && "opacity-45")}
                  />)
              };
            })}
          />
        ) : undefined}
      />
    </>
  );
};