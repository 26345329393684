import type { FlywheelReviewTargetData } from "~/hooks/flywheel/use-get-flywheel-review";
import dayjs from "~/utils/dayjs";

import type { Dayjs } from "dayjs";

/**
 * Get the metric target for a given week from a list of metrictargets
 * @param metricTargets the list of metric targets for the metric
 * @param weekStart the starting day of the week - use dayjs(<week>).startOf("isoWeek") for this
 * @param weekEnd the end day of the week - use dayjs(<week>).endOf("isoWeek") for this
 * @returns a metric target for the given week, if it exists
 */
export const getWeekMetricTarget = (metricTargets: Array<Pick<FlywheelReviewTargetData, "target" | "toDate" | "fromDate">> | null, weekStart: Dayjs) => {
  if (!metricTargets) return undefined;

  const currentWeekTarget = metricTargets?.find(metricTarget => metricTarget.toDate ? weekStart.isBetween(dayjs(metricTarget.fromDate).startOf("day"), dayjs(metricTarget.toDate).endOf("day"), null, "[]") : weekStart.isSame(dayjs(metricTarget.fromDate).startOf("day")) || weekStart.isAfter(dayjs(metricTarget.fromDate).startOf("day")));

  return currentWeekTarget;
};