import growthTemplate from "@roda/core/flywheel-template-json/Growth.json";
import impactTemplate from "@roda/core/flywheel-template-json/Impact.json";
import opsManagementTemplate from "@roda/core/flywheel-template-json/Ops_Management.json";
import { nanoid } from "nanoid";

import type { FlywheelTemplateJson } from "@roda/shared/types";

interface Params {
  flywheelTemplateId?: number | null;
}

export const getFlywheelTemplateInfo = (params: Params): FlywheelTemplateJson | null => {
  if (!params.flywheelTemplateId) {
    return null;
  }

  const addStepIds = (template: FlywheelTemplateJson) => ({
    ...template,
    flywheel: {
      ...template.flywheel,
      steps: template.flywheel.steps.map(step => ({
        ...step,
        id: nanoid()
      }))
    }
  });

  switch (params.flywheelTemplateId) {
    case 2:
      return addStepIds(opsManagementTemplate as FlywheelTemplateJson);
    case 3:
      return addStepIds(impactTemplate as FlywheelTemplateJson);
    case 1:
    default:
      return addStepIds(growthTemplate as FlywheelTemplateJson);
  }
};

export const getTemplatedSteps = (params: Params): FlywheelTemplateJson["flywheel"]["steps"] => {
  const addStepIds = (template: FlywheelTemplateJson) => ({
    ...template,
    flywheel: {
      ...template.flywheel,
      steps: template.flywheel.steps.map(step => ({
        ...step,
        id: nanoid()
      }))
    }
  });

  const growthFlywheel = addStepIds(growthTemplate as FlywheelTemplateJson);
  const opsManagementFlywheel = addStepIds(opsManagementTemplate as FlywheelTemplateJson);
  const impactFlywheel = addStepIds(impactTemplate as FlywheelTemplateJson);

  if (!params.flywheelTemplateId) {
    return [
      ...opsManagementFlywheel.flywheel.steps,
      ...impactFlywheel.flywheel.steps,
      ...growthFlywheel.flywheel.steps
    ];
  }

  switch (params.flywheelTemplateId) {
    case 2:
      return opsManagementFlywheel.flywheel.steps;
    case 3:
      return impactFlywheel.flywheel.steps;
    case 1:
    default:
      return growthFlywheel.flywheel.steps;
  }
};