import { useNavigate } from "react-router-dom";

import { Avatar } from "~/components/Avatar";
import { Breadcrumbs } from "~/components/Breadcrumbs";
import { CompanyAvatar } from "~/components/CompanyAvatar";
import { Divider } from "~/components/Divider";
import { Loading } from "~/components/Spinner";
import { routes } from "~/constants/routes";
import { useRodaAdminCompaniesContext } from "~/contexts/RodaAdminCompaniesContext";
import { useIsMobile } from "~/hooks/useIsMobile";
import { cn } from "~/utils/cn";
import { parseDbDate } from "~/utils/dates/parseDbDate";
import dayjs from "~/utils/dayjs";
import { getUserDisplayName } from "~/utils/getUserDisplayName";

import type { User } from "@roda/graphql/genql";
import type React from "react";

export const CompanyManagement = () => {
  const isMobile = useIsMobile();
  const { companies, loading } = useRodaAdminCompaniesContext();
  const navigate = useNavigate();

  const handleSelectCompany = (companyId: string) => {
    navigate(routes.companySettings(companyId));
  };

  const tableHeadings = [
    "Name",
    "Users",
    "Last seen user",
    "Created At"
  ];

  const gridRowStyle: React.CSSProperties = {
    display: "grid",
    gridTemplateColumns: "minmax(250px,1.5fr) minmax(150px,1fr) minmax(220px,1fr) minmax(200px,1fr) 100px",
    alignItems: "center",
    justifyContent: "space-between"
  };

  // gridRowClassname
  const classname = "px-2 *:min-h-14 *:flex *:items-center last:*:w-full last:*:text-right last:*:flex last:*:justify-end";

  return (
    <div className={`flex flex-col w-full flex-1 bg-white ${!isMobile && "px-10"}`}>

      <div className="flex flex-col items-center justify-start w-full flex-1">

        {/* Container for form */}
        <div className={`max-w-[1100px] flex-1 w-full text-xs sm:text-sm flex flex-col px-5 ${isMobile ? "mt-4 gap-4" : "mt-10 gap-8"}`}>

          {!isMobile && (
            <Breadcrumbs
              crumbs={[
                {
                  label: "Admin",
                  to: routes.rodaAdminOrganisationManagement
                },
                { label: "Company Management" }
              ]}
            />
          )}

          {/* Header */}
          <div className="flex flex-row gap-2 items-center w-full">
            <div className={`font-bold text-left pb-2 ${isMobile ? "text-lg" : "text-2xl"}`}>Company Management</div>

            {loading && <Loading.Spinner />}
          </div>

          <Divider />

          <div
            role="table"
            className="relative overflow-x-auto"
          >
            <header
              className={cn(classname, "")}
              style={gridRowStyle}
            >
              {tableHeadings.map(heading => (
                <p
                  key={heading}
                  role="heading"
                  className="font-medium"
                >
                  <span>
                    {heading}
                  </span>
                </p>
              ))}
            </header>

            <Divider />

            {/*
            Would you believe me if I said you had to wrap an inner-element of a parent who is scrollable-x in inline-block for the child to then be able to stretch full-width?? i.e:

            <Parent (overflow-x) />
            └──<Wrapper (inline-block) />
                └──<Child (full-width) />
            https://stackoverflow.com/questions/52361472/cant-get-a-div-to-fill-its-container-scroll-able-width
            */}
            <section
              /** TABLE BODY ROWS */
              className="inline-block pt-2 pb-10 -space-y-2"
            >
              {companies?.map(company => {
                return (
                  <div
                    key={company.id}
                    style={gridRowStyle}
                    className={cn(classname, "group whitespace-nowrap w-full flex items-center hover:bg-brand-cold-metal-100 rounded-lg ")}
                    role="button"
                    onClick={() => handleSelectCompany(company.id)}
                  >
                    <div className="flex items-center overflow-hidden">
                      <div className="mr-2">
                        <CompanyAvatar
                          companyName={company.name}
                          logoS3Key={company.logoS3Key ?? undefined}
                        />
                      </div>

                      {!!company.name && (
                        <p className="break-all">
                          {company.name}
                        </p>
                      )}
                    </div>

                    <div className="flex w-auto gap-2">
                      <div className="flex w-auto px-1.5 py-1 bg-brand-cold-metal-100 rounded-full text-center text-xs">
                        {company.activeUserCount} active
                      </div>

                      {!!company.invitedUserCount && (
                        <div className="flex w-auto px-1.5 py-1 bg-brand-cold-metal-100 rounded-full text-center text-xs">
                          {company.invitedUserCount} invited
                        </div>
                      )}
                    </div>

                    <div className="flex *:min-w-0">
                      {company.lastSeenUser ? (
                        <div className="flex *:min-w-0 gap-1.5 items-baseline [&>:first-child]:shrink-0">
                          <span className="relative -top-0.5">
                            <Avatar
                              user={company.lastSeenUser as User}
                              px={20}
                            />
                          </span>

                          <p className="text-xs leading-[16px] line-clamp-2 flex-1 text-wrap inline-flex flex-wrap">
                            <span className="truncate">{`${getUserDisplayName(company.lastSeenUser)},`}&nbsp;</span>

                            <span>{`${parseDbDate(company.lastSeenUser?.lastSeenAt).fromNow()}`}</span>
                          </p>
                        </div>
                      ) : "n/a"}
                    </div>

                    <div className="flex w-auto">
                      <div className="flex w-auto px-1.5 py-1 bg-brand-cold-metal-100 rounded-full text-center text-xs">
                        {dayjs(company.createdAt).format("DD MMMM YYYY")}
                      </div>
                    </div>
                  </div>
                );
              })}
            </section>
          </div>

        </div>
      </div>
    </div>
  );
};