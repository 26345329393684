import { Industry } from "~/fragments/industry";

import type { CompanyGenqlSelection } from "@roda/graphql/genql";

export const Company: CompanyGenqlSelection = {
  id: true,
  name: true,
  industryId: true,
  createdAt: true,
  updatedAt: true,
  paymentPrompt: true,
  trialExpiryAt: true,
  stripeCustomerRef: true,
  stripeStatus: true,
  stripeSubscriptionRef: true,
  completedOnboarding: true,
  industry: Industry,
  logoS3Key: true,
  logoS3Bucket: true,
  flywheels: {
    id: true,
    setupComplete: true,
    deletedAt: true,
    name: true
  }
};
