import clsx from "clsx";
import {
  useState,
  useCallback,
  Children,
  cloneElement,
  isValidElement
} from "react";

import type { AccordionItemProps } from "~/components/onboarding/AccordionItem";

import type { ReactElement } from "react";

interface AccordionWrapperProps {
  className?: string;
  children: Array<ReactElement<AccordionItemProps>> | ReactElement<AccordionItemProps>;
  firstChildOpen?: boolean;
}
/**
 * A wrapper component that controls the expand/collapse state of nested AccordionItem components.
 * Only one AccordionItem can be open at a time. The `className` prop is used to add custom styles to the wrapper.
 *
 * @param className - Optional: Additional CSS classes to apply to the wrapper for styling.
 * @param children - AccordionItem components that are controlled by this wrapper.
 * @returns {React.ReactElement} - The rendered set of accordion items within a wrapper that controls their open state.
 *
 * @example
 * <AccordionWrapper className="my-custom-class">
 *   <AccordionItem
 *     title="Section 1"
 *     text="This is the content for section 1"
 *     onPress={() => console.log('Section 1 pressed')}
 *   />
 *   <AccordionItem
 *     title="Section 2"
 *     text="This is the content for section 2"
 *     onPress={() => console.log('Section 2 pressed')}
 *   />
 * </AccordionWrapper>
 */
export const AccordionWrapper: React.FC<AccordionWrapperProps> = ({
  children, className, firstChildOpen = false
}) => {
  // Set initial openId to 0 if firstChildOpen is true and children exist
  const [ openId, setOpenId ] = useState<number | null>(firstChildOpen && Children.count(children) > 0 ? 0 : null);

  const handleToggle = useCallback((id: number) => {
    if (openId !== id) {
      setOpenId(id); // Open the new item immediately
    } else {
      setOpenId(null); // Close the item if it's the same one
    }
  }, [ openId ]);

  return (
    <div className={clsx("flex flex-col gap-4", className)}>
      {Children.map(children, (child, index) => {
        if (isValidElement<AccordionItemProps>(child)) {
          const handleItemToggle = () => handleToggle(index);

          return cloneElement(child, {
            onToggle: handleItemToggle,
            isOpen: openId === index
          });
        }

        return child;
      })}
    </div>
  );
};
