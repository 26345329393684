import {
  createContext,
  useContext,
  useMemo,
  useState
} from "react";

import type { PropsWithChildren } from "react";
import type React from "react";

interface SideNavigationProps {
  hideMainSideNav: boolean;
  setHideMainSideNav: (hide: boolean) => void;
}

const SideNavigationContext = createContext<SideNavigationProps>({
  hideMainSideNav: false,
  setHideMainSideNav: () => null
});

export const SideNavigationProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [ hideMainSideNav, setHideMainSideNav ] = useState(false);

  const memoedValue = useMemo<SideNavigationProps>(
    () => ({
      hideMainSideNav,
      setHideMainSideNav
    }),
    [ hideMainSideNav ]
  );

  return (
    <SideNavigationContext.Provider value={memoedValue}>
      {children}
    </SideNavigationContext.Provider>
  );
};

export const useSideNavigation = () => useContext(SideNavigationContext);
