interface CheckInBadgeProps {
  text: string;
  bgColour?: string;
  icon?: JSX.Element;
}
export const CheckInBadge: React.FC<CheckInBadgeProps> = ({
  text, bgColour, icon
}) => {
  return (
    <div className={`px-1.5 ${bgColour ? bgColour : "bg-brand-cold-metal-100"}  text-brand-cold-metal-700 text-[9px] rounded-md flex flex-row items-center gap-x-1 self-end justify-center font-semibold tracking-wide`}>
      <p>{text}</p>

      {icon && icon}
    </div>

  );
};