import * as Popover from "@radix-ui/react-popover";
import clsx from "clsx";
import {
  useEffect,
  useState
} from "react";

import { Icon } from "~/components/Icon";
import { useIsMobile } from "~/hooks/useIsMobile";

import type React from "react";

interface InfoTooltipProps {
  text: string;
  infoCircleStyle?: string,
  forceOpen?: boolean;
  enabled?: boolean;
  slideDirection?: "left" | "right" | "top" | "bottom";
  children?: JSX.Element;
}

export const InfoTooltip: React.FC<InfoTooltipProps> = ({
  infoCircleStyle = "text-brand-cold-metal-300", text, slideDirection = "right", forceOpen = false, children, enabled = true
}) => {
  const [ isOpen, setIsOpen ] = useState(forceOpen);
  const isMobile = useIsMobile();

  useEffect(() => {
    setIsOpen(forceOpen);
  }, [ forceOpen ]);

  return (
    <Popover.Root
      open={isOpen}
    >
      <Popover.Trigger
        asChild
        onMouseEnter={() => enabled && setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        <button
          className={clsx({ "!cursor-default ": !enabled })}
          onClick={() => enabled && setIsOpen(!isOpen)}
        >
          {children ?? (
            <Icon.InfoCircle className={`${infoCircleStyle} size-4 mt-0.5`} />
          )}
        </button>
      </Popover.Trigger>

      <Popover.Portal>
        <Popover.Content
          className="static animate-in fade-in zoom-in-90 p-1 bg-brand-cold-metal-800 z-40 rounded-md whitespace-pre-wrap"
          style={{ maxWidth: isMobile ? window.innerWidth * 0.35 : "350px" }}
          sideOffset={slideDirection === "top" ? -2 : 3}
          align="center"
          side={slideDirection}
        >
          <div className="flex flex-col">
            <p className={`text-brand-cold-metal-50 text-xs p-1 ${slideDirection === "top" ? "text-center" : "text-left"}`}>{text}</p>
          </div>

          <Popover.Arrow />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};