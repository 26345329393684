import clsx from "clsx";
import { twMerge } from "tailwind-merge";

import type { ClassValue } from "clsx";

/**
 * Useful utility to combine tailwind-classes using the merge-logic from
 * tailwind-merge and the utility of clsx.
 *
 * @example
 * ```tsx
 * <Element
 * 	className={cn("...local_styles...", props.isActive && "ring-blue-500", props.className)}
 * />
 * ```
 *
 * @see https://www.npmjs.com/package/clsx
 */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}