import type { GetFlywheelMetricData } from "~/hooks/flywheel/use-get-flywheel";
import dayjs from "~/utils/dayjs";

import type { Dayjs } from "dayjs";

/**
 * Get the metric update for a given week from a list of metric updates
 * @param metricUpdates the list of metric updates for the metric
 * @param weekStart the starting day of the week - use dayjs(<week>).startOf("isoWeek") for this
 * @param weekEnd the end day of the week - use dayjs(<week>).endOf("isoWeek") for this
 * @returns a metric update for the given week, if it exists
 */
export const getWeekMetricUpdate = (metricUpdates: NonNullable<GetFlywheelMetricData["metricUpdates"]>, weekStart: Dayjs, weekEnd: Dayjs) => {
  if (!metricUpdates) return undefined;

  // Find a metric update where the fromDate and toDate are between the given weekStart and weekEnd
  // The [] means that this is inclusive - so midnight on the weekStart day and 11:59pm on the weekEnd day
  // will be included
  return metricUpdates?.find(metricUpdate => dayjs(metricUpdate.startDate).isBetween(weekStart, weekEnd, null, "[]"));
};