import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect
} from "react";

import { routes } from "~/constants/routes";
import { STORAGE_KEYS } from "~/constants/storageKeys";
import { clearStorage } from "~/utils/clearStorage";

interface AuthContextProps {
  loading: boolean;
  authenticated: boolean;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextProps>({
  loading: true,
  authenticated: false,
  logout: () => null
});

type Props = {
  children?: React.ReactNode
};

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [ authenticated, setAuthenticated ] = useState(localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE.AUTH_TOKEN) != null);
  const [ loading, setLoading ] = useState(false);

  /**x
   * Logout function
   * Will clear localStorage
   * and navigate user back to the login screen
   */
  const logout = useCallback(async () => {
    setAuthenticated(false);
    clearStorage({ groupNames: [ "logout" ] });
    window.location.href = `${import.meta.env.VITE_WEB_APP_URL}${routes.home}`;
  }, []);

  /**
   * Function for checking if user is already logged in
   */
  const checkIfLoggedIn = useCallback(() => {
    setLoading(true);
    const existingToken = localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE.AUTH_TOKEN);

    if (existingToken) {
      setAuthenticated(true);
      setLoading(false);

      return true;
    }

    setLoading(false);

    return false;
  }, [ ]);

  // Check if user is already logged in on first load
  useEffect(() => {
    checkIfLoggedIn();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ ]);

  const memoedValue = useMemo<AuthContextProps>(
    () => ({
      logout,
      loading,
      authenticated
    }),
    [
      logout,
      loading,
      authenticated
    ]
  );

  return (
    <AuthContext.Provider value={memoedValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
