import { ReadOnlyUser } from "~/fragments/user";

import type { MetricTargetGenqlSelection } from "@roda/graphql/genql";

export const MetricTarget: MetricTargetGenqlSelection = {
  id: true,
  metricId: true,
  target: true,
  createdBy: ReadOnlyUser,
  fromDate: true,
  toDate: true,
  isCurrent: true,
  isNext: true,
  createdAt: true,
  updatedAt: true
};
