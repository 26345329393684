import { enumUserRole } from "@roda/graphql/genql";
import { ReviewPeriod } from "@roda/shared/types";
import {
  useEffect,
  useMemo,
  useState
} from "react";
import { useNavigate } from "react-router-dom";

import Logo from "~/assets/logo.svg";
import { Button } from "~/components/Button";
import { CompanyAvatar } from "~/components/CompanyAvatar";
import { Icon } from "~/components/Icon";
import * as SideNav from "~/components/side-navigation/components";
import { Footer } from "~/components/side-navigation/components";
import { routes } from "~/constants/routes";
import { useCustomiseFlywheelGoalDispatch } from "~/contexts/CustomiseFlywheelGoalContext/CustomiseFlywheelGoalContext";
import { useCustomiseMetricDispatch } from "~/contexts/CustomiseMetricContext/CustomiseMetricContext";
import { useFlywheelLoader } from "~/contexts/FlywheelLoaderContext";
import { useOnboardingDispatch } from "~/contexts/OnboardingContext/OnboardingContext";
import { useRodaAdminCompaniesContext } from "~/contexts/RodaAdminCompaniesContext";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useSideNavigation } from "~/contexts/SideNavigationContext";
import { useCurrentUser } from "~/contexts/UserContext";
import dayjs from "~/utils/dayjs";

enum MobileMenuLevel {
  RodaAdmin,
  Company
}

export const MobileNavigation = () => {
  const { user } = useCurrentUser();
  const isRodaAdmin = user?.role === enumUserRole.RODA_ADMIN;
  const isAdmin = user?.role === enumUserRole.ADMIN;
  const isMetricOwner = Boolean(user?.isMetricOwner);
  const [ isMenuOpen, setIsMenuOpen ] = useState(false);
  const [ menuLevel, setMenuLevel ] = useState(isRodaAdmin ? MobileMenuLevel.RodaAdmin : MobileMenuLevel.Company);
  const { currentCompany, setCurrentCompany } = useRodaAdminCompaniesContext();
  const onboardingDispatch = useOnboardingDispatch();
  const customiseMetricDispatch = useCustomiseMetricDispatch();
  const customiseFlywheelGoalDispatch = useCustomiseFlywheelGoalDispatch();

  const {
    setActiveFlywheelId, isCheckInDue, flywheel, activeFlywheelReview, subgoalInReview, isReviewDue, checkInSubgoal, userHasMetrics
  } = useSelectedFlywheel();

  const { availableFlywheels } = useFlywheelLoader();
  const isMetricOwnerForReview = !!activeFlywheelReview?.metrics?.find(metric => metric.owner?.id === user?.id);
  const allowGoBack = window.location.href.includes("step") || window.location.href.includes("goal");
  const isFlywheelGoalOwner = flywheel?.latestFlywheelGoal?.ownerId == user?.id;
  const { setHideMainSideNav } = useSideNavigation();
  const companyName = currentCompany ? currentCompany.name : user?.company?.name;
  const navigate = useNavigate();
  // Permissions to check-in
  const hasAccessToViewCheckIn = isAdmin || isRodaAdmin || isMetricOwner;
  // Permissions to review
  const hasAccessToReview = isAdmin || isRodaAdmin || isMetricOwnerForReview || isFlywheelGoalOwner;
  // Check if we're at the start of the quarter
  const twoWeeksInQuarter = dayjs(checkInSubgoal?.startDate).add(2, "weeks");
  const isAtStartOfQuarter = dayjs().isBetween(dayjs(checkInSubgoal?.startDate), twoWeeksInQuarter, null, "[]");
  // Check that there's a subgoal in review AND if it's at the start of the quarter then check if there's still a review due
  const checkExtendedDeadlineReviewDue = !!subgoalInReview && (isAtStartOfQuarter ? isReviewDue : true);

  // Check if we should show the review nav item
  const showReview = useMemo(() => activeFlywheelReview ? hasAccessToReview && (isAtStartOfQuarter ? checkExtendedDeadlineReviewDue : true) : false, [
    checkExtendedDeadlineReviewDue,
    activeFlywheelReview,
    hasAccessToReview,
    isAtStartOfQuarter
  ]);

  // Show true if there's not a subgoalInReview yet - means that they haven't set it up -> review is due

  const showReviewDueBanner = activeFlywheelReview?.reviewPeriod === ReviewPeriod.NEXT_YEAR ? subgoalInReview === null ? true : isReviewDue : isReviewDue;

  const handleLinkClick = () => {
    setHideMainSideNav(false); // Reset main navigation to always show
    setIsMenuOpen(false);
  };

  useEffect(() => {
    if (isRodaAdmin) {
      if (currentCompany) {
        setMenuLevel(MobileMenuLevel.Company);
      } else {
        setMenuLevel(MobileMenuLevel.RodaAdmin);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isRodaAdmin, currentCompany ]);

  const mobileHeader = document.getElementById("mobile-header");

  if (mobileHeader) {
    document.documentElement.style.setProperty("--mobile-header", `${mobileHeader.clientHeight}px`);
  }

  const navigateToDashboard = () => {
    navigate(routes.dashboard(currentCompany ? currentCompany.id : undefined));
  };

  const navigateToCreateFlywheel = () => {
    navigate(routes.createFlywheel);
  };

  const navigateToCustomiseFlywheel = () => {
    navigate(routes.customiseFlywheel(currentCompany ? currentCompany.id : undefined));
  };

  return (
    <div className="z-50">
      {/* Mobile Header */}
      <header
        id="mobile-header"
        className="flex justify-between items-center bg-white p-3 border-b-[1px] inset-x-0 top-0 w-full"
      >
        {/* Left Section: Roda logo or Organisation info */}
        {menuLevel === MobileMenuLevel.RodaAdmin ? (
          <div>
            {/* Roda logo */}
            <img
              src={Logo}
              alt="Logo"
            />
          </div>
        ) : (
          <div className="flex items-center max-w-full">
            {isRodaAdmin && (
              <button
                className="flex-1 font-semibold p-3 pr-6 truncate capitalize text-left flex items-center gap-4 hover:bg-brand-cold-metal-100"
                title="Go back"
                aria-label="Go back to admin menu"
                onClick={() => {
                  setCurrentCompany(null);
                  setActiveFlywheelId(null);
                  setMenuLevel(MobileMenuLevel.RodaAdmin);
                  navigate(routes.rodaAdminOrganisationManagement);
                }}
              >
                <Icon.ChevronLeft />
              </button>
            )}

            {allowGoBack ? (
              <Button
                title="Home"
                iconComponent={<Icon.ChevronLeft />}
                className="bg-brand-cold-metal-200 text-brand-cold-metal-800 font-normal p-1.5 text-xs"
                onClick={() => navigate(routes.dashboard(currentCompany ? currentCompany.id : undefined))}
              />
            ) : companyName && (
              <>
                <div className="flex justify-between items-center w-full">
                  {/* Left Section: Company logo and name */}
                  <div className="flex items-center flex-1 min-w-0">
                    <div className="flex-none mr-2">
                      <CompanyAvatar
                        companyName={companyName}
                        logoS3Key={user?.company?.logoS3Key ?? undefined}
                      />
                    </div>

                    <h4 className="flex-auto truncate font-semibold">
                      {companyName}
                    </h4>
                  </div>
                </div>
              </>
            )}
          </div>
        )}

        {/* Right Section: Menu and notifications */}
        <div className="flex">
          <div
            className=" cursor-pointer flex justify-center gap-4 items-center"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            Menu
            <Icon.HamburgerMenu className="w-5 h-5" />
          </div>
        </div>

      </header>

      {/* Mobile Menu */}
      {menuLevel === MobileMenuLevel.RodaAdmin ? (
        <nav
          className={`bg-white shadow-md flex w-full fixed inset-0 top-[53px] z-40 ${isMenuOpen ? "translate-x-0" : "translate-x-full"}`}
          style={{ transition: "transform 800ms cubic-bezier(0.7, 0.18, 0.06, 1)" }}
        >
          <div className="flex flex-col justify-between w-full h-full">
            <div className="flex flex-col justify-between w-full pb-6 pt-3 px-8 h-full">
              <div className="w-full flex flex-col gap-y-3 text-sm">
                <div className="py-2">
                  <SideNav.NavItem
                    label="Company Management"
                    icon={<Icon.SettingsCog className="w-4.5 h-4.5" />}
                    onClick={() => {
                      setMenuLevel(MobileMenuLevel.RodaAdmin);
                      handleLinkClick();
                    }}
                    href={routes.rodaAdminOrganisationManagement}
                  />
                </div>

                <SideNav.Subheading heading="Account" />

                <SideNav.NavItem
                  label="Profile"
                  icon={<Icon.Profile className="w-4.5 h-4.5" />}
                  href={routes.account}
                  onClick={handleLinkClick}
                />

              </div>
            </div>
          </div>
        </nav>
      ) : (
        <nav
          className={`bg-white shadow-md flex w-full fixed inset-0 top-[53px] z-40 ${isMenuOpen ? "translate-x-0" : "translate-x-full"}`}
          style={{ transition: "transform 800ms cubic-bezier(0.7, 0.18, 0.06, 1)" }}
        >
          <div className="flex flex-col justify-between w-full h-full">
            <div className="flex flex-col justify-between w-full pb-6 pt-3 px-8 h-full">
              <div className="w-full flex flex-col gap-y-3 text-sm">
                <div className="py-2 flex flex-col gap-y-2">

                  {availableFlywheels && (
                    <SideNav.ExpandableNavItem
                      label="Flywheels"
                      icon={<Icon.Refresh className="w-4.5 h-4.5" />}
                      onClick={navigateToDashboard}
                      expandableOptions={[
                        ...availableFlywheels.map(fl => (
                          <SideNav.ExpandedNavItemOption
                            key={`availableFlywheel_${fl.id}`}
                            label={fl.name}
                            onClick={() => {
                              setActiveFlywheelId(fl.id);
                              handleLinkClick();
                              navigateToDashboard();
                            }}
                            isSelected={flywheel?.id === fl.id}
                            editAction={(isAdmin || isRodaAdmin) ? () => {
                              setActiveFlywheelId(fl.id);
                              handleLinkClick();
                              navigateToCustomiseFlywheel();
                            } : undefined}
                          />
                        )),
                        isAdmin ? (
                          <SideNav.ExpandedNavItemOption
                            key="add_flywheel_option"
                            label="Add new flywheel"
                            onClick={() => {
                              onboardingDispatch({ type: "RESET" });
                              customiseMetricDispatch({ type: "RESET" });
                              customiseFlywheelGoalDispatch({ type: "RESET" });

                              onboardingDispatch({
                                type: "SET_STEP",
                                step: "flywheel_intro"
                              });
                              handleLinkClick();
                              navigateToCreateFlywheel();
                            }}
                            isSelected={false}
                            icon={<Icon.PlusCircle className="w-4 text-brand-cold-metal-600" />}
                          />
                        ) : <></>
                      ]}
                    />
                  )}

                  {hasAccessToViewCheckIn && (
                    <SideNav.NavItem
                      label="Check-in"
                      icon={<Icon.BarChart className="w-4.5 h-4.5" />}
                      href={routes.checkIn(currentCompany ? currentCompany.id : undefined)}
                      onClick={handleLinkClick}
                      banner={isCheckInDue && userHasMetrics ? { bannerText: "Check-in due" } : undefined}
                    />
                  )}

                  {/** Show nav item if
                  * user has access to review
                  * there's a subgoal in review
                  * it's an extended deadline and there's a review due
                  */}
                  {showReview && (
                    <SideNav.NavItem
                      label={`Next ${activeFlywheelReview?.reviewPeriod === ReviewPeriod.NEXT_YEAR ? "year" : "quarter"} review`}
                      icon={<Icon.Calendar className="w-4.5 h-4.5" />}
                      href={routes.review(currentCompany ? currentCompany.id : undefined)}
                      onClick={handleLinkClick}
                      banner={showReviewDueBanner ? { bannerText: "Review due" } : undefined}
                    />
                  )}

                </div>

                {!isRodaAdmin && (
                  <>
                    <SideNav.Subheading heading="Account" />

                    <SideNav.NavItem
                      label="Profile"
                      icon={<Icon.Profile className="w-4.5 h-4.5" />}
                      href={routes.account}
                      onClick={handleLinkClick}
                    />

                  </>
                )}

                {(isRodaAdmin || isAdmin) && (
                  <>

                    <SideNav.Subheading heading="Organisation" />

                    <SideNav.NavItem
                      label="General"
                      icon={<Icon.Company className="w-4.5 h-4.5" />}
                      href={routes.organisationSettings(currentCompany ? currentCompany.id : undefined)}
                      onClick={handleLinkClick}
                    />

                    <SideNav.NavItem
                      label="Members"
                      icon={<Icon.Users className="w-4.5 h-4.5" />}
                      href={routes.members(currentCompany ? currentCompany.id : undefined)}
                      onClick={handleLinkClick}
                    />

                  </>
                )}

              </div>

              <div className="flex flex-col gap-y-3">
                <SideNav.NavItem
                  label="Getting started"
                  icon={<Icon.HelpCircle className="w-4.5 h-4.5" />}
                  href={routes.gettingStarted}
                  onClick={() => {
                    setHideMainSideNav(true); // hide main nav
                    setIsMenuOpen(false);
                  }}
                />

                {(isRodaAdmin || isAdmin) && (
                  <SideNav.NavItem
                    label="Invite team members"
                    icon={<Icon.InviteUsers className="w-4.5 h-4.5" />}
                    href={routes.members(currentCompany ? currentCompany.id : undefined)}
                    onClick={handleLinkClick}
                  />
                )}
              </div>

            </div>

            <Footer />

          </div>
        </nav>
      )}
    </div>
  );
};
