import type dayjs from "../dayjs";

/**
 * We set up subgoals with absolute starts and ends - e.g. 1st Jan - 31st March
 * But in reality, we have to use these as approximate windows for when the quarter / year begins -
 * because if the 31st March isn't a Sunday, we'll have a week which is both the current and next quarter!
 *
 * To account for this, we make sure the last week of the quarter is the week in which the last
 * day of the quarter falls - and so technically, the last day of the quarter is the Sunday of that
 * week. So depending on the day on which 31st March falls, the last day could be anything from
 * the 31st March to the 6th April.
 */
export const getLastSundayFromEndDate = (absoluteEndDate: dayjs.Dayjs) => {
  // Always return the Sunday of the week this date falls in
  return absoluteEndDate.endOf("isoWeek");
};