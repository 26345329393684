import type { User } from "@roda/graphql/genql";
import type { Optional } from "@roda/shared/types";

type UserType = Optional<Pick<User, "firstName" | "lastName" | "email">>;

/**
 * Function that shows the first and last name of the user if they're available
 * otherwise fallbacks to their email
 * @param user
 */
export const getUserDisplayName = (user: UserType) => {
  const { firstName, lastName } = user;
  let { email } = user;

  if (email === "-") {
    email = "(deleted)";
  }

  return firstName && lastName ? `${firstName} ${lastName}` : email;
};