import { getDateRangeWeekBounds } from "@roda/shared/utils/getDateRangeWeekBounds";
import { getFirstMondayFromStartDate } from "@roda/shared/utils/getFirstMondayFromStartDate";

import dayjs from "~/utils/dayjs";

import type { FlywheelGoalUpdate } from "@roda/graphql/genql";
import type { Dayjs } from "dayjs";

/**
 * Get the flywheel goal update for a given week from a list of flywheel goal updates
 * @param flywheelGoalUpdates the list of flywheel goal updates for the subgoal
 * @param monthStart the starting day of the week - use dayjs(<week>).startOf("isoWeek") for this
 * @param monthEnd the end day of the week - use dayjs(<week>).endOf("isoWeek") for this
 * @returns a flywheel goal update for the given week, if it exists
 */
export const getMonthFlywheelGoalUpdate = (flywheelGoalUpdates: FlywheelGoalUpdate[], monthStart: Dayjs, monthEnd: Dayjs) => {
  // First, get the actual start and end of the month based on the absolute range
  const { startDate, endDate } = getDateRangeWeekBounds(monthStart, monthEnd);

  // Find a flywheel goal update where the update.startDate is between the given monthStart and monthEnd
  // Always compare the first monday from start date with the startDate/endDate we get back from getDateRangeWeekBounds
  // The [) means that this is inclusive of the start date but not the end date
  return flywheelGoalUpdates?.find(update => getFirstMondayFromStartDate(dayjs(update.startDate)).isBetween(startDate, endDate, null, "[)"));
};