import { Button } from "~/components/Button";
import { Icon } from "~/components/Icon";

interface ReminderBannerProps {
  bannerTitle: string;
  bannerText: string;
  onDismiss?: () => void;
  onAction?: () => void;
  actionText: string;
  dismissText?: string;
  loading?: boolean
}
export const ReminderBanner: React.FC<ReminderBannerProps> = ({
  bannerText, bannerTitle, onDismiss, onAction, actionText, dismissText, loading
}) => {
  return (
    <div
      id="reminder-banner"
      className="flex flex-row p-3 border-brand-cold-metal-200 border-[1px] border-t-0 justify-between items-center rounded-b-lg absolute top-0 z-20 bg-white w-[95%] max-w-[650px]"
    >
      <div className="flex flex-row items-center gap-x-3">
        <div className="border-[1px] p-2 rounded-lg border-brand-cold-metal-200">
          <Icon.Announcement />

        </div>

        <div className="flex flex-col items-start text-sm overflow-hidden">
          <p className="font-semibold">{bannerTitle}</p>

          <p className="text-sm break-all opacity-60 text-brand-cold-metal-500">{bannerText}</p>
        </div>
      </div>

      <div className="flex flex-row gap-x-2">
        {onDismiss && (
          <Button
            title={dismissText || "Dismiss"}
            className="bg-brand-cold-metal-100 text-brand-cold-metal-700"
            onClick={onDismiss}

          />
        )}

        {onAction && (
          <Button
            title={actionText}
            onClick={onAction}
            loading={loading}
          />
        )}
      </div>
    </div>
  );
};