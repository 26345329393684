import { zodResolver } from "@hookform/resolvers/zod";
import { Currency } from "@roda/shared/types";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

import { ConfirmationPopup } from "~/components/ConfirmationPopup";
import {
  Input,
  TextArea
} from "~/components/form";
import { FlywheelGoalCheckInSchema } from "~/components/form/formSchemas";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useCurrentUser } from "~/contexts/UserContext";
import { useCreateOrUpdateFlywheelGoalUpdate } from "~/hooks/flywheel-goal-update";
import { useError } from "~/hooks/useError";
import { useIsMobile } from "~/hooks/useIsMobile";
import { constructUtcDate } from "~/utils/dates/constructUtcDate";
import dayjs from "~/utils/dayjs";
import { getUnitSymbol } from "~/utils/getUnitSymbol";
import { cleanNumberInput } from "~/utils/validation/cleanNumberInput";
import { trimTrailingDots } from "~/utils/validation/trimTrailingDots";

import type { Dayjs } from "dayjs";
import type { z } from "zod";

interface FlywheelGoalCheckInPopupProps {
  isOpen: boolean;
  onClose: () => void;
  monthStart: Dayjs;
  monthEnd: Dayjs;
}

export const FlywheelGoalCheckInPopup: React.FC<FlywheelGoalCheckInPopupProps> = ({
  isOpen, onClose, monthStart
}) => {
  const { user } = useCurrentUser();
  const [ checkInRes, checkInReq ] = useCreateOrUpdateFlywheelGoalUpdate();
  const { flywheel } = useSelectedFlywheel();
  const flywheelGoal = flywheel?.latestFlywheelGoal;
  const flywheelGoalUnitSymbol = getUnitSymbol(flywheelGoal?.unitTypeLabel, flywheel?.currency as Currency || Currency.GBP, true);
  const isMobile = useIsMobile();
  const { assertGraphQLSuccess, handleRodaError } = useError();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm<z.infer<typeof FlywheelGoalCheckInSchema>>({
    resolver: zodResolver(FlywheelGoalCheckInSchema),
    shouldFocusError: false
  });

  // Submit handler
  const onSubmit = handleSubmit(fields => {
    // We will always have a flywheel goal
    if (flywheelGoal?.id) {
      // Check in for subgoal
      checkInReq({
        flywheelGoalId: Number(flywheelGoal.id),
        value: trimTrailingDots(fields.value),
        startDate: constructUtcDate(monthStart),
        notes: fields.notes,
        updatedBy: Number(user?.id)
      })
        .then(res => {
          assertGraphQLSuccess(res);
          reset(); // reset form
          onClose(); // close modal
          toast.success("Checked in successfully!", { position: isMobile ? "top-center" : "top-right" });
        })
        .catch(e => {
          handleRodaError(e, "An error occurred! Try again later!");
        });
    }
  });

  // Cancel handler, reset form and close modal
  const handleCancel = () => {
    reset();
    onClose();
  };

  return (
    <ConfirmationPopup
      isOpen={isOpen}
      title="Flywheel goal check-in"
      subtitle={`Check in for ${dayjs(monthStart).format("MMMM YYYY")}`}
      onContinue={onSubmit}
      continueText="Save"
      cancelText="Cancel"
      onCancel={handleCancel}
      loading={checkInRes.fetching}
      continueDisabled={checkInRes.fetching}
    >
      <div>
        <form onSubmit={onSubmit}>
          <div className="flex flex-col gap-y-3">

            <Input
              {...register("value", {
                onChange: e => {
                  const cleanedValue = cleanNumberInput(e.target.value);

                  setValue("value", cleanedValue);
                }
              })}
              label={flywheel?.latestFlywheelGoal?.name}
              name="value"
              min={0.01}
              autoComplete="off"
              autoCorrect="off"
              inputMode="numeric"
              hasError={!!errors.value}
              errorMessage={errors.value?.message}
              iconPosition="left"
              iconComponent={<p>{flywheelGoalUnitSymbol}</p>}
            />

            <TextArea
              {...register("notes")}
              disabled={checkInRes.fetching}
              label="Any notes to add?"
              name="notes"
              placeholder="Please insert any comments you would like to share on this week's progress"
              inputMode="text"
              hasError={!!errors.notes}
              errorMessage={errors.notes?.message}
            />
          </div>

        </form>
      </div>
    </ConfirmationPopup>
  );
};