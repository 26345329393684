import { Company } from "~/fragments/company";
import {
  ReadOnlyUser,
  User
} from "~/fragments/user";

import type { SessionGenqlSelection } from "@roda/graphql/genql";

export const Session: SessionGenqlSelection = {
  ...User,
  fakeNowDate: true,
  company: {
    ...Company,
    activeUsers: ReadOnlyUser
  }
};