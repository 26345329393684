import {
  useCallback,
  useReducer
} from "react";
import { useLocalStorage } from "react-use";

/**
  A hook that returns a reducer, while synchronizing the state with the localStorage of the browser.
  @template State   - The type of the state object.
  @template Transform - The type of the action object.
  @param {string} key - The key used to identify the data in the localStorage.
  @param {(prevState: State, action: Transform) => State} reducer - A function that accepts the previous state and an action object, and returns the new state.
  @param {State} initialState - The initial state of the reducer.
  @returns {[State, React.Dispatch<Transform>]} - An array that contains the current state and the dispatch function to update it.
*/
export function usePersistReducer<
// eslint-disable-next-line @typescript-eslint/no-explicit-any
State = Record<string, any>,
Transform = string>(
  key: string,
  reducer: (prevState: State, action: Transform) => State,
  initialState: State
) {
  // grab saved value from `localStorage` and
  // a function to update it. if
  // no value is retrieved, use `initial state`
  const [ savedState, saveState ] = useLocalStorage<State>(
    key,
    initialState,
  );

  // wrap `reducer` with a memoized function that
  // syncs the `newState` to `localStorage` before
  // returning `newState`. memoizing is important!
  const reducerLocalStorage = useCallback(
    (state: State, action: Transform): State => {
      const newState = reducer(state, action);

      saveState(newState);

      return newState;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ saveState ],
  );

  // use wrapped reducer and the saved value from
  // `localStorage` as params to `useReducer`.
  // this will return `[state, dispatch]`
  return useReducer(reducerLocalStorage, savedState || {} as State);
}
