import {
  Metric,
  ReducedMetric
} from "~/fragments/metric";

import type { StepGenqlSelection } from "@roda/graphql/genql";

export const Step: StepGenqlSelection = {
  id: true,
  name: true,
  order: true,
  ownerId: true,
  alias: true,
  metrics: Metric
};

export const ReducedStep: StepGenqlSelection = {
  id: true,
  name: true,
  order: true,
  ownerId: true,
  alias: true,
  metrics: ReducedMetric
};
