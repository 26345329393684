import { ConfirmationPopup } from "~/components/ConfirmationPopup";
import { Icon } from "~/components/Icon";
import type { ImageCaptureTargetId } from "~/contexts/ImageCaptureContext";
import { useImageCaptureContext } from "~/contexts/ImageCaptureContext";
import { useTimedBoolean } from "~/hooks/useTimedBoolean";
import type { captureElement } from "~/utils/captureElement";
import { downloadFromUrl } from "~/utils/downloadFromUrl";

export const SaveImageMenuItem: React.FC<{captureId: ImageCaptureTargetId, html2canvasOptions?: Parameters<typeof captureElement>[1]} > = ({ captureId, html2canvasOptions }) => {
  const { captureAndPreview } = useImageCaptureContext();

  return (
    <>
      <button
        onClick={() => {
          captureAndPreview(captureId, html2canvasOptions);
        }}
      >
        Save as image
      </button>
    </>
  );
};

export const ImagePreviewConfirmationPopup: React.FC<{imageUrl: string | null, handleClose?: () => void}> = ({ imageUrl, handleClose }) => {
  const [ downloadTimeoutActive, timeoutDownload ] = useTimedBoolean(5000);

  return (
    <ConfirmationPopup
      isOpen
      title="Image preview"
      continueText={<span className="inline-flex items-center gap-[5px]">Download <Icon.DownloadImage /></span>}
      continueDisabled={!imageUrl || downloadTimeoutActive}
      onContinue={() => {
        timeoutDownload(); // prevent spam
        downloadFromUrl(imageUrl!);
      }}
      onCancel={handleClose}
    >
      {imageUrl && (
        <div className="relative w-full ring-[1px] ring-brand-cold-metal-100 rounded *:p-2">
          <img
            src={imageUrl}
            alt="Capture output"
            className="w-full h-auto"
          />
        </div>
      )}
    </ConfirmationPopup>
  );
};