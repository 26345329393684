import dayjs from "~/utils/dayjs";

import type { MetricUpdate } from "@roda/graphql/genql";

interface Params {
  weekStart: string;
  weekEnd: string;
  metricUpdates: MetricUpdate[];
}

/**
 * Function that filters metricUpdates that fall within weekStart and weekEnd
 * @param params
 * @returns - list of metricUpdates
 */
export const filterMetricUpdatesByWeek = (params: Params) => {
  const {
    weekStart, weekEnd, metricUpdates
  } = params;

  const filteredMetricUpdates = metricUpdates.filter(metricUpdate => dayjs(metricUpdate.startDate).isBetween(weekStart, weekEnd, null, "[]"));

  return filteredMetricUpdates;
};