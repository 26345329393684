import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";

import type { Dayjs } from "dayjs";

dayjs.extend(timezone);

/**
 * Dates from the db are coming back as UTC - we need to tell dayjs that's a UTC date.
 * @param date
 * @returns dayjs
 */
export const parseDbDate = (date: string | Dayjs) => {
  return dayjs.tz(date, "UTC");
};