import { enumUserRole } from "@roda/graphql/genql";
import {
  createContext,
  useContext,
  useMemo
} from "react";

import { routes } from "~/constants/routes";
import { useAuth } from "~/contexts/AuthContext";
import { useCurrentUser } from "~/contexts/UserContext";

import type { PropsWithChildren } from "react";

/**
 * Having a specified home route avoids redirection UI flicker, if the user just
 * gets bounced around screens with redirects
 */

export const HomeRouteContext = createContext<string>("/");
export const useHomeRouteContext = () => useContext(HomeRouteContext);
export const HomeRouteProvider = ({ children }: PropsWithChildren) => {
  const { user } = useCurrentUser();
  const { authenticated } = useAuth();

  const homeRoute = useMemo(() => {
    if (user && authenticated) {
      // Direct Roda admins to the admin company management page
      if (user.role === enumUserRole.RODA_ADMIN) return routes.rodaAdminOrganisationManagement;

      // And all other authenticated users to the dashboard
      return routes.dashboard();
    } else {
      // Direct everyone else to the home page to sign up
      return routes.home;
    }
  }, [ authenticated, user ]);

  return (
    <HomeRouteContext.Provider value={homeRoute}>
      {children}
    </HomeRouteContext.Provider>
  );
};