import {
  STORAGE_KEYS,
  storageCleanupGroups
} from "~/constants/storageKeys";

interface ClearStorageParams {
  groupNames: Array<keyof typeof storageCleanupGroups>;
}
/**
 * Cleanup related stored data.
 *
 * see: {@link storageCleanupGroups}
 */
export const clearStorage = (params: ClearStorageParams): void => {
  const { groupNames } = params;

  const keysToRemove = groupNames.reduce<string[]>((acc, groupName) => {
    return [ ...acc, ...storageCleanupGroups[ groupName ] ];
  }, []);

  const localStorageKeys = Object.values(STORAGE_KEYS.LOCAL_STORAGE);

  keysToRemove.forEach(key => {
    if (localStorageKeys.includes(key as typeof localStorageKeys[number])) {
      localStorage.removeItem(key);
    }
  });
};