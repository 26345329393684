import { enumUserRole } from "@roda/graphql/genql";
import {
  useMemo,
  useState
} from "react";
import { useParams } from "react-router-dom";

import { CustomiseMetricMenuItem } from "~/components/metric/CustomiseMetricMenuItem";
import { EditExistingMetricUpdateMenuItem } from "~/components/metric/EditMetricUpdateMenuItem";
import { SaveImageMenuItem } from "~/components/metric/SaveImageMenuItem";
import { VerticalDotMenu } from "~/components/VerticalDotMenu";
import { useCurrentUser } from "~/contexts/UserContext";
import type { GetFlywheelMetricData } from "~/hooks/flywheel/use-get-flywheel";
import { useIsMobile } from "~/hooks/useIsMobile";

import type { ComponentProps } from "react";

export enum EditMetricAction {
  EditMetricName,
  EditMetricTarget,
  EditMetricOwner,
  SaveImage,
  EditMetricUpdate
}

interface EditMetricCardMenuProps extends Pick<ComponentProps<typeof VerticalDotMenu>, "buttonClassName"> {
  metric: GetFlywheelMetricData;
  onlyImageCapture?: boolean;
  editableUpdate?: NonNullable<GetFlywheelMetricData["metricUpdates"]>[0]
}

export const EditMetricCardMenu: React.FC<EditMetricCardMenuProps> = ({
  metric, buttonClassName, onlyImageCapture, editableUpdate
}) => {
  const [ editPopupType, setEditPopupType ] = useState<EditMetricAction | null>(null);
  const { user } = useCurrentUser();
  const isMobile = useIsMobile();
  const params = useParams();

  // Roda admins, admins and the metric owner can view this menu
  const canViewMetricMenu = useMemo(() => user?.role === enumUserRole.RODA_ADMIN || user?.role === enumUserRole.ADMIN || metric.owner?.id === user?.id, [
    metric.owner?.id,
    user?.id,
    user?.role
  ]);

  if (!canViewMetricMenu || (onlyImageCapture && isMobile)) return null;

  const getCaptureId = () => params.metricId ? "metric" : "step-" + metric.id;

  const containsChart = document.querySelector(
    `[data-capture-target="${getCaptureId()}"]`
  )
    ?.contains(
      document.querySelector(`[data-capture-target="${getCaptureId()}"]`)?.querySelector("canvas") ?? null
    );

  // Everything here can be viewed & used by Roda admins, admins and the metric owner - except where
  // there's additional permission checks
  return (
    <VerticalDotMenu buttonClassName={buttonClassName}>

      <>
        {editableUpdate && (
          <EditExistingMetricUpdateMenuItem
            openPopup={() => setEditPopupType(EditMetricAction.EditMetricUpdate)}
            closePopup={() => setEditPopupType(null)}
            isOpen={editPopupType === EditMetricAction.EditMetricUpdate}
            metric={metric}
            update={editableUpdate}
          />
        )}

        {user?.role === enumUserRole.RODA_ADMIN || user?.role === enumUserRole.ADMIN ? (
          <CustomiseMetricMenuItem metric={metric} />
        ) : (
          <></>
        )}

      </>

      {!isMobile && (
        <SaveImageMenuItem
          captureId={getCaptureId()}
          html2canvasOptions={{
            onclone(_, element) {
              element.style.minHeight = "unset";
              element.style.maxHeight = "unset";
              element.style.flex = "unset";

              const borderW = parseInt(getComputedStyle(element).borderInlineWidth ?? 0) * 2; // * 2 because it only accounts for one side

              // If the element doesn't contain a canvas (chart), we can set the
              // width to 600px - this is bc the chart doesn't resize
              if (!containsChart) {
                element.style.width = (600 - borderW) + "px";
              }

              element.style.height = "auto";
              element.style.flex = "unset";

              // Safari doesn't like this text sizing and clips the output (??)-
              // so doing it manually
              element.querySelectorAll("[class*='xl:text-xl']")
                .forEach(el => {
                  (el as HTMLElement).classList.remove("xl:text-xl");
                  (el as HTMLElement).style.fontSize = "1.25rem";
                  (el as HTMLElement).style.lineHeight = "1.75rem";
                });
            },
            width: containsChart ? undefined : 600

          }}
        />
      )}

    </VerticalDotMenu>
  );
};