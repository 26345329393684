import * as Tooltip from "@radix-ui/react-tooltip";
import clsx from "clsx";
import React, {
  Children,
  useState
} from "react";
import { twMerge } from "tailwind-merge";

import type { BaseFlywheelStepProps } from "~/components/flywheel/base/BaseFlywheelStep";
import { useIsMobile } from "~/hooks/useIsMobile";

import type {
  ComponentProps,
  ReactElement
} from "react";
import type { RectReadOnly } from "react-use-measure";

interface BaseFlywheelProps extends Pick<React.SVGProps<SVGSVGElement>, "fill" | "stroke"> {
  children: Array<React.ReactElement<BaseFlywheelStepProps>>;
  /** width/height of main flywheel circle (other elements make the overall svg larger) */
  size?: number;
  /** Thickness of the main stroke */
  thickness?: number;
  /** How many steps should this flywheel render? Used to ensure the correct step is focused when rotated  */
  stepQty?: number;
  /** Optional - what is the focused step? Used to ensure the correct step is focused when rotated  */
  stepIndex?: number;
  /**
   * Position on the page:
   * `side`
   * • On desktop, this moves the flywheel so it peeks from the left
   * • On mobile, this moves the flywheel so it peeks from the top
   * `huge`
   * • Huge is the state where it's absolutely massive (so off-screen)
   * `centre`
   * • Centres the flywheel in the centre of the page
   *  */
  position?: "side" | "centre" | "huge";
  /** offsets points along the outer circle */
  stepsAngleOffset?: number;
  /** force entire graphic to fit the container (strokes and other sizings will be scaled and therefore inaccurate to the props passed) */
  responsive?: boolean;
  /**
   * render elements between flywheel steps (like arrows!)
   *
   * Also provides `angle` as a CSS var (`var(--angle)`)
  */
  renderSeparator?: React.ReactNode | ((props: {index: number, angle: number}) => React.ReactNode);
  /**
   * render content inside the main circle
   *
   * *DOESN'T NEED TO BE A FN*-
   * since we are only passing through `size` which isn't really something we
   * would functionally use outside of styling, its also being provided as a CSS
   * var (`var(--size)`)
   **/
  renderInnerContent?: ReactElement | ((props: {size: number}) => ReactElement);
  /** boolean - Optionally fade steps when once is being hovered */
  shouldHoverSteps?: boolean;
}

/**
 * *You probably wont be using this component directly.* Instead you might use
 * our pre-themed `RodaFlywheel` component. This is a base component with full
 * customisation should we need it.
 * @example
 * ```jsx
 * <BaseFlywheel>
 *   <BaseFlywheelStep size={5} />
 *   // ...multiple...
 * </BaseFlywheel>
 * ```
 *
 */
export const BaseFlywheel: React.FC<BaseFlywheelProps> = ({
  children,
  size = 200,
  position = "centre",
  stepQty,
  stepIndex,
  thickness = 2,
  stepsAngleOffset = 0,
  responsive = false,
  renderSeparator,
  renderInnerContent,
  stroke,
  shouldHoverSteps
}) => {
  const [ maxStepAnnotationSize, setMaxStepAnnotationSize ] = useState({
    width: 0,
    height: 0
  });

  const isMobile = useIsMobile();
  const radius = size / 2;

  // Even padding around the flywheel to account for step size (including
  // strokes and outer ring)
  const maxStepSize = Math.max(0, ...children.map(child => {
    let sum = child.props.size || 0;

    sum += child.props.ring?.offset || 0;
    sum += (child.props.ring?.strokeWidth || 0) / 2;

    return sum;
  }));

  // "thickness / 4" seemed to properly butt the svg up against the container
  const cx = radius + (thickness / 4) + maxStepSize + maxStepAnnotationSize.width;
  const cy = radius + (thickness / 4) + maxStepSize + maxStepAnnotationSize.height;
  const totalSteps = children.length;
  const stepCountAngleOffset = 2 * Math.PI / totalSteps;
  const width = (radius + (thickness / 4) + maxStepSize + maxStepAnnotationSize.width) * 2;
  const height = (radius + (thickness / 4) + maxStepSize + maxStepAnnotationSize.height) * 2;

  const responsiveProps = responsive ? {
    viewBox: `0 0 ${width} ${height}`,
    preserveAspectRatio: "xMidYMid meet"
  } : {};

  const annotationContainerRef = React.useRef<HTMLDivElement>(null);

  // This transform property determines the flywheel position
  // FYI the "transition" property handles animation between positions
  const transform = React.useMemo(() => {
    switch (position) {
      // Huge is the state where it's absolutely massive (so off-screen)
      case "huge":
        return "translate(-50%, -50%) scale(8) translateZ(0px)";

      // On desktop, this moves the flywheel so it peeks from the left
      // On mobile, this moves the flywheel so it peeks from the top
      case "side": {
        // Degrees for each segment of the flywheel
        // This would be 72° each if the flywheel has 5 steps.
        const segmentDegrees = 360 / (stepQty || 0);

        if (isMobile) {
          // We rotate the flywheel so the active step is at 180°
          const rotation = (180 - ((stepIndex || 0) * segmentDegrees));

          return `translate(-50%, -90%) scale(1.5) rotate(${rotation}deg) translateZ(0px)`;
        } else {
          // We rotate the flywheel so the active step is at 90°
          const rotation = (90 - ((stepIndex || 0) * segmentDegrees));

          return `translate(-80%, -50%) scale(1.5) rotate(${rotation}deg) translateZ(0px)`;
        }
      }

      // Centre / default is centrally positioned.
      case "centre":
      default:
        return "translate(-50%, -50%) scale(1) translateZ(0px)";
    }
  }, [
    position,
    stepIndex,
    stepQty,
    isMobile
  ]);

  // Top position for the the flywheel
  const top = React.useMemo(() => {
    if (position === "side" && isMobile) {
      return 0;
    } else {
      return "50%";
    }
  }, [ position, isMobile ]);

  // Left position for the the flywheel
  const left = React.useMemo(() => {
    if (position === "side" && !isMobile) {
      return 0;
    } else {
      return "50%";
    }
  }, [ position, isMobile ]);

  const polarToCartesian = (centerX: number, centerY: number, radius: number, angleInDegrees: number) => {
    const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

    return {
      x: centerX + (radius * Math.cos(angleInRadians)),
      y: centerY + (radius * Math.sin(angleInRadians))
    };
  };

  const describeArc = (x: number, y: number, radius: number, startAngle: number, endAngle: number): string => {
    const start = polarToCartesian(x, y, radius, endAngle);
    const end = polarToCartesian(x, y, radius, startAngle);
    const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

    const d = [
      "M",
      start.x,
      start.y,
      "A",
      radius,
      radius,
      0,
      largeArcFlag,
      0,
      end.x,
      end.y
    ].join(" ");

    return d;
  };

  return (
    <FlywheelPropertiesContextProvider
      value={{
        cx,
        cy,
        radius,
        thickness,
        stepsAngleOffset,
        getStepContainer: (stepIndex: number) => document.getElementById(`step-${stepIndex}`)
      }}
    >
      <Tooltip.Provider>
        <div
          id="flywheel"
          style={{
            willChange: "transform",
            transform,
            top,
            left,
            transition: "all 600ms cubic-bezier(0.9, 0.06, 0.06, 0.98)"
          } as React.CSSProperties}
          className={clsx("absolute duration-600 pointer-events-none  ", shouldHoverSteps && "[&>[id^='step-']]:pointer-events-auto [&:hover>[id^='step-']_button:hover]:!opacity-100 [&:hover>[id^='step-']_button]:opacity-50 [&>[id^='step-']_button]:delay-0 [&>[id^='step-']_button]:duration-400")}
        >
          <svg
            width={width}
            height={height}
            {...responsiveProps}
          >
            {/* Main flywheel circle */}
          </svg>

          {/* Steps and separators */}

          {React.Children.map(children, (child, index) => {
            // start at the top of the circle
            const firstStepAngleOffset = -Math.PI * 0.5; // in radians
            const angle = index * stepCountAngleOffset + firstStepAngleOffset;
            const stepX = cx + radius * Math.cos(angle);
            const stepY = cy + radius * Math.sin(angle);
            // Calculate the midpoint for this step, for the separator
            const endPointOfSegment = angle + (stepCountAngleOffset);
            const midAngleDegrees = endPointOfSegment * (180 / Math.PI);
            // sets the angle to zero in degrees with the first item as the
            // starting point (0 deg) for more simple child usage
            const normalisedAngleDeg = (angle - firstStepAngleOffset) * (180 / Math.PI);
            // Curve logic!
            const gapAngle = 8; // Adjust this value to change the gap between arcs
            const startAngle = (360 / Children.count(children)) * index + gapAngle;
            const endAngle = (360 / Children.count(children)) * (index + 1) - gapAngle;
            const arc = describeArc(cx, cy, radius, startAngle, endAngle);
            const end = polarToCartesian(cx, cy, radius, endAngle);
            const arrowX = end.x;
            const arrowY = end.y;

            return (
              <>
                <div
                  className={twMerge("absolute inset-0 w-full h-full group should-fade !pointer-events-none [&>*]:pointer-events-auto", typeof child.props.stepContainerClassname === "string" ? child.props.stepContainerClassname : child.props.stepContainerClassname?.({
                    index,
                    side: stepX === cx ? "center" : stepX < cx ? "left" : "right",
                    alignment: stepY === cy ? "center" : stepY < cy ? "top" : "bottom",
                    angle: normalisedAngleDeg
                  }))}
                // sometimes tailwind classes don't like using template strings
                // so passing these down as CSS vars too
                  style={Object.assign({
                    "--index": index,
                    "--angle": normalisedAngleDeg + "deg"
                  } as React.CSSProperties, child.props.stepContainerStyle)}
                  ref={annotationContainerRef}
                  id={`step-${index}`}
                >
                  <svg className="w-full h-full absolute inset-0 !pointer-events-none [&>*]:pointer-events-auto">

                    <path
                      d={arc}
                      fill="none"
                      strokeLinecap="round"
                      stroke={stroke}
                      strokeWidth="1.5"
                    />

                    {/* Step circles (which render annotations) */}
                    {React.cloneElement(child, {
                      __index: index,
                      __cx: stepX,
                      __cy: stepY,
                      __angle: normalisedAngleDeg,
                      __onAnnotationResize: (rect: RectReadOnly) => {
                        if (!rect) return;

                        setMaxStepAnnotationSize(prev => ({
                          width: Math.max(prev.width, rect.width),
                          height: Math.max(prev.height, rect.height)
                        }));
                      }
                    })}
                  </svg>

                  {/* Step separators */}
                  {renderSeparator ? (
                    <div
                      style={{
                        "--angle": midAngleDegrees + "deg",
                        "position": "absolute",
                        "left": arrowX,
                        "top": arrowY,
                        "transform": "translate(-50%, -50%)"
                      } as React.CSSProperties}
                      data-separator
                    >
                      {typeof renderSeparator === "function" ? renderSeparator({
                        index,
                        angle: midAngleDegrees
                      }) : renderSeparator}
                    </div>
                  ) : null}
                </div>

              </>
            );
          })}

          {renderInnerContent && (
            <div
              style={{
                "--size": `${radius * 2}px`,
                "maxWidth": "var(--size)",
                "minHeight": "var(--size)",
                "left": `calc(${cx}px - (var(--size) / 2))`,
                "top": `calc(${cy}px - (var(--size) / 2))`,
                "position": "absolute",
                "width": "100%",
                "display": "flex",
                "flexDirection": "column",
                "justifyContent": "center",
                "alignItems": "center",
                "animationDelay": "2s"
              } as React.CSSProperties}
              // by default the container blocks the mouse events of the
              // step-circles so we're disabling pointer events on the outermost
              // element and re-enabling them on inner elements
              className="fade-in-100 animate-in duration-1000  pointer-events-none [&>*]:pointer-events-auto"
              children={typeof renderInnerContent === "function" ? renderInnerContent({ size: radius * 2 }) : renderInnerContent}
            />
          )}
        </div>
      </Tooltip.Provider>
    </FlywheelPropertiesContextProvider>
  );
};

const FlywheelPropertiesContext = React.createContext<{
  cx: number;
  cy: number;
  radius: number;
  thickness: number;
  stepsAngleOffset: number;
  getStepContainer: (stepIndex: number) => HTMLElement | null;
}>({
      getStepContainer: () => null,
      cx: 0,
      cy: 0,
      radius: 0,
      stepsAngleOffset: 0,
      thickness: 0
    });

export const useFlywheelProperties = () => React.useContext(FlywheelPropertiesContext);

const FlywheelPropertiesContextProvider: React.FC<ComponentProps<typeof FlywheelPropertiesContext.Provider>> = props => <FlywheelPropertiesContext.Provider {...props} />;