export const removeEmojis = (input: string): string => {
  // Unicode range for emojis is from \u{1F600} to \u{1F64F}
  // Extended Unicode ranges for emojis are from \u{1F300} to \u{1FAFF}
  // and \u{2600} to \u{26FF}, among others.
  return input.replace(
    /[\u{1F600}-\u{1F64F}]/gu, "" // Regular emojis
  ).replace(
    /[\u{1F300}-\u{1FAFF}]/gu, "" // Miscellaneous Symbols and Pictographs
  ).replace(
    /[\u{2600}-\u{26FF}]/gu, "" // Dingbats
  ).replace(
    /[\u{2700}-\u{27BF}]/gu, "" // Additional symbols
  );
};
