import { twMerge } from "tailwind-merge";

import { Loading } from "./Spinner";

import type {
  ReactNode,
  ComponentProps
} from "react";

interface ButtonProps extends Omit<ComponentProps<"button">, "title"> {
  title?: string | (ReactNode & {});
  iconComponent?: ReactNode;
  loading?: boolean;
  disabled?: boolean;
}
/**
 * A custom Button component with disabled state & optional icon and loading state.
 * @param title - Optional: Title to display on the button.
 * @param iconComponent - Optional: Icon to display on the left side of the button. please use our custom Icon component.
 * @param loading - Optional: Whether the button is in a loading state.
 * @param disabled - Optional: Whether the button is disabled.
 * @returns {React.ReactElement} - Rendered button component.
 */
export const Button: React.FC<ButtonProps> = ({
  title,
  iconComponent,
  loading,
  className,
  disabled,
  ...props
}) => {
  const buttonStyles = twMerge([
    "relative font-semibold py-3 px-2 sm:px-6 rounded-lg flex items-center justify-center text-brand-cold-metal-50 text-xs",
    "overflow-hidden transition-all",
    "bg-brand-cold-metal-900 hover:opacity-80",
    "disabled:relative disabled:cursor-not-allowed disabled:opacity-50",
    className
  ]);

  return (
    <button
      type={props.type || "button"}
      className={buttonStyles}
      disabled={disabled}
      {...props}
    >
      {loading && (
        <Loading.AbsoluteCenter
          color="white"
          size={20}
        />
      )}

      <>
        {iconComponent && <span className={`${title ? "mr-1" : ""} transition-opacity ${loading ? "opacity-0" : "opacity-100"}`}>{iconComponent}</span>}

        {title && <span className={`transition-opacity ${loading ? "opacity-0" : "opacity-100"}`}>{title}</span>}
      </>

    </button>
  );
};
