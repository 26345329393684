import { ReportingCycle } from "~/components/onboarding/content/ReportingCycle";
import type {
  OnboardingStep,
  ChangeStep
} from "~/components/onboarding/OnboardingProgressBar";
import { SelectedStepProvider } from "~/contexts/SelectedStepContext";

import {
  CompanyDetails,
  UserDetails,
  FlywheelGoal,
  FlywheelGoalProgress,
  FlywheelIntro,
  MetricsAndTargets
} from "./content";

export interface OnboardingContentProps {
  currentStep: OnboardingStep;
  changeStep: ChangeStep;
  createFlywheelMode?: boolean;
}

export interface OnboardingContentStepProps {
  onNextStep: () => void;
  onBack?: () => void;
  changeStep: ChangeStep;
  createFlywheelMode?: boolean;
}

interface Content {
  title: string;
  subTitle: string;
}

export const CONTENT: Record<OnboardingStep, Content> = {
  user_details: {
    title: "Welcome to Roda",
    subTitle: "Tell us a few details about yourself to get started"
  },
  company_details: {
    title: "Your organisation",
    subTitle: "Tell us about some key organisation details"
  },
  flywheel_intro: {
    title: "Introducing the flywheel",
    subTitle: "Let's explore the key features of a flywheel"
  },
  reporting_cycle: {
    title: "Set up your reporting cycle",
    subTitle: "A flywheel cycle runs for a year. Setting your cycle completion date tells us when you want your typical cycle to run from."
  },
  flywheel_goal: {
    title: "Setting a goal",
    subTitle: "Let's set up a goal for your organisation's flywheel"
  },
  flywheel_goal_progress: {
    title: "Progress towards goal",
    subTitle: "Let us know how much progress you have made since your flywheel cycle started"
  },
  metrics_targets: {
    title: "Steps and metrics",
    subTitle: "Let's set up your quarterly metrics for each step"
  }
};

export const OnboardingContent: React.FC<OnboardingContentProps> = ({
  currentStep, changeStep, createFlywheelMode
}) => {
  const handleNextStep = () => {
    switch (currentStep) {
      case "user_details":
        changeStep("company_details");
        break;

      case "company_details":
        changeStep("flywheel_intro");
        break;

      case "flywheel_intro":
        changeStep("reporting_cycle");
        break;

      case "reporting_cycle":
        changeStep("flywheel_goal");
        break;

      case "flywheel_goal":
        changeStep("flywheel_goal_progress");
        break;

      case "flywheel_goal_progress":
        changeStep("metrics_targets");
        break;

      default:
        changeStep("user_details");
        break;
    }
  };

  const handleGoBack = () => {
    switch (currentStep) {
      case "flywheel_intro":
        changeStep("company_details");
        break;

      case "reporting_cycle":
        changeStep("flywheel_intro");
        break;

      case "flywheel_goal":
        changeStep("reporting_cycle");
        break;

      case "flywheel_goal_progress":
        changeStep("flywheel_goal");
        break;

      case "metrics_targets":
        changeStep("flywheel_goal_progress");
        break;

      default:
        changeStep("user_details");
        break;
    }
  };

  return (
    <>
      <div className="flex flex-col flex-1">

        {currentStep === "user_details" && (
          <UserDetails
            onNextStep={handleNextStep}
            changeStep={changeStep}
          />
        )}

        {currentStep === "company_details" && (
          <CompanyDetails
            onNextStep={handleNextStep}
            changeStep={changeStep}
            onBack={handleGoBack}
          />
        )}

        {currentStep === "flywheel_intro" && (
          <FlywheelIntro
            onNextStep={handleNextStep}
            changeStep={changeStep}
            onBack={!createFlywheelMode ? handleGoBack : undefined}
            createFlywheelMode={createFlywheelMode}
            overrideTitle={createFlywheelMode ? "Set up a new flywheel" : undefined}
          />
        )}

        {currentStep === "reporting_cycle" && (
          <ReportingCycle
            onNextStep={handleNextStep}
            changeStep={changeStep}
            onBack={handleGoBack}
            createFlywheelMode={createFlywheelMode}
          />
        )}

        {currentStep === "flywheel_goal" && (
          <FlywheelGoal
            onNextStep={handleNextStep}
            changeStep={changeStep}
            onBack={handleGoBack}
            createFlywheelMode={createFlywheelMode}
          />
        )}

        {currentStep === "flywheel_goal_progress" && (
          <FlywheelGoalProgress
            onNextStep={handleNextStep}
            changeStep={changeStep}
            onBack={handleGoBack}
            createFlywheelMode={createFlywheelMode}
          />
        )}

        {currentStep === "metrics_targets" && (
          <SelectedStepProvider>
            <MetricsAndTargets
              onNextStep={handleNextStep}
              changeStep={changeStep}
              onBack={handleGoBack}
              createFlywheelMode={createFlywheelMode}
            />
          </SelectedStepProvider>
        )}
      </div>
    </>

  );
};