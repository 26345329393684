import { useTypedMutation } from "@roda/graphql/urql";

import { Subgoal } from "~/fragments/index";

import type { MutationGenqlSelection } from "@roda/graphql/genql";

type Params = NonNullable<MutationGenqlSelection["bulkUpdateSubgoalsAndProgress"]>["__args"];

export const useBulkUpdateSubgoalsAndProgress = () => {
  return useTypedMutation((vars: Params) => ({
    bulkUpdateSubgoalsAndProgress: {
      __args: vars,
      ...Subgoal
    }
  }));
};