import {
  Currency,
  FlywheelTemplateUnitTypeLabelEnum
} from "@roda/shared/types";
import { useMemo } from "react";

import { ProgressBar } from "~/components/flywheel/roda/ProgressBar";
import { EditFlywheelGoalMenu } from "~/components/flywheel-goal/EditFlywheelGoalMenu";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import dayjs from "~/utils/dayjs";
import { formatGoalNumber } from "~/utils/formatGoalNumber";
import { getUnitSymbol } from "~/utils/getUnitSymbol";

import type { Dayjs } from "dayjs";

interface FlywheelGoalTotalCardProps {
  selectedMonthStart: Dayjs;
}

export const FlywheelGoalTotalCard: React.FC<FlywheelGoalTotalCardProps> = ({ selectedMonthStart }) => {
  const { flywheel, flywheelCycleNotStarted } = useSelectedFlywheel();
  const flywheelGoal = flywheel?.latestFlywheelGoal;
  const flywheelGoalStart = dayjs(flywheelGoal?.fromDate);
  const flywheelGoalUnitSymbol = getUnitSymbol(flywheelGoal?.unitTypeLabel, flywheel?.currency as Currency || Currency.GBP, true);
  const updatesUntilSelectedMonth = useMemo(() => flywheelGoal?.subgoals?.flatMap(subgoal => subgoal.updates.filter(update => dayjs(update.startDate).isBefore(selectedMonthStart.add(1, "month")))), [ flywheelGoal?.subgoals, selectedMonthStart ]);

  // Figure out how much of the full target we'd expect by now for the goal to be on track
  // We figure out the difference between the start of the flywheel cycle and the selected month for this, and
  // add 1 because e.g. month 1 is 0 months away from month 1 - but we'd expect 1 month's worth of goal progress
  const targetToSelectedMonth = useMemo(() => (+(flywheelGoal?.goal || 0) / 12) * (selectedMonthStart.diff(flywheelGoalStart, "months") + 1), [
    flywheelGoal?.goal,
    flywheelGoalStart,
    selectedMonthStart
  ]);

  // If the flywheel goal is a percentage then get only the value of the latest update
  const cumulativeTotalToSelectedMonth = useMemo(() => {
    if (flywheelGoal?.unitTypeLabel === FlywheelTemplateUnitTypeLabelEnum.PERCENTAGE) {
      return updatesUntilSelectedMonth?.length ? Number(updatesUntilSelectedMonth[ updatesUntilSelectedMonth.length - 1 ].value) : 0;
    }

    return updatesUntilSelectedMonth?.reduce((prev, curr) => prev + Number(curr.value), 0) || 0;
  }, [ flywheelGoal?.unitTypeLabel, updatesUntilSelectedMonth ]);

  const currentMonthUpdate = useMemo(() => updatesUntilSelectedMonth?.find(update => dayjs(update.startDate).isSame(selectedMonthStart, "month")), [ selectedMonthStart, updatesUntilSelectedMonth ]);

  // If it's a percentage then compare to the flywheel goal target
  const isHealthy = useMemo(() => cumulativeTotalToSelectedMonth >= (flywheelGoal?.unitTypeLabel === FlywheelTemplateUnitTypeLabelEnum.PERCENTAGE ? +(flywheelGoal?.goal || 0) : targetToSelectedMonth), [
    cumulativeTotalToSelectedMonth,
    flywheelGoal?.goal,
    flywheelGoal?.unitTypeLabel,
    targetToSelectedMonth
  ]);

  return (
    <div
      className="w-full h-full flex flex-col justify-between p-5"
    >
      <div className="flex flex-col mb-2">
        <div className="flex flex-row justify-between items-start">
          <p className="text-sm font-semibold">{`Total ${flywheelGoal?.name.toLowerCase()}`}</p>

          <EditFlywheelGoalMenu />
        </div>

        <span className="font-bold text-brand-cold-metal-800 text-3xl md:text-4xl">
          {formatGoalNumber(cumulativeTotalToSelectedMonth || 0, getUnitSymbol(flywheelGoal?.unitTypeLabel, flywheel?.currency), {
            shouldCompact: true,
            stripTrailingZeros: true
          })}
        </span>

      </div>

      <div className="w-full flex-1 mt-2 mb-6 flex flex-center">
        <div className="w-full flex flex-col gap-4">
          <div className="flex flex-row justify-between font-semibold text-sm">
            <p>{flywheelGoal?.unitTypeLabel === FlywheelTemplateUnitTypeLabelEnum.PERCENTAGE ? "Main goal performance" : "Progress towards main goal"}</p>

            <p>{dayjs(flywheelGoal?.achieveBy).format("MMMM YYYY")}</p>
          </div>

          <ProgressBar
            target={Number(flywheelGoal?.goal)}
            progress={cumulativeTotalToSelectedMonth || 0}
            recentProgress={currentMonthUpdate ? Number(currentMonthUpdate.value) : 0}
            isHealthy={isHealthy}
            neutral={flywheelCycleNotStarted || !cumulativeTotalToSelectedMonth}
          />

          <div className="flex flex-row justify-between text-sm font-semibold">
            <p className={`${(flywheelCycleNotStarted || !cumulativeTotalToSelectedMonth) ? "text-brand-cold-metal-400" : isHealthy ? "text-brand-healthy-green-400" : "text-brand-attention-orange-400"}`}>{formatGoalNumber(cumulativeTotalToSelectedMonth || 0, flywheelGoalUnitSymbol, {
              shouldCompact: true,
              stripTrailingZeros: true
            })}
            </p>

            <p>{`Target ${formatGoalNumber(flywheelGoal?.goal ? Number(flywheelGoal?.goal) : 0, flywheelGoalUnitSymbol, {
              shouldCompact: true,
              stripTrailingZeros: true
            })}`}
            </p>
          </div>

        </div>
      </div>
    </div>
  );
};