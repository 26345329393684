import dayjs from "../dayjs";

import type { Dayjs } from "dayjs";

export const constructUtcDate = (date: string | Dayjs | Date) => {
  const dateObj = dayjs(date);
  const Y = dateObj.get("year");
  const M = dateObj.get("month");
  const D = dateObj.get("date");
  const utcDate = dayjs().year(Y).month(M).date(D);

  return utcDate.format("YYYY-MM-DD");
};