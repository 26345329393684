import dayjs from "~/utils/dayjs";

import type { Dayjs } from "dayjs";

/**
 * Get an array of dates for the start dates of the 12 months of the flywheel year
 * @param flywheelStartMonth the flywheelStartMonth
 * @param untilNow whether we should limit the array to dates until now - so a subset of the full 12 months
 * @returns an array of month start dates as strings
 */
export const getArrayOfFlywheelMonths = (flywheelStartMonth: Dayjs | null, untilNow?: boolean, nowDate?: Dayjs) => {
  // If we have a latest flywheel goal with at least one subgoal
  if (flywheelStartMonth) {
    const now = nowDate || dayjs();
    // Get the start of the month - a flywheel can start on any day but we need the 1st of each month here
    const flywheelStartOfMonth = flywheelStartMonth.startOf("month");
    const monthsToInclude = untilNow ? Math.abs(flywheelStartOfMonth?.diff(now, "months")) : 12;

    // Fill an array with up to 12 dates - the start dates of each month of the flywheel year
    // We get the flywheel start date from the start date of its first subgoal
    return Array(monthsToInclude).fill(0).map((_, i) => {
      const startOfMonth = flywheelStartMonth.add(i, "months").startOf("month");
      const startOfMonthString = startOfMonth.format(); // format will return a string

      return startOfMonthString;
    });
  }

  return [];
};