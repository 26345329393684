import Logo from "~/assets/logo.svg";

export const RodaLogoHeader = () => {
  return (
    <header className="flex flex-row gap-3 p-3 items-center border-brand-cold-metal-200 border-solid border-b w-full">
      {/* Roda logo */}
      <img
        src={Logo}
        alt="Logo"
      />
    </header>
  );
};