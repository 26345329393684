import { useTypedMutation } from "@roda/graphql/urql";

import { Flywheel } from "~/fragments/index";

import type { MutationGenqlSelection } from "@roda/graphql/genql";

type Params = NonNullable<MutationGenqlSelection["updateFlywheel"]>["__args"];

export const useUpdateFlywheel = () => {
  return useTypedMutation((vars: Params) => ({
    updateFlywheel: {
      __args: vars,
      ...Flywheel
    }
  }));
};