// Store local/session storage keys in here to avoid typos and duplication.

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { clearStorage } from "../utils/clearStorage";

export const STORAGE_KEYS = {
  LOCAL_STORAGE: {
    AUTH_TOKEN: "token",
    USER_SESSION: "user",
    FLYWHEEL: "flywheels",
    FLYWHEEL_REVIEW: "flywheel_review",
    CURRENT_FLYWHEEL_ID: "current_flywheel_id",
    ONBOARDING: "onboarding",
    METRIC: "metric",
    FLYWHEEL_GOAL: "flywheel_goal",
    REVIEW_REMINDER_DISMISSED_UNTIL: "review_reminder_dismissed_until"
  }
} as const;

// we can also group related keys if multiple need to be set/removed
// together.

/** see: {@link clearStorage} */

export const storageCleanupGroups = {
  logout: [
    STORAGE_KEYS.LOCAL_STORAGE.USER_SESSION,
    STORAGE_KEYS.LOCAL_STORAGE.AUTH_TOKEN,
    STORAGE_KEYS.LOCAL_STORAGE.FLYWHEEL,
    STORAGE_KEYS.LOCAL_STORAGE.FLYWHEEL_REVIEW,
    STORAGE_KEYS.LOCAL_STORAGE.METRIC,
    STORAGE_KEYS.LOCAL_STORAGE.FLYWHEEL_GOAL,
    STORAGE_KEYS.LOCAL_STORAGE.CURRENT_FLYWHEEL_ID,
    STORAGE_KEYS.LOCAL_STORAGE.ONBOARDING
  ]
} as const;
