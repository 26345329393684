import { enumUserRole } from "@roda/graphql/genql";
import {
  ReviewPeriod,
  UserRole
} from "@roda/shared/types";
import {
  useEffect,
  useMemo,
  useState
} from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "~/components/Button";
import { CompanyAvatar } from "~/components/CompanyAvatar";
import { Icon } from "~/components/Icon";
import { Spacer } from "~/components/Spacer";
import { routes } from "~/constants/routes";
import { useCustomiseFlywheelGoalDispatch } from "~/contexts/CustomiseFlywheelGoalContext/CustomiseFlywheelGoalContext";
import { useCustomiseMetricDispatch } from "~/contexts/CustomiseMetricContext/CustomiseMetricContext";
import { useFlywheelLoader } from "~/contexts/FlywheelLoaderContext";
import { useOnboardingDispatch } from "~/contexts/OnboardingContext/OnboardingContext";
import { useRodaAdminCompaniesContext } from "~/contexts/RodaAdminCompaniesContext";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useSideNavigation } from "~/contexts/SideNavigationContext";
import { useCurrentUser } from "~/contexts/UserContext";
import dayjs from "~/utils/dayjs";

import * as SideNav from "./components";

type SubMenuLevel = "admin" | "root" | "settings";

export const SideNavigation = () => {
  const { user } = useCurrentUser();
  const { currentCompany, setCurrentCompany } = useRodaAdminCompaniesContext();

  const {
    flywheel, setActiveFlywheelId, isCheckInDue, subgoalInReview, isReviewDue, checkInSubgoal, userHasMetrics, activeFlywheelReview
  } = useSelectedFlywheel();

  const { availableFlywheels } = useFlywheelLoader();
  const onboardingDispatch = useOnboardingDispatch();
  const customiseMetricDispatch = useCustomiseMetricDispatch();
  const customiseFlywheelGoalDispatch = useCustomiseFlywheelGoalDispatch();
  const isAdmin = user?.role === enumUserRole.ADMIN;
  const isRodaAdmin = user?.role === enumUserRole.RODA_ADMIN;
  const isMetricOwner = user?.isMetricOwner;
  // In this case, we count a metric owner as someone who owns a metric for the review quarter
  const isMetricOwnerForReview = !!activeFlywheelReview?.metrics?.find(metric => metric.owner?.id === user?.id);
  const isFlywheelGoalOwner = flywheel?.latestFlywheelGoal?.ownerId == user?.id;
  const { hideMainSideNav, setHideMainSideNav } = useSideNavigation();
  const [ subMenuLevel, _setSubMenuLevel ] = useState<SubMenuLevel>(isRodaAdmin && !flywheel ? "admin" : "root");
  // We don't want to animate the first render
  const [ hasChangedSubMenuLevel, setHasChangedSubMenuLevel ] = useState(false);
  const navigate = useNavigate();
  // Permissions to check-in
  const hasAccessToViewCheckIn = isAdmin || isRodaAdmin || isMetricOwner;
  // Permissions to review
  const hasAccessToReview = isAdmin || isRodaAdmin || isMetricOwnerForReview || isFlywheelGoalOwner;
  // Check if we're at the start of the quarter
  const twoWeeksInQuarter = dayjs(checkInSubgoal?.startDate).add(2, "weeks");
  const isAtStartOfQuarter = dayjs().isBetween(dayjs(checkInSubgoal?.startDate), twoWeeksInQuarter, null, "[]");
  // Check that there's a subgoal in review AND if it's at the start of the quarter then check if there's still a review due
  const checkExtendedDeadlineReviewDue = !!subgoalInReview && (isAtStartOfQuarter ? isReviewDue : true);

  // Check if we should show the review nav item
  const showReview = useMemo(() => activeFlywheelReview ? hasAccessToReview && (isAtStartOfQuarter ? checkExtendedDeadlineReviewDue : true) : false, [
    checkExtendedDeadlineReviewDue,
    activeFlywheelReview,
    hasAccessToReview,
    isAtStartOfQuarter
  ]);

  // Show true if there's not a subgoalInReview yet - means that they haven't set it up -> review is due
  const showReviewDueBanner = activeFlywheelReview?.reviewPeriod === ReviewPeriod.NEXT_YEAR ? subgoalInReview === null ? true : isReviewDue : isReviewDue;

  const setSubMenuLevel: typeof _setSubMenuLevel = level => {
    _setSubMenuLevel(level);
    setHasChangedSubMenuLevel(true);
  };

  useEffect(() => {
    if (isRodaAdmin && subMenuLevel !== "settings") {
      if (currentCompany) {
        setSubMenuLevel("root");
      } else {
        setSubMenuLevel("admin");
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isRodaAdmin, currentCompany ]);

  const navigateToDashboard = () => {
    navigate(routes.dashboard(currentCompany ? currentCompany.id : undefined));
  };

  const navigateToCreateFlywheel = () => {
    navigate(routes.createFlywheel);
  };

  const navigateToCustomiseFlywheel = () => {
    navigate(routes.customiseFlywheel(currentCompany ? currentCompany.id : undefined));
  };

  return (
    !hideMainSideNav ? (
      <aside
        className={`w-[275px] sticky top-0 shrink-0 bg-brand-cold-metal-50 border-r-brand-cold-metal-200 border-solid border-r flex flex-col gap-px ${subMenuLevel === "root" ? "[&>*]:slide-in-from-left-4" : "[&>*]:slide-in-from-right-4"} ${!hasChangedSubMenuLevel && "[&>*]:!animate-none"} max-h-[100dvh]`}
        key={subMenuLevel}
      >

        {/** Everyone should see a back button everywhere but the admin & root nav */}
        {subMenuLevel !== "admin" && subMenuLevel !== "root" && (
          <>
            <SideNav.BackButtonHeader
              label={subMenuLevel}
              onClick={() => {
                navigate(routes.home);
                setSubMenuLevel("root");
              }}
            />
          </>
        )}

        {(() => {
          switch (subMenuLevel) {
            case "settings": return (
              <>
                <SideNav.Gap />

                {!isRodaAdmin && (
                  <>
                    <SideNav.Subheading heading="Account" />

                    <SideNav.NavItem
                      label="Profile"
                      icon={<Icon.Profile className="w-4.5 h-4.5" />}
                      href={routes.account}
                    />

                  </>
                )}

                {(isRodaAdmin || isAdmin) && (
                  <>
                    <SideNav.Subheading heading="Organisation" />

                    <SideNav.NavItem
                      label="Company & Billing"
                      icon={<Icon.Company className="w-4.5 h-4.5" />}
                      href={routes.organisationSettings(currentCompany ? currentCompany.id : undefined)}
                    />

                    <SideNav.NavItem
                      label="Members"
                      icon={<Icon.Users className="w-4.5 h-4.5" />}
                      href={routes.members(currentCompany ? currentCompany.id : undefined)}
                    />

                  </>
                )}

              </>
            );

            case "admin": return (
              <>
                <SideNav.RodaLogoHeader />

                <SideNav.Gap />

                <SideNav.NavItem
                  label="Company Management"
                  icon={<Icon.SettingsCog className="w-4.5 h-4.5" />}
                  onClick={() => setSubMenuLevel("admin")}
                  href={routes.rodaAdminOrganisationManagement}
                />

                <SideNav.Gap />

                <SideNav.Subheading heading="Account" />

                <SideNav.NavItem
                  label="Profile"
                  icon={<Icon.Profile className="w-4.5 h-4.5" />}
                  href={routes.account}
                />

                <Spacer />

                <SideNav.Gap />

                <SideNav.Footer />
              </>
            );

            case "root":
            default: return (
              <>
                <SideNav.Header
                  onBack={() => {
                    setCurrentCompany(null);
                    setActiveFlywheelId(null);
                    setSubMenuLevel("admin");
                    navigate(routes.rodaAdminOrganisationManagement);
                  }}
                />

                <SideNav.Gap />

                {availableFlywheels && (
                  <SideNav.ExpandableNavItem
                    label="Flywheels"
                    icon={<Icon.Refresh className="w-4.5 h-4.5" />}
                    onClick={navigateToDashboard}
                    expandableOptions={[
                      ...availableFlywheels.map(fl => (
                        <SideNav.ExpandedNavItemOption
                          key={`availableFlywheel_${fl.id}`}
                          label={fl.name}
                          onClick={() => {
                            setActiveFlywheelId(fl.id);
                            navigateToDashboard();
                          }}
                          isSelected={flywheel?.id === fl.id}
                          editAction={(isAdmin || isRodaAdmin) ? () => {
                            setActiveFlywheelId(fl.id);
                            navigateToCustomiseFlywheel();
                          } : undefined}
                        />
                      )),
                      isAdmin ? (
                        <SideNav.ExpandedNavItemOption
                          key="add_flywheel_option"
                          label="Add new flywheel"
                          onClick={() => {
                            customiseMetricDispatch({ type: "RESET" });
                            customiseFlywheelGoalDispatch({ type: "RESET" });
                            onboardingDispatch({ type: "RESET" });

                            onboardingDispatch({
                              type: "SET_STEP",
                              step: "flywheel_intro"
                            });
                            navigateToCreateFlywheel();
                          }}
                          isSelected={false}
                          icon={<Icon.PlusCircle className="w-4 text-brand-cold-metal-600" />}
                        />
                      ) : <></>
                    ]}
                  />
                )}

                {hasAccessToViewCheckIn && (
                  <SideNav.NavItem
                    label="Check-in"
                    icon={<Icon.BarChart className="w-4.5 h-4.5" />}
                    href={routes.checkIn(currentCompany ? currentCompany.id : undefined)}
                    banner={isCheckInDue && userHasMetrics ? { bannerText: "Check-in due" } : undefined}
                  />
                )}

                {/** Show nav item if
                * user has access to review
                * there's a subgoal in review
                * it's an extended deadline and there's a review due
                */}
                {showReview && (
                  <SideNav.NavItem
                    label={`Next ${activeFlywheelReview?.reviewPeriod === ReviewPeriod.NEXT_YEAR ? "year" : "quarter"} review`}
                    icon={<Icon.Calendar className="w-4.5 h-4.5" />}
                    href={routes.review(currentCompany ? currentCompany.id : undefined)}
                    banner={showReviewDueBanner ? { bannerText: "Review due" } : undefined}
                  />
                )}

                <SideNav.NavItem
                  label="Settings"
                  icon={<Icon.SettingsCog className="w-4.5 h-4.5" />}
                  onClick={() => {
                    setSubMenuLevel("settings");

                    if (user?.role === UserRole.RODA_ADMIN) {
                      navigate(routes.organisationSettings(currentCompany ? currentCompany.id : undefined));
                    } else {
                      navigate(routes.account);
                    }
                  }}
                />

                <Spacer />

                {(isRodaAdmin || isAdmin) && (
                  <SideNav.NavItem
                    label="Invite team members"
                    icon={<Icon.InviteUsers className="w-4.5 h-4.5" />}
                    href={routes.members(currentCompany ? currentCompany.id : undefined)}
                  />
                )}

                <SideNav.NavItem
                  label="Getting started"
                  icon={<Icon.HelpCircle className="w-4.5 h-4.5" />}
                  href={routes.gettingStarted}
                  onClick={() => setHideMainSideNav(true)}
                />

                <SideNav.Gap />

                <SideNav.Footer />
              </>
            );
          }
        })()}
      </aside>
    ) : (
      <>
        <div className={`items-center p-3 w-[55px] shrink-0 bg-brand-cold-metal-50 border-r-brand-cold-metal-200 border-solid border-r flex flex-col gap-px ${subMenuLevel === "root" ? "[&>*]:slide-in-from-left-4" : "[&>*]:slide-in-from-right-4"} ${!hasChangedSubMenuLevel && "[&>*]:!animate-none"} max-h-[100dvh]`}>
          <div
            className="cursor-pointer"
            onClick={() => {
              setHideMainSideNav(false); // Show the main nav bar
              navigate(routes.home); // Navigate user back to their home page
            }}
          >
            <CompanyAvatar
              companyName={currentCompany ? currentCompany.name : user?.company?.name || ""}
              logoS3Key={user?.company?.logoS3Key ?? undefined}
            />
          </div>

        </div>

        <div className="absolute left-12 top-4 pl-4 z-20">
          <Button
            title="Back to Flywheel"
            iconComponent={<Icon.ChevronLeft />}
            className="bg-brand-cold-metal-200 text-brand-cold-metal-800 font-normal px-2 py-1 text-xs lg:px-2"
            onClick={() => {
              navigate(routes.dashboard(currentCompany ? currentCompany.id : undefined));
              setHideMainSideNav(false);
            }}
          />
        </div>
      </>

    )
  );
};
