import { removeEmojis } from "@roda/shared/utils";
import { nanoid } from "nanoid";

import { Button } from "~/components/Button";
import { Input } from "~/components/form";
import { Icon } from "~/components/Icon";
import { PillMessage } from "~/components/PillMessage";
import { Spacer } from "~/components/Spacer";
import {
  useCustomiseFlywheelGoalDispatch,
  useCustomiseFlywheelGoalState
} from "~/contexts/CustomiseFlywheelGoalContext/CustomiseFlywheelGoalContext";
import { useIsMobile } from "~/hooks/useIsMobile";

export const CustomiseFlywheelTemplate: React.FC<{showButtons?: boolean}> = ({ showButtons = true }) => {
  const isMobile = useIsMobile();
  const dispatch = useCustomiseFlywheelGoalDispatch();
  const { flywheelTemplate } = useCustomiseFlywheelGoalState();

  return (
    <div className="h-full w-full">

      <div
        className="flex pt-5 flex-col gap-y-5 flex-1"
      >
        <div className="flex flex-col gap-y-1">
          <Input
            value={flywheelTemplate?.name || ""}
            onChange={e =>
              dispatch({
                type: "SET_SELECTED_FLYWHEEL_TEMPLATE",
                flywheelTemplate: {
                  id: 1,
                  steps: [],
                  ...flywheelTemplate,
                  name: removeEmojis(e.target.value)
                }
              })}
            label="Flywheel name"
            name="name"
            min={1}
            maxLength={100}
            placeholder="E.g. Growth"
            inputMode="text"
            autoComplete="off"
            autoCorrect="off"
          />

          <div className="flex text-brand-cold-metal-700 font-semibold text-base pt-6 flex-row items-center justify-between">
            <h3>Customise steps</h3>

            <button
              className={`${flywheelTemplate?.steps.length === 6 ? "opacity-25" : "opacity-100"}`}
              disabled={flywheelTemplate?.steps.length === 6}
              type="button"
              onClick={() => {
                if (flywheelTemplate?.steps) {
                  dispatch({
                    type: "SET_SELECTED_FLYWHEEL_TEMPLATE",
                    flywheelTemplate: {
                      ...flywheelTemplate,
                      steps: [
                        ...flywheelTemplate.steps,
                        {
                          id: nanoid(),
                          name: `Step ${flywheelTemplate.steps.length + 1}`,
                          order: flywheelTemplate.steps.length + 1,
                          description: "",
                          metrics: []
                        }
                      ]
                    }
                  });
                } else {
                  dispatch({
                    type: "SET_SELECTED_FLYWHEEL_TEMPLATE",
                    flywheelTemplate: {
                      ...flywheelTemplate,
                      steps: [
                        {
                          id: nanoid(),
                          name: "Step 1",
                          order: 1,
                          description: "",
                          metrics: []
                        }
                      ]
                    }
                  });
                }
              }}
            >
              <Icon.PlusSquare
                size={20}
              />
            </button>
          </div>

          {flywheelTemplate?.steps.some(step => !step.name) && (
            <div className="py-4">
              <PillMessage
                text="We've started you off with 5 steps - please add or delete as you need. You can change the order of steps by clicking the arrow buttons."
                icon={<Icon.InfoCircle />}
              />
            </div>
          )}

          <div>

            {flywheelTemplate?.steps.map((step, idx) => {
              const canGoDown = idx < flywheelTemplate.steps.length - 1;
              const canGoUp = idx > 0;

              return (
                <div
                  key={`${step.id}`}
                  className="relative pt-4"
                >
                  <Input
                    value={step.name}
                    onChange={e => {
                      dispatch({
                        type: "SET_SELECTED_FLYWHEEL_TEMPLATE",
                        flywheelTemplate: {
                          ...flywheelTemplate,
                          steps: flywheelTemplate.steps.map(original => {
                            if (original.id === step.id) {
                              return ({
                                ...step,
                                name: removeEmojis(e.target.value),
                                description: "" // We reset description when overriding a templated step
                              });
                            } else {
                              return original;
                            }
                          })
                        }
                      });
                    }}
                    label={`Step ${idx + 1}`}
                    placeholder={step.name || `Step ${step.order}`}
                    max={16}
                    maxLength={16}
                    iconComponent={(
                      <div className="flex flex-row gap-2 items-center">
                        <button
                          className={`${!canGoUp ? "opacity-25" : "opacity-100"}`}
                          tabIndex={-1}
                          type="button"
                          disabled={!canGoUp}
                          onClick={() => {
                            dispatch({
                              type: "MOVE_STEP_UP",
                              stepIdx: idx
                            });
                          }}
                        >
                          <Icon.ChevronUp />
                        </button>

                        <button
                          className={`${!canGoDown ? "opacity-25" : "opacity-100"}`}
                          tabIndex={-1}
                          type="button"
                          disabled={!canGoDown}
                          onClick={() => {
                            dispatch({
                              type: "MOVE_STEP_DOWN",
                              stepIdx: idx
                            });
                          }}
                        >
                          <Icon.ChevronDown />
                        </button>

                        <button
                          tabIndex={-1}
                          type="button"
                          className={`${flywheelTemplate.steps.length === 3 ? "opacity-25" : "opacity-100"}`}
                          disabled={flywheelTemplate.steps.length === 3}
                          onClick={() => {
                            dispatch({
                              type: "SET_SELECTED_FLYWHEEL_TEMPLATE",
                              flywheelTemplate: {
                                ...flywheelTemplate,
                                steps: flywheelTemplate.steps.filter(original => {
                                  if (original.id === step.id) {
                                    return false;
                                  } else {
                                    return true;
                                  }
                                })
                              }
                            });
                          }}
                        >
                          <Icon.Bin />
                        </button>
                      </div>
                    )}

                  />

                </div>
              );
            })}

          </div>
        </div>

        {flywheelTemplate?.steps.length === 6 && (
          <PillMessage
            text="A flywheel can contain at most 6 steps"
            icon={<Icon.InfoCircle />}
          />
        )}

        {flywheelTemplate?.steps.length === 3 && (
          <PillMessage
            text="A flywheel must contain at least 3 steps."
            icon={<Icon.InfoCircle />}
          />
        )}

        {showButtons && (

          <div className={`flex my-5 ${isMobile ? "flex-col flex-1" : "flex-row"} justify-between`}>
            {isMobile && (<Spacer />)}

            <div className={`flex gap-x-3 self-start ${isMobile ? "flex-col w-full gap-3" : "flex-row"}`}>

              <Button
                title="Discard"
                type="button"
                className="bg-brand-cold-metal-200 hover:contrast-75 text-brand-cold-metal-900"

                onClick={() => {
                  dispatch({ type: "RESET" });
                }}
              />

              <Button
                title="Done"
                type="button"
                disabled={flywheelTemplate?.steps.some(step => !step.name.length)}
                className={`px-10 ${isMobile ? "order-first" : ""}`}
                onClick={() => {
                  dispatch({ type: "FINISH" });
                }}
              />

            </div>

          </div>
        )}

      </div>

    </div>
  );
};