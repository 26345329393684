import { FlywheelTemplateUnitTypeLabelEnum } from "@roda/shared/types";

import type { FlywheelTemplateUnit } from "@roda/shared/types";

interface Params {
  metricUnitTypeLabel: FlywheelTemplateUnit["typeLabel"];
  metricName: string;
}
/**
 * Util function for constructing the information for a metric unit that's displayed under the target for it
 * e.g "Minutes for content engagement"
 * ${typeLabel} of ${unitName}
 * @param metricUnit
 */
export const getMetricUnitInfoTitle = (params: Params) => {
  const { metricUnitTypeLabel, metricName } = params;
  let typeLabel;

  switch (metricUnitTypeLabel as FlywheelTemplateUnitTypeLabelEnum) {
    case FlywheelTemplateUnitTypeLabelEnum.HOURS_DAYS_WEEKS:
      typeLabel = `Number of ${FlywheelTemplateUnitTypeLabelEnum.HOURS_DAYS_WEEKS}`;
      break;
    case FlywheelTemplateUnitTypeLabelEnum.DAYS_WEEKS_MONTHS:
      typeLabel = `Number of ${FlywheelTemplateUnitTypeLabelEnum.DAYS_WEEKS_MONTHS}`;
      break;
    case FlywheelTemplateUnitTypeLabelEnum.CURRENCY:
      typeLabel = "Cost";
      break;
    default:
      typeLabel = metricUnitTypeLabel;
      break;
  }

  return `${typeLabel} for ${metricName.toLowerCase()}`;
};