import { twMerge } from "tailwind-merge";

import type React from "react";
import type {
  DetailedHTMLProps,
  HTMLAttributes
} from "react";

/**
Used within flex containers to create predictable spacing between elements that
are easier read and reasoned about (less surprises when refactoring/adding new
sibling items, versus its equivalent `justify-between` CSS).
 */
export const Spacer: React.FC<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({ className = "", ...props }) => {
  return (
    <div
      {...props}
      className={twMerge("flex-1", className)}
    />
  );
};