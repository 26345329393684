import clsx from "clsx";
import { FiChevronRight } from "react-icons/fi";

import { BaseFlywheel } from "~/components/flywheel/base/BaseFlywheel";
import { BaseFlywheelStep } from "~/components/flywheel/base/BaseFlywheelStep";
import { MetricStatusDots } from "~/components/flywheel/roda/MetricStatusDots";
import { withStepTheme } from "~/components/flywheel/roda/RodaFlywheel";
import { HealthStatus } from "~/components/flywheel/roda/types";
import { InfoTooltip } from "~/components/InfoTooltip";
import type { CustomFlywheelTemplate } from "~/contexts/CustomiseFlywheelGoalContext/flywheel-goal-reducer";
import { useIsMobile } from "~/hooks/useIsMobile";
import { getDynamicStepOffset } from "~/utils/getDynamicStepOffset";
import { getHealthStatusColour } from "~/utils/getHealthStatusColour";

import type { Step } from "@roda/graphql/genql";

type IntroFlywheelProps = {
  size?: NonNullable<React.ComponentProps<typeof BaseFlywheel>["size"]>;
  displayMode?: DisplayModes;
  innerContent?: JSX.Element;
  flywheel: CustomFlywheelTemplate
};

// Enum for display modes
export enum DisplayModes {
  OVERVIEW = "overview",
  STEPS = "steps",
  GOAL = "goal",
  METRICS = "metrics",
  HEALTH_INDICATORS = "healthIndicators",
}

/**
 * The IntroFlywheel component is a blank visual representation of the flywheel, showcasing the different features of it.
 * By default only the flywheel is rendered, with no additional content, you can use the displayMode prop to render additional content along with innerContent.
 * Optional custom content can be rendered inside the flywheel by passing a React component to the innerContent prop.
 *
 * @param size - Optional: The diameter of the flywheel, defaulting to 400 pixels.
 * @param displayMode - Optional: ENUM DisplayModes, The current mode of the flywheel which determines the thematic styling and displayed elements, each one stacks the previous on top of it.
 * @param innerContent - Optional: A function returning JSX content to be rendered inside the flywheel, centered and scaled appropriately.
 * @returns {React.ReactElement} - A component that renders a flywheel with steps that visually represents different statuses like waiting, healthy, or needs-attention.
 * @example
 * <IntroFlywheel
 *   size={500}
 *   displayMode={DisplayModes.HEALTH_INDICATORS}
 *   innerContent={CustomContent}
 * />
 */
export const IntroFlywheel: React.FC<IntroFlywheelProps> = ({
  size = 400, displayMode = DisplayModes.OVERVIEW, innerContent, flywheel
}) => {
  const isMobile = useIsMobile();
  const stepOffset = getDynamicStepOffset();

  return (
    <>
      <BaseFlywheel
        size={size}
        thickness={2}
        stepsAngleOffset={0}
        stroke="#667085"
        renderInnerContent={innerContent}
        renderSeparator={(
          <FiChevronRight
            className="text-brand-sub-text [&_*]:[vector-effect:non-scaling-stroke]"
            strokeWidth={1.5}
            style={{
              transform: "rotate(calc(var(--angle) + 90deg))",
              width: size / 9,
              height: size / 9
            }}
          />
        )}
      >
        {flywheel.steps?.map((step, idx) => {
          const stepStatus = step.health || !!step.name ? HealthStatus[ "Healthy" ] : HealthStatus[ "CheckInDue" ];

          return (
            <BaseFlywheelStep
              // eslint-disable-next-line react/no-array-index-key
              key={`${step.order}-${idx}`}
              size={7}
              childOffset={stepOffset}
              stepContainerClassname={displayMode !== DisplayModes.STEPS ? clsx("[animation-delay:calc(500ms+200ms+var(--index)*100ms)]", flywheel?.steps ? "animate-in fade-in-0 fill-mode-both duration-1000" : "opacity-0") : "animate-in fade-in-0 fill-mode-both duration-1000"}
              circle={{
                strokeColor: stepStatus === "check-in-due" ? "#D1E0FF" : stepStatus === "healthy" ? "#DCFAE6" : "#FEE6C7",
                strokeWidth: 5,
                className: stepStatus === "check-in-due" ? "fill-[#3779FF]" : stepStatus === "healthy" ? "fill-[#17B26A]" : "fill-[#F78512]"
              }}
              ring={{
                offset: 6,
                strokeWidth: 4,
                strokeColor: "#F9FAFB",
                fill: stepStatus === "check-in-due" ? "#A6C3FF" : stepStatus === "healthy" ? "#A9EFC5" : "#FECD89",
                className: ""
              }}
            >
              <div className="flex flex-col items-center">
                <div
                  className="h-3 transition-opacity duration-500 ease-in-out"
                  style={{ opacity: (displayMode === DisplayModes.METRICS || displayMode === DisplayModes.HEALTH_INDICATORS) ? 1 : 0 }}
                >
                  <div
                    style={displayMode === DisplayModes.HEALTH_INDICATORS ? withStepTheme(getHealthStatusColour(stepStatus as HealthStatus)) : withStepTheme("green")}
                    className="flex gap-1"
                  >
                    <MetricStatusDots
                      step={step as unknown as Step}
                      metricStatus={displayMode !== DisplayModes.HEALTH_INDICATORS ? HealthStatus.Waiting : step.metrics[ 0 ].health as HealthStatus}
                    />
                  </div>
                </div>

                <InfoTooltip
                  slideDirection="top"
                  enabled={!!step.description}
                  text={step.description}
                >
                  <span
                    className={clsx("flex items-center gap-1 rounded-2xl text-sm  transition-opacity opacity-0 font-semibold duration-500 ease-in-out w-full", isMobile ? "h-[1.5vh] p-2.5" : "h-6 py-3.5 px-5", stepStatus === "check-in-due" ? "bg-brand-check-in-due-100 text-brand-check-in-due-600" : stepStatus === "healthy" ? "bg-green-100 text-green-600" : "bg-brand-attention-orange-100 text-brand-attention-orange-600 ", {
                      "opacity-100": !displayMode || [
                        DisplayModes.STEPS,
                        DisplayModes.HEALTH_INDICATORS,
                        DisplayModes.METRICS
                      ].some(d => d === displayMode)
                    })}

                  >
                    {/* This text below creates the correct dummy step bubble size*/}

                    <p className="select-none">{step.name}</p>
                  </span>
                </InfoTooltip>

              </div>
            </BaseFlywheelStep>
          );
        }
        )}
      </BaseFlywheel>
    </>
  );
};
