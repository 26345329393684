import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import clsx from "clsx";
import {
  useState,
  useRef,
  forwardRef
} from "react";
import { useOnClickOutside } from "usehooks-ts";

import {
  InputLabel,
  SelectInputDescription
} from "~/components/form";
import { Icon } from "~/components/Icon";
import { cn } from "~/utils/cn";

import type React from "react";
import type {
  ForwardedRef,
  MutableRefObject,
  ReactNode
} from "react";

/**
 * A custom SelectInput component with label, error message, and optional custom rendering for options.
 * VERY IMPORTANT, to ensure error state is managed correctly, use the clearErrors function from React Hook Form.
 * This can be passed to the component like this: clearFormError={() => clearErrors("fieldName")}
 *
 * @param value - Value of the select input.
 * @param onValueChange - Function to call when the value changes.
 * @param options - Array of options to display in the dropdown.
 * @param label - Optional: Label for the select input.
 * @param placeholder - Placeholder to display when no value is selected.
 * @param hasError - Optional: Whether the input has an error.
 * @param errorMessage - Optional: Error message to display. Defaults to "Please select an option".
 * @param clearFormError - Optional: Function to clear form error.
 * @param renderOptionLabel - Optional: Function to render custom labels for options.
 * @param className - Optional: Class name to apply to the select input parent div container.
 * @param optionsDescriptions - Optional: Array of descriptions for the options.
 * @returns {React.ReactElement} - Rendered select input component.
 */
interface SelectInputProps extends SelectInputDropdownProps {
  onValueChange: (value: string) => void;
  label?: string;
  placeholder: string;
  hasError?: boolean;
  renderButton?: React.ReactNode;
  errorMessage?: string;
  clearFormError?: () => void;
  className?: string;
  disabled?: boolean;
  tooltipInfo?: string;
  /** apply a subtle fade to overflowing (truncated) inner content */
  fadeInnerEnd?: boolean;
}

export const SelectInput = forwardRef((
  {
    value,
    onValueChange,
    options,
    label,
    renderButton,
    placeholder,
    hasError,
    errorMessage = "Please select an option",
    clearFormError,
    renderOptionLabel,
    renderOptionBadge,
    fadeInnerEnd = true,
    width,
    className,
    disabled,
    tooltipInfo,
    optionsDescriptions,
    comingSoonOptions
  }: SelectInputProps,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const [ isDropdownOpen, setIsDropdownOpen ] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleSelection = (option: string) => {
    onValueChange?.(option);
    setIsDropdownOpen(false);
    clearFormError && clearFormError();
  };

  const displayedValue = hasError ? errorMessage : (value ? (renderOptionLabel ? renderOptionLabel(value) : value) : placeholder);

  useOnClickOutside(dropdownRef, () => setIsDropdownOpen(false));

  const showInnerEndFade = fadeInnerEnd && label && typeof displayedValue === "string" && !hasError && !renderOptionBadge;
  const fadeCSSProp = "linear-gradient(to right, black calc(100% - 4ch), transparent calc(100% - 0.5ch))";

  return (
    <DropdownMenu.Root open={isDropdownOpen}>
      <div
        className={clsx("flex flex-col items-start gap-2 select-none", className)}
        ref={ref}
      >
        {label && (
          <InputLabel
            htmlFor={label}
            extraInfo={tooltipInfo}
          >
            {label}
          </InputLabel>
        )}

        <div
          className="relative w-full"
          style={{ transition: "z-index 500ms" }}
        >
          <DropdownMenu.Trigger asChild>
            <div
              className={`w-full text-sm p-2 border rounded-lg text-left cursor-pointer flex justify-between items-center text-brand-cold-metal-500
          ${hasError ? "bg-brand-error-red-50 border-brand-error-red-300 text-brand-error-red-600" : "bg-white border-brand-cold-metal-200"}
        ${disabled ? "opacity-40 pointer-events-none" : ""}
        `}
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              <div className="flex-1 min-w-0 relative">
                <div className="flex gap-x-4 items-center">
                  <div
                    className="truncate flex-1"
                    // fade out overflowing text more gracefully
                    style={showInnerEndFade && ({
                      WebkitMaskImage: fadeCSSProp,
                      maskImage: fadeCSSProp
                    }) || {}}
                  >
                    {displayedValue}
                  </div>

                  {value && renderOptionBadge && (
                    <div className="ml-10">
                      {renderOptionBadge(value)}
                    </div>
                  )}
                </div>
              </div>

              <span className="ml-2">
                {hasError ? (
                  <Icon.Error
                    size={20}
                    className="text-brand-error-red-600"
                  />
                ) : (
                  <Icon.ChevronDown
                    size={20}
                    className={`text-brand-cold-metal-500 ${isDropdownOpen ? "rotate-180" : "rotate-0"}`}
                    style={{ transition: "transform 500ms cubic-bezier(0.86, 0.14, 0.06, 0.92)" }}
                  />
                )}
              </span>

            </div>
          </DropdownMenu.Trigger>

          {/* DROPDOWN */}
          <DropdownMenu.Portal>
            <DropdownMenu.Content
              className="z-[51]"
              id="dropdownMenu"
              side="bottom"
              align="center"
            >
              <SelectInputDropdown
                isOpen={isDropdownOpen}
                options={options}
                width={width}
                renderButton={renderButton}
                value={value}
                ref={dropdownRef}
                handleSelection={handleSelection}
                renderOptionLabel={renderOptionLabel}
                renderOptionBadge={renderOptionBadge}
                optionsDescriptions={optionsDescriptions}
                comingSoonOptions={comingSoonOptions}
              />
            </DropdownMenu.Content>
          </DropdownMenu.Portal>

        </div>
      </div>
    </DropdownMenu.Root>
  );
}
);

interface SelectInputDropdownUniqueProps {
  isOpen: boolean;
  handleSelection: (option: string) => void;
}

interface SelectInputDropdownProps {
  options: string[];
  value: string;
  renderButton?: React.ReactNode;
  renderOptionLabel?: (value: string) => ReactNode | string;
  renderOptionBadge?: (value: string) => ReactNode | null;
  optionsDescriptions?: string[];
  /**
   * Width of the dropdown menu; scale to the min-width of the items, or
   * strictly the container (item triggering dropdown).
   */
  width?: "contents" | "container";
  comingSoonOptions?: string[];
}

export const SelectInputDropdown = forwardRef(({
  isOpen,
  options,
  value,
  handleSelection,
  renderOptionLabel,
  renderOptionBadge,
  optionsDescriptions,
  renderButton,
  comingSoonOptions,
  width = "container"
}: SelectInputDropdownProps & SelectInputDropdownUniqueProps,
_ref: ForwardedRef<HTMLDivElement>) => {
  const ref = _ref as MutableRefObject<HTMLDivElement>;

  return (
    <div
      ref={ref}
      className={cn(
        "mt-2 w-[--radix-popper-anchor-width] bg-white rounded-md shadow-lg overflow-y-auto transition-all duration-500 ease-out flex flex-col",
        isOpen ? "border border-brand-cold-metal-200" : "border border-transparent",
        width === "container" ?
          "w-[--radix-popper-anchor-width]" :
          "min-w-[--radix-popper-anchor-width] w-[min(fit-content,var(--radix-popper-available-width))]"
      )}
      style={{ maxHeight: "clamp(300px,calc(var(--radix-popper-available-height)*0.9),600px)" }}
    >
      <div
        className={cn(
          "relative",
          "w-[--radix-popper-anchor-width] scrollbar-thin [scrollbar-color:lightgray_transparent] bg-white rounded-md  overflow-y-auto transition-all duration-500 ease-out flex flex-col",
          width === "container" ?
            "w-[--radix-popper-anchor-width]" :
            "min-w-[--radix-popper-anchor-width] w-[min(fit-content,var(--radix-popper-available-width))]"
        )}
        style={{ maxHeight: "clamp(300px,calc(var(--radix-popper-available-height)*0.9),600px)" }}
      >
        {options.map((option, idx) => (
          <button
            type="button"
            ref={r => {
              value === option && r?.scrollIntoView({
                block: "nearest",
                inline: "nearest",
                behavior: "smooth"
              });
            }}
            key={option}
            className={`p-2 scroll-m-10 hover:bg-brand-cold-metal-100 outline-0 focus-visible:[&:not(&:hover)]:outline-2 cursor-pointer rounded-lg mx-2 my-1 first:mt-2 last:mb-2 text-sm text-brand-cold-metal-500 flex flex-col grow text-left gap-y-1 ${value === option ? "bg-brand-cold-metal-100" : "bg-white"}`}
            onClick={() => handleSelection(option)}
          >
            <div className="flex flex-row justify-between gap-2 items-center">
              {renderOptionLabel ? renderOptionLabel(option) : option}

              {renderOptionBadge && renderOptionBadge(option)}
            </div>

            {/** Render the associated optionDescription if there's one */}
            {optionsDescriptions && <SelectInputDescription description={optionsDescriptions[ idx ] || ""} />}
          </button>
        ))}

        {comingSoonOptions?.map(comingSoonOption => (
          <div
            key={comingSoonOption}
            className="p-2 bg-white pointer-events-none rounded-lg m-2 text-sm text-brand-cold-metal-300 flex flex-row justify-between items-center *:min-w-0"
          >
            {comingSoonOption}

            <div className="bg-brand-cold-metal-100 text-brand-cold-metal-500 rounded-2xl px-3 py-1 text-xs shrink-0">Coming soon</div>
          </div>
        ))}

        <div className="sticky w-full bottom-0 inset-x-0 p-2 z-10 bg-gradient-to-t to-white/0 from-white" />

      </div>

      {renderButton && (
        <>

          <div className=" ">
            <div className="w-full flex flex-col">
              {renderButton}
            </div>
          </div>

        </>
      )}
    </div>
  );
});
