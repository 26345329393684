import tailwindBrandColours from "@roda/shared/tailwindBrandColours";
import {
  FlywheelTemplateUnitTypeLabelEnum,
  FlywheelTemplateReportingWindowTimingEnum
} from "@roda/shared/types";
import { getDateRangeWeekBounds } from "@roda/shared/utils/getDateRangeWeekBounds";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip
} from "chart.js/auto";
import clsx from "clsx";
import { useMemo } from "react";
import { Chart } from "react-chartjs-2";

import { ProgressBar } from "~/components/flywheel/roda/ProgressBar";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import type { GetFlywheelMetricData } from "~/hooks/flywheel/use-get-flywheel";
import { backgroundBarPlugin } from "~/utils/barBackgroundPlugin";
import dayjs from "~/utils/dayjs";
import { formatGoalNumber } from "~/utils/formatGoalNumber";
import { getBarColour } from "~/utils/getBarColour";
import { getUnitSymbol } from "~/utils/getUnitSymbol";
import { getWeekMetricTarget } from "~/utils/getWeekMetricTarget";
import { getWeekMetricUpdate } from "~/utils/getWeekMetricUpdate";

import type { ScriptableContext } from "chart.js/auto";
import type { Dayjs } from "dayjs";

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, PointElement, LineElement);

interface MetricProgressChartProps {
  metric: GetFlywheelMetricData
  selectedWeekStart?: Dayjs;
  hideGraph?: boolean;
  hideTarget?: boolean;
}

export const MetricProgressChart: React.FC<MetricProgressChartProps> = ({
  metric, selectedWeekStart, hideGraph, hideTarget
}) => {
  const {
    flywheel, flywheelSubgoals, flywheelStartWeek, flywheelCycleNotStarted
  } = useSelectedFlywheel();

  // We get the quarter, current week and its update based on the passed in week - defaulting to the current week
  const selectedQuarter = useMemo(() => selectedWeekStart ? flywheelSubgoals?.find(subgoal => selectedWeekStart.isBetween(dayjs(subgoal.startDate).startOf("day"), dayjs(subgoal.endDate).endOf("day"), "days", "[]")) : flywheelSubgoals?.find(subgoal => dayjs().startOf("isoWeek").subtract(1, "week").isBetween(dayjs(subgoal.startDate).startOf("day"), dayjs(subgoal.endDate).endOf("day"), "days", "[]")), [ flywheelSubgoals, selectedWeekStart ]);
  // Find the selected quarter idx - always 0 for next year reviews
  const selectedQuarterIdx = useMemo(() => flywheelSubgoals?.findIndex(s => s.id === selectedQuarter?.id), [ flywheelSubgoals, selectedQuarter?.id ]);
  const selectedQuarterNumber = useMemo(() => (selectedQuarterIdx && selectedQuarterIdx !== -1) ? selectedQuarterIdx + 1 : 1, [ selectedQuarterIdx ]);
  const weekStart = useMemo(() => selectedWeekStart || dayjs().subtract(1, "week").startOf("isoWeek"), [ selectedWeekStart ]);
  const weekEnd = useMemo(() => weekStart.endOf("isoWeek"), [ weekStart ]);
  const isFuture = useMemo(() => weekStart.isAfter(dayjs()), [ weekStart ]);
  const currentMetricTarget = metric.targets?.find(target => target.isCurrent);

  const weekUpdate = useMemo(() => metric.metricUpdates ? getWeekMetricUpdate(metric.metricUpdates, weekStart, weekEnd) : null, [
    metric.metricUpdates,
    weekEnd,
    weekStart
  ]);

  // Check in is due if there's not an update for this week
  const isCheckInDue = useMemo(() => !flywheelCycleNotStarted && !weekUpdate && !isFuture, [
    flywheelCycleNotStarted,
    weekUpdate,
    isFuture
  ]);

  const weekTarget = useMemo(() => weekUpdate?.metricTarget?.target || currentMetricTarget?.target, [ currentMetricTarget, weekUpdate ]);
  // Filter the updates to just ones from this quarter
  const quarterUpdates = metric.metricUpdates ? metric.metricUpdates?.filter(metricUpdate => dayjs(metricUpdate.startDate).isBetween(dayjs(selectedQuarter?.startDate).startOf("day"), dayjs(selectedQuarter?.endDate).endOf("day"), null, "[]")) : [];
  const progressDisplayText = useMemo(() => metric.unitTypeLabel === FlywheelTemplateUnitTypeLabelEnum.PERCENTAGE ? `Q${selectedQuarterNumber} Performance` : `Progress towards Q${selectedQuarterNumber} target`, [ metric.unitTypeLabel, selectedQuarterNumber ]);

  // Quarterly metrics show a progress bar
  if (metric.unitDisplay === "quarterly") {
    // If it's a percentage OR reporting window is set to quarter to date always return the current week update instead of cumulative total
    // Sum the quarter's updates until the selected week, so we've got a cumulative total for the quarter
    // We use .isBefore(weekStart.add(1, "week")) to filter the updates to cover time up to and including the current week -
    // so before the first minute of the first day of next week
    const quarterTotalToWeek = metric.unitTypeLabel === FlywheelTemplateUnitTypeLabelEnum.PERCENTAGE || metric?.reportingWindowTiming === FlywheelTemplateReportingWindowTimingEnum.QUARTER_TO_DATE ? weekUpdate?.value !== undefined ? Number(weekUpdate?.value) : undefined : quarterUpdates?.filter(update => dayjs(update.startDate).isBefore(weekStart.add(1, "week"))).reduce((prev, curr) => prev + Number(curr.value), 0);
    const metricUnitSymbol = getUnitSymbol(metric.unitTypeLabel, flywheel?.currency, true);

    return (
      <div className="w-full flex flex-col gap-4">
        <p className={clsx("font-semibold antialiased text-sm", isCheckInDue ? "text-brand-check-in-due-800" : "text-brand-cold-metal-800")}>{progressDisplayText}</p>

        <ProgressBar
          target={weekUpdate?.metricTarget ? +weekUpdate.metricTarget?.target : currentMetricTarget?.target ? +currentMetricTarget?.target : undefined}
          progress={quarterTotalToWeek || 0}
          recentProgress={weekUpdate ? +weekUpdate?.value : undefined}
          isHealthy={weekUpdate?.isHealthy !== null ? weekUpdate?.isHealthy : true}
          isCheckInDue={isCheckInDue}
          neutral={isFuture || flywheelCycleNotStarted}
        />

        {!hideTarget && (
          <div className="flex flex-row justify-between antialiased items-center text-sm">
            <p className={`font-medium ${isCheckInDue ? "text-brand-check-in-due-500" : (quarterTotalToWeek ? weekUpdate?.isHealthy ? "text-brand-healthy-green-400" : "text-brand-attention-orange-400" : "text-brand-cold-metal-400")}`}>
              {quarterTotalToWeek === undefined ? "Progress" : formatGoalNumber(quarterTotalToWeek, metricUnitSymbol, {
                stripTrailingZeros: true,
                shouldCompact: true
              })}
            </p>

            <p className={`font-semibold ${isCheckInDue ? "text-brand-check-in-due-900" : "text-brand-cold-metal-600"}`}>{`Target ${formatGoalNumber(Number(weekTarget), metricUnitSymbol, {
              stripTrailingZeros: true,
              shouldCompact: true
            })}`}
            </p>
          </div>
        )}

      </div>
    );
  }

  // Don't show a graph when we've selected a week! This stops the graph showing twice on the
  // Metric Detail page, which is where we can move between weeks
  if (hideGraph) return null;

  // Figure out the week dates for the last 6 weeks - this lets us place data correctly on the graph
  // and get the right week numbers
  const last6Weeks = Array(6).fill(0).map((_, i) => (dayjs(weekStart).subtract(5 - i, "weeks").startOf("isoWeek")));

  /**
   * Get the week numbers for each bar of data - e.g. W48, W49, W50 and allowing it to wrap over years
   * So you should be able to have e.g. W50, W51, W52, W1, W2, W3
   *
   * The logic here for the above example weeks:
   * 1. Get the week differences from W1 of the flywheel - we'll get -3, -2, -1, 0, 1, 2
   * 2. Add 1 to these - we'll get -2, -1, 0, 1, 2, 3
   * 3. For the values < 1, subtract their absolute values from 52 - we'll get 52 - 2 = 50, 52 - 1 = 51,
   * 52 - 0 = 52 and the others are 1 or more
   * 4. Prepend "W" to the results to get the week numbers - we'll get W50, W51, W52, W1, W2, W3
  */
  const last6WeeksWeekNumberLabels = last6Weeks.map(week => {
    // Find the difference between the flywheel start date and the current week
    // This will be an integer of weeks - so e.g. Week 3 is 2 weeks after the start date
    const weekDifference = week.diff(dayjs(flywheelStartWeek), "week");
    // Add 1 to the difference to turn it into a week number - e.g. Week 3 has difference 2 -> +1 = 3
    const weekNumber = weekDifference + 1;
    // Use 52 weeks as a backup - but we should actually calculate the number of weeks in the last
    // year based on its start and end dates (because it could be 53 weeks long!)
    let weeksInPrevYear = 52;

    if (flywheelStartWeek) {
      const { startDate: prevYearStart, endDate: prevYearEnd } = getDateRangeWeekBounds(flywheelStartWeek?.startOf("month").subtract(1, "year"), flywheelStartWeek?.startOf("month").subtract(1, "day"));

      // Add 1 because we always end up with a decimal (since we're comparing a Monday to Sunday range), but dayjs
      // seems to floor the value to an integer number of weeks. Cycles will always be 52 or 53 weeks long!
      weeksInPrevYear = prevYearEnd.diff(prevYearStart, "weeks") + 1;
    }

    // If this is negative, then the week is from the previous flywheel year - e.g. W52 of the previous
    // flywheel - so we need to subtract the week number from the 52 total in the previous year
    if (weekNumber < 1) {
      // We use Math.abs to get the absolute value from the negative week difference in case the last
      // 6 weeks span 2 years! So we'll have e.g. W51 W52 W1 W2 W3 W4
      return `W${weeksInPrevYear - Math.abs(weekNumber)}`;
    }

    return `W${weekNumber}`;
  });

  // For each of the last 6 weeks, find
  const last6WeeksTargetData = last6Weeks.map(week => {
    if (!metric.metricUpdates) {
      return null;
    }
    const weekUpdate = getWeekMetricUpdate(metric.metricUpdates, week, week.endOf("isoWeek"));

    if (weekUpdate?.metricTarget?.target) {
      return weekUpdate?.metricTarget?.target;
    }

    const weekTarget = getWeekMetricTarget(metric.targets, week);

    return weekTarget?.target || currentMetricTarget?.target || "0";
  });

  // For the last 6 weeks, find the metric updates for each week to show as bars on the graph
  const last6WeeksUpdateData = last6Weeks.map(week => {
    if (!metric.metricUpdates) return "0";

    // Get the week update for the given week
    const weekMetricUpdate = getWeekMetricUpdate(metric.metricUpdates, week, week.endOf("isoWeek"));

    // Return the week metric update's value if it exists, or 0
    return weekMetricUpdate?.value || "0";
  });

  const getChartBackgroundColour = (index: number) => {
    const currentWeek = dayjs().startOf("isoWeek");
    const indexWeek = dayjs(last6Weeks[ index ]).startOf("isoWeek");

    // if the index week has check in due and is the current week, return the check-in-due colour
    if (indexWeek.isSame(currentWeek.subtract(1, "week")) && isCheckInDue) {
      return tailwindBrandColours.brand[ "check-in-due-50" ];
    } else return "#F8F9FC";
  };

  // Weekly metrics show a bar chart of weekly data with a target line
  return (
    <div className="-mx-5 mt-1 w-[calc(100%+1.25rem)]">
      <Chart
        type="bar"
        data={{
          labels: last6WeeksWeekNumberLabels,
          datasets: [
            {
              type: "line",
              label: "Target",
              data: last6WeeksTargetData,
              borderColor: "#191a1a",
              backgroundColor: "#4B5563",
              borderDash: [ 4, 4 ],
              borderWidth: 1,
              pointStyle: false,
              order: 1,
              spanGaps: true,
              stepped: "after"
            },
            {
              type: "bar",
              label: "Check-in value",
              data: last6WeeksUpdateData,
              backgroundColor: (ctx: ScriptableContext<"bar">) => {
                const barWeekStart = last6Weeks[ ctx.dataIndex ];

                return getBarColour(barWeekStart, weekStart, weekEnd, !weekUpdate, weekUpdate?.isHealthy);
              },
              borderSkipped: false,

              borderRadius: 5,
              order: 5
            }
          ]
        }}
        plugins={[ backgroundBarPlugin ]}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              display: false,
              beginAtZero: true,
              border: { display: false },
              grid: { display: false }
            },
            x: {
              grid: { display: false },
              border: { display: false },
              beginAtZero: true,
              offset: true,
              ticks: {
                font: ctx => {
                  const tickWeek = last6Weeks[ ctx.index ];
                  const currentWeek = dayjs().startOf("isoWeek");

                  if (dayjs(tickWeek).isSame(currentWeek.subtract(1, "week"))) {
                    return {
                      size: 16,
                      weight: "bold"
                    };
                  }
                }
              }
            }
          },
          plugins: {
          // Show blue bars if a check-in is due for the metric - but only if the selected week is now or before
            backgroundBar: { colour: getChartBackgroundColour },
            legend: { display: false },
            title: { display: false }
          }
        }}
      />
    </div>

  );
};