import {
  useRef,
  useEffect
} from "react";

/**
Hijacks scrollY and instead scrolls horizontally. This makes scrolling with a
regular mouse-wheel easier in a horizontal scroll-layout. (As opposed to a trackpad)
*/
export function useForceScrollHorizontal<R extends HTMLElement>(enabled = true) {
  const ref = useRef<R>(null);
  const mouseUser = window.matchMedia("(pointer:fine)").matches;

  useEffect(() => {
    const el = ref.current;

    if (!el || !mouseUser || !enabled) return;

    const handler = (e: WheelEvent) => {
      e.preventDefault();

      if (Math.abs(e.deltaY) >= Math.abs(e.deltaX)) {
        const delta = ("wheelDeltaY" in e && e.wheelDeltaY) as number || -e.detail;

        el.scrollLeft -= Math.round(delta * 1); // configurable scroll multiplier
      }
    };

    el.addEventListener("wheel", handler, { passive: false });

    return () => el.removeEventListener("wheel", handler);
  }, [
    ref,
    mouseUser,
    enabled
  ]);

  return ref;
}