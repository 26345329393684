import { FlywheelTemplateReportingWindowTimingEnum } from "@roda/shared/types";
import {
  useNavigate,
  useParams
} from "react-router-dom";

import { routes } from "~/constants/routes";
import { useCustomiseMetricDispatch } from "~/contexts/CustomiseMetricContext/CustomiseMetricContext";
import { CustomiseMetricStage } from "~/contexts/CustomiseMetricContext/metric-reducer";
import type { GetFlywheelMetricData } from "~/hooks/flywheel/use-get-flywheel";

interface CustomiseMetricMenuItemProps {
  metric: GetFlywheelMetricData;
}

export const CustomiseMetricMenuItem: React.FC<CustomiseMetricMenuItemProps> = ({ metric }) => {
  const navigate = useNavigate();
  const dispatch = useCustomiseMetricDispatch();
  const params = useParams() as { companyId?: string };
  const currentTarget = metric.targets?.find(target => target.isCurrent);

  return (
    <>
      <button
        onClick={() => {
          dispatch({
            type: "SET_SELECTED_METRIC",
            stage: CustomiseMetricStage.REVIEW,
            metric: {
              ...metric,
              cap: null,
              slug: null,
              target: currentTarget?.target,
              ownerEmail: metric.owner?.email,
              reportingWindowTiming: metric.reportingWindowTiming || FlywheelTemplateReportingWindowTimingEnum.THIS_WEEK,
              isCheckInDue: false,
              isHealthy: true
            }
          });
          navigate(routes.customiseMetric(metric.stepId.toString(), metric.id, params.companyId));
        }}
      >
        Edit metric
      </button>
    </>
  );
};