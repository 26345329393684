import { FlywheelTemplateUnitTypeLabelEnum } from "@roda/shared/types";

import { Icon } from "~/components/Icon";

export const getMetricSvgIcon = (unitTypeLabel: FlywheelTemplateUnitTypeLabelEnum) => {
  switch (unitTypeLabel) {
    case FlywheelTemplateUnitTypeLabelEnum.COUNT:
      return <Icon.MetricCount />;

    case FlywheelTemplateUnitTypeLabelEnum.HOURS_DAYS_WEEKS:
      return <Icon.MetricHoursDaysWeeks />;

    case FlywheelTemplateUnitTypeLabelEnum.DAYS_WEEKS_MONTHS:
      return <Icon.MetricHoursDaysWeeks />;

    case FlywheelTemplateUnitTypeLabelEnum.CURRENCY:
      return <Icon.MetricCurrency />;

    case FlywheelTemplateUnitTypeLabelEnum.MINUTES:
      return <Icon.MetricMinutes />;

    case FlywheelTemplateUnitTypeLabelEnum.NPS:
      return <Icon.MetricNPS />;

    case FlywheelTemplateUnitTypeLabelEnum.PERCENTAGE:
      return <Icon.MetricPercentage />;

    case FlywheelTemplateUnitTypeLabelEnum.PROJECTS:
      return <Icon.MetricProjects />;

    case FlywheelTemplateUnitTypeLabelEnum.SESSIONS:
      return <Icon.MetricSessions />;

    case FlywheelTemplateUnitTypeLabelEnum.SUBS:
      return <Icon.MetricSubs />;

    case FlywheelTemplateUnitTypeLabelEnum.USERS:
      return <Icon.MetricUsers />;

    default:
      return <Icon.MetricViews />;
  }
};