import type { LooseStep } from "~/components/flywheel/roda/types";
import { HealthStatus } from "~/components/flywheel/roda/types";
import type { StepType } from "~/contexts/OnboardingContext/onboarding-reducer";

import type { Step } from "@roda/graphql/genql";

/**
 * Function that returns the health status of a step
 * Check-in-due -> if there's at least one metric with a status of check-in-due
 * Needs attention -> if more than 50% of the metrics need attention
 * Healthy -> If more than 50% of the metrics are healthy
 * @param step
 */
export const getStepStatus = (step: LooseStep<Step | StepType>): HealthStatus => {
  const metrics = step.metrics;
  let status = HealthStatus.Waiting;
  const isCheckInDue = metrics?.some(metric => metric.isCheckInDue);

  if (isCheckInDue) {
    status = HealthStatus.CheckInDue;
  } else {
    // If more than 50% (round up) of the metrics are healthy -> isHealthy,
    const isHealthy = metrics ?
      metrics.filter(metric => metric.isHealthy).length >= Math.round(metrics.length / 2) :
      false;

    status = isHealthy ? HealthStatus.Healthy : HealthStatus.NeedsAttention;
  }

  return status;
};