import React, { useState } from "react";
import { useLocation } from "react-router-dom";

interface Props {
  children: React.ReactNode
}

const DynamicSizeContext = React.createContext<{ready: boolean; handleResize: () => void}>({
  ready: false,
  handleResize: () => null
});

/**
 * Dynamic Sizing
 * Sets CSS variables to keep track of dynamically sized components such as headers and footers.
 * Listens to page resize events and provides a function to retrigger sizing calculations
 */
export function DynamicSizeProvider({ children }: Props) {
  const [ ready, setReady ] = useState(false);

  const handleResize = React.useCallback(() => {
    // The flywheel footer (hidden elements)
    const flywheelFooter = document.getElementById("flywheel-footer");

    if (flywheelFooter) {
      document.documentElement.style.setProperty("--flywheel-footer", `${flywheelFooter.clientHeight}px`);
    }
    const reminderBanner = document.getElementById("reminder-banner");

    if (reminderBanner) {
      document.documentElement.style.setProperty("--reminder-banner", `${reminderBanner.clientHeight}px`);
    }

    const mobileHeader = document.getElementById("mobile-header");

    if (mobileHeader) {
      document.documentElement.style.setProperty("--mobile-header", `${mobileHeader.clientHeight}px`);
    }
    // The step outlet menu on desktop
    const stepOutletMenu = document.getElementById("step-outlet-menu");

    if (stepOutletMenu) {
      document.documentElement.style.setProperty("--step-outlet-menu", `${stepOutletMenu.clientHeight + 32}px`);
    }

    // The header containing a back button on the goal page
    const goalBackHeader = document.getElementById("goal-back-header");

    if (goalBackHeader) {
      document.documentElement.style.setProperty("--goal-back-header", `${goalBackHeader.clientHeight}px`);
    }

    // The half flywheel at the top of the on mobile step pages
    const mobileFlywheelHeader = document.getElementById("mobile-flywheel-header");

    if (mobileFlywheelHeader) {
      document.documentElement.style.setProperty("--mobile-flywheel-header", `${mobileFlywheelHeader.clientHeight}px`);
    }

    // The header for the check-in summary page
    const checkInSummaryHeader = document.getElementById("check-in-summary-header");

    if (checkInSummaryHeader) {
      document.documentElement.style.setProperty("--check-in-summary-header", `${checkInSummaryHeader.clientHeight}px`);
    }

    // The metric review header shown on ReviewMetrics.tsx

    const metricReviewHeader = document.getElementById("metric-review-header");

    if (metricReviewHeader) {
      document.documentElement.style.setProperty("--metric-review-header", `${metricReviewHeader.clientHeight}px`);
    }

    // The metric owner name & avatar before the footer on the metric detail page
    const metricOwnerSection = document.getElementById("metric-owner-section");

    if (metricOwnerSection) {
      document.documentElement.style.setProperty("--metric-owner-section", `${metricOwnerSection.clientHeight}px`);
    }

    setReady(true);
  }, [ setReady ]);

  const location = useLocation();

  React.useLayoutEffect(() => {
    setTimeout(() => {
      handleResize();
    }, 100);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ location ]);

  React.useEffect(() => {
    // Do this once on load
    handleResize();
    // Causes infinite loop in deps array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [ handleResize ]);

  return (
    <DynamicSizeContext.Provider
      value={{
        handleResize,
        ready
      }}
    >

      {children}
    </DynamicSizeContext.Provider>
  );
}

/**
 * Use this hook to trigger resize events which will set css variables
 */
export const useDynamicResize = () => React.useContext(DynamicSizeContext);
