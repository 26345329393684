import { generateQueryOp } from "@roda/graphql/genql";

import { Company as CompanyFragment } from "~/fragments/company";
import { Session as SessionFragment } from "~/fragments/session";
import { ReadOnlyUser as ReadOnlyUserFragment } from "~/fragments/user";
import type { InviteUserResponse } from "~/hooks/user/use-invite-user";

import type {
  Company,
  Session,
  User
} from "@roda/graphql/genql";
import type {
  Cache,
  Variables,
  ResolveInfo
} from "@urql/exchange-graphcache";

/**
 * Runs after successful inviteUser
 * Takes the results of a mutation and adds to our cache, to insert the new user into the user's company cache
 * @returns Updates the normalised cache with the results of the mutation
 */
export function inviteUser(result: InviteUserResponse, _args: Variables, cache: Cache, _info: ResolveInfo) {
  try {
    // For Admins and Users:
    // Regenerate the query
    const { query, variables } = generateQueryOp({ session: { ...SessionFragment } });

    // Query our cache for session
    cache.updateQuery<{session: Session | null}>({
      query,
      variables
    }, data => {
      const session = data?.session as Session;

      if (!session) {
        // null causes network refetch instead
        return data;
      }

      // Finally update our cache with the result of our successful mutation
      return {
        session: {
          ...session,
          company: {
            ...session.company,
            activeUsers: [ ...(session.company?.activeUsers || []), result.inviteUser ]
          }
        } as Session
      };
    });

    // For Roda admins: they access this user list through listCompanies
    // Get the previous time we queried for listCompanies (since this'll be admin only, we should only
    // update the cache if it's been returned before)
    const prevListCompaniesQuery = cache.inspectFields("Query").filter(item => item.fieldName === "listCompanies");

    if (prevListCompaniesQuery.length) {
      const ListCompaniesFragment = {
        ...CompanyFragment,
        activeUserCount: true,
        invitedUserCount: true,
        activeUsers: ReadOnlyUserFragment
      };

      // Regenerate the admin query
      const { query: companiesQuery, variables: companiesVariables } = generateQueryOp({ listCompanies: ListCompaniesFragment });

      // Query our cache for session
      cache.updateQuery<{listCompanies: Company[] | null}>({
        query: companiesQuery,
        variables: companiesVariables
      }, data => {
        const inviteUserResult = result.inviteUser as User;
        const companies = data?.listCompanies;
        const company = data?.listCompanies?.find(company => Number(company.id) === inviteUserResult.companyId) as Company;

        if (!companies || !company) {
        // null causes network refetch instead
          return data;
        }

        // Finally update our cache with the result of our successful mutation
        return {
          listCompanies: [
            ...companies,
            {
              ...company,
              invitedUserCount: (company.invitedUserCount || 0) + 1,
              activeUsers: [ ...(company?.activeUsers || []), result.inviteUser ]
            }
          ] as Company[]
        };
      });
    }
  } catch (error) {
    console.error("error updating cache", error);
  }
}