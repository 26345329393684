import { useTypedQuery } from "@roda/graphql/urql";

import {
  Subgoal,
  ReadOnlyUser
} from "~/fragments/index";
import { MetricUpdate } from "~/fragments/metricUpdate";

type UseTypedQueryOpts = Omit<Parameters<typeof useTypedQuery>[0], "query">;

export type FlywheelReviewResponse = ReturnType<typeof useGetFlywheelReview>[0]["data"];
export type FlywheelReviewData = NonNullable<FlywheelReviewResponse>["getFlywheelReview"];

export type FlywheelReviewMetricDataArray = NonNullable<FlywheelReviewData>["metrics"];
export type FlywheelReviewMetricData = NonNullable<FlywheelReviewMetricDataArray>[0];

export type FlywheelReviewTargetDataArray = NonNullable<FlywheelReviewMetricData>["targets"];
export type FlywheelReviewTargetData = NonNullable<FlywheelReviewTargetDataArray>[0];

export type FlywheelReviewMetricUpdateDataArray = NonNullable<FlywheelReviewMetricData>["metricUpdates"];
export type FlywheelReviewMetricUpdateData = NonNullable<FlywheelReviewTargetDataArray>[0];

/**
 * Get a Flywheel by ID
 * @param flywheelId The flywheelId to fetch
 * @param opts UseTypedQueryOpts
 * @returns
 */
export const useGetFlywheelReview = (flywheelId?: number, opts?: UseTypedQueryOpts) => {
  return useTypedQuery({
    ...opts,
    query: {
      getFlywheelReview: {
        __args: { flywheelId: flywheelId! },
        subgoal: Subgoal,
        metrics: {
          id: true,
          unitTypeLabel: true,
          name: true,
          reportingWindowTiming: true,
          metricUpdates: MetricUpdate,
          owner: {
            id: true,
            firstName: true,
            lastName: true,
            email: true,
            avatarS3Key: true
          },
          unitName: true,
          createdAt: true,
          unitDisplay: true,
          unitTargetType: true,
          unitType: true,
          stepId: true,
          cap: true,
          targets: {
            id: true,
            metricId: true,
            target: true,
            isNext: true,
            createdBy: ReadOnlyUser,
            fromDate: true,
            toDate: true,
            updatedAt: true
          },
          isReviewDue: true,
          fromDate: true,
          toDate: true,
          reviewers: {
            id: true,
            userId: true,
            user: ReadOnlyUser,
            createdAt: true,
            updatedAt: true
          }
        },
        reviewPeriod: true,
        flywheelGoal: {
          id: true,
          achieveBy: true,
          ownerId: true,
          owner: {
            id: true,
            firstName: true,
            lastName: true,
            email: true,
            avatarS3Key: true
          },
          createdBy: true,
          goal: true,
          cap: true,
          name: true,
          unitName: true,
          unitType: true,
          unitTypeLabel: true,
          unitDisplay: true,
          unitDescription: true,
          updateFrequency: true,
          subgoals: {
            id: true,
            endDate: true,
            startDate: true,
            goal: true,
            flywheelGoalId: true,
            latestUpdate: {
              id: true,
              flywheelGoalId: true,
              startDate: true,
              value: true,
              notes: true,
              isHealthy: true,
              updatedAt: true,
              updatedBy: {
                id: true,
                firstName: true,
                lastName: true,
                email: true,
                avatarS3Key: true
              }
            },
            updates: {
              id: true,
              flywheelGoalId: true,
              startDate: true,
              value: true,
              isHealthy: true,
              updatedAt: true,
              updatedBy: {
                id: true,
                firstName: true,
                lastName: true,
                email: true,
                avatarS3Key: true
              }
            },
            isHealthy: true,
            latestProgress: true
          },
          latestTotalValue: true,
          isHealthy: true,
          fromDate: true,
          toDate: true
        }

      }
    },
    pause: !flywheelId
  });
};