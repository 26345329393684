import type { Generated } from "../core/sql.generated";

export enum Currency {
  GBP = "GBP",
  EUR = "EUR",
  USD = "USD"
}

export enum UserRole {
  ADMIN = "ADMIN",
  RODA_ADMIN = "RODA_ADMIN",
  USER = "USER"
}

export enum UpdateFrequency {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
  QUARTERLY = "QUARTERLY"
}

export enum Timeframe {
  MONTHLY = "MONTHLY",
  QUARTERLY = "QUARTERLY",
  HALF_YEARLY = "HALF_YEARLY",
  YEARLY = "YEARLY"
}

export enum TargetType {
  EXCEED = "exceed",
  BELOW = "below"
}

export enum ReviewPeriod {
  NEXT_YEAR = "next_year",
  NEXT_QUARTER = "next_quarter"
}

export type NullableOptional<T> = {
  [K in keyof T]?: T[K] | null;
};

export type Optional<T> = {
  [K in keyof T]?: T[K];
};

export type RemoveGenerated<T> = {
  [K in keyof T]: T[K] extends Generated<infer U> ? U : T[K];
};

// Options for Hours/Days/Weeks (HDW)
export const unitTypeLabelOptionsHDW = [
  "Hours",
  "Days",
  "Weeks"
];

// Options for Days/Weeks/Months (DWM)
export const unitTypeLabelOptionsDWM = [
  "Days",
  "Weeks",
  "Months"
];

export const industryOptions = [
  "Agency",
  "SaaS",
  "E-commerce",
  "Consultancy",
  "Outsourced services",
  "Pharma/biotech",
  "Utilities",
  "Professional services",
  "Central government",
  "Local government",
  "Health and social care e.g. NHS, GPs, care homes",
  "VCSE",
  "Education & training",
  "Other"
];
// Options for unitDisplay
export const unitDisplayOptions = [ "weekly", "quarterly" ];

export interface FlywheelGoalUnit {
  name: string;
  unitName: string;
  unitTypeLabel: string;
  unitDisplay: string;
  unitDescription: string;
  cap: number | null;
}

export enum FlywheelGoalEnum {
  GROWTH = "Growth",
  OPS_MANAGEMENT = "Operations Management",
  IMPACT = "Impact",
}

export const availableFlywheelTemplates = [
  {
    name: FlywheelGoalEnum.GROWTH,
    description: "Build awareness, convert your customers, deliver quality, ensure retention and continuously innovate.",
    id: 1
  },
  {
    name: FlywheelGoalEnum.OPS_MANAGEMENT,
    description: "Plan and execute, delivery quality and assess performance to find ways to improve.",
    id: 2
  },
  {
    name: FlywheelGoalEnum.IMPACT,
    description: "Engage people in your social mission, measure financial success and build strong relationships to deliver positive social and environmental impact.",
    id: 3
  }
];

/** Up to date flywheel unit options */
export const flywheelGoalUnits: { [key in FlywheelGoalEnum]: FlywheelGoalUnit[] } = {
  [ FlywheelGoalEnum.GROWTH ]: [
    {
      name: "Income",
      unitName: "Currency",
      unitTypeLabel: "Currency",
      unitDisplay: "quarterly",
      unitDescription: "Total income (or revenue) generated in the period",
      cap: null
    },
    {
      name: "Total number of customers",
      unitName: "Number of customers",
      unitTypeLabel: "Count",
      unitDisplay: "quarterly",
      unitDescription: "The total number of customers you served during the period",
      cap: null
    },
    {
      name: "Number of new customers",
      unitName: "Number of customers",
      unitTypeLabel: "Count",
      unitDisplay: "quarterly",
      unitDescription: "The total number of new customers attracted during the period",
      cap: null
    },
    {
      name: "Gross profit",
      unitName: "Currency",
      unitTypeLabel: "Currency",
      unitDisplay: "quarterly",
      unitDescription: "Total gross profit generated in the period",
      cap: null
    },
    {
      name: "EBITDA",
      unitName: "Currency",
      unitTypeLabel: "Currency",
      unitDisplay: "quarterly",
      unitDescription: "Total EBITDA (Earnings Before Interest, Tax, Depreciation and Amortisation) generated in the period",
      cap: null
    }
  ],
  [ FlywheelGoalEnum.IMPACT ]: [
    {
      name: "Costs",
      unitName: "Cost reduction",
      unitTypeLabel: "Currency",
      // unitTargetType: "below",
      unitDisplay: "quarterly",
      unitDescription: "Total reduction in costs during the period",
      cap: null
    },
    {
      name: "Decrease in reserves",
      unitName: "Decrease in reserves",
      unitTypeLabel: "Currency",
      unitDisplay: "quarterly",
      unitDescription: "Decrease in organisational reserves during the period",
      cap: null
    },
    {
      name: "Employee Net Promoter Score",
      unitName: "Net Promoter Score",
      unitTypeLabel: "Count",
      unitDisplay: "quarterly",
      unitDescription: "Organisation-wide employee satisfaction score (NPS) achieved during the period",
      cap: null
    },
    {
      name: "Income",
      unitName: "Income",
      unitTypeLabel: "Currency",
      unitDisplay: "quarterly",
      unitDescription: "Total income generated during the period",
      cap: null
    },
    {
      name: "Increase in reserves",
      unitName: "Increase in reserves",
      unitTypeLabel: "Currency",
      unitDisplay: "quarterly",
      unitDescription: "Increase in organisational reserves achieved during the period",
      cap: null
    },
    {
      name: "Service user engagement",
      unitName: "Number of service users engaged",
      unitTypeLabel: "Count",
      unitDisplay: "quarterly",
      unitDescription: "Number of service users supported during the period",
      cap: null
    },
    {
      name: "Service user outcomes",
      unitName: "Service user outcomes",
      unitTypeLabel: "Percentage",
      unitDisplay: "quarterly",
      unitDescription: "Percentage of service users reporting positive outcomes",
      cap: null
    },
    {
      name: "Social Return on Investment",
      unitName: "SROI",
      unitTypeLabel: "Currency",
      unitDisplay: "quarterly",
      unitDescription: "Social return on investment during the period",
      cap: null
    },
    {
      name: "Surplus",
      unitName: "Surplus",
      unitTypeLabel: "Currency",
      unitDisplay: "quarterly",
      unitDescription: "Total surplus generated during the period",
      cap: null
    }
  ],
  [ FlywheelGoalEnum.OPS_MANAGEMENT ]: [
    {
      name: "Client Satisfaction Score (CSAT)",
      unitName: "CSAT score",
      unitTypeLabel: "Percentage",
      unitDisplay: "quarterly",
      unitDescription: "Client Satisfaction Score (CSAT) for overall project experience",
      cap: 100
    },
    {
      name: "Net Promoter Score (NPS)",
      unitName: "NPS score",
      unitTypeLabel: "Percentage",
      unitDisplay: "quarterly",
      unitDescription: "Net Promoter Score (NPS) specific to project outcomes",
      cap: 100
    },
    {
      name: "Employee Satisfaction Score",
      unitName: "Employee satisfaction score",
      unitTypeLabel: "Percentage",
      unitDisplay: "quarterly",
      unitDescription: "Employee Satisfaction Score (ESS) related to project work",
      cap: 100
    },
    {
      name: "Project Profit Margin",
      unitName: "Percentage margin",
      unitTypeLabel: "Percentage",
      unitDisplay: "quarterly",
      unitDescription: "Project Profit Margin",
      cap: null
    },
    {
      name: "Project Success Rate",
      unitName: "Percentage success rate",
      unitTypeLabel: "Percentage",
      unitDisplay: "quarterly",
      unitDescription: "Project Success Rate (on-time, on-budget, against scope, meeting quality standards)",
      cap: 100
    }
  ]
};

export const AVAILABLE_FLYWHEEL_GOALS: { [key in FlywheelGoalEnum]: string } = {
  [ FlywheelGoalEnum.GROWTH ]: "Growth",
  [ FlywheelGoalEnum.OPS_MANAGEMENT ]: "Operations Management",
  [ FlywheelGoalEnum.IMPACT ]: "Impact"
};

export enum FlywheelTemplateUnitTypeLabelEnum {
  MINUTES = "Minutes",
  VIEWS = "Views",
  PERCENTAGE = "Percentage",
  SESSIONS = "Sessions",
  USERS = "Users",
  COUNT = "Count",
  SUBS = "Subs",
  CURRENCY = "Currency",
  HOURS_DAYS_WEEKS = "Hours/Days/Weeks",
  DAYS_WEEKS_MONTHS = "Days/Weeks/Months",
  PROJECTS = "Projects",
  NPS = "NPS",
  EVENTS = "Events",
  ATTENDEES = "Attendees",
}

export type FlywheelTemplateUnitDisplay = "weekly" | "quarterly" | "any";
export type FlywheelTemplateTargetType = "exceed" | "below";
export type FlywheelTemplateReportingWindowTiming = "quarter to date" | "this week";
export enum FlywheelTemplateReportingWindowTimingEnum {
  QUARTER_TO_DATE = "quarter to date",
  THIS_WEEK = "this week"
}
export interface FlywheelTemplateUnit {
  name: string;
  typeLabel: FlywheelTemplateUnitTypeLabelEnum | string;
  type: string;
  targetType: FlywheelTemplateTargetType;
  display: FlywheelTemplateUnitDisplay;
  description: string;
  cap: number | null;
  reportingWindowTiming: FlywheelTemplateReportingWindowTiming | null;
  slug: string | null;
}

export interface FlywheelTemplateMetric {
  name: string;
  units: FlywheelTemplateUnit[]
}

export interface FlywheelTemplateStep {
  name: string;
  description: string;
  order: number;
  metrics: FlywheelTemplateMetric[]
}

export interface FlywheelTemplateJson {
  flywheel: {
    name: string;
    steps: FlywheelTemplateStep[]
  }
}

export enum NotificationType {
  Email = "email"
}

export enum NotificationStatus {
  Queued = "queued",
  Sent = "sent",
  Error = "error",
  Failed = "failed",
  Delivered = "delivered"
}

export enum UserMetricReviewAction {
  /** User created a new metric */
  Create = "metric_created",
  /** User updated the details of a metric */
  Update = "metric_updated",
  /** User approved the metric without changes */
  Approve = "metric_approved",
  /** User disabled a metric */
  Disable = "metric_disabled",
  /** User re-enabled a previously disabled metric */
  Reenable = "metric_re-enabled"
}

// Used for media
export type ObjectCannedACL = "private"|"public-read"|"public-read-write"|"authenticated-read"|"aws-exec-read"|"bucket-owner-read"|"bucket-owner-full-control";