import * as Tooltip from "@radix-ui/react-tooltip";
import clsx from "clsx";
import {
  useEffect,
  type ComponentProps,
  useState
} from "react";

import { useFlywheelProperties } from "~/components/flywheel/base/BaseFlywheel";

import type React from "react";
import type { RectReadOnly } from "react-use-measure";

const orZero = (n: number | undefined) => n || 0;

export interface BaseFlywheelStepProps extends Omit<ComponentProps<"button">, "children"> {
  // using @deprecated so the editor/intellisense discourages using it
  /** @deprecated **INTERNAL**: gets overwritten by parent `Flywheel` */
  __cx?: number;
  /** @deprecated **INTERNAL**: gets overwritten by parent `Flywheel` */
  __cy?: number;
  /** @deprecated **INTERNAL**: gets overwritten by parent `Flywheel` */
  __index?: number;
  /** @deprecated **INTERNAL**: gets overwritten by parent `Flywheel` */
  __angle?: number;
  /** @deprecated **INTERNAL**: gets overwritten by parent `Flywheel` */
  __onAnnotationResize?: (rect: RectReadOnly) => void;
  /**
   * Style the container which wraps all of the step circle/ring/line/annotation
   *
   * NOT USED IN FILE: set from parent flywheel on wrapping element
  */
  stepContainerClassname?: string | ((props: {index: number, side: "center" | "left" | "right", alignment: "center" | "top" | "bottom", angle: number}) => string);
  /**
   * Style the container which wraps all of the step circle/ring/line/annotation
  */
  stepContainerStyle?: React.CSSProperties;
  size: number;
  /**
   * style the step circle
   *
   * Tailwind's `stroke-<COLOR>`|`stroke-<WIDTH>`|`fill-<COLOR>` classes can be
   * passed as a string for things like hover styles
   *
   * circle/ring/line elements are grouped together using Tailwind's `group` modifier (@link https://tailwindcss.com/docs/hover-focus-and-other-states#styling-based-on-parent-state)
  */
  circle?: { className?: string } & ({fill: string, strokeColor?: string, strokeWidth?: number} | {fill?: string, strokeColor: string, strokeWidth: number})
  /**
   * render an outer ring (`<circle>`) atop main step circle
   *
   * Tailwind's `stroke-<COLOR>`|`stroke-<WIDTH>`|`fill-<COLOR>` classes can be
   * passed as a string for things like hover styles
   *
   * circle/ring/line elements are grouped together using Tailwind's `group` modifier (@link https://tailwindcss.com/docs/hover-focus-and-other-states#styling-based-on-parent-state)
  */
  ring?: {offset: number, className?: string} & ({fill: string, strokeColor?: string, strokeWidth?: number} | {fill?: string, strokeColor: string, strokeWidth: number});
  /**
   * render a line connecting the flywheel and step's (children) annotation element
   *
   * Tailwind's `stroke-<COLOR>`|`stroke-<WIDTH>` classes can be passed as a
   * string for things like hover styles
   *
   * circle/ring/line elements are grouped together using Tailwind's `group` modifier (@link https://tailwindcss.com/docs/hover-focus-and-other-states#styling-based-on-parent-state)
  */
  line?: { strokeColor: string; strokeWidth: number; /** where the line finishes (along the bottom edge) in relation to the annotation element */ spans?: "start" | "half" | "full", className?: string }
  /** how far from the flywheel */
  childOffset?: number;
  children?: React.ReactNode;
  addStep?: boolean;
  selected?: boolean;
}

/**
 * Nest the direct child otherwise padding will overlap its alignment with the
 * connecting line
 */
export const BaseFlywheelStep: React.FC<BaseFlywheelStepProps> = ({
  __cx, __cy, __onAnnotationResize, __index, __angle, children, size, circle, ring, childOffset = 0, disabled, stepContainerClassname: _containerClassname, stepContainerStyle: _stepContainerStyle, addStep, selected, ...rest
}) => {
  const parentFlywheel = useFlywheelProperties();
  const [ enableChildren, setEnableChildren ] = useState(false);
  enum AnnotationSide {
    // can only be left/right for line annotated steps
    Left = "left",
    Right = "right",
    // otherwise can also be top/bottom
    Top = "top",
    Bottom = "bottom"
  }
  // Determine the side of annotation based on the step's X-coordinate
  let annotationSide = __cx && __cx < parentFlywheel.cx ? AnnotationSide.Left : AnnotationSide.Right;
  const verticalThreshold = 90; // cones of degrees

  if (orZero(__angle) >= 360 - (verticalThreshold / 2) || orZero(__angle) <= verticalThreshold / 2) {
    annotationSide = AnnotationSide.Top;
  } else if (orZero(__angle) >= 180 - (verticalThreshold / 2) && orZero(__angle) <= 180 + (verticalThreshold / 2)) {
    annotationSide = AnnotationSide.Bottom;
  }

  let offsetX = 0;
  let offsetY = 0;
  const angleRadians = orZero(__angle) * (Math.PI / 180);

  offsetX = childOffset * Math.sin(angleRadians);
  offsetY = childOffset * -Math.cos(angleRadians);

  // fix for floating point rounding errors (ugh)
  const tolerance = 1e-10; // Tolerance level for considering a value as zero

  if (Math.abs(offsetX) < tolerance) {
    offsetX = 0;
  }

  if (Math.abs(offsetY) < tolerance) {
    offsetY = 0;
  }

  useEffect(() => {
    setTimeout(() => {
      setEnableChildren(true);
    }, 100);
  }, []);

  return (
    <Tooltip.Root open>
      <Tooltip.Trigger asChild>
        <g
          // only have it behave like a button if an onClick is passed
          {...rest as unknown as React.SVGProps<SVGGElement>}
          {...(rest.onClick ? {
            "role": "button",
            "tabIndex": 0,
            "aria-disabled": disabled
          } : {})}
            // <g> elements don't support the disabled attribute, so we have to
            // use aria-disabled instead (which is functionally fine!)
          className={clsx("ring-0 outline-none", rest.className?.replace(/^disabled:/, "aria-disabled:"))}
        >
          {/* Optional outer ring/circle */}
          {ring && (
            <circle
              cx={__cx}
              cy={__cy}
              r={size + orZero(ring.offset)}
              stroke={ring.strokeColor || "black !important"}
              strokeWidth={ring.strokeWidth || "0 !important"}
              fill={ring.fill || "none !important"}
              aria-disabled={disabled}
              className={clsx("step-ring transition-all", ring.className?.replace(/^disabled:/, "aria-disabled:"))}
            />
          )}

          {/* Step circle */}
          {!addStep ? (
            <circle
              r={size}
              cx={__cx}
              cy={__cy}
              fill={circle?.fill || "black"}
              stroke={circle?.strokeColor || "none"}
              strokeWidth={circle?.strokeWidth || 0}
              aria-disabled={disabled}
              className={clsx("step-circle transition-all", circle?.className?.replace(/^disabled:/, "aria-disabled:"))}
            />
          ) : (
          // Show + sign if adding step
            <text
              x={__cx}
              y={__cy}
              textAnchor="middle"
              dominantBaseline="middle"
              fill={selected ? "white" : "black"}
              pointerEvents="none"
            >
              +
            </text>
          )}

        </g>
      </Tooltip.Trigger>

      {(enableChildren && children) && (
        <Tooltip.Portal
          forceMount
          container={parentFlywheel.getStepContainer(__index!)}
        >
          <Tooltip.Content
            collisionBoundary={parentFlywheel.getStepContainer(__index!)}
            avoidCollisions={false}
            side={annotationSide}
            align="center"
            sideOffset={childOffset}
            className="step-annotation"
            style={{ transform: `translate(${offsetX}px, ${offsetY}px)` }}
          >
            {children}
          </Tooltip.Content>
        </Tooltip.Portal>
      )}
    </Tooltip.Root>
  );
};
