import React from "react";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { Icon } from "~/components/Icon";

import type { To } from "react-router-dom";

type Crumb = {
  label: string;
  to: To;
};
/** final crumb is not a link, just for display (current position among related previous paths) */
type FinalCrumb = Omit<Crumb, "to">;

/**
 * A visually expressed trail of links to help users understand related
 * navigation paths, and allow moving backward to those previous related paths.
 * @example
 * <Breadcrumbs
 *   crumbs={[
 *     { label: "Home", to: "/" },
 *     { label: "Settings", to: "/settings" },
 *     // final crumb is not a link, just for display (current position among related previous paths
 *     { label: "Profile" }
 *   ]}
 * />
 */
export const Breadcrumbs = ({ crumbs }: { crumbs: [...Crumb[], FinalCrumb] }) => {
  return (
    <ol className="flex items-center py-4">
      {crumbs.map((crumb, i) => {
        const isLast = i === crumbs.length - 1;
        const style = "px-1.5 -mx-1 py-1 text-xs text-brand-cold-metal-400 hover:to-brand-cold-metal-300";

        return (
          <React.Fragment key={i.toLocaleString()}>
            <li className="flex">
              {isLast ? (
                <span className={twMerge(style, "bg-brand-cold-metal-100 px-2 py-1 rounded-lg text-brand-cold-metal-900 font-medium")}>{crumb.label}</span>
              ) : (
                <Link
                  to={(crumb as Crumb).to}
                  className={style}
                >{crumb.label}
                </Link>
              )}
            </li>

            {!isLast && <Icon.ChevronRight className="w-3 h-3 relative top-px text-brand-cold-metal-200 mr-2" />}
          </React.Fragment>
        );
      })}
    </ol>
  );
};
