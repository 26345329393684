import type dayjs from "../dayjs";
import { getFirstMondayFromStartDate } from "./getFirstMondayFromStartDate";
import { getLastSundayFromEndDate } from "./getLastSundayFromStartDate";

/**
 * Given an absolute date range (e.g. flywheel start and end, or subgoal start and end), get the
 * ACTUAL week range that this will cover.
 *
 * This is because we can't have weeks that are both e.g. Q1 and Q2, so we have to make some decisions
 * about how the weeks fall into quarters.
 *
 * We choose that the start date of a quarter is the first day of the week in which EVERY DAY falls within
 * the quarter - so this could start on anything from the 1st to the 7th of the month!
 *
 * We choose that the end date of the quarter is the last day of the week in which the absolute
 * end date falls - so this could be anything from the 30th / 31st of the current month, to the
 * 6th of the following month!
 * @param absoluteStartDate the absolute start of the date range - normally the 1st of a month
 * @param absoluteEndDate the absolute end of the date range - normally the 30th or 31st of a month
 * @returns
 */
export const getDateRangeWeekBounds = (absoluteStartDate: dayjs.Dayjs, absoluteEndDate: dayjs.Dayjs) => {
  const firstMondayOfRange = getFirstMondayFromStartDate(absoluteStartDate);
  const lastSundayOfRange = getLastSundayFromEndDate(absoluteEndDate);

  return {
    startDate: firstMondayOfRange,
    endDate: lastSundayOfRange
  };
};