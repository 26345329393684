import { zodResolver } from "@hookform/resolvers/zod";
import { Currency } from "@roda/shared/types";
import {
  useContext,
  useRef
} from "react";
import { useForm } from "react-hook-form";

import { ConfirmationPopup } from "~/components/ConfirmationPopup";
import { Input } from "~/components/form";
import { FlywheelSubgoalSchema } from "~/components/form/formSchemas";
import { VerticalDotMenuContext } from "~/components/VerticalDotMenu";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useUpdateSubgoal } from "~/hooks/subgoal/use-update-subgoal";
import { useError } from "~/hooks/useError";
import dayjs from "~/utils/dayjs";
import { getUnitSymbol } from "~/utils/getUnitSymbol";
import { cleanNumberInput } from "~/utils/validation/cleanNumberInput";

import type { z } from "zod";

interface EditFlywheelSubgoalMenuItemProps {
  openPopup: () => void;
  closePopup: () => void;
  isOpen: boolean;
}

export const EditFlywheelSubgoalMenuItem: React.FC<EditFlywheelSubgoalMenuItemProps> = ({
  openPopup, closePopup, isOpen
}) => {
  const { assertGraphQLSuccess, handleRodaError } = useError();
  const { flywheel, flywheelSubgoals } = useSelectedFlywheel();
  const { closeVDotMenu } = useContext(VerticalDotMenuContext);
  const flywheelGoal = flywheel?.latestFlywheelGoal;
  const flywheelSubgoal = flywheel?.latestFlywheelGoal?.checkInSubgoal;
  const currentQuarterIdx = flywheelSubgoals?.findIndex(s => s.id === flywheelSubgoal?.id);
  const editFlywheelSubgoalContainerRef = useRef<HTMLDivElement>(null);
  const [ updateFlywheelSubgoalRes, updateFlywheelSubgoalReq ] = useUpdateSubgoal();
  const flywheelSubgoalUnitSymbol = getUnitSymbol(flywheelGoal?.unitTypeLabel, flywheel?.currency as Currency || Currency.GBP, true);

  // Edit flywheel subgoal form
  const {
    reset: resetFlywheelSubgoalForm,
    register: registerFlywheelSubgoalForm,
    handleSubmit: handleSubmitFlywheelSubgoalForm,
    setValue,
    formState: { errors: flywheelSubgoalFormErrors }
  } = useForm<z.infer<typeof FlywheelSubgoalSchema>>({
    resolver: zodResolver(FlywheelSubgoalSchema),
    // Default to the current flywheel subgoal
    defaultValues: { goal: (flywheelSubgoal?.goal ?? "0") },
    shouldFocusError: false
  });

  const handleUpdateFlywheelSubgoal = handleSubmitFlywheelSubgoalForm(formValues => {
    // Update the flywheel goal
    if (flywheelSubgoal?.id) {
      updateFlywheelSubgoalReq({
        subgoalId: Number(flywheelSubgoal?.id),
        goal: String(formValues.goal)
      })
        .then(res => {
          assertGraphQLSuccess(res);
          closePopup();
          closeVDotMenu();
        })
        .catch(e => {
          handleRodaError(e, "Something went wrong. Please try again.");
        });
    }
  });

  return (
    <>
      <button onClick={openPopup}>
        Change quarterly target
      </button>

      {/** Edit flywheel subgoal popup */}
      <ConfirmationPopup
        isOpen={isOpen}
        title="Edit current quarter goal"
        subtitle="Change your goal"
        text=""
        ref={editFlywheelSubgoalContainerRef}
        continueText="Save"
        onContinue={() => handleUpdateFlywheelSubgoal()}
        onCancel={() => {
          if (!updateFlywheelSubgoalRes.fetching) { // Disable click outside to close the popup if the request is still fetching
            resetFlywheelSubgoalForm();
            closePopup();
            closeVDotMenu();
          }
        }}
        loading={updateFlywheelSubgoalRes.fetching}
        continueDisabled={updateFlywheelSubgoalRes.fetching}
      >
        <form
          onSubmit={handleUpdateFlywheelSubgoal}
          className="flex flex-col flex-1 gap-4"
        >
          <Input
            label={`Current target for Q${currentQuarterIdx ? currentQuarterIdx + 1 : 1} ${dayjs().year()}`}
            value={flywheelSubgoal?.goal}
            iconPosition="left"
            iconComponent={<p>{flywheelSubgoalUnitSymbol}</p>}
            disabled
          />

          <Input
            {...registerFlywheelSubgoalForm("goal", {
              onChange: e => {
                const cleanedValue = cleanNumberInput(e.target.value);

                setValue("goal", cleanedValue);
              }
            })}
            label={`Enter the new target for Q${currentQuarterIdx ? currentQuarterIdx + 1 : 1} ${dayjs().year()}`}
            hasError={!!flywheelSubgoalFormErrors.goal}
            errorMessage={flywheelSubgoalFormErrors.goal?.message}
            min={1}
            inputMode="numeric"
            autoComplete="off"
            autoCorrect="off"
            iconPosition="left"
            iconComponent={<p>{flywheelSubgoalUnitSymbol}</p>}
          />
        </form>
      </ConfirmationPopup>
    </>
  );
};