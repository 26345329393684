import { enumUserRole } from "@roda/graphql/genql";
import {
  Currency,
  FlywheelTemplateUnitTypeLabelEnum
} from "@roda/shared/types";
import {
  useMemo,
  useState
} from "react";

import { Button } from "~/components/Button";
import { ProgressBar } from "~/components/flywheel/roda/ProgressBar";
import { EditExistingFlywheelGoalUpdateMenuItem } from "~/components/flywheel-goal/EditFlywheelGoalCheckInMenuItem";
import { EditFlywheelSubgoalMenuItem } from "~/components/flywheel-goal/EditFlywheelSubgoalMenuItem";
import { FlywheelGoalCheckInPopup } from "~/components/flywheel-goal/FlywheelGoalCheckInPopup";
import { Icon } from "~/components/Icon";
import { SaveImageMenuItem } from "~/components/metric/SaveImageMenuItem";
import { VerticalDotMenu } from "~/components/VerticalDotMenu";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useCurrentUser } from "~/contexts/UserContext";
import { useIsMobile } from "~/hooks/useIsMobile";
import dayjs from "~/utils/dayjs";
import { formatGoalNumber } from "~/utils/formatGoalNumber";
import { getUnitSymbol } from "~/utils/getUnitSymbol";

import type {
  FlywheelGoalUpdate,
  Subgoal
} from "@roda/graphql/genql";
import type { Dayjs } from "dayjs";

enum EditFlywheelSubgoalAction {
  EditFlywheelSubgoal,
  SaveImage,
  EditCheckIn
}

interface FlywheelSubgoalCardProps {
  selectedMonthStart: Dayjs;
  selectedSubgoal: Subgoal | undefined;
  selectedUpdate: FlywheelGoalUpdate | undefined;
}

export const FlywheelSubgoalCard: React.FC<FlywheelSubgoalCardProps> = ({
  selectedMonthStart, selectedSubgoal, selectedUpdate
}) => {
  const { flywheel, flywheelSubgoals } = useSelectedFlywheel();
  const isMobile = useIsMobile();
  const flywheelGoal = flywheel?.latestFlywheelGoal;
  const flywheelGoalUnitSymbol = getUnitSymbol(flywheelGoal?.unitTypeLabel, flywheel?.currency as Currency || Currency.GBP, true);
  const flywheelCycleNotStarted = !selectedSubgoal;
  const currentQuarterIdx = flywheelSubgoals?.findIndex(s => s.id === selectedSubgoal?.id) || -1;
  const currentQuarterNumber = useMemo(() => currentQuarterIdx !== -1 ? currentQuarterIdx + 1 : 1, [ currentQuarterIdx ]);
  const progressDisplayText = useMemo(() => flywheelGoal?.unitTypeLabel === FlywheelTemplateUnitTypeLabelEnum.PERCENTAGE ? `Q${currentQuarterNumber} Performance` : `Progress towards Q${currentQuarterNumber} target`, [ currentQuarterNumber, flywheelGoal?.unitTypeLabel ]);
  const updatesUntilSelectedMonth = useMemo(() => selectedSubgoal?.updates.filter(update => dayjs(update.startDate).isBefore(selectedMonthStart.add(1, "month"))), [ selectedMonthStart, selectedSubgoal?.updates ]);

  // If the flywheel goal is a percentage then get only the value of the latest update
  const subgoalCumulativeProgress = useMemo(() => {
    if (flywheelGoal?.unitTypeLabel === FlywheelTemplateUnitTypeLabelEnum.PERCENTAGE) {
      return updatesUntilSelectedMonth?.length ? Number(updatesUntilSelectedMonth[ updatesUntilSelectedMonth.length - 1 ].value) : 0;
    }

    return updatesUntilSelectedMonth?.reduce((prev, curr) => prev + Number(curr.value), 0);
  }, [ flywheelGoal?.unitTypeLabel, updatesUntilSelectedMonth ]);

  const currentMonthUpdate = useMemo(() => updatesUntilSelectedMonth?.find(update => dayjs(update.startDate).isSame(selectedMonthStart, "month")), [ selectedMonthStart, updatesUntilSelectedMonth ]);
  // Enum to be consistent with other forms like this - and so we can add more options easily
  const [ editPopupType, setEditPopupType ] = useState<EditFlywheelSubgoalAction | null>(null);
  const [ isCheckInOpen, setIsCheckInOpen ] = useState(false);
  const { user } = useCurrentUser();
  // Check if the current user can update the subgoal, only admins, roda admins and the owner of the goal can update it
  const userCanUpdateSubgoal = useMemo(() => user?.id === flywheelGoal?.owner?.id || user?.role === enumUserRole.RODA_ADMIN || user?.role === enumUserRole.ADMIN, [ user, flywheelGoal?.owner?.id ]);
  // Check in is due if there's not an update for this month
  const isCheckInDue = useMemo(() => !currentMonthUpdate && !flywheelCycleNotStarted, [ currentMonthUpdate, flywheelCycleNotStarted ]);
  const textColour = useMemo(() => isCheckInDue ? "text-brand-check-in-due-900" : "text-brand-cold-metal-800", [ isCheckInDue ]);

  return (
    <div
      className="w-full h-full flex flex-col justify-between p-5"
    >
      <div
        className="flex flex-col mb-2"
      >
        <div className="flex flex-row justify-between items-start">
          <p className={`text-sm font-semibold ${textColour}`}>{`${flywheelGoal?.name} this month`}</p>

          <VerticalDotMenu buttonClassName={`m-0 -mr-4 md:-mr-8 -mt-3 ${isCheckInDue ? "text-brand-check-in-due-200 hover:text-brand-check-in-due-500" : "text-brand-cold-metal-400 hover:text-black"} `}>
            {(selectedUpdate && userCanUpdateSubgoal) ? (
              <EditExistingFlywheelGoalUpdateMenuItem
                openPopup={() => setEditPopupType(EditFlywheelSubgoalAction.EditCheckIn)}
                closePopup={() => setEditPopupType(null)}
                isOpen={editPopupType === EditFlywheelSubgoalAction.EditCheckIn}
                update={selectedUpdate}
              />
            ) : <></>}

            {/* Only showing menu if user has permission to update subgoal */}
            {userCanUpdateSubgoal && (
              <EditFlywheelSubgoalMenuItem
                openPopup={() => setEditPopupType(EditFlywheelSubgoalAction.EditFlywheelSubgoal)}
                closePopup={() => setEditPopupType(null)}
                isOpen={editPopupType === EditFlywheelSubgoalAction.EditFlywheelSubgoal}
              />
            )}

            {!isMobile && (
              <SaveImageMenuItem
                captureId="main-goal"
                html2canvasOptions={{
                  width: 600,
                  onclone(_, element) {
                    const borderW = parseInt(getComputedStyle(element).borderInlineWidth ?? 0) * 2; // * 2 because it only accounts for one side

                    element.style.width = (600 - borderW) + "px";
                    element.style.height = "auto";
                    element.style.flex = "unset";
                  }
                }}
              />
            )}
          </VerticalDotMenu>
        </div>

        {!isCheckInDue && (
          <span className="font-bold text-brand-cold-metal-800 text-3xl md:text-4xl">
            {formatGoalNumber(Number(currentMonthUpdate?.value || 0), getUnitSymbol(flywheelGoal?.unitTypeLabel, flywheel?.currency), {
              stripTrailingZeros: true,
              shouldCompact: true
            })}
          </span>
        )}
      </div>

      <div className="w-full flex-1 mt-2 mb-6 flex flex-center">
        <div className="w-full flex flex-col gap-4">

          {/* Show check-in if an update hasn't been posted for the current month, and the user has permission to submit one */}
          {isCheckInDue && userCanUpdateSubgoal && (
            <Button
              title="Check-in required"
              iconComponent={<Icon.Clock className="size-3" />}
              className="bg-brand-check-in-due-700 min-w-[100px] h-[30px] p-4 w-full z-10 text-sm text-brand-cold-metal-50 rounded-md font-semibold antialiased"
              onClick={() => {
                // Close any edit menus
                setEditPopupType(null);
                // And open the check in popup
                setIsCheckInOpen(true);
              }}
            />
          )}

          <p className={`font-semibold text-sm ${textColour}`}>{progressDisplayText}</p>

          <ProgressBar
            target={Number(selectedSubgoal?.goal)}
            progress={subgoalCumulativeProgress || 0}
            recentProgress={currentMonthUpdate ? Number(currentMonthUpdate.value) : 0}
            isHealthy={currentMonthUpdate?.isHealthy !== null ? currentMonthUpdate?.isHealthy : true}
            isCheckInDue={isCheckInDue}
            neutral={flywheelCycleNotStarted}
          />

          <div className="flex flex-row justify-between text-sm font-semibold">
            <p
              className={`${flywheelCycleNotStarted ? "text-brand-cold-metal-400" : isCheckInDue ? "text-brand-check-in-due-400" : currentMonthUpdate?.isHealthy ? "text-brand-healthy-green-400" : "text-brand-attention-orange-400"}`}
            >
              {subgoalCumulativeProgress ? formatGoalNumber(Number(subgoalCumulativeProgress), flywheelGoalUnitSymbol, {
                stripTrailingZeros: true,
                shouldCompact: true
              }) : "Progress"}
            </p>

            <p className={`${textColour}`}>{`Target ${formatGoalNumber(selectedSubgoal?.goal ? Number(selectedSubgoal.goal) : 0, flywheelGoalUnitSymbol, {
              shouldCompact: true,
              stripTrailingZeros: true
            })}`}
            </p>
          </div>

        </div>
      </div>

      <FlywheelGoalCheckInPopup
        isOpen={isCheckInOpen}
        onClose={() => setIsCheckInOpen(false)}
        monthStart={selectedMonthStart}
        monthEnd={selectedMonthStart.endOf("month")}
      />
    </div>
  );
};