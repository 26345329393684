interface Params {
  firstName?: string | null;
  lastName?: string | null;
  email: string;
}

// Function to get user's initials for avatar, shows a ? if the name is not defined
export const getUserInitials = (user: Params) => {
  const initialA = user.firstName ? user.firstName.charAt(0) : user.email.charAt(0);
  const initialB = user.lastName ? user.lastName.charAt(0) : user.email.charAt(1);

  return `${initialA}${initialB}`.toUpperCase();
};
