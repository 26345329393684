import clsx from "clsx";

import { MetricStatusDots } from "~/components/flywheel/roda/MetricStatusDots";
import {
  RodaFlywheel,
  withStepTheme
} from "~/components/flywheel/roda/RodaFlywheel";
import { HealthStatus } from "~/components/flywheel/roda/types";
import type { FlywheelType } from "~/contexts/OnboardingContext/onboarding-reducer";
import { useIsMobile } from "~/hooks/useIsMobile";

interface FlywheelPreviewProps {
  flywheelSize: number;
  availableSteps: FlywheelType["steps"];
}
export const FlywheelPreview: React.FC<FlywheelPreviewProps> = ({ flywheelSize, availableSteps }) => {
  const isMobile = useIsMobile();

  return (
    <RodaFlywheel
      position="centre"
      size={flywheelSize}
      stepRingOutlineColor="#f9fafb"
      flywheel={{ steps: availableSteps ?? [] }}
      separators={isMobile ? true : false}
      stepSize={() => 7}
      stepsProps={availableSteps?.map(step => {
        const hasMetrics = step?.metrics && step?.metrics?.length !== 0;

        return ({
          stepContainerStyle: withStepTheme(hasMetrics ? "green" : "blue"),
          children: (
          // If mobile don't show the outer step annotations
            (
              <button
                className="flex flex-col flex-center gap-2"
              >
                <MetricStatusDots
                  step={step}
                  // Any metric is considered healthy
                  metricStatus={HealthStatus.Healthy}
                />

                <span
                  className={clsx(
                    "text-xs 2xl:text-base font-medium py-1 2xl:py-5 px-4 2xl:px-6 rounded-lg h-[30px] flex flex-center transition-all min-w-[90px] 2xl:min-w-[120px] bg-[var(--step-theme-lighter)] text-[var(--step-theme-medium)]",
                  )}
                >
                  {step.alias ?? step.name}
                </span>
              </button>
            )
          )
        });
      }) ?? []}
    />
  );
};