import {
  enumUserRole,
  type Flywheel,
  type Subgoal
} from "@roda/graphql/genql";
import { getDateRangeWeekBounds } from "@roda/shared/utils/getDateRangeWeekBounds";
import {
  createContext,
  useCallback,
  useContext,
  useMemo
} from "react";

import type { LoadedFlywheel } from "~/contexts/FlywheelLoaderContext";
import { useCurrentUser } from "~/contexts/UserContext";
import type { FlywheelReviewData } from "~/hooks/flywheel/use-get-flywheel-review";
import type { CreateStepsWithMetricsParams } from "~/hooks/step/use-create-steps-with-metrics";
import { useCreateStepsWithMetrics } from "~/hooks/step/use-create-steps-with-metrics";
import { useError } from "~/hooks/useError";
import dayjs from "~/utils/dayjs";

import type { Dayjs } from "dayjs";
import type { PropsWithChildren } from "react";

interface SelectedFlywheelProviderProps {
  flywheels?: Flywheel[];
  activeFlywheelReview?: FlywheelReviewData | null;
  setActiveFlywheelId: React.Dispatch<React.SetStateAction<string | undefined | null>>
  activeFlywheelId: string | undefined | null;
  refetchCurrentFlywheel: () => void;
  setStoredFlywheels: React.Dispatch<React.SetStateAction<LoadedFlywheel[] | undefined>>
}

interface SelectedFlywheelContextProps {
  loading: boolean;
  flywheel?: Flywheel;
  flywheelSubgoals: Subgoal[] | null;
  flywheelStartWeek: Dayjs | null;
  flywheelStartMonth: Dayjs | null;
  flywheelEndDate: Dayjs | null;
  checkInSubgoal: Subgoal | null;
  finishFlywheelSetup: (params: CreateStepsWithMetricsParams, callback: () => void, newFlywheelId?: string) => void;
  setActiveFlywheelId: React.Dispatch<React.SetStateAction<string | undefined | null>>
  flywheelCycleNotStarted: boolean;
  isCheckInDue: boolean;
  refetchFlywheel: () => void;
  userHasMetrics: boolean;
  isReviewDue: boolean;
  activeFlywheelReview?: FlywheelReviewData | null;
  subgoalInReview: NonNullable<FlywheelReviewData>["subgoal"] | null;
}

const SelectedFlywheelContext = createContext<SelectedFlywheelContextProps>({
  loading: false,
  flywheelSubgoals: null,
  flywheelStartWeek: null,
  flywheelStartMonth: null,
  activeFlywheelReview: null,
  flywheelEndDate: null,
  refetchFlywheel: () => null,
  checkInSubgoal: null,
  setActiveFlywheelId: () => null,
  finishFlywheelSetup: () => null,
  isCheckInDue: false,
  userHasMetrics: false,
  flywheelCycleNotStarted: false,
  isReviewDue: false,
  subgoalInReview: null
});

export const useSelectedFlywheel = () => useContext(SelectedFlywheelContext);

/**
 * A set of functions and state for the current active flywheel
 */
export const SelectedFlywheelProvider = (props: PropsWithChildren<SelectedFlywheelProviderProps>) => {
  const { user } = useCurrentUser();
  const { flywheels } = props;
  const activeFlywheel = props.activeFlywheelId ? flywheels?.find(fw => fw.id === props.activeFlywheelId) : flywheels?.[ 0 ];
  // Variable for whether this should be a full request, or partial
  // default to true if we already have a flywheel in storage because the full request happens in the background anyway
  // Get subgoal for review
  const subgoalInReview = useMemo(() => props.activeFlywheelReview?.subgoal || null, [ props.activeFlywheelReview ]);
  // isReview is due if there's at least one metric that's due for a review
  const isReviewDue = useMemo(() => activeFlywheel?.steps?.some(step => step.metrics?.some(metric => metric.isReviewDue)) ?? false, [ activeFlywheel?.steps ]);
  const flywheelSubgoals = useMemo(() => activeFlywheel?.latestFlywheelGoal?.subgoals, [ activeFlywheel?.latestFlywheelGoal?.subgoals ]);
  // Calculate the flywheel start date as the start date of the first subgoal (quarter) - and default to the start of the current year
  const { startDate: flywheelStartDate, endDate: flywheelEndDate } = useMemo(() => getDateRangeWeekBounds(activeFlywheel?.latestFlywheelGoal ? dayjs(activeFlywheel?.latestFlywheelGoal.fromDate) : dayjs().startOf("year"), activeFlywheel?.latestFlywheelGoal ? dayjs(activeFlywheel.latestFlywheelGoal.achieveBy) : dayjs().endOf("year")), [ activeFlywheel?.latestFlywheelGoal ]);
  // Set check-in subgoal
  const checkInSubgoal = useMemo(() => activeFlywheel?.latestFlywheelGoal?.checkInSubgoal || null, [ activeFlywheel?.latestFlywheelGoal?.checkInSubgoal ]);
  const selectedWeekStart = useMemo(() => dayjs().subtract(1, "week").startOf("isoWeek"), []);
  const selectedSubgoal = useMemo(() => flywheelSubgoals?.find(subgoal => selectedWeekStart.isBetween(dayjs(subgoal.startDate).startOf("isoWeek"), dayjs(subgoal.endDate).endOf("isoWeek"), null, "[]")), [ flywheelSubgoals, selectedWeekStart ]);
  const flywheelCycleNotStarted = !selectedSubgoal;
  // Check-in is due if there's at least one metric that's due for a check-in
  const isCheckInDue = useMemo(() => (!flywheelCycleNotStarted && activeFlywheel?.steps?.some(step => step.metrics?.some(metric => metric.isCheckInDue))) ?? false, [ activeFlywheel?.steps, flywheelCycleNotStarted ]);
  // Is a check in available for the user
  const isAdmin = user?.role === enumUserRole.ADMIN;
  const isRodaAdmin = user?.role === enumUserRole.RODA_ADMIN;
  const { assertGraphQLSuccess } = useError();

  // Used in the nav bars to show the check in badge if the user has av. metrics to check in
  const userHasMetrics = useMemo(() => {
    // Map the steps and filter out metrics that the user does not own
    const stepsWithFilteredMetrics = activeFlywheel?.steps?.map(step => {
      return {
        ...step,
        metrics: ((isAdmin || isRodaAdmin) ? step.metrics : step.metrics?.filter(metric => metric.owner?.id == user?.id)) ?? []
      };
    });

    // Then, filter out steps that have no metrics accessible to the user
    const filteredSteps = stepsWithFilteredMetrics?.filter(step => step.metrics.length > 0);

    // Return true if there are any filtered steps, otherwise false
    return (filteredSteps?.length ?? 0) > 0;
  }, [
    activeFlywheel?.steps,
    isAdmin,
    isRodaAdmin,
    user?.id
  ]);

  const [ , createStepsReq ] = useCreateStepsWithMetrics();

  const finishFlywheelSetup = useCallback(async (params: CreateStepsWithMetricsParams, callback: () => void, newFlywheelId?: string) => {
    createStepsReq(params).then(res => {
      assertGraphQLSuccess(res);

      if (res.data?.createStepsWithMetrics) {
        props.setActiveFlywheelId(newFlywheelId);

        props.setStoredFlywheels([
          ...flywheels?.filter(existing => {
            if (res.data?.createStepsWithMetrics.find(fl => fl.id === existing.id)) {
              return false;
            } else {
              return true;
            }
          }) || [],
          ...res.data.createStepsWithMetrics
        ]);
        callback();
      }
    });
  }, [
    flywheels,
    assertGraphQLSuccess,
    createStepsReq,
    props
  ]);

  const memoedValue = useMemo<SelectedFlywheelContextProps>(
    () => ({
      loading: true,
      flywheel: activeFlywheel,
      flywheelSubgoals: flywheelSubgoals || null,
      flywheelStartWeek: flywheelStartDate,
      flywheelStartMonth: flywheelStartDate,
      finishFlywheelSetup,
      flywheelEndDate,
      checkInSubgoal,
      activeFlywheelReview: props.activeFlywheelReview,
      isCheckInDue,
      userHasMetrics,
      subgoalInReview,
      isReviewDue,
      flywheelCycleNotStarted,
      setActiveFlywheelId: props.setActiveFlywheelId,
      refetchFlywheel: () => props.refetchCurrentFlywheel
    }),
    [
      activeFlywheel,
      flywheelSubgoals,
      flywheelStartDate,
      flywheelEndDate,
      checkInSubgoal,
      props,
      isCheckInDue,
      userHasMetrics,
      subgoalInReview,
      finishFlywheelSetup,
      isReviewDue,
      flywheelCycleNotStarted
    ]
  );

  return (
    <SelectedFlywheelContext.Provider value={memoedValue}>
      {props.children}
    </SelectedFlywheelContext.Provider>
  );
};