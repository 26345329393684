import { useIsMobile } from "~/hooks/useIsMobile";

interface SubheadingProps {
  heading: string;
}

export const Subheading: React.FC<SubheadingProps> = ({ heading }) => {
  const isMobile = useIsMobile();

  return (
    <div
      className={`${isMobile ? "pl-0" : "pl-2"} pb-1 [&~[data-id='subheading']]:pt-4`}
      data-id="subheading"
    >
      <span className="text-brand-cold-metal-400 font-medium text-xs">
        {heading}
      </span>
    </div>
  );
};