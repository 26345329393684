import { useTypedQuery } from "@roda/graphql/urql";

import { User } from "~/fragments/index";

import type {
  FieldsSelection,
  User as UserType
} from "@roda/graphql/genql";

type UseTypedQueryOpts = Omit<Parameters<typeof useTypedQuery>[0], "query">;
export type UserGenqlSelectionRes = FieldsSelection<UserType, typeof User>;
export const useListUsers = (companyId?: number, opts?: UseTypedQueryOpts) => {
  return useTypedQuery({
    ...opts,
    query: {
      listUsers: {
        __args: { companyId },
        ...User
      }
    }
  });
};