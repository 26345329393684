import dayjs from "~/utils/dayjs";

import type { Dayjs } from "dayjs";

interface Params {
  date: string | Dayjs;
  flywheelStartWeek: Dayjs | null;
}
/**
 * Function for returning the week number of the passed date, based on the flywheel's starting date
 */
export const getWeekNumber = (params: Params) => {
  const { date, flywheelStartWeek } = params;

  return dayjs(date).diff(dayjs(flywheelStartWeek), "week") + 1;
};