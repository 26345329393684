import { type Session } from "@roda/graphql/genql";
import {
  createContext,
  useContext,
  useEffect,
  useMemo
} from "react";
import { useLocalStorage } from "react-use";

import { STORAGE_KEYS } from "~/constants/storageKeys";
import { useGetSession } from "~/hooks/session/use-get-session";
import type { UserSession } from "~/hooks/session/use-get-session";

import { useAuth } from "./AuthContext";

import type { OperationContext } from "urql";

export interface UserContextProps {
  user: UserSession | null;
  fetching: boolean;
  setCurrentUser: (user: Session) => void;
  refetch: (opts?: Partial<OperationContext> | undefined) => void;
}
interface Props {
  children: React.ReactNode;
}

const UserContext = createContext<UserContextProps>({
  user: null,
  fetching: false,
  setCurrentUser: () => null,
  refetch: () => null
});

export function UserProvider({ children }: Props) {
  const [ user, setCurrentUser ] = useLocalStorage<UserSession | null>(STORAGE_KEYS.LOCAL_STORAGE.USER_SESSION, null);
  const { authenticated, logout } = useAuth();

  const [
    {
      data: sessionData, fetching: fetchingSession, error: sessionError
    },
    refetch
  ] = useGetSession(authenticated);

  useEffect(() => {
    if (!authenticated) {
      setCurrentUser(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ authenticated ]);

  const session = sessionData?.session;

  // Set current user
  useEffect(() => {
    if (session) {
      setCurrentUser(session);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ session ]);

  useEffect(() => {
    if (sessionError) {
      console.error(sessionError);

      if (!authenticated) {
        logout();
      } else {
        refetch({ requestPolicy: "network-only" });
      }
    }
  // Don't include refetch or logout as dependencies to avoid an infinite loop
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ authenticated, sessionError ]);

  // memo for efficiency
  const memoedValue = useMemo<UserContextProps>(
    () => ({
      user: user || null,
      setCurrentUser,
      fetching: fetchingSession,
      refetch
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      user,
      setCurrentUser,
      fetchingSession
    ],
  );

  return (
    <UserContext.Provider
      value={memoedValue}
    >
      {children}
    </UserContext.Provider>
  );
}

export const useCurrentUser = () => useContext(UserContext);