import {
  useEffect,
  useState
} from "react";

import { getAvatarColours } from "~/utils/getAvatarColours";

import type React from "react";

interface CompanyAvatarProps {
  companyName: string;
  logoS3Key?: string;
  size?: "small" | "medium" | "large"
}

export const CompanyAvatar: React.FC<CompanyAvatarProps> = ({
  companyName, size = "medium", logoS3Key
}) => {
  const avatarColours = getAvatarColours(companyName.charAt(0).toUpperCase());
  const [ avatarValue, setAvatarValue ] = useState<string | undefined>(logoS3Key ?? undefined);

  // Update the avatar if the logoS3Key changes
  useEffect(() => {
    setAvatarValue(logoS3Key ?? undefined);
  }, [ logoS3Key ]);

  const avatarSize = (size: string) => {
    switch (size) {
      case "small":
        return "h-6 w-6";
      case "medium":
        return "h-7 w-7";
      case "large":
        return "h-10 w-10";
      default:
        return "h-7 w-7";
    }
  };

  return (
    <div
      className={`${avatarSize(size)} shrink-0 aspect-square rounded-lg relative overflow-hidden grid place-items-center`}
      style={{ backgroundColor: avatarValue ? undefined : avatarColours.bgColour }}
    >
      {avatarValue ? (
        <img
          className="absolute inset-0 w-full h-full object-cover"
          src={avatarValue}
        />
      ) : (
        <span
          className={`text-sm font-semibold ${size === "small" || size === "medium" ? "text-sm" : "text-xl"}`}
          style={{ color: avatarColours.textColour }}
        >
          {companyName.charAt(0).toUpperCase()}
        </span>
      )}
    </div>
  );
};