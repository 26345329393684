import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "~/components/Button";
import { MetricPreview } from "~/components/customise-metric/MetricPreview";
import { SelectCustomiseMetric } from "~/components/customise-metric/SelectCustomiseMetric";
import { Icon } from "~/components/Icon";
import { FlywheelPreview } from "~/components/onboarding/metrics-and-targets/FlywheelPreview";
import { StepOverview } from "~/components/onboarding/metrics-and-targets/StepOverview";
import type { OnboardingContentStepProps } from "~/components/onboarding/OnboardingContent";
import { OnboardingContentWrapper } from "~/components/onboarding/OnboardingContentWrapper";
import { routes } from "~/constants/routes";
import { useCustomiseFlywheelGoalDispatch } from "~/contexts/CustomiseFlywheelGoalContext/CustomiseFlywheelGoalContext";
import {
  useCustomiseMetricDispatch,
  useCustomiseMetricState
} from "~/contexts/CustomiseMetricContext/CustomiseMetricContext";
import type { MetricType } from "~/contexts/CustomiseMetricContext/metric-reducer";
import {
  useOnboardingDispatch,
  useOnboardingState
} from "~/contexts/OnboardingContext/OnboardingContext";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useCurrentUser } from "~/contexts/UserContext";
import { useIsMobile } from "~/hooks/useIsMobile";

import type {
  MetricWithoutStepId,
  StepWithMetrics
} from "@roda/core/step/createStepsWithMetrics";

export const MetricsAndTargets: React.FC<OnboardingContentStepProps> = ({
  changeStep, onBack, createFlywheelMode
}) => {
  const customiseFlywheelGoalDispatch = useCustomiseFlywheelGoalDispatch();
  const { flywheel: onboardingFlywheel } = useOnboardingState();
  const setOnboardingDispatch = useOnboardingDispatch();
  const setCustomiseMetricDispatch = useCustomiseMetricDispatch();
  const { finishFlywheelSetup } = useSelectedFlywheel();
  const navigate = useNavigate();
  const { refetch: refetchCurrentUser, user } = useCurrentUser();
  const [ loading, setLoading ] = useState(false);

  const {
    metric, stage, step
  } = useCustomiseMetricState();

  const isMobile = useIsMobile();
  const flywheelContainerRef = useRef<HTMLDivElement>(null);
  const [ flywheelSize, setFlywheelSize ] = useState(0);

  useEffect(() => {
    const updateFlywheelSize = () => {
      if (flywheelContainerRef.current) {
        const containerHeight = Math.min(flywheelContainerRef.current.offsetHeight, flywheelContainerRef.current.offsetWidth * 0.5); // slightly smaller than other screen since this one has annotations
        const size = containerHeight - 60;

        setFlywheelSize(size);
      }
    };

    // Call the update function initially and on window resize
    updateFlywheelSize();
    window.addEventListener("resize", updateFlywheelSize);

    return () => {
      window.removeEventListener("resize", updateFlywheelSize);
    };
  }, [ isMobile, flywheelContainerRef.current?.offsetHeight ]);

  /**
   * Checks if the onboarding steps are 5
   * and whether all of them have at least 1 metric
   */
  const hasMinMetricsPerStep = useMemo(() => {
    const hasMin = onboardingFlywheel?.steps?.every(step => (step?.metrics?.length || 0) >= 1) || false;

    return hasMin;
  }, [ onboardingFlywheel?.steps ]);

  const onboardingSteps = useMemo(() => onboardingFlywheel?.steps ?? [], [ onboardingFlywheel?.steps ]);

  const onAddMetric = (metric: MetricType) => {
    if (step?.index !== undefined) {
      setOnboardingDispatch({
        type: "ADD_OR_UPDATE_METRIC",
        metric,
        stepIdx: step.index
      });
      setCustomiseMetricDispatch({ type: "RESET" });
    }
  };

  const onDeleteMetric = (metricIdx: number) => {
    if (step?.index !== undefined) {
      setOnboardingDispatch({
        type: "REMOVE_METRIC",
        metricIdx,
        stepIdx: step.index
      });
      setCustomiseMetricDispatch({ type: "RESET" });
    }
  };

  const handleCompleteSetup = useCallback(async () => {
    setLoading(true);

    // Construct the steps array for batch creating steps with metrics
    const steps: StepWithMetrics[] = onboardingFlywheel && onboardingFlywheel.id !== undefined && onboardingSteps?.length !== undefined ? onboardingSteps.map((step, idx) => {
      const metrics = step?.metrics?.map(({
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        metricIdx, stepId, ...metricWithoutIdx
      }) => metricWithoutIdx);

      return {
        flywheelId: +onboardingFlywheel.id!,
        order: step.order || idx,
        name: step?.name || "",
        alias: step?.alias || "",
        metrics: metrics as MetricWithoutStepId[] || []
      };
    }) : [];

    if (user?.companyId && user.email) {
      finishFlywheelSetup({
        steps,
        companyId: user?.companyId
      }, () => {
        setLoading(false);
        refetchCurrentUser();
        // Reset onboarding state - don't need it anymore
        setOnboardingDispatch({ type: "RESET" });

        createFlywheelMode ?
          // Navigate to flywheel setup complete if we're on createFlywheel mode
          navigate(routes.newFlywheelSetupComplete, { replace: true }) :
          navigate(routes.readyToStart, { replace: true });
      }, onboardingFlywheel?.id);
    }
  }, [
    onboardingFlywheel,
    onboardingSteps,
    user?.companyId,
    finishFlywheelSetup,
    refetchCurrentUser,
    setOnboardingDispatch,
    createFlywheelMode,
    user?.email,
    navigate
  ]);

  return (
    <OnboardingContentWrapper
      createFlywheelMode={createFlywheelMode}
      hideLogo={createFlywheelMode}
      hideTitle={!!stage}
      changeStep={changeStep}
      leftContent={!stage ? (
        <div className="w-full flex flex-col flex-1 pb-10">
          {onboardingSteps?.map(step => (
            <StepOverview
              key={step?.stepIdx}
              step={step}
            />
          ))}

          <div className="flex flex-row gap-2">
            <Button
              title="Back"
              onClick={onBack}
            />

            <Button
              title="Finish setup"
              onClick={handleCompleteSetup}
              loading={loading}
              disabled={!hasMinMetricsPerStep}
            />
          </div>
        </div>
      ) : (
        <SelectCustomiseMetric
          onSave={onAddMetric}
          onDelete={onDeleteMetric}
          flywheelTemplateId={onboardingFlywheel?.flywheelTemplateId}
        />
      )}
      rightContent={(
        <div
          ref={flywheelContainerRef}
          className={`relative flex-1 grid place-items-center ${isMobile ? "h-full max-h-[1000px] w-full max-w-[1000px] overflow-x-visible pb-10" : "h-screen overflow-visible"}`}
        >
          {stage ?
            (
              <MetricPreview metric={metric} />
            ) :
            (
              <div>
                <FlywheelPreview
                  flywheelSize={flywheelSize}
                  availableSteps={onboardingFlywheel?.steps}
                />

                <Button
                  className="lg:bottom-10 absolute -translate-x-1/2  bg-transparent rounded-full text-brand-cold-metal-500 border border-brand-cold-metal-300 border-solid"
                  title="Edit steps"
                  onClick={() => {
                    customiseFlywheelGoalDispatch({ type: "CUSTOMISE_TEMPLATE" });

                    setOnboardingDispatch({
                      type: "SET_STEP",
                      step: "flywheel_goal"
                    });
                  }}
                  iconComponent={<Icon.Edit />}
                />
              </div>
            )}
        </div>
      )}
    />

  );
};