import { useTypedMutation } from "@roda/graphql/urql";

import { FlywheelGoal } from "~/fragments/flywheelGoal";

import type { MutationGenqlSelection } from "@roda/graphql/genql";

type Params = NonNullable<MutationGenqlSelection["editExistingFlywheelGoalUpdate"]>["__args"];

export const useEditExistingFlywheelGoalUpdate = () => {
  return useTypedMutation((vars: Params) => ({
    editExistingFlywheelGoalUpdate: {
      __args: vars,
      ...FlywheelGoal
    }
  }));
};