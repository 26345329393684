import { useMemo } from "react";

import { Button } from "~/components/Button";
import { SearchableMetrics } from "~/components/customise-metric/SearchableMetrics";
import { Icon } from "~/components/Icon";
import {
  useCustomiseMetricDispatch,
  useCustomiseMetricState
} from "~/contexts/CustomiseMetricContext/CustomiseMetricContext";
import { CustomiseMetricStage } from "~/contexts/CustomiseMetricContext/metric-reducer";
import { useIsMobile } from "~/hooks/useIsMobile";

import type {
  FlywheelTemplateMetric,
  FlywheelTemplateStep
} from "@roda/shared/types";

interface AddMetricProps {
  templateSteps: FlywheelTemplateStep[];
  flywheelTemplateId?: number | null
}

export const AddMetric: React.FC<AddMetricProps> = ({ templateSteps, flywheelTemplateId }) => {
  const { step: selectedStep } = useCustomiseMetricState();
  const dispatch = useCustomiseMetricDispatch();
  const isMobile = useIsMobile();

  const suggestedMetrics = useMemo(() => {
    // If a template is provided and the step is found, then return suggestions
    if (flywheelTemplateId && templateSteps.some(step => step.name === selectedStep?.name)) {
      return templateSteps.filter(step => selectedStep ? step.name === selectedStep.name : true).flatMap(step => step.metrics);
    } else {
      // Otherwise return everything
      return templateSteps.flatMap(step => step.metrics);
    }
  }, [
    flywheelTemplateId,
    selectedStep,
    templateSteps
  ]);

  const grouped: FlywheelTemplateMetric[] = [];

  suggestedMetrics.map(metric => {
    const foundIdx = grouped.findIndex(group => group.name === metric.name);

    if (foundIdx !== -1) {
      grouped[ foundIdx ] = {
        ...grouped[ foundIdx ],
        units: [ ...grouped[ foundIdx ].units, ...metric.units ]
      };
    } else {
      grouped.push(metric);
    }
  });

  return (
    <div className="w-full flex flex-col flex-1">
      <div className="flex flex-col gap-y-2 mb-3 w-full">
        <p className={`font-bold ${isMobile ? "text-xl" : "text-2xl"}`}>Add a metric</p>

        <p className={`${isMobile ? "text-xs" : "text-base"} opacity-80`}>Choose from a list of metric options below or build your own with create new metric
        </p>
      </div>

      <SearchableMetrics
        suggestedMetrics={grouped}
      />

      <div className="flex flex-row gap-x-2 items-center justify-center my-3">
        <Button
          title="Back"
          className="rounded-lg bg-brand-cold-metal-100 text-brand-cold-metal-700"
          onClick={() => dispatch({ type: "RESET" })}
        />

        <Button
          className="rounded-lg bg-brand-cold-metal-100 flex flex-row justify-center items-center py-2.5 px-6 gap-x-2 w-full hover:contrast-[90%] relative text-brand-cold-metal-700"
          onClick={() => dispatch({
            type: "SET_SELECTED_METRIC",
            metric: undefined,
            step: selectedStep,
            stage: CustomiseMetricStage.CUSTOMISE_NAMING
          })}
          iconComponent={<Icon.PlusCircle className="text-brand-cold-metal-700" />}
          title="Create new metric"
        />
      </div>

    </div>
  );
};