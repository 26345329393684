import { useMemo } from "react";

import { Avatar } from "~/components/Avatar";
import { Icon } from "~/components/Icon";
import ResponsiveName from "~/components/side-navigation/components/ResponsiveName";
import { useAuth } from "~/contexts/AuthContext";
import { useCurrentUser } from "~/contexts/UserContext";

export const Footer = () => {
  const { logout } = useAuth();
  const { user } = useCurrentUser();
  const displayName = useMemo(() => user?.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : undefined, [ user ]);

  return (
    user?.email && (
      <div className="p-4 border-solid border-t border-brand-cold-metal-200 flex items-center w-full  [&:has(button[title]:hover)]:[background:linear-gradient(to_right,transparent_calc(100%-5rem),#99999910)]">
        {/* Avatar */}
        <div className="flex-none mr-4">
          <Avatar
            user={user}
          />
        </div>

        {/* Name & email: using as string because null is not valid */}
        <div className="flex-1 min-w-0 flex flex-col">
          <ResponsiveName
            displayName={displayName}
            firstName={user.firstName as string}
            lastName={user.lastName as string}
          />

          <h6
            className="text-xs text-brand-cold-metal-500 truncate"
            title={user?.email ?? ""}
          >{user?.email}
          </h6>
        </div>

        {/* Logout button */}
        <div className="flex-none [&:hover_path]:stroke-[royalblue]">
          <button
            className="rounded-lg h-14 px-3 flex items-center justify-center"
            title="Sign out"
            onClick={logout}
          >
            <Icon.Logout className="w-5 h-5" />
          </button>
        </div>
      </div>
    )
  );
};
