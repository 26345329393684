import type { Dayjs } from "dayjs";

/**
 * Get the colour of a bar based on whether it's selected, check-in is due and the metric is healthy
 * @param barStart the start of the week being represented by this bar
 * @param selectedStart the start of the currently selected week
 * @param selectedEnd the end of the currently selected week
 * @param isCheckInDue whether check-in is due for the metric
 * @param isHealthy whether the currently selected week has a healthy metric update
 * @param isCumulativeDataset whether the dataset is cumulative - if so, we should lower the opacity
 * @returns a hex code for the bar colour - grey, green, orange or blue
 */
export const getBarColour = (barStart: Dayjs, selectedStart: Dayjs, selectedEnd: Dayjs, isCheckInDue: boolean, isHealthy?: boolean | null, isCumulativeDataset?: boolean) => {
  let barColour = "#ADB3C6";

  // If the bar is the currently selected date range, we want to colour it depending on its state
  if (barStart.isBetween(selectedStart, selectedEnd, null, "[)")) {
    // Show the blue check-in due style if relevant
    if (isCheckInDue) {
      barColour = "#528BFF";
    // Otherwise, figure if the metric is / was healthy in that week
    } else {
      barColour = isHealthy ? "#47CD86" : "#FD9322";
    }
  }

  // Return the calculated bar colour, with 50% opacity if this is a cumulative dataset
  return `${barColour}${isCumulativeDataset ? "50" : ""}`;
};