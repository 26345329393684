import { useTypedQuery } from "@roda/graphql/urql";

import { ReadOnlyUser } from "~/fragments/index";

type UseTypedQueryOpts = Omit<Parameters<typeof useTypedQuery>[0], "query">;

export type GetCompanyResponse = NonNullable<NonNullable<ReturnType<typeof useGetCompany>["0"]["data"]>["getCompany"]>;

export const useGetCompany = (companyId: number, opts?: UseTypedQueryOpts) => {
  return useTypedQuery({
    ...opts,
    query: {
      getCompany: {
        __args: { id: companyId },
        id: true,
        name: true,
        createdAt: true,
        updatedAt: true,
        stripeCustomerRef: true,
        stripeStatus: true,
        completedOnboarding: true,
        logoS3Key: true,
        logoS3Bucket: true,
        activeUserCount: true,
        invitedUserCount: true,
        flywheels: {
          id: true,
          setupComplete: true,
          deletedAt: true,
          name: true
        },
        activeUsers: {
          ...ReadOnlyUser,
          lastSeenAt: true
        },
        counts: {
          metricsCheckedInCount: true,
          metricsTotalCount: true,
          flywheelCount: true
        }
      }
    }
  });
};