import type { Plugin } from "chart.js/auto";

/**
 * ChartJS Plugin to show a coloured background behind the bar on a bar chart.
 */
export const backgroundBarPlugin: Plugin<"bar", {
  colour?: (index: number) => string,
  maxBarThickness?: number,
  borderSkipped?: boolean
}> = {
  id: "backgroundBar",
  beforeDatasetsDraw(chart, args, opts) {
    const {
      data, ctx, chartArea: {
        top, width, height
      }, scales: { x }
    } = chart;

    if (!data.labels) {
      return;
    }

    ctx.save();

    // Segment width is the width of the chart divided by the number of actual values/labels.
    const segment = width / data.labels.length;

    for (let index = 0; index < data.labels?.length; index++) {
      // Get the width of the current bar (segment width multiplied by the bar & category percentages. Or use the defaults for each (90% and 80% respectively))
      let barWidth = segment * (data.datasets[ index ]?.barPercentage || 0.9) * (data.datasets[ index ]?.categoryPercentage || 0.8);

      // We can override this thickness using a passed in option
      if (opts.maxBarThickness && (barWidth > opts.maxBarThickness)) {
        barWidth = opts.maxBarThickness;
      }

      ctx.fillStyle = opts.colour ? opts.colour(index) : "#F8F9FC";
      ctx.beginPath();

      // Draw a rounded rectange
      ctx.roundRect(
        x.getPixelForValue(index) - barWidth / 2, // Get the centre position for this bar
        top - 4, // Offset -4 to ensure background always sits above the actual value
        barWidth, // width of this bar
        height + 3, // Add the offset, but not all the way to account for pixel line at the bottom of a chart
        !opts.borderSkipped ? [ 5 ] : [
          5,
          5,
          0,
          0
        ] // borderRadius
      );
      ctx.fill();
    }
  }
};