import { useTypedMutation } from "@roda/graphql/urql";

import { Subgoal } from "~/fragments/index";

import type { MutationGenqlSelection } from "@roda/graphql/genql";

type Params = NonNullable<MutationGenqlSelection["updateSubgoal"]>["__args"];

export const useUpdateSubgoal = () => {
  return useTypedMutation((vars: Params) => ({
    updateSubgoal: {
      __args: vars,
      ...Subgoal
    }
  }));
};