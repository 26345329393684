import { Input } from "~/components/form";
import { Icon } from "~/components/Icon";

interface SearchBartProps {
  setSearchTerm: (searchTerm: string) => void;
  searchTerm: string;
}
export const SearchBar: React.FC<SearchBartProps> = ({ searchTerm, setSearchTerm }) => {
  return (
    <Input
      className="w-full ring-0 ring-transparent pl-7"
      value={searchTerm}
      onChange={e => setSearchTerm(e.target.value)}
      placeholder="search for a metric..."
      iconComponent={<Icon.Search className="w-4 text-brand-cold-metal-400" />}
      iconPosition="left"
    />
  );
};