export function deduplicateByName<Obj extends object & {name: string}>(arr: Obj[]): Obj[] {
  const seenNames = new Set<string>();

  return arr.reduce((accumulator, current) => {
    if (!seenNames.has(current.name)) {
      seenNames.add(current.name);
      accumulator.push(current);
    }

    return accumulator;
  }, [] as Obj[]);
}