import { useTypedMutation } from "@roda/graphql/urql";

import {
  FlywheelGoal,
  FlywheelGoalUpdate,
  Subgoal
} from "~/fragments/index";

import type { MutationGenqlSelection } from "@roda/graphql/genql";

type Params = NonNullable<MutationGenqlSelection["createFlywheelGoalAndSubgoals"]>["__args"];

export const useCreateFlywheelGoalAndSubgoals = () => {
  return useTypedMutation((vars: Params) => ({
    createFlywheelGoalAndSubgoals: {
      __args: vars,
      ...FlywheelGoal,
      subgoals: {
        ...Subgoal,
        updates: FlywheelGoalUpdate
      }
    }

  }));
};