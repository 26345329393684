import { zodResolver } from "@hookform/resolvers/zod";
import { Currency } from "@roda/shared/types";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

import { ConfirmationPopup } from "~/components/ConfirmationPopup";
import {
  Input,
  TextArea
} from "~/components/form";
import { MetricCheckInSchema } from "~/components/form/formSchemas";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import type { GetFlywheelMetricData } from "~/hooks/flywheel/use-get-flywheel";
import { useEditExistingMetricUpdate } from "~/hooks/metric-update/use-edit-existing-metric-update";
import { useError } from "~/hooks/useError";
import { useIsMobile } from "~/hooks/useIsMobile";
import dayjs from "~/utils/dayjs";
import { getUnitSymbol } from "~/utils/getUnitSymbol";
import { getWeekNumber } from "~/utils/getWeekNumber";
import { cleanNumberInput } from "~/utils/validation/cleanNumberInput";
import { trimTrailingDots } from "~/utils/validation/trimTrailingDots";

import type { z } from "zod";

interface MetricEditCheckInPopupProps {
  isOpen: boolean;
  onClose: () => void;
  metric: GetFlywheelMetricData;
  update: NonNullable<GetFlywheelMetricData["metricUpdates"]>[0] | null;
}

/**
 * Slightly different from `MetricCheckInPopup` in that its for updating an
 * existing checked in metric.
 */
export const MetricEditExistingCheckInPopup: React.FC<MetricEditCheckInPopupProps> = ({
  isOpen, metric, onClose, update = null
}) => {
  const [ editUpdateRes, editUpdateReq ] = useEditExistingMetricUpdate();
  const { flywheel, flywheelStartWeek } = useSelectedFlywheel();
  const { assertGraphQLSuccess, handleRodaError } = useError();
  const metricUnitSymbol = getUnitSymbol(metric?.unitTypeLabel, flywheel?.currency as Currency || Currency.GBP);
  const isMobile = useIsMobile();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm<z.infer<typeof MetricCheckInSchema>>({
    resolver: zodResolver(MetricCheckInSchema),
    shouldFocusError: false,
    defaultValues: {
      value: update?.value,
      notes: update?.notes
    }
  });

  // Submit handler
  const onSubmit = handleSubmit(fields => {
    if (update) {
      editUpdateReq({
        metricUpdateId: +update.id,
        value: trimTrailingDots(fields.value), // trim dots
        notes: fields.notes || null
      })
        .then(res => {
          assertGraphQLSuccess(res);
          toast.success("Updated successfully!", { position: isMobile ? "top-center" : "top-right" });

          reset({
            value: fields.value ?? fields.value !== update.value ? fields.value : update.value,
            notes: fields.notes ?? fields.notes !== update.notes ? fields.notes : update.notes
          }); // reset form
          onClose(); // close modal
        })
        .catch(e => {
          handleRodaError(e, "An error occurred! Try again later!");
        });
    }
  });

  // Cancel handler, reset form and close modal
  const handleCancel = () => {
    reset();
    onClose();
  };

  const weekNumber = getWeekNumber({
    flywheelStartWeek,
    date: update?.startDate
  });

  return (
    <ConfirmationPopup
      isOpen={isOpen}
      title={metric.name}
      subtitle={`${update ? "Update" : "Check in for"} W.${weekNumber} ${dayjs(update?.startDate).format("MMMM DD")}`}
      onContinue={onSubmit}
      continueText={update ? "Update" : "Save"}
      cancelText="Cancel"
      onCancel={handleCancel}
      loading={editUpdateRes.fetching}
      continueDisabled={editUpdateRes.fetching}
    >
      <div>
        <form
          onSubmit={onSubmit}
        >
          <div className="flex flex-col gap-y-3">

            <Input
              {...register("value", {
                onChange: e => {
                  const cleanedValue = cleanNumberInput(e.target.value);

                  setValue("value", cleanedValue);
                }
              })}
              disabled={editUpdateRes.fetching}
              label={metric.unitName}
              min={0.01}
              autoComplete="off"
              autoCorrect="off"
              inputMode="numeric"
              hasError={!!errors.value}
              errorMessage={errors.value?.message}
              iconPosition="left"
              iconComponent={<p>{metricUnitSymbol}</p>}
            />

            <TextArea
              key={`notes-${metric.id}`}
              {...register("notes")}
              disabled={editUpdateRes.fetching}
              label="Any notes to add?"
              placeholder="Please insert any comments you would like to share on this week's progress"
              name="notes"
              autoComplete="off"
              autoCorrect="off"
              inputMode="text"
              hasError={!!errors.notes}
              errorMessage={errors.notes?.message}
            />
          </div>

        </form>
      </div>
    </ConfirmationPopup>
  );
};