import { zodResolver } from "@hookform/resolvers/zod";
import { enumCurrency } from "@roda/graphql/genql";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

import CompanyImg from "~/assets/illustrations/company.png";
import { Button } from "~/components/Button";
import {
  Input,
  SelectInput
} from "~/components/form";
import type { OnboardingContentStepProps } from "~/components/onboarding/OnboardingContent";
import { OnboardingContentWrapper } from "~/components/onboarding/OnboardingContentWrapper";
import { Spacer } from "~/components/Spacer";
import { Loading } from "~/components/Spinner";
import { useAuth } from "~/contexts/AuthContext";
import {
  useOnboardingDispatch,
  useOnboardingState
} from "~/contexts/OnboardingContext/OnboardingContext";
import { useCurrentUser } from "~/contexts/UserContext";
import {
  useCreateCompany,
  useUpdateCompany
} from "~/hooks/company";
import { useListIndustries } from "~/hooks/industry/use-list-industries";
import { useError } from "~/hooks/useError";
import { useIsMobile } from "~/hooks/useIsMobile";
import { getCurrencyIcon } from "~/utils/getCurrencyIcon";

import { CompanyDetailsSchema } from "./formSchemas";

import type { Currency } from "@roda/graphql/genql";
import type { z } from "zod";

export const CompanyDetails: React.FC<OnboardingContentStepProps> = ({
  onNextStep, changeStep, onBack
}) => {
  const { company } = useOnboardingState();
  const { authenticated } = useAuth();
  const { user: currentUser, refetch: refetchCurrentUser } = useCurrentUser();
  const dispatch = useOnboardingDispatch();
  const [ createCompanyRes, createCompanyReq ] = useCreateCompany();
  const [ updateCompanyRes, updateCompanyReq ] = useUpdateCompany();
  const { handleRodaError } = useError();
  const isMobile = useIsMobile();

  // Fetch all of the company flywheels
  const [ { data: industries } ] = useListIndustries({
    pause: !authenticated,
    requestPolicy: "network-only"
  });

  useEffect(() => {
    if (createCompanyRes.error) {
      handleRodaError(createCompanyRes.error, "Failed to create organisation");
    }

    if (updateCompanyRes.error) {
      handleRodaError(updateCompanyRes.error, "Failed to update organisation");
    }

    // If user has been updated move to the next step
    if (createCompanyRes.data || updateCompanyRes.data) {
      // Refetch current user so that we have updated info about company and user
      refetchCurrentUser();
      onNextStep();
    }
  }, [
    onNextStep,
    createCompanyRes.data,
    createCompanyRes.error,
    updateCompanyRes.error,
    updateCompanyRes.data,
    handleRodaError,
    refetchCurrentUser
  ]);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    formState: { errors }
  } = useForm<z.infer<typeof CompanyDetailsSchema>>({
    resolver: zodResolver(CompanyDetailsSchema),
    defaultValues: {
      name: company?.name,
      industryId: company?.industryId ?? undefined,
      currency: company?.currency ?? undefined
    },
    shouldFocusError: false
  });

  // Submit handler
  const onSubmit = handleSubmit(fields => {
    // Update organisation details in state
    dispatch({
      type: "UPDATE_COMPANY",
      company: {
        id: currentUser?.companyId || null,
        name: fields.name,
        industryId: fields.industryId,
        currency: fields.currency
      }
    });

    // If company already exists then update
    if (currentUser?.companyId) {
      updateCompanyReq({
        name: fields.name,
        industryId: fields.industryId,
        companyId: currentUser?.companyId
      });
    } else {
      // create company
      createCompanyReq({
        name: fields.name,
        industryId: fields.industryId
      });
    }
  });

  return (
    <OnboardingContentWrapper
      changeStep={changeStep}
      rightContent={(
        <img
          src={CompanyImg}
          className="absolute inset-y-0 top-14 object-contain desktop:max-w-[min(35vmax,800px)] desktop:absolute-center w-full h-full"
        />
      )}
      leftContent={industries?.listIndustries ? (
        <div className="w-full flex flex-col flex-1">
          <form
            onSubmit={onSubmit}
            className="flex flex-col flex-1"
          >
            <div className="flex flex-col flex-1 gap-y-5">
              <Input
                {...register("name")}
                label="Your organisation name"
                name="name"
                placeholder="Organisation name"
                inputMode="text"
                hasError={!!errors.name}
                errorMessage={errors.name?.message}
              />

              <SelectInput
                value={watch("currency")}
                onValueChange={val => setValue("currency", val)}
                label="Which currency do you use?"
                placeholder="*Pick a currency*"
                options={[
                  enumCurrency.GBP,
                  enumCurrency.EUR,
                  enumCurrency.USD
                ]}
                renderOptionLabel={val => `${getCurrencyIcon(val as Currency)} - ${val}`}
                hasError={!!errors.currency}
                errorMessage={errors.currency?.message}
                clearFormError={() => clearErrors("currency")}
              />

              <SelectInput
                value={watch("industryId")?.toString()}
                onValueChange={val => setValue("industryId", +val)}
                label="How would you describe your organisation?"
                placeholder="*Pick an option*"
                options={industries.listIndustries.map(e => e.id.toString()) ?? []}
                // Render the Name of the available industries
                renderOptionLabel={val => industries?.listIndustries?.find(i => +i.id === +val)?.name || ""}
                hasError={!!errors.industryId}
                errorMessage={errors.industryId?.message}
                clearFormError={() => clearErrors("industryId")}
              />

              {isMobile && (<Spacer />)}

              <div className={`flex gap-x-3 self-start pb-5 ${isMobile ? "flex-col w-full gap-3" : "flex-row mt-5"}`}>
                {onBack && (
                  <Button
                    title="Back"
                    onClick={onBack}
                    className="bg-brand-cold-metal-100 hover:contrast-75 w-full text-brand-cold-metal-900"
                  />
                )}

                <Button
                  title="Next"
                  type="submit"
                  className={`px-6 ${isMobile ? "order-first" : ""}`}
                  loading={updateCompanyRes.fetching || createCompanyRes.fetching}
                />
              </div>

            </div>

          </form>
        </div>
      ) : <Loading.FlexCenter />}
    />

  );
};