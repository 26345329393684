import { useEffect } from "react";

// As we are using the ResizeObserver API via a measure hook, we need to
// polyfill it for browsers that don't support it

export const useResizeObserverPolyfill = () => {
  useEffect(() => {
    // Polyfill ResizeObserver if it doesn't exist
    if (window && !("ResizeObserver" in window)) {
      import("@juggle/resize-observer").then(({ ResizeObserver }) => {
        window.ResizeObserver = ResizeObserver;
      });
    }
  }, []);
};